import { Select, SelectProps, Skeleton } from "@chakra-ui/react"
import { useCountries } from "Hooks/use-countries"
import React, { forwardRef } from "react"

export const CountrySelect: React.FC<SelectProps> = forwardRef((props, ref) => {
  const { isLoading, countries } = useCountries()

  if (isLoading) return <Skeleton h="40px" />

  return (
    <Select
      ref={ref}
      sx={{
        "&:has(option:checked[value=''])": {
          color: "gray.400",
        },
      }}
      {...props}
    >
      <option value="">Select...</option>
      {countries.map((country) => (
        <option key={country.code} value={country.code}>
          {country.name}
        </option>
      ))}
    </Select>
  )
})
