import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Center,
  Checkbox,
  CloseButton,
  FormControl,
  FormLabel,
  Select,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { HOUR_OF_DAY_OPTIONS, TITLE_BY_DAY } from "Constants/time"
import {
  Card,
  CardBody,
  CardGroup,
  LegacyCardHeader,
} from "Shared/components/Card/Card"
import { Option } from "Types"
import { memoizeOnce } from "Utilities/memoization"
import { friendlyTimezone } from "Utilities/timezone"
import jstz from "jstz"
import { takeRightWhile, takeWhile } from "lodash"
import React from "react"
import { useForm } from "react-hook-form"
import * as Yup from "yup"
import {
  GetPanelistAvailabilityResponse,
  ListTimezonesResponse,
  useGetPanelistAvailability,
  useListTimezones,
  useUpdatePanelistAvailability,
} from "~/api/generated/usabilityhub-components"

const UpdatePanelistAvailabilityFormSchema = Yup.object({
  timezone: Yup.string().defined().nullable(),
  start_time: Yup.string().required(),
  end_time: Yup.string().required(),
  sunday: Yup.boolean().defined().required(),
  monday: Yup.boolean().defined().required(),
  tuesday: Yup.boolean().defined().required(),
  wednesday: Yup.boolean().defined().required(),
  thursday: Yup.boolean().defined().required(),
  friday: Yup.boolean().defined().required(),
  saturday: Yup.boolean().defined().required(),
})

type FormValues = Yup.InferType<typeof UpdatePanelistAvailabilityFormSchema>

function isTimezoneSupported(
  options: ReadonlyArray<Option<string>>,
  timezone: string
): boolean {
  return options.find((option) => option.value === timezone) != null
}

const getStartTimeOptions = memoizeOnce((endTime: string) =>
  takeWhile(HOUR_OF_DAY_OPTIONS, ({ value }) => value < endTime)
)

const getEndTimeOptions = memoizeOnce((startTime: string) =>
  takeRightWhile(HOUR_OF_DAY_OPTIONS, ({ value }) => value > startTime)
)

interface Props {
  lastDismissed: string | null
  handleDismissTimezoneRecommendation: (recommendedTimezone: string) => void
}

export const PanelistAvailability: React.FC<React.PropsWithChildren<Props>> = ({
  lastDismissed,
  handleDismissTimezoneRecommendation,
}) => {
  const { data: timezoneData } = useListTimezones({})
  const { data: panelistAvailability, isError } = useGetPanelistAvailability({})

  if (isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>
          There was an error loading availability information. Please try
          refreshing the page.
        </AlertDescription>
      </Alert>
    )
  }

  if (!timezoneData || !panelistAvailability) {
    return (
      <Center minH="100px">
        <Spinner />
      </Center>
    )
  }

  return (
    <PanelistAvailabilityForm
      timezoneData={timezoneData}
      serverValues={panelistAvailability}
      lastDismissed={lastDismissed}
      handleDismissTimezoneRecommendation={handleDismissTimezoneRecommendation}
    />
  )
}

type PanelistAvailabilityFormProps = {
  lastDismissed: string | null
  handleDismissTimezoneRecommendation: (recommendedTimezone: string) => void
  timezoneData: ListTimezonesResponse
  serverValues: GetPanelistAvailabilityResponse
}

const PanelistAvailabilityForm: React.FC<PanelistAvailabilityFormProps> = ({
  lastDismissed,
  handleDismissTimezoneRecommendation,
  timezoneData,
  serverValues,
}) => {
  const toast = useToast()
  const recommendedTimezone: string = jstz.determine().name()

  const { mutate: updateAvailability, isLoading } =
    useUpdatePanelistAvailability({
      onSuccess: () => {
        toast({
          status: "success",
          title: "Availability updated",
        })
      },
      onError: () => {
        toast({
          status: "error",
          title: "Unable to update availability",
        })
      },
    })

  const { register, watch, handleSubmit, setValue } = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(UpdatePanelistAvailabilityFormSchema),
    defaultValues: serverValues,
  })

  const onSubmit = async (values: FormValues) => {
    updateAvailability({
      body: values,
    })
  }

  const selectedTimezone = watch("timezone")

  const showTimezoneRecommendation =
    // We found a recommendation...
    recommendedTimezone &&
    // ...that recommendation has not been dismissed by the user...
    recommendedTimezone !== lastDismissed &&
    // ...it is not the currently selected timezone...
    selectedTimezone !== recommendedTimezone &&
    // ...and we actually support this timezone.
    isTimezoneSupported(timezoneData.timezones, recommendedTimezone)

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardGroup>
        <Card>
          <LegacyCardHeader size="lg">
            When would you like to be notified?
          </LegacyCardHeader>
          <CardBody>
            <FormControl isDisabled={isLoading}>
              <FormLabel htmlFor="timezone">Timezone</FormLabel>
              <Select {...register("timezone")}>
                {timezoneData.timezones.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
              {showTimezoneRecommendation && (
                <Alert status="info" mt={2}>
                  <AlertIcon />
                  <AlertTitle fontSize="md">
                    Are you in {friendlyTimezone(recommendedTimezone)}?
                  </AlertTitle>
                  <AlertDescription>
                    <Stack direction="row" spacing={2} align="center">
                      <Button
                        size="sm"
                        onClick={() =>
                          setValue("timezone", recommendedTimezone)
                        }
                        colorScheme="brand.primary"
                      >
                        Yes, update timezone
                      </Button>
                      <CloseButton
                        onClick={() =>
                          handleDismissTimezoneRecommendation(
                            recommendedTimezone
                          )
                        }
                      />
                    </Stack>
                  </AlertDescription>
                </Alert>
              )}
            </FormControl>
            <FormControl isDisabled={isLoading}>
              <FormLabel>Availability</FormLabel>
              <Stack direction="row" align="baseline">
                <Select {...register("start_time")}>
                  {getStartTimeOptions(serverValues.end_time).map(
                    ({ label, value }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    )
                  )}
                </Select>
                <Text>to</Text>
                <Select {...register("end_time")}>
                  {getEndTimeOptions(watch("start_time")).map(
                    ({ label, value }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    )
                  )}
                </Select>
              </Stack>
              <Stack spacing={2} mt={4}>
                {Object.entries(TITLE_BY_DAY).map(([day, title]) => {
                  return (
                    <Checkbox
                      key={day}
                      variant="mdWithSmFont"
                      isDisabled={isLoading}
                      // Object.entries type is always just `string` for keys
                      {...register(day as keyof FormValues)}
                    >
                      {title}
                    </Checkbox>
                  )
                })}
              </Stack>
            </FormControl>
            <Button
              type="submit"
              loadingText="Saving"
              isLoading={isLoading}
              alignSelf="flex-start"
              colorScheme="brand.primary"
            >
              Save
            </Button>
          </CardBody>
        </Card>
      </CardGroup>
    </form>
  )
}
