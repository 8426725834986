import { Box, ModalBody } from "@chakra-ui/react"
import React, { PropsWithChildren, ReactNode } from "react"
import { DisplayModal } from "./DisplayModal"

type Props = PropsWithChildren<{
  controls: ReactNode
}>

export const SinglePaneContent: React.FC<Props> = ({ children, controls }) => (
  <ModalBody display="flex" flexDirection="column" h="full" p={0}>
    <Box
      pos="relative"
      flexGrow={1}
      minH={0}
      maxH={[
        // Screen height - padding - commands
        "calc(100dvh - 40px - 56px)",
        // Screen height - padding - commands
        "calc(100dvh - 62px - 48px)",
        // Image height - padding - commands
        "calc(100dvh - 48px - 32px)",
      ]}
      overflowY="auto"
    >
      {children}
    </Box>

    {controls && (
      <DisplayModal.Controls pt={3} ps={[6, 0]} pe={[6, 0]}>
        {controls}
      </DisplayModal.Controls>
    )}
  </ModalBody>
)
