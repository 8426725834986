import { alertAnatomy } from "@chakra-ui/anatomy"
import { AlertProps, createMultiStyleConfigHelpers } from "@chakra-ui/react"
import _ from "lodash"

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys)

const baseStyle = definePartsStyle((props: AlertProps) => {
  const { status, colorScheme, id } = props

  const alertBase = {
    icon: {
      color: status ? `alert.${status}.icon` : `${colorScheme}.500`,
      gridArea: "icon",
      marginRight: 0,
      width: 6,
      height: 6,
      visibility: status ? "hidden" : "visible",
    },
    title: {
      color: status ? `alert.${status}.text` : "text.default",
      fontWeight: "medium",
      fontSize: "md",
      gridArea: "title",
      marginBottom: 2,
    },
    description: {
      gridArea: "content",
    },
    container: {
      background: status ? `alert.${status}.background` : `${colorScheme}.100`,
      color: status ? `alert.${status}.text` : "text.default",
      fontWeight: "normal",
      fontSize: "sm",
      columnGap: 4,
      rowGap: 0,
      paddingTop: 4,
      paddingBottom: 4,
      fontFamily: "body",
      alignItems: "flex-start",
      borderRadius: "base",
      display: "grid",
      gridTemplateAreas: "'icon title' 'icon content'",
      gridTemplateColumns: "24px auto",
      // TODO we need to find a better way to deal with <Button variant="link">
      // inside Alerts. For now you need to wrap it inside a <Text> or <li> for
      // the styles to apply (there are instances of actual buttony buttons
      // inside Alerts and we don't want to break those by doing "& .chakra-button")
      "& .chakra-link, & a, & .chakra-text .chakra-button, & li .chakra-button":
        {
          fontSize: "inherit",
          color: status ? `alert.${status}.icon` : `${colorScheme}.500`,
          textDecoration: "none",
          _hover: {
            color: status ? `alert.${status}.hover` : `${colorScheme}.700`,
            textDecoration: "underline",
          },
        },
      // welcome to the jank :tm:
      // we can't customise icons at a global level in chakra,
      // so I'm going to force it with some janky old school css.
      // Basically display:inherit means it won't display
      // chakra's icon, and in the psuedo after element, we put the
      // icon we actually want. If the icon doesn't exist, it displays nothing
      "&::before": {
        gridArea: "icon",
        display: "block",
        visibility: "visible",
        width: 6,
        height: 6,
        content: '""',
        background: status ? `url('/icons/alerts/${status}.png')` : "",
        backgroundSize: "contain",
      },
    },
  }

  // toasts are alerts in disguise, so we'll take the alert base and override with
  // any toast specific styles. For defaults like position or isClosable, see toasts.ts
  const toastBase =
    id?.startsWith("toast") &&
    _.merge(alertBase, {
      icon: {
        color: "toast.icon",
        width: 5,
        height: 5,
      },
      title: {
        color: "toast.text",
        lineHeight: 5,
        marginBottom: 0,
      },
      description: {
        marginTop: 1,
        lineHeight: 5,
      },
      container: {
        minWidth: "356px",
        paddingTop: 3,
        paddingBottom: 3,
        paddingRight: 8,
        columnGap: 3,
        background: status ? `toast.${status}` : `${colorScheme}.900`,
        color: "toast.text",
        // welcome to the jank pt 2 :tm: See above for explanation
        "&::before": {
          // margin to match line height of text
          width: 5,
          height: 5,
          background: status ? `url('/icons/toasts/${status}.png')` : "",
        },
        "& > div:last-child:not(button)": {
          paddingRight: 4,
        },
      },
    })

  return {
    ...alertBase,
    ...toastBase,
  }
})

const alertWithButton = {
  container: {
    gridTemplateAreas: {
      base: "'icon title' 'icon content' 'icon button'",
      md: "'icon title button' 'icon content button'",
    },
    gridTemplateColumns: {
      base: "24px auto",
      md: "24px auto auto",
    },
    "& button": {
      gridArea: "button",
      alignSelf: "center",
      mt: { base: 3, md: 0 },
    },
  },
}

const alertWithCustomStatusIcon = {
  container: {
    "&::before": {
      // Emptying the content removes the psuedoelement
      content: "none",
    },
  },
  icon: {
    // show the custom icon instead of default status icon
    visibility: "visible",
  },
}

export const Alert = defineMultiStyleConfig({
  baseStyle,
  variants: {
    withButton: alertWithButton,
    withCustomStatusIcon: alertWithCustomStatusIcon,
  },
})
