export type SelectValue = any

export const PATH_SEPARATOR = "." as const

export const SEARCH_PATH = "__search" as const

type PathSeparator = typeof PATH_SEPARATOR

export type SelectPathComponent<T extends string> =
  T extends `${infer Head}${infer Tail}`
    ? Head extends PathSeparator
      ? never
      : `${Head}${SelectPathComponent<Tail>}`
    : T extends PathSeparator
      ? never
      : T

export type SelectPath = string | typeof SEARCH_PATH

export const SELECT_SIZES = ["default", "compact"] as const

export type SelectSize = (typeof SELECT_SIZES)[number]

export type SelectOption<T extends SelectValue> = {
  type: "option"
  id: string
  value: T
  path: SelectPath
  isDisabled?: boolean
}

export type SelectGroup = {
  type: "group"
  id: string
  label: string
  path: SelectPath
  isDisabled?: boolean
}

export type SelectItem<T extends SelectValue> = SelectOption<T> | SelectGroup

export type SearchFunction<T extends SelectValue> = (
  query: string,
  item: SelectItem<T>
) => boolean

export type GroupSelectionState = "none" | "some" | "all"
