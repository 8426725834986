import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Text,
  useToast,
} from "@chakra-ui/react"
import {
  OptionWithHelper,
  SelectWithOptionHelper,
} from "Shared/components/SelectWithOptionHelper/SelectWithOptionHelper"
import { ManagedPromiseCancelledError } from "Shared/helpers/createManagedPromise"
import { useDebounceWhileLoading } from "Shared/hooks/useDebounceWhileLoading"
import { isEqual, map } from "lodash"
import React, { useState } from "react"
import { useDemographicProfileUpdate } from "~/api/generated/usabilityhub-components"
import { DemographicAttributeOption } from "~/api/generated/usabilityhubSchemas"

interface DemographicAttributeFieldProps {
  id: string
  label: string
  helpText: string | null
  isMulti: boolean
  required: boolean
  options: DemographicAttributeOption[]
  demographicAttributeId: number
  allInitialSelectedOptions: number[]
  markAnswered: (attributeId: number) => void
  markUnanswered: (attributeId: number) => void
}

export const DemographicAttributeField: React.FC<
  React.PropsWithChildren<DemographicAttributeFieldProps>
> = ({
  id,
  label,
  helpText,
  required,
  isMulti,
  options,
  demographicAttributeId,
  allInitialSelectedOptions,
  markAnswered,
  markUnanswered,
}) => {
  const toast = useToast()
  const { mutateAsync: mutateDemographicProfile, isLoading } =
    useDemographicProfileUpdate({
      onSuccess: (_, variables) => {
        const {
          demographic_attribute_id,
          selected_demographic_attribute_option_ids,
        } = variables.body

        if (selected_demographic_attribute_option_ids.length > 0) {
          markAnswered(demographic_attribute_id)
        } else {
          markUnanswered(demographic_attribute_id)
        }
      },
      onError: () => {
        toast({
          title: `Couldn${"\u2019"}t update '${label}'. Please refresh the page and try again.`,
          duration: null,
          status: "error",
        })
      },
    })

  // We only want a single call to the API at any given time.
  const updateDemographicProfile = useDebounceWhileLoading(
    mutateDemographicProfile,
    { dependencies: [] }
  )

  const optionsMapped: OptionWithHelper[] = options.map(
    ({ value, id, profile_helper }) => {
      return { value: `${id}`, label: value, helper: profile_helper }
    }
  )
  const singleSelectOptions = options.filter(
    (o) => o.is_none_of_above || o.is_prefer_not_to_say
  )
  const [errorMessage, setErrorMessage] = useState<null | string>(null)
  const [selectedOptions, setSelectedOptions] = useState<OptionWithHelper[]>(
    optionsMapped.filter(({ value }) =>
      allInitialSelectedOptions.map((id) => `${id}`).includes(value)
    )
  )

  const handleOptionsSelect = (
    optionOrOptions: OptionWithHelper[] | OptionWithHelper
  ) => {
    const options = [optionOrOptions].flat()

    // If nothing has changed, bail out early
    if (isEqual(map(options, "value"), map(selectedOptions, "value"))) {
      return
    }

    setSelectedOptions(options)

    // Persist immediately.
    // - Single-selects need to persist immediately because the change event fires AFTER the menuClose.
    // - When clearing we also want to persist immediately since there is no menuClose event at all.
    // - Multi-selects may not be closed before the user navigates to another page.
    // We're passing the latest options through in this case since otherwise persistSelection will
    // be a stale closure over the previous render's value of selectedOptions.

    const selectedIds: string[] = options
      .filter((o) => o)
      .map(({ value }) => value)

    // These options can only be selected by themselves
    const selectedSingleSelectOptions = singleSelectOptions.filter((option) =>
      selectedIds.includes(`${option.id}`)
    )

    // Validation
    if (selectedIds.length > 1 && selectedSingleSelectOptions.length > 0) {
      setErrorMessage(
        `Can${"\u2019"}t select '${
          selectedSingleSelectOptions[0].value
        }' and other options`
      )
      return
    } else if (required && selectedIds.length === 0) {
      setErrorMessage("required")
      return
    } else {
      setErrorMessage(null)
    }

    updateDemographicProfile({
      body: {
        selected_demographic_attribute_option_ids: selectedIds,
        demographic_attribute_id: demographicAttributeId,
      },
    }).catch((e) => {
      if (!(e instanceof ManagedPromiseCancelledError)) {
        throw e
      }
    })
  }

  return (
    <FormControl isInvalid={!!errorMessage} id={id}>
      <FormLabel>
        <Text color="ds.text.default" textStyle="ds.heading.primary">
          {label}
        </Text>
      </FormLabel>

      {helpText && (
        <FormHelperText
          color="ds.text.default"
          textStyle="ds.paragraph.primary"
        >
          {helpText}
        </FormHelperText>
      )}

      <Box mt={3}>
        <SelectWithOptionHelper
          isMulti={isMulti}
          closeMenuOnSelect={!isMulti}
          blurInputOnSelect={!isMulti}
          selectedOptionStyle={isMulti ? "check" : "color"}
          hideSelectedOptions={false}
          isClearable={!required}
          isDisabled={isLoading && !isMulti} // Disabling will close the menu
          options={optionsMapped}
          value={selectedOptions}
          onChange={handleOptionsSelect}
        />
      </Box>

      {errorMessage !== "required" && (
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      )}
    </FormControl>
  )
}
