import React from "react"

import { DisplayText } from "Components/display-text/display-text"
import { IMPORTANT_DURATION, STANDARD_DURATION } from "Constants/flash"
import { RawState, State } from "Redux/app-store"
import { createInitializer } from "Redux/initialize-state"
import { getNextFlashId } from "Redux/reducers/flash"
import { FlashType } from "Types"

type RawFlash = RawState["flash"]

export default createInitializer<RawState, State, "flash">(
  (flash: RawFlash) => {
    if (flash === null) return null
    return {
      children: <DisplayText text={flash.message} />,
      kind: flash.kind,
      id: getNextFlashId(),
      duration:
        flash.kind === FlashType.Error ? IMPORTANT_DURATION : STANDARD_DURATION,
    }
  }
)
