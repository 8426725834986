// These *must* be kept in sync with `UsabilityTestSection::Type`
enum UsabilityTestSectionType {
  CardSort = "card_sort",
  DesignQuestions = "design_questions",
  FirstClickTest = "first_click_test",
  FiveSecondTest = "five_second_test",
  Information = "information",
  NavigationTest = "navigation_test",
  PreferenceTest = "preference_test",
  PrototypeTask = "prototype_task",
  Questions = "questions",
  TreeTest = "tree_test",
  ExternalStudy = "external_study",
  LiveWebsiteTest = "live_website_test",
}

export default UsabilityTestSectionType
