// Must be kept in sync with our question_type ENUM in Postgres and
// `UsabilityTestSectionQuestion::Type`.
export enum QuestionType {
  CheckboxMultipleChoice = "checkbox_multiple_choice_question",
  LinearScale = "linear_scale_question",
  LongAnswer = "long_answer_question",
  RadioMultipleChoice = "radio_multiple_choice_question",
  Ranking = "ranking_question",
  ShortAnswer = "short_answer_question",
}
