import { Flex } from "@chakra-ui/react"
import { assertNever } from "Components/filter-controls/utils"
import { Button } from "DesignSystem/components"
import { cq } from "Shared/helpers/containerQuerySx"
import { ROUTES } from "UserCrowd/views/routes"
import React, { ComponentProps } from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import { useOnboardingDrawerContext } from "./OnboardingDrawerContextProvider"
import { OnboardingDrawerStepCard } from "./OnboardingDrawerStepCard"
import { BREAKPOINT_MID } from "./constants"

export const OnboardingDrawerSteps: React.FC<{
  isDrawer?: boolean
  practiceTestStatus: "active" | "complete" | "rejected" | "not_required"
  isProfileStepCompleted: boolean
  stepsCompletedCount: number
  handleAllDoneClick: () => void
}> = ({
  isDrawer,
  practiceTestStatus,
  isProfileStepCompleted,
  handleAllDoneClick,
  stepsCompletedCount,
}) => {
  const { totalSteps } = useOnboardingDrawerContext()

  return (
    <Flex
      flexDirection="column"
      rounded="12px"
      bg="ds.background.neutral.resting"
      gap={6}
      mx={isDrawer ? 3 : 2}
      p={isDrawer ? 3 : 2}
      sx={isDrawer ? undefined : cq(BREAKPOINT_MID, { gap: 3, mx: 3, p: 3 })}
    >
      <Flex flexDirection="column" gap={isDrawer ? 3 : 2}>
        <OnboardingDrawerStepCard title="Join UserCrowd" status="complete" />
        {practiceTestStatus !== "not_required" && (
          <OnboardingDrawerStepCard
            title="Complete practice test"
            description={practiceTestStatusText(practiceTestStatus)}
            action={
              <Button
                size="compact"
                as={ReactRouterLink}
                to={ROUTES.DASHBOARD.START_PRACTICE_TEST.path}
              >
                Take now
              </Button>
            }
            showBadge
            status={practiceTestStatus}
          />
        )}
        <OnboardingDrawerStepCard
          title="Complete your profile"
          description="A strong profile increases your chance of being chosen for studies."
          action={
            <Button
              size="compact"
              as={ReactRouterLink}
              to={ROUTES.SETTINGS.PROFILE.path}
            >
              Go to profile
            </Button>
          }
          status={isProfileStepCompleted ? "complete" : "active"}
        />
      </Flex>
      {stepsCompletedCount === totalSteps && (
        <Button variant="primary" onClick={handleAllDoneClick}>
          All done!
        </Button>
      )}
    </Flex>
  )
}

function practiceTestStatusText(
  practiceTestStatus: ComponentProps<
    typeof OnboardingDrawerSteps
  >["practiceTestStatus"]
) {
  switch (practiceTestStatus) {
    case "active":
      return "This must be completed in order to receive real studies. Your response will be reviewed by the UserCrowd Team."
    case "rejected":
      return "Unfortunately your practice test response didn't meet our response quality standards so your account has not been approved."
    case "complete":
    case "not_required":
      return null
    default:
      return assertNever(practiceTestStatus)
  }
}
