import { useFeatureFlag } from "Hooks/use-feature-flag"
import React, { useEffect } from "react"
import { createContext, useState } from "react"
import {
  CurrentPanelistInfoResponse,
  useCurrentPanelistInfo,
  usePanelistStats,
} from "~/api/generated/usabilityhub-components"
import { TOTAL_STEPS } from "./constants"

type OnboardingDrawerContextType = {
  showDrawer: boolean
  practiceTestStatus: "active" | "complete" | "rejected" | "not_required"
  isProfileStepCompleted: boolean
  stepsCompletedCount: number
  totalSteps: number
  handleAllDoneClick: () => void
}

const OnboardingDrawerContext =
  createContext<OnboardingDrawerContextType | null>(null)

export const useOnboardingDrawerContext = () => {
  const context = React.useContext(OnboardingDrawerContext)

  if (context === null) {
    throw new Error(
      "useOnboardingDrawerContext must be used inside the OnboardingDrawerContextProvider"
    )
  }

  return context
}

const PRACTICE_TEST_STATUS_MAP: {
  [s in CurrentPanelistInfoResponse["practice_test_state"]]:
    | "active"
    | "complete"
    | "rejected"
    | "not_required"
} = {
  not_required: "not_required",
  not_started: "active",
  in_progress: "active",
  // We mark the task as "complete" even if the panelist's response is still pending manual review
  awaiting_review: "complete",
  passed: "complete",
  auto_review_fail: "rejected",
  manual_qa_fail: "rejected",
}

export const OnboardingDrawerContextProvider: React.FC<
  React.PropsWithChildren
> = ({ children }) => {
  const isPracticeTestFlagEnabled = useFeatureFlag("practice_tests")
  const { data: user } = useCurrentPanelistInfo({})
  const { data: stats } = usePanelistStats({})

  const practiceTestStatus =
    PRACTICE_TEST_STATUS_MAP[user?.practice_test_state ?? "not_started"]
  const isProfileStepCompleted = stats
    ? stats.profile_completeness >= 100
    : false

  const stepsCompletedCount = [
    true,
    practiceTestStatus === "complete",
    isProfileStepCompleted,
  ].filter(Boolean).length

  const totalSteps =
    TOTAL_STEPS - (practiceTestStatus === "not_required" ? 1 : 0)

  const [isDismissed, setIsDismissed] = useState(true)

  // Once both the user and stats queries have finished, open the drawer if
  // onboarding is not fully complete.
  useEffect(() => {
    if (!user || !stats) return

    if (stepsCompletedCount < totalSteps) {
      setIsDismissed(false)
    }
  }, [user, stats])

  const handleAllDoneClick = () => setIsDismissed(true)
  const showDrawer = isPracticeTestFlagEnabled && !isDismissed

  return (
    <OnboardingDrawerContext.Provider
      value={{
        showDrawer,
        practiceTestStatus,
        isProfileStepCompleted,
        stepsCompletedCount,
        totalSteps,
        handleAllDoneClick,
      }}
    >
      {children}
    </OnboardingDrawerContext.Provider>
  )
}
