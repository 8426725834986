import { Center, Flex, Image, Text, useToast } from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { Button, Heading } from "DesignSystem/components"
import { UserCrowdDefaultPageLayout } from "UserCrowd/components/UserCrowdDefaultPageLayout"
import { UserCrowdNavbar } from "UserCrowd/components/UserCrowdNavbar/UserCrowdNavbar"
import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { Navigate } from "react-router"
import {
  useConfirmUserEmail,
  useCurrentPanelistInfo,
  useResendUserEmailConfirmation,
} from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"
import { ROUTES } from "../routes"
import expiredImage from "./link-expired.png"
import verifyImage from "./verify-email.png"

export const VerificationRoute: React.FC = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const { queryKeyFn } = useUsabilityhubContext()

  const invalidateMe = () => {
    const queryKey = queryKeyFn({
      path: "/api/usercrowd/panelist/me",
      operationId: "currentPanelistInfo",
      variables: {},
    })

    queryClient.invalidateQueries(queryKey)
  }

  const { data: user, isLoading, isError } = useCurrentPanelistInfo({})
  const { mutate: resendEmail } = useResendUserEmailConfirmation({
    onSuccess: invalidateMe,
    onError: () => {
      toast({
        title: "Something went wrong",
        description: "Try refreshing the page",
        status: "error",
      })
    },
  })

  // In practice this means the panelist was accepted from the waitlist
  const firstEmailVerification = user?.panelist_state === "accepted"

  const { mutate: confirmEmail } = useConfirmUserEmail({
    onSuccess: () => {
      if (!firstEmailVerification) {
        toast({
          title: "Email updated",
          description: "Your email address has been changed successfully.",
          status: "success",
        })
      }
    },
    onError: () => {
      if (!firstEmailVerification) {
        toast({
          title: "Link expired",
          description:
            "The link has expired, and your email address couldn’t be changed. Please try again.",
          status: "error",
        })
      }
    },
    onSettled: invalidateMe,
  })

  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get("token")

  // If we have a token in the URL let's try use to to verify the user
  useEffect(() => {
    if (!token) return

    confirmEmail({
      body: { token },
    })
  }, [token])

  if (isLoading || isError) return null

  if (!firstEmailVerification) {
    return <Navigate to={ROUTES.DASHBOARD.path} />
  }

  const isTokenExpired = user.email_confirmation_state?.is_expired ?? false

  const handleResend = () => {
    if (!user.email_confirmation_state) {
      toast({
        title: "Something went wrong",
        description: "Try refreshing the page",
        status: "error",
      })
      return
    }

    resendEmail({
      body: {
        user_email_confirmation_id:
          user.email_confirmation_state.confirmation_id,
      },
    })
  }

  return (
    <UserCrowdDefaultPageLayout hasNoVerticalPadding>
      <Helmet title="Email verification" />

      <UserCrowdNavbar variant="onboarding" />

      <Center h="calc(100dvh - 130px)" px={2}>
        <Flex maxW="500px" direction="column" align="center" gap={10}>
          {isTokenExpired ? (
            <VerificationContent
              title="Your invitation link has expired"
              description="Not to worry! Simply resend your invitation email to receive a new link and start earning money with UserCrowd."
              image={expiredImage}
              imageWidth="120px"
            />
          ) : (
            <VerificationContent
              title="Please accept your invitation"
              description="Click the link in your invitation email to accept and start earning with UserCrowd. If you didn’t receive the email, you can resend it."
              image={verifyImage}
              imageWidth="140px"
            />
          )}

          <Button variant="primary" size="emphasized" onClick={handleResend}>
            Resend email
          </Button>
        </Flex>
      </Center>
    </UserCrowdDefaultPageLayout>
  )
}

const VerificationContent: React.FC<{
  title: string
  description: string
  image: string
  imageWidth: string
}> = ({ title, description, image, imageWidth }) => (
  <>
    <Image w={imageWidth} src={image} alt={title} />

    <Flex direction="column" align="center" gap={4}>
      <Heading as="h1" textStyle="ds.display.emphasized" textAlign="center">
        {title}
      </Heading>

      <Text textStyle="ds.paragraph.emphasized" textAlign="center">
        {description}
      </Text>
    </Flex>
  </>
)
