import { createIcon } from "@chakra-ui/react"
import React from "react"

export const DesktopMobileCustomIcon = createIcon({
  displayName: "DesktopMobileCustomIcon",
  viewBox: "0 0 24 25",
  defaultProps: { fill: "none" },
  path: (
    <>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.854 3.278h-3.677c-.527 0-.982 0-1.356.03-.396.032-.789.104-1.167.297a3 3 0 0 0-1.311 1.31c-.193.379-.264.772-.297 1.168-.03.374-.03.829-.03 1.356v9.677c0 .527 0 .982.03 1.357.033.395.104.788.297 1.167a3 3 0 0 0 1.31 1.31c.379.193.772.265 1.168.297.374.03.829.03 1.356.03h3.677c.527 0 .982 0 1.357-.03.395-.032.788-.104 1.167-.296a3 3 0 0 0 1.31-1.311c.193-.379.265-.772.297-1.167.03-.375.03-.83.03-1.357V7.44c0-.527 0-.982-.03-1.356-.032-.396-.104-.789-.296-1.167a3 3 0 0 0-1.311-1.311c-.379-.193-.772-.265-1.167-.297-.375-.03-.83-.03-1.357-.03Zm-5.292 2.109c.05-.026.15-.063.421-.085.284-.024.656-.024 1.233-.024h3.6c.576 0 .949 0 1.232.024.272.022.372.06.422.085a1 1 0 0 1 .437.437c.025.049.063.15.085.421.023.284.024.656.024 1.233v9.803c0 .463-.004.78-.024 1.029-.023.271-.06.372-.085.422a1 1 0 0 1-.437.437c-.05.025-.15.063-.422.085-.283.023-.656.024-1.232.024h-3.6c-.577 0-.95-.001-1.232-.024-.272-.023-.373-.06-.422-.085a1 1 0 0 1-.437-.437c-.026-.05-.063-.15-.085-.422-.02-.249-.024-.566-.024-1.03V7.479c0-.577 0-.95.024-1.233.022-.271.06-.372.085-.421a1 1 0 0 1 .437-.437Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M9.016 5.278H5.177c-.527 0-.982 0-1.356.03-.396.032-.789.104-1.167.297a3 3 0 0 0-1.311 1.31c-.193.379-.264.772-.297 1.168-.03.374-.03.829-.03 1.356v3.677c0 .527 0 .982.03 1.357.032.395.104.788.297 1.167a3 3 0 0 0 1.31 1.31c.379.193.772.265 1.168.297.374.03.829.03 1.356.03h3.839a1 1 0 1 0 0-2h-3.8c-.577 0-.95 0-1.233-.023-.271-.022-.372-.06-.421-.085a1 1 0 0 1-.437-.437c-.026-.05-.063-.15-.085-.422-.024-.284-.024-.656-.024-1.232v-3.6c0-.577 0-.95.024-1.233.022-.271.06-.372.085-.421a1 1 0 0 1 .437-.437c.05-.026.15-.063.421-.085.284-.024.656-.024 1.233-.024h3.8a1 1 0 0 0 0-2ZM5.516 19.278a1 1 0 1 0 0 2h3.5a1 1 0 1 0 0-2h-3.5Z"
      />
      <path
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17.516 16.778a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
      />
    </>
  ),
})
