import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react"
import { Mutation, useMutation } from "@tanstack/react-query"
import React, { useRef } from "react"

import { useRefreshSession } from "~/api/generated/usabilityhub-components"

type Props = {
  onClose: () => void
  mutation: Mutation<unknown, unknown, unknown, unknown>
  isDestructive: boolean
  actionToConfirm: string | null
}

export const ReauthenticationModal: React.FC<Props> = ({
  onClose,
  mutation,
  isDestructive,
  actionToConfirm,
}) => {
  const toast = useToast()
  const passwordRef = useRef<HTMLInputElement | null>(null)
  const hydratedMutation = useMutation(mutation.options)

  const { mutate: refreshSession } = useRefreshSession({
    onSuccess: () => {
      hydratedMutation.mutate(mutation.state.variables)
      onClose()
    },
    onError: () => {
      toast({
        title: "Invalid password. Please try again.",
        status: "error",
      })
    },
  })

  const handleSubmit = () => {
    const password = passwordRef.current?.value ?? ""
    refreshSession({ body: { password } })
  }

  return (
    <Modal isOpen onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay>
        <ModalContent>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              void handleSubmit()
            }}
          >
            <ModalHeader>Confirm your password</ModalHeader>
            <ModalBody>
              <Text fontSize="md" mb={3}>
                Please re-enter your password in order to continue.
              </Text>
              <FormControl isRequired>
                <FormLabel htmlFor="password">Your password</FormLabel>
                <Input
                  ref={passwordRef}
                  type="password"
                  id="password"
                  autoComplete="password"
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose} mr={3}>
                Cancel
              </Button>
              <Button
                type="submit"
                colorScheme={isDestructive ? "red" : "brand.primary"}
              >
                Authenticate
                {actionToConfirm && <> and {actionToConfirm}</>}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
