import { Box } from "@chakra-ui/react"
import React from "react"

interface DisplayTextProps {
  readonly text: string
}

export const DisplayText = ({ text }: DisplayTextProps) => (
  <>
    {text
      .trim()
      .split(/\s*\n\s*/g)
      .map((paragraphText, index) => (
        <Box as="p" mb="1em" lineHeight="130%" key={index} _last={{ mb: 0 }}>
          {paragraphText}
        </Box>
      ))}
  </>
)
