import React from "react"

import { PanelistSupportMailToAnchor as SupportMail } from "Components/anchors/panelist-support-mail-to-anchor"
import { TextList } from "Components/text-list"
import { Language } from "Types"
import { pluralize } from "Utilities/string"

interface Props {
  readonly languages: readonly Language[]
}

export const RestrictedFluenciesMessage = ({ languages }: Props) => {
  const languageNames = languages.map((l) => l.english_name)
  return (
    <>
      <p>
        <TextList items={languageNames} component="strong" />{" "}
        {pluralize(languages.length, "was", "were")} removed by a UserCrowd
        administrator.
      </p>
      <p>
        Please <SupportMail>contact support</SupportMail> if you would like to
        discuss this decision.
      </p>
    </>
  )
}
