import React from "react"

import { getDateTimeString } from "Utilities/date-formats"
import { TimeImpl } from "./time-impl"

interface Props {
  date: Date | string | number
}

export function DateTime({ date }: Props) {
  const d = new Date(date)
  return <TimeImpl date={d}>{getDateTimeString(d)}</TimeImpl>
}
