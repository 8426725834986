import { Box, BoxProps, Flex } from "@chakra-ui/react"
import React, { PropsWithChildren } from "react"

type Props = BoxProps & {
  barColor?: string
}

export const Card: React.FC<PropsWithChildren<Props>> = ({
  barColor,
  ...rest
}) => {
  return (
    <Box
      pos="relative"
      bg="white"
      borderWidth={1}
      borderColor="gray.200"
      rounded="md"
      p={5}
      overflow="hidden"
      _after={
        barColor
          ? {
              content: "''",
              display: "block",
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              width: 1.5,
              bg: barColor,
            }
          : undefined
      }
      {...rest}
    />
  )
}

export const CardList: React.FC<PropsWithChildren> = ({ children }) => {
  return <Flex direction="column">{children}</Flex>
}

export const CardListItem: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      color="text.secondary"
      px={4}
      py={5}
      _notLast={{
        borderBottomWidth: 1,
        borderBottomColor: "gray.200",
      }}
    >
      {children}
    </Box>
  )
}
