import MarkdownIt from "markdown-it"
import React, { FunctionComponent } from "react"

import styles from "./display-markdown-text.module.scss"

interface Props {
  text: string
  allowLinks?: boolean
}

const blockMarkdown = new MarkdownIt("zero")
  .enable(["emphasis", "escape", "list", "newline"])
  .set({ breaks: true })

export const DisplayBlockMarkdownText: React.FC<Props> = ({
  text,
  allowLinks,
}) => {
  if (allowLinks) {
    blockMarkdown.enable(["linkify"])
    blockMarkdown.set({ linkify: true })
  }
  return (
    <div
      className={styles.blockMarkdown}
      // biome-ignore lint/security/noDangerouslySetInnerHtml: legacy code
      dangerouslySetInnerHTML={{
        __html: blockMarkdown.render(text),
      }}
    />
  )
}

const inlineMarkdown = new MarkdownIt("zero").enable(["emphasis", "escape"])

export const DisplayInlineMarkdownText: FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ text }) => (
  <span
    // biome-ignore lint/security/noDangerouslySetInnerHtml: legacy code
    dangerouslySetInnerHTML={{
      __html: inlineMarkdown.renderInline(text),
    }}
  />
)
