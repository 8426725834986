import { useControllableState } from "@chakra-ui/react"
import { useForceUpdate } from "Shared/hooks/useForceUpdate"
import { debounce, deburr, partial, sortBy } from "lodash"
import React, {
  useCallback,
  PropsWithChildren,
  useRef,
  useState,
  useEffect,
  useMemo,
} from "react"
import { Provider, SelectContextType } from "./Provider"
import { SearchFunction, SelectItem, SelectPath, SelectValue } from "./types"
import {
  combineFilters,
  defaultFormatValue,
  defaultSearchFunction,
  parentPath,
  pathStartsWith,
} from "./util"

type SingleSelectProps<T extends SelectValue> = {
  isMulti?: false
  value?: T | null
  onChange?: (value: T | null) => void
}

type MultiSelectProps<T extends SelectValue> = {
  isMulti: true
  value?: T[]
  onChange?: (value: T[]) => void
}

type SelectSearchProps<T extends SelectValue> = {
  isSearchable?: boolean
  searchBy?: SearchFunction<T>
}

type SelectProps<T extends SelectValue> = (
  | SingleSelectProps<T>
  | MultiSelectProps<T>
) &
  Partial<Pick<SelectContextType<T>, "formatValue">> &
  SelectSearchProps<T>

export const Root = <T extends SelectValue>({
  isMulti,
  isSearchable = true,
  searchBy,
  value: valueProp,
  formatValue = defaultFormatValue,
  onChange,
  children,
  ...context
}: PropsWithChildren<SelectProps<T>>) => {
  const [trigger, setTrigger] = useState<HTMLElement | null>(null)

  const [input, setInput] = useState<HTMLInputElement | null>(null)

  const [popup, setPopup] = useState<HTMLElement | null>(null)

  const [path, setPath] = useState<SelectPath>("")

  const [isOpen, setIsOpen] = useState(false)

  const [activeIndex, setActiveIndex] = useState<number>(-1)

  const [isSearching, setIsSearching] = useState(false)

  const [searchResults, setSearchResults] = useState<SelectItem<T>[]>([])

  const valuesById = useRef(new Map<string, T>())

  const getValueById = useCallback(
    (id: string): T => {
      const value = valuesById.current.get(id)
      if (!value) {
        throw new Error(`No value found for id: ${id}`)
      }
      return value
    },
    [valuesById]
  )

  const selectionChanged = useCallback(
    (value: Set<T>) => {
      if (isMulti) {
        onChange?.(Array.from(value))
      } else {
        onChange?.(value.size > 0 ? Array.from(value)[0] : null)
      }
    },
    [isMulti, onChange]
  )

  const selection = useMemo(
    () =>
      isMulti
        ? valueProp
          ? new Set<T>(valueProp)
          : undefined
        : valueProp === null
          ? new Set<T>()
          : valueProp
            ? new Set([valueProp])
            : undefined,
    [isMulti, valueProp]
  )

  const [selected, setSelected] = useControllableState<Set<T>>({
    value: selection,
    defaultValue: new Set(),
    onChange: (v) => {
      selectionChanged(v)
    },
  })

  const isSelected = useCallback((value: T) => selected.has(value), [selected])

  const everything = useRef(new Map<SelectPath, SelectItem<T>[]>())

  const [visibleItems, setVisibleItems] = useState<SelectItem<T>[]>([])

  const updateVisibleItems = useCallback(
    (force = false) => {
      if (!isOpen && !force) return

      setTimeout(() => setVisibleItems(everything.current.get(path) ?? []))
    },
    [path, isOpen]
  )

  const forceUpdate = useForceUpdate(updateVisibleItems)

  const enabledItems = useMemo(
    () =>
      isSearching && !path
        ? searchResults
        : visibleItems.filter((v) => !v.isDisabled),
    [visibleItems, isSearching, searchResults, path]
  )

  const activeItem = useMemo(
    () =>
      (activeIndex > -1 &&
        activeIndex < enabledItems.length &&
        enabledItems[activeIndex]) ||
      null,
    [enabledItems, activeIndex]
  )

  const setActiveItemId = useCallback(
    (id: string) => {
      setActiveIndex(enabledItems.findIndex((v) => v.id === id))
    },
    [enabledItems]
  )

  useEffect(() => {
    updateVisibleItems()
  }, [updateVisibleItems])

  const open = useCallback(() => {
    clearTimeout(closing.current)
    if (isOpen) return
    setIsOpen(true)
    setPath("")
    updateVisibleItems(true)
    setActiveIndex(0)
  }, [updateVisibleItems, isOpen])

  const closing = useRef<ReturnType<typeof setTimeout>>()

  const close = useCallback(() => {
    clearTimeout(closing.current)
    closing.current = setTimeout(() => {
      setIsOpen(false)
      setIsSearching(false)
      setSearchResults([])
      setPath("")
      if (document.activeElement && trigger?.contains(document.activeElement)) {
        ;(document.activeElement as HTMLElement).blur()
      }
    }, 0)
  }, [trigger])

  const cancelClose = useCallback(() => {
    clearTimeout(closing.current)
  }, [])

  const register = useCallback((item: SelectItem<T>, path: SelectPath) => {
    everything.current.set(path, [
      ...(everything.current.get(path) ?? []),
      item,
    ])
    if (item.type === "option") {
      valuesById.current.set(item.id, item.value)
    }
    forceUpdate()
  }, [])

  const deregister = useCallback((id: string, path: SelectPath) => {
    everything.current.set(
      path,
      (everything.current.get(path) ?? []).filter((v) => v.id !== id)
    )
    forceUpdate()
  }, [])

  const descendantOptions = useCallback((path: SelectPath) => {
    return Array.from(everything.current.entries())
      .filter(([p]) => pathStartsWith(path, p))
      .flatMap(([_, values]) => values.filter((v) => v.type === "option"))
  }, [])

  const toggle = useCallback(
    (value: T) => {
      setSelected(() => {
        if (isMulti) {
          const newSet = new Set(selected)
          if (isSelected(value)) {
            const newSet = new Set(selected)
            newSet.delete(value)
            return newSet
          } else {
            return newSet.add(value)
          }
        }

        if (isSelected(value)) {
          return new Set()
        }
        return new Set([value])
      })
    },
    [setSelected, isSelected, isMulti, close, input]
  )

  const groupSelectionState = useCallback(
    (path: SelectPath) => {
      const items = descendantOptions(path)
      const selectedItems = items.filter((v) => isSelected(v.value))
      if (items.length === 0 || selectedItems.length === 0) return "none"
      if (selectedItems.length === items.length) return "all"
      return "some"
    },
    [descendantOptions, isSelected]
  )

  const toggleGroup = useCallback(
    (path: SelectPath) => {
      const items = descendantOptions(path).filter((v) => !v.isDisabled)
      const selectedItems = items.filter((v) => isSelected(v.value))

      if (selectedItems.length < items.length) {
        setSelected((prev) =>
          items.reduce((acc, v) => acc.add(v.value), new Set(prev))
        )
      } else {
        setSelected((prev) => {
          const newSet = new Set(prev)
          items.forEach((v) => newSet.delete(v.value))
          return newSet
        })
      }
    },
    [descendantOptions, isSelected]
  )

  const findSearchResults = useCallback(
    (query: string) => {
      if (!isSearchable) return []

      // Construct a function that takes a SelectItem<T> and returns a boolean.
      // If we don't get one from the searchBy prop, we use a default function,
      // but we need to partially apply the formatValue function to it to get the text.
      const matcher = partial(
        (searchBy ??
          ((query: string, item: SelectItem<T>) =>
            defaultSearchFunction(
              query,
              item,
              formatValue
            ))) as SearchFunction<T>,
        query
      )
      const allItems = Array.from(everything.current.values())
        .flat()
        .filter(combineFilters(matcher, (v) => !v.isDisabled))
      const sorted = sortBy(allItems, (item) =>
        deburr(
          item.type === "group" ? item.label : formatValue(item.value)
        ).toLowerCase()
      ).map(({ id, ...item }) => ({ id: `search:${id}`, ...item }))
      return sorted
    },
    [isSearchable, searchBy]
  )

  const search = useMemo(
    () =>
      debounce((query: string) => {
        const trimmed = deburr(query).trim().toLowerCase()
        if (trimmed && isSearchable) {
          setIsSearching(true)
          setPath("")
          const results = findSearchResults(trimmed)
          setSearchResults(results)
          setActiveIndex(Math.min(results.length - 1, 0))
        } else {
          setIsSearching(false)
          setSearchResults([])
          setActiveIndex(-1)
        }
      }, 100),
    [findSearchResults, isSearchable]
  )

  useEffect(() => {
    if (!input) return
    if (!isMulti && selected.size > 0) {
      input.value = formatValue(Array.from(selected)[0])
    } else {
      input.value = ""
    }
  }, [input, selected])

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const input =
        (e.target as HTMLElement).tagName === "INPUT"
          ? (e.target as HTMLInputElement)
          : null

      switch (e.key) {
        case "ArrowDown":
          setActiveIndex((current) =>
            Math.min(current + 1, enabledItems.length - 1)
          )
          e.preventDefault()
          break
        case "ArrowUp":
          setActiveIndex((current) => Math.max(current - 1, 0))
          e.preventDefault()
          break
        case "Home":
          setActiveIndex(enabledItems.length ? 0 : -1)
          break
        case "End":
          setActiveIndex(enabledItems.length - 1)
          break
        case "Escape":
          close()
          break
        case "Enter":
          if (activeItem?.type === "group") {
            setPath(activeItem.path)
            setActiveIndex(0)
          } else if (activeItem?.type === "option" && !activeItem.isDisabled) {
            toggle(activeItem.value)
          }
          e.stopPropagation()
          e.preventDefault()
          break
        case "ArrowLeft":
          if (input?.selectionStart === 0 && !!path) {
            setPath(parentPath(path))
            setActiveIndex(0)
            e.stopPropagation()
            e.preventDefault()
          }
          break
        case "ArrowRight":
          if (
            input &&
            input.selectionEnd === input.value.length &&
            activeItem?.type === "group"
          ) {
            setPath(activeItem.path)
            setActiveIndex(0)
            e.stopPropagation()
            e.preventDefault()
          }
          break
        case "PageUp":
        case "PageDown": {
          const pageSize = Math.floor((popup?.clientHeight ?? 0) / 32)
          setActiveIndex((current) =>
            Math.max(
              0,
              Math.min(
                current + (e.key === "PageDown" ? 1 : -1) * pageSize,
                enabledItems.length - 1
              )
            )
          )
          e.preventDefault()
          break
        }
        case " ":
          if (!!activeItem && !activeItem.isDisabled && !input?.value) {
            e.preventDefault()
            e.stopPropagation()
            if (activeItem.type === "group") {
              toggleGroup(activeItem.path)
            } else {
              toggle(activeItem.value)
            }
          }
          break
      }
    },
    [enabledItems, input, trigger, path, activeItem, toggle, close, toggleGroup]
  )

  useEffect(() => {
    if (!trigger) return
    if (isOpen) {
      const blurred = (e: FocusEvent) => {
        const el = e.relatedTarget as HTMLElement | null
        if (el && (trigger.contains(el) || popup?.contains(el))) {
          return
        }
        close()
      }
      const handleSearchInput = (e: InputEvent) => {
        if (e.target) {
          search((e.target as HTMLInputElement).value)
        }
      }
      if (input && isSearchable) {
        input.addEventListener("input", handleSearchInput)
      }
      trigger.addEventListener("focusout", blurred)
      trigger.addEventListener("keydown", onKeyDown)
      return () => {
        trigger.removeEventListener("focusout", blurred)
        trigger.removeEventListener("keydown", onKeyDown)
        if (input && isSearchable) {
          input.removeEventListener("input", handleSearchInput)
        }
      }
    }
  }, [isOpen, trigger, popup, close, input, onKeyDown, isSearchable, search])

  return (
    <Provider
      {...context}
      isOpen={isOpen}
      isSearchable={isSearchable}
      isMulti={isMulti}
      isSearching={isSearching}
      searchResults={searchResults}
      selected={selected}
      setSelected={setSelected}
      isSelected={isSelected}
      everything={everything.current}
      activeItem={activeItem}
      formatValue={formatValue}
      toggle={toggle}
      toggleGroup={toggleGroup}
      groupSelectionState={groupSelectionState}
      register={register}
      deregister={deregister}
      trigger={trigger}
      popup={popup}
      input={input}
      setTrigger={setTrigger}
      setInput={setInput}
      setPopup={setPopup}
      path={path}
      onPathChange={setPath}
      open={open}
      close={close}
      cancelClose={cancelClose}
      setActiveItemId={setActiveItemId}
      getValueById={getValueById}
    >
      {children}
    </Provider>
  )
}

Root.displayName = "Select.Root"
