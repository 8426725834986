import { Flex, Icon, Image } from "@chakra-ui/react"
import { InterviewStudyIcon } from "Shared/icons/InterviewStudyIcon"
import { UsabilityTestIcon } from "Shared/icons/UsabilityTestIcon"
import LyssnaCircleIconTealSrc from "Shared/icons/raster/lyssna-circle-icon-teal.png"
import { Link01SolidIcon } from "Shared/icons/untitled-ui/Link01SolidIcon"
import { Users01SolidIcon } from "Shared/icons/untitled-ui/Users01SolidIcon"
import React from "react"

export const UsabilityTestCompositeIcon: React.FC = () => (
  <Flex
    backgroundColor="pink.100"
    width={6}
    height={6}
    align="center"
    justify="center"
    rounded="sm"
  >
    <UsabilityTestIcon boxSize={4} color="#D61F69" />
  </Flex>
)

export const InterviewsStudyCompositeIcon: React.FC = () => (
  <Flex
    backgroundColor="cyan.100"
    width={6}
    height={6}
    align="center"
    justify="center"
    rounded="sm"
  >
    <InterviewStudyIcon boxSize={4} />
  </Flex>
)

// the forwardRef and spread props are needed for use with Chakra tooltips
export const RecruitmentLinkCompositeIcon: React.FC<{
  size?: number
  isRounded?: boolean
}> = React.forwardRef(({ size, isRounded, ...rest }, ref) => (
  <Flex
    w={size ?? 10}
    h={size ?? 10}
    bg="bg.accent.cyan.subtle"
    align="center"
    justify="center"
    rounded={isRounded ? "full" : "md"}
    shrink={0}
  >
    <Icon
      ref={ref}
      as={Link01SolidIcon}
      textColor="icon.accent.cyan"
      boxSize="70%"
      {...rest}
    />
  </Flex>
))

// the forwardRef and spread props are needed for use with Chakra tooltips
export const PanelOrderCompositeIcon: React.FC<{
  size?: number
  isRounded?: boolean
}> = React.forwardRef(({ size, isRounded, ...rest }, ref) => (
  <Flex
    w={size ?? 10}
    h={size ?? 10}
    bg="bg.accent.purple.subtle"
    align="center"
    justify="center"
    rounded={isRounded ? "full" : "md"}
    shrink={0}
  >
    <Icon
      ref={ref}
      as={Users01SolidIcon}
      textColor="icon.accent.purple"
      boxSize="70%"
      transform="translateX(0.05em) translateY(-0.05em)"
      {...rest}
    />
  </Flex>
))

export const LyssnaLogoCompositeIcon: React.FC<{
  size?: number
  isRounded?: boolean
}> = ({ size, isRounded = true }) => (
  <Flex
    w={size ?? 10}
    h={size ?? 10}
    bg="teal.50"
    align="center"
    justify="center"
    rounded={isRounded ? "full" : "md"}
  >
    <Image src={LyssnaCircleIconTealSrc} />
  </Flex>
)
