import { Flex } from "@chakra-ui/react"
import React from "react"

import { LongTextScreenerQuestionsAnswerForm } from "./LongText"
import { MultiSelectScreenerQuestionsAnswerForm } from "./MultiSelect"
import { ShortTextScreenerQuestionsAnswerForm } from "./ShortText"
import { SingleSelectScreenerQuestionsAnswerForm } from "./SingleSelect"
import { ParticipantScreenerQuestion, ScreenerQuestionAnswer } from "./types"

interface ScreenerQuestionAnswerFormProps {
  question: ParticipantScreenerQuestion
  answer: ScreenerQuestionAnswer | undefined
  onAddAnswer: (screenerQuestionAnswer: ScreenerQuestionAnswer) => void
}

export function ScreenerQuestionAnswerForm({
  question,
  answer,
  onAddAnswer,
}: ScreenerQuestionAnswerFormProps) {
  const answerIsSelect = (
    answer: ScreenerQuestionAnswer
  ): answer is {
    screener_question_id: string
    type: "single_select" | "multi_select"
    screener_question_options: string[]
  } => {
    return answer.type === "single_select" || answer.type === "multi_select"
  }

  if (question.type === "short_text") {
    return (
      <ShortTextScreenerQuestionsAnswerForm
        question={question}
        defaultValue={answer && !answerIsSelect(answer) ? answer.text : ""}
        onSubmit={onAddAnswer}
      />
    )
  }

  if (question.type === "long_text") {
    return (
      <LongTextScreenerQuestionsAnswerForm
        question={question}
        defaultValue={answer && !answerIsSelect(answer) ? answer.text : ""}
        onSubmit={onAddAnswer}
      />
    )
  }

  if (question.type === "single_select") {
    return (
      <Flex justifyContent="center">
        <SingleSelectScreenerQuestionsAnswerForm
          question={question}
          defaultValue={
            answer && answerIsSelect(answer)
              ? answer.screener_question_options[0]
              : ""
          }
          onSubmit={onAddAnswer}
        />
      </Flex>
    )
  }

  if (question.type === "multi_select") {
    return (
      <Flex justifyContent="center">
        <MultiSelectScreenerQuestionsAnswerForm
          question={question}
          defaultValue={
            answer && answerIsSelect(answer)
              ? question.options!.map((questionOption) => ({
                  question_option_id: questionOption.id,
                  value: questionOption.value,
                  isSelected: answer.screener_question_options.includes(
                    questionOption.id
                  ),
                }))
              : question.options!.map((questionOption) => ({
                  question_option_id: questionOption.id,
                  value: questionOption.value,
                  isSelected: false,
                }))
          }
          onSubmit={onAddAnswer}
        />
      </Flex>
    )
  }

  throw new Error("question type is not supported")
}
