import { Center, Spinner, useToast } from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { ROUTES } from "UsabilityHub/views/routes"
import React, { useEffect } from "react"
import { useNavigate } from "react-router"
import { useTypedParams } from "react-router-typesafe-routes/dom"
import { useAcceptModeratedStudyOrderAssignment } from "~/api/generated/usabilityhub-components"

// This component is rendered at the `/apply/:token` route which is linked from the panelist assignment emails.
// Since we can't create an application directly from the email, we'll create one here and then immediately
// redirect to it.
export const CreateApplicationFromPanelAssignment: React.FC = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const navigate = useNavigate()
  const { moderatedStudyRecruitmentLinkToken: orderAssignmentId } =
    useTypedParams(ROUTES.INTERVIEW_APPLICATIONS.APPLY)

  const { mutate: acceptAssignment } = useAcceptModeratedStudyOrderAssignment({
    onSuccess: (data) => {
      void queryClient.invalidateQueries(["api", "order_assignments"])
      navigate(
        ROUTES.INTERVIEW_APPLICATIONS.APPLICATION.WELCOME.buildPath({
          moderatedStudyApplicationId: data.moderated_study_application_id,
        })
      )
    },
    onError: () => {
      toast({
        title: "Could not accept assignment",
        description: "The link you used may be invalid or expired.",
        status: "error",
      })
      navigate(ROUTES.DASHBOARD.path)
    },
  })

  useEffect(() => {
    if (orderAssignmentId) {
      acceptAssignment({
        pathParams: {
          id: orderAssignmentId,
        },
      })
    } else {
      navigate(ROUTES.DASHBOARD.path)
    }
  }, [])

  return (
    <Center h="100vh">
      <Spinner />
    </Center>
  )
}
