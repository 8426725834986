import isSafari from "is-safari"
import { every, find, flatMap, groupBy, some, toArray } from "lodash"

import { isBlank } from "../utilities/values"

/**
 * Super simple polyfill to prevent submission of a form with missing `required`
 * fields. If we want to do anything more complex we should use a proper
 * polyfill or our own validation method.
 */

function isSupported(): boolean {
  // Safari defines JavaScript API without actually preventing submission.
  return !isSafari && "required" in document.createElement("input")
}

function getValue(inputElement: HTMLInputElement | HTMLTextAreaElement) {
  switch (inputElement.tagName.toLowerCase()) {
    case "input":
    case "textarea": {
      const type = inputElement.getAttribute("type")
      if (type === "checkbox" || type === "radio") {
        if ((inputElement as HTMLInputElement).checked)
          return inputElement.value
      } else {
        return inputElement.value
      }
      break
    }
    case "select": {
      const selected = find(
        inputElement.getElementsByTagName("option"),
        "selected"
      )
      if (selected) {
        const value = selected.getAttribute("value")
        return value == null ? selected.innerHTML : value
      }
    }
  }
}

function initialize() {
  if (isSupported()) return

  function reportInvalid(element: HTMLElement) {
    window.alert("At least one required field was left blank.")
    element.focus()
  }

  document.addEventListener("submit", (event) => {
    const form = event.target as HTMLFormElement
    const elements = flatMap(
      ["input", "select", "textarea"],
      (tagName) =>
        toArray(form.getElementsByTagName(tagName)) as Array<
          HTMLInputElement | HTMLTextAreaElement
        >
    )

    // Group by name so that we can handle required radio buttons correctly;
    // they'll share the same name, and only one needs to be non-blank.
    // Other input types should have a single element array for each unique name.
    const elementsByName = groupBy(elements, "name")

    const failedElement = find(elementsByName, (es) => {
      const required = some(es, (e) => e.hasAttribute("required"))
      const blank = every(es, (e) => isBlank(getValue(e)))
      return required && blank
    })

    if (failedElement != null) {
      event.stopImmediatePropagation()
      event.preventDefault()
      reportInvalid(failedElement[0])
    }
  })
}

initialize()
