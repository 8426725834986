import {
  Heading as LegacyHeading,
  HeadingProps as LegacyHeadingProps,
  StackProps,
} from "@chakra-ui/react"
import { Stack } from "@chakra-ui/react"
import {
  HeadingProps as DSHeadingProps,
  Heading,
} from "DesignSystem/components"
import React from "react"

type Card = React.FC<React.PropsWithChildren<unknown>>

export const Card: Card = ({ children }) => {
  return <Stack spacing={4}>{children}</Stack>
}

/**
 * @deprecated This component is deprecated. Use CardHeader instead.
 */
export const LegacyCardHeader: React.FC<
  React.PropsWithChildren<LegacyHeadingProps>
> = ({ children, ...headingProps }) => {
  return (
    <LegacyHeading size="md" lineHeight="none" {...headingProps}>
      {children}
    </LegacyHeading>
  )
}

// Omit textStyle because it's the defining feature of the CardHeader and shouldn't be overridden
type CardHeaderProps = Omit<DSHeadingProps, "textStyle">

export const CardHeader: React.FC<React.PropsWithChildren<CardHeaderProps>> = ({
  children,
  as,
  ...props
}) => {
  return (
    <Heading as={as} textStyle="ds.display.emphasized" {...props}>
      {children}
    </Heading>
  )
}

type CardGroup = React.FC<React.PropsWithChildren<unknown>>

export const CardGroup: CardGroup = ({ children }) => {
  return <Stack spacing={8}>{children}</Stack>
}

type CardFooter = React.FC<React.PropsWithChildren<StackProps>>

export const CardFooter: CardFooter = ({ children, ...stackProps }) => {
  return (
    <Stack direction="row" {...stackProps}>
      {children}
    </Stack>
  )
}

type CardBody = React.FC<React.PropsWithChildren<StackProps>>

export const CardBody: CardBody = ({ children, ...stackProps }) => {
  return (
    <Stack
      bg="white"
      border="1px"
      borderColor="gray.200"
      borderRadius="lg"
      p={6}
      spacing={6}
      {...stackProps}
    >
      {children}
    </Stack>
  )
}
