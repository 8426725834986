import { createIcon } from "@chakra-ui/react"
import React from "react"

export const TimeIcon = createIcon({
  displayName: "TimeIcon",
  viewBox: "0 0 16 16",
  path: (
    <>
      <g id="times">
        <path
          id="Shape-Copy-3"
          d="M10.2489 4.30044C10.6495 3.89985 11.299 3.89985 11.6996 4.30044C12.1001 4.70103 12.1001 5.35052 11.6996 5.75111L9.45066 8L11.6996 10.2489C12.1001 10.6495 12.1001 11.299 11.6996 11.6996C11.299 12.1001 10.6495 12.1001 10.2489 11.6996L8 9.45066L5.75111 11.6996C5.35052 12.1001 4.70103 12.1001 4.30044 11.6996C3.89985 11.299 3.89985 10.6495 4.30044 10.2489L6.54934 8L4.30044 5.75111C3.89985 5.35052 3.89985 4.70103 4.30044 4.30044C4.70103 3.89985 5.35052 3.89985 5.75111 4.30044L8 6.54934L10.2489 4.30044Z"
          fill="currentColor"
        />
      </g>
    </>
  ),
})
