export enum ChurnReason {
  NothingLeftToTest = "nothing_left_to_test",
  TooExpensive = "too_expensive",
  LackOfUse = "lack_of_use",
  MissingFeature = "missing_feature",
  BadTestResults = "bad_test_results",
  SwitchedToCompetitor = "switched_to_competitor",
  Other = "other",
  Unspecified = "unspecified",
}
