import React, { createContext } from "react"

import { TestBranding, TestBrandingType } from "Types"

const DEFAULT_BRANDING: TestBranding = {
  type: TestBrandingType.UsabilityHub,
  logo_url: "",
  button_color: "brand.primary.500",
}

const TestBrandingContext = createContext(DEFAULT_BRANDING)

export const useTestBranding = () => {
  return React.useContext(TestBrandingContext)
}

export const TestBrandingContextProvider: React.FC<
  React.PropsWithChildren<{ branding: TestBranding | null }>
> = ({ branding, children }) => {
  return (
    <TestBrandingContext.Provider value={branding ?? DEFAULT_BRANDING}>
      {children}
    </TestBrandingContext.Provider>
  )
}
