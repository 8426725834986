import {
  DemographicProfileForUserResponse,
  DemographicProfileIndexResponse,
} from "~/api/generated/usabilityhub-components"

export const findUnansweredQuestions = (
  demographicData: DemographicProfileIndexResponse,
  profileData: DemographicProfileForUserResponse
) => {
  const demographicAttributes = demographicData.flatMap((d) =>
    d.demographic_attributes.map((a) => ({
      // Decorate each attribute with the group name so we can use it later
      group_name: d.group_name,
      ...a,
    }))
  )
  const selectedOptionIds =
    profileData.selected_demographic_attribute_option_ids

  const unansweredQuestions = demographicAttributes.filter((a) =>
    a.options.every((o) => !selectedOptionIds.includes(o.id))
  )

  const sortedUnansweredQuestions = unansweredQuestions.sort(
    (a, b) => a.individual_sort_order - b.individual_sort_order
  )

  return sortedUnansweredQuestions
}
