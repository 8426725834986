export class ManagedPromiseCancelledError extends Error {
  constructor() {
    super("Cancelled Managed Promise")
  }
}

export type ManagedPromise<T> = Promise<T> & {
  id: string
  call: () => ManagedPromise<T>
  cancel: () => void
  called: boolean
  cancelled: boolean
}

/**
 * Promise that can be called at a later time, and can be cancelled.
 *
 * @param fn
 */
export const createManagedPromise = <T>(
  fn: (...args: any) => Promise<T>
): ManagedPromise<T> => {
  let resolve: (value: T) => void
  let reject: (reason?: any) => void

  const promise = new Promise<T>((res, rej) => {
    resolve = res
    reject = rej
  }) as ManagedPromise<T>

  promise.id = crypto.randomUUID()
  promise.called = false
  promise.cancelled = false
  promise.call = () => {
    if (promise.called || promise.cancelled) {
      return promise
    }
    promise.called = true
    fn().then(resolve, reject)
    return promise
  }

  promise.cancel = () => {
    promise.cancelled = true
    reject(new ManagedPromiseCancelledError())
  }

  return promise
}
