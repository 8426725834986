import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/react"
import React, { FC } from "react"

import { Language } from "Types"
import { RestrictedFluenciesMessage } from "./RestrictedFluenciesMessage"

interface Props {
  readonly languages: readonly Language[]
}
export const RestrictedFluenciesAlert: FC<React.PropsWithChildren<Props>> = ({
  languages,
}) => (
  <Alert status="info">
    <AlertIcon />
    <AlertDescription>
      <RestrictedFluenciesMessage languages={languages} />
    </AlertDescription>
  </Alert>
)
