import { As, Grid, GridProps, HStack, Icon, Text } from "@chakra-ui/react"
import React, { PropsWithChildren } from "react"

const List: React.FC<GridProps> = ({ children, ...props }) => (
  <Grid
    as="dl"
    templateColumns="max-content 1fr"
    rowGap={2}
    columnGap={8}
    alignItems="flex-start"
    {...props}
  >
    {children}
  </Grid>
)

type ItemProps = PropsWithChildren<{
  icon: As
  label: string
}>

const Item: React.FC<ItemProps> = ({ icon, label, children }) => (
  <>
    <HStack as="dt" color="text.secondary">
      <Icon as={icon} />
      <Text>{label}</Text>
    </HStack>
    <dd>{children}</dd>
  </>
)

export const IconDefinitionList = Object.assign(List, { Item })
