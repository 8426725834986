import React, { ComponentType, ReactHTML, ReactNode } from "react"

interface Props {
  readonly items: readonly string[]
  readonly conjunction?: string
  readonly component?:
    | ComponentType<React.PropsWithChildren<unknown>>
    | keyof ReactHTML
}

export const TextList = ({
  items,
  conjunction = "and",
  component: C = React.Fragment,
}: Props) => {
  if (items.length === 0) return null
  const result: ReactNode[] = [<C key={0}>{items[0]}</C>]
  if (items.length > 1) {
    const lastIndex = items.length - 1
    for (let i = 1; i < lastIndex; i++) {
      result.push(", ")
      result.push(<C key={i}>{items[i]}</C>)
    }
    result.push(` ${conjunction} `)
    result.push(<C key={lastIndex}>{items[lastIndex]}</C>)
  }
  return <>{result}</>
}
