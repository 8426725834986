// Note: These values must match the :symbol keys used on Rails `flash` for
// server supplied flash messages to be styled properly.
// Eg. 'error' for flash[:error]

enum FlashType {
  Info = "info",
  Error = "error",
  Notice = "notice",
  Alert = "alert",
}

export default FlashType
