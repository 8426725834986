import { createIcon } from "@chakra-ui/react"
import React from "react"

export const InfoCircleIcon = createIcon({
  displayName: "InfoCircleIcon",
  viewBox: "0 0 24 24",
  path: (
    <g id="info-circle">
      <path
        id="Shape"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.54546 5.18182C9.54546 4.3785 10.1967 3.72727 11 3.72727H11.0145C11.8179 3.72727 12.4691 4.3785 12.4691 5.18182C12.4691 5.98514 11.8179 6.63636 11.0145 6.63636H11C10.1967 6.63636 9.54546 5.98514 9.54546 5.18182ZM11 9.54545C11.8033 9.54545 12.4545 10.1967 12.4545 11V16.8182C12.4545 17.6215 11.8033 18.2727 11 18.2727C10.1967 18.2727 9.54546 17.6215 9.54546 16.8182V11C9.54546 10.1967 10.1967 9.54545 11 9.54545ZM11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z"
        fill="currentColor"
      />
    </g>
  ),
})
