interface TesterGuideline {
  heading: string
  guideline: string[]
}

export const testerGuidelines: TesterGuideline[] = [
  {
    heading: "Read questions and instructions carefully",

    guideline: [
      `Many tests are similar, but every test is different. Please read the
      questions and instructions carefully to ensure you know what you are
      required to do.`,
    ],
  },
  {
    heading: "Take your time",
    guideline: [
      `Don\u2019t rush through tests. Every test must be given your best effort.
      Moderate rushing may result in some of your responses being rejected.
      Speeding through tests will result in being banned from our platform.`,
    ],
  },
  {
    heading: "Be thorough, clear and specific",
    guideline: [
      `Take the time to provide as much information as you can when giving
      written responses. Make your answers clear by writing in sentences
      (where appropriate).`,
    ],
  },
  {
    heading: "Be honest, polite, and professional",
    guideline: [
      `You don\u2019t have to give positive feedback, but you should give feedback like you would to someone you met in person. For example:`,
      `“The logo looks unprofessional” or “I find the navigation very confusing” are great answers.`,
      `“This sucks”, “I hate it”, or answers containing profanity, are unacceptable.`,
    ],
  },
  {
    heading: "Respond in the correct language",
    guideline: [
      `Always respond in the same language as the question.
      You must be fluent in the language(s) listed on your profile 
      and should not use translation apps to formulate your responses.`,
    ],
  },
  {
    heading: "Provide genuine answers",
    guideline: [
      `Researchers want to know your real thoughts; there\u2019s rarely a 'right' or 'wrong' answer. 
      Your answers must be genuine and from you.
      Using AI tools to answer written questions is prohibited.`,
    ],
  },
]
