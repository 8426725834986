async function playAudio(audio: HTMLAudioElement): Promise<void> {
  try {
    await audio.play()
  } catch (error) {
    if (error instanceof DOMException) {
      // This happens when Chrome mobile suppresses media autoplay.
      // See: https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
      // See: https://github.com/wearelyssna/hub/issues/3214
    } else {
      throw error
    }
  }
}

export async function playAudioPath(audioPath: string): Promise<void> {
  if (window.Audio !== undefined) {
    return playAudio(new Audio(audioPath))
  }
}
