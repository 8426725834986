import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react"
import { Field, FieldProps, Form, Formik, FormikErrors } from "formik"
import React from "react"
import { useDispatch } from "react-redux"
import { Navigate } from "react-router"

import { getApplicationState } from "JavaScripts/state"
import { Dispatch } from "Redux/app-store"
import { showError, showNotice } from "Redux/reducers/flash"
import { axios, isBadRequestError } from "Services/axios"
import {
  Card,
  CardBody,
  CardFooter,
  LegacyCardHeader,
} from "Shared/components/Card/Card"
import { newUserPasswordPath, userPasswordPath } from "Shared/constants/routes"
import { ROUTES } from "UsabilityHub/views/routes"

interface EditPasswordFormValues {
  password: string
  password_confirmation: string
}

export const EditPasswordForm: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch: Dispatch = useDispatch()
  const { resetPasswordToken } = getApplicationState()

  if (resetPasswordToken === null) {
    dispatch(
      showError("Your password reset request has expired. Please try again.")
    )
    return <Navigate to={newUserPasswordPath()} />
  }

  const handleSubmit = async (values: EditPasswordFormValues) => {
    const { password, password_confirmation } = values
    try {
      const { data } = await axios.put(userPasswordPath(), {
        user: {
          password,
          password_confirmation,
          reset_password_token: resetPasswordToken,
        },
      })
      dispatch(showNotice(data.message))

      // NOTE: This component is used by the main Lyssna app and Usercrowd
      // but both sites happen to have a "dashboard" route
      window.location.href = ROUTES.DASHBOARD.path
    } catch (error) {
      if (isBadRequestError(error)) {
        dispatch(showError(error.response.data.message))
      } else {
        throw error
      }
    }
  }

  const handleValidate = (
    values: EditPasswordFormValues
  ): FormikErrors<EditPasswordFormValues> => {
    const errors: FormikErrors<EditPasswordFormValues> = {}
    if (values.password !== values.password_confirmation) {
      errors.password_confirmation = "The password confirmation does not match"
    }
    return errors
  }

  return (
    <Formik<EditPasswordFormValues>
      initialValues={{
        password: "",
        password_confirmation: "",
      }}
      onSubmit={handleSubmit}
      validate={handleValidate}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ isSubmitting }) => (
        <Form>
          <Card>
            <LegacyCardHeader size="lg">Edit password</LegacyCardHeader>
            <CardBody>
              <Field name="password">
                {({ field }: FieldProps) => (
                  <FormControl>
                    <FormLabel>Choose new password</FormLabel>
                    <Input {...field} autoFocus isRequired type="password" />
                  </FormControl>
                )}
              </Field>
              <Field name="password_confirmation">
                {({ field, meta }: FieldProps) => (
                  <FormControl isInvalid={!!meta.error}>
                    <FormLabel>Confirm new password</FormLabel>
                    <Input {...field} isRequired type="password" />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <CardFooter>
                <Button
                  isLoading={isSubmitting}
                  loadingText="Changing password"
                  type="submit"
                  colorScheme="brand.primary"
                  width="full"
                >
                  Change my password
                </Button>
              </CardFooter>
            </CardBody>
          </Card>
        </Form>
      )}
    </Formik>
  )
}
