// if it refers to a color listed here (or should refer to a color here)
// it belongs in theme-tokens, not this list
export const baseColors = {
  transparent: "transparent",
  current: "currentColor",

  black: "#000000",
  white: "#ffffff",
  whiteAlpha: {
    "50": "#ffffff0a",
    "100": "#ffffff0f",
    "200": "#ffffff14",
    "300": "#ffffff29",
    "400": "#ffffff3d",
    "500": "#ffffff5c",
    "600": "#ffffff7a",
    "700": "#ffffffa3",
    "800": "#ffffffcc",
    "900": "#ffffffeb",
  },
  blackAlpha: {
    "50": "#061d290a",
    "100": "#061d290f",
    "200": "#061d2914",
    "300": "#061d2929",
    "400": "#061d293d",
    "500": "#061d295c",
    "600": "#061d297a",
    "700": "#061d29a3",
    "800": "#061d29cc",
    "900": "#061d29eb",
  },

  gray: {
    "50": "#f5fafe",
    "100": "#ebf3fa",
    "200": "#e1ebf5",
    "300": "#bccbd8",
    "400": "#97abba",
    "500": "#6d8494",
    "600": "#425d6d",
    "700": "#183547",
    "800": "#0f2938",
    "900": "#061d29",
  },
  red: {
    "50": "#ffdcd6",
    "100": "#ffbaac",
    "200": "#ff9882",
    "300": "#ff7658",
    "400": "#f4572c",
    "500": "#e83800",
    "600": "#c12f00",
    "700": "#992600",
    "800": "#872416",
    "900": "#6b1400",
  },
  orange: {
    "50": "#fffaf0",
    "100": "#feebcb",
    "200": "#fbd38d",
    "300": "#f6ad55",
    "400": "#ed8936",
    "500": "#dd6b20",
    "600": "#c05621",
    "700": "#9c4221",
    "800": "#7b341e",
    "900": "#652b19",
  },
  green: {
    "50": "#f0fff4",
    "100": "#C6F6D5",
    "200": "#9AE6B4",
    "300": "#68D391",
    "400": "#48BB78",
    "500": "#38A169",
    "600": "#25855A",
    "700": "#276749",
    "800": "#22543D",
    "900": "#1C4532",
  },
  teal: {
    "50": "#defdf3",
    "100": "#bcfbe7",
    "200": "#8fe2d0",
    "300": "#63c9ba",
    "400": "#37b0a4",
    "500": "#0b978e",
    "600": "#068382",
    "700": "#006e75",
    "800": "#044a4e",
    "900": "#143333",
  },
  blue: {
    "50": "#ebf8ff",
    "100": "#e1effe",
    "200": "#c3ddfd",
    "300": "#a4cafe",
    "400": "#76a9fa",
    "500": "#3f83f8",
    "600": "#1c64f2",
    "700": "#1a56db",
    "800": "#1e429f",
    "900": "#233876",
  },
  purple: {
    "50": "#f0e8ff",
    "100": "#e1d1ff",
    "200": "#d1b9ff",
    "300": "#b698f2",
    "400": "#9b77e5",
    "500": "#8056d8",
    "600": "#6633c9",
    "700": "#5723b4",
    "800": "#47129e",
    "900": "#31046d",
  },
  pink: {
    "50": "#FFE2F3",
    "100": "#FFC5E7",
    "200": "#FFA9DB",
    "300": "#EC88C1",
    "400": "#D966A6",
    "500": "#C55292",
    "600": "#B03D7D",
    "700": "#8F2865",
    "800": "#6E144E",
    "900": "#4D0037",
  },
  yellow: {
    "50": "#FFFACF",
    "100": "#FFF59F",
    "200": "#FFEF6A",
    "300": "#FFE935",
    "400": "#FFE300",
    "500": "#DCC300",
    "600": "#B9A200",
    "700": "#978101",
    "800": "#756002",
    "900": "#533F03",
  },
}
