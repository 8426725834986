type SomethingWithAName = {
  name: string
  preferred_name: string | null
}

export const dashboardGreeting = (user: SomethingWithAName | null) => {
  const currentHour = new Date().getHours()
  let greeting: string

  if (currentHour >= 4 && currentHour < 12) {
    greeting = "Morning"
  } else if (currentHour < 18) {
    greeting = "Afternoon"
  } else {
    greeting = "Evening"
  }

  if (user) {
    return `${greeting}, ${user.preferred_name ?? user.name}`
  }

  return greeting
}
