import { BaseError } from "make-error"

import { axios, isNotFoundError } from "Services/axios"
import { PanelistNotificationSubscription } from "Types"
import PanelistNotificationSubscriptionsApi from "~/api/panelistNotificationSubscriptionsApi"

import { VAPID_PUBLIC_KEY } from "../constants/vapid"

// -- Constants --

const SERVICE_WORKER_PATH = "/service-worker.js"
const DEFAULT_LYSSNA_ICON = "/usercrowd/usercrowd-android-chrome-192x192.png"

const ARE_SERVICE_WORKERS_SUPPORTED = "serviceWorker" in navigator
export const ARE_NOTIFICATIONS_SUPPORTED =
  ARE_SERVICE_WORKERS_SUPPORTED &&
  "showNotification" in ServiceWorkerRegistration.prototype

// -- Helpers --

export class PermissionDeniedError extends BaseError {}

function serializeSubscription(subscription: PushSubscription) {
  const { endpoint, keys } = subscription.toJSON()
  return {
    endpoint,
    public_key: keys!.p256dh,
    auth_secret: keys!.auth,
  }
}

async function installServiceWorker() {
  if (!ARE_SERVICE_WORKERS_SUPPORTED) {
    throw new Error("Browser does not support service workers")
  }
  await navigator.serviceWorker.register(SERVICE_WORKER_PATH)
  return navigator.serviceWorker.ready
}

// -- Public interface --

export async function subscribe(): Promise<PanelistNotificationSubscription> {
  try {
    const registration = await installServiceWorker()
    const pushSubscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: VAPID_PUBLIC_KEY,
    })
    const subscription = serializeSubscription(pushSubscription)
    const result = await axios.post(
      PanelistNotificationSubscriptionsApi.create.path(),
      {
        panelist_notification_subscription: subscription,
      }
    )
    return result.data as PanelistNotificationSubscription
  } catch (error) {
    // Catch both `DOMException` `NotAllowedError` and the `AbortError`
    // equivalent.
    //
    // The difference in case that might raise either one is unclear
    // to me at the time of writing, but this `catch` handler is exhaustive.
    // Previously we were relying on `Notification.permission === "denied"` but
    // this apparently was not enough (see #3172).
    if (
      error.name === "DOMException" ||
      error.name === "NotAllowedError" ||
      error.name === "AbortError" ||
      Notification.permission === "denied"
    ) {
      throw new PermissionDeniedError("User denied permission")
    }
    throw error
  }
}

/**
 * Unsubscribe the push subscription associated with this browser.
 *
 * @param {(endpoint: string) => Object} getSubscriptionByEndpoint
 *    A callback that returns the subscription record associated with a given
 *    endpoint strting.
 * @returns {Promise<Subscription?>}
 *    The subscription record whose corresponding push subscription was
 *    unsubscribed.
 */
export async function unsubscribe(
  getSubscriptionByEndpoint: (
    endpoint: string
  ) => PanelistNotificationSubscription | null
) {
  const pushSubscription = await getPushSubscription()
  if (pushSubscription == null) return null

  const isSuccessful = await pushSubscription.unsubscribe()
  if (!isSuccessful) {
    // Just throw if this is false. By my reading of MDN this is won't
    // actually happen because the promise will be rejected on failure.
    //
    // See: https://developer.mozilla.org/en-US/docs/Web/API/PushSubscription/unsubscribe
    throw new Error("Unsubscription was unsuccessful")
  }

  const subscription = getSubscriptionByEndpoint(pushSubscription.endpoint)
  if (subscription == null) return null

  try {
    // Delete the subscription (if it doesn't exist that's fine too).
    await axios.delete(
      PanelistNotificationSubscriptionsApi.destroy.path({ id: subscription.id })
    )
    return subscription
  } catch (error) {
    if (isNotFoundError(error)) {
      // Ignore not found error.
    } else {
      throw error
    }
  }
}

// `navigator.serviceWorker.ready` will never resolve if there is no service
// worker already installed, so we need to check first if there are any
// existing registrations.
async function checkRegistered() {
  // NOTE: `getRegistrations` is not supported on some devices (Android Chrome)
  // at the time of writing.
  return (await navigator.serviceWorker.getRegistration()) !== undefined
}

export async function showNotification(
  title: string,
  options: NotificationOptions = {}
) {
  const registration = await navigator.serviceWorker.ready
  return registration.showNotification(title, {
    icon: DEFAULT_LYSSNA_ICON,
    ...options,
  })
}

export async function getPushSubscription() {
  const isRegistered = await checkRegistered()
  if (!isRegistered) return null

  // Although the `getRegistrations` API suggests that there may be multiple
  // registrations, I believe that it is actually not possible to have more
  // than one.
  const registration = await navigator.serviceWorker.ready
  return registration.pushManager.getSubscription()
}
