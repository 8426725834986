import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"

import { ParticipantScreenerQuestion, ScreenerQuestionAnswer } from "./types"

type ScreenerQuestionAnswersContextType = {
  screenerQuestions: ParticipantScreenerQuestion[]
  screenerQuestionAnswers: ScreenerQuestionAnswer[]
  addScreenerQuestionAnswer: (
    screenerQuestionAnswer: ScreenerQuestionAnswer
  ) => void
  currentQuestion?: ParticipantScreenerQuestion
  currentQuestionIndex: number
  setCurrentQuestionIndex: React.Dispatch<React.SetStateAction<number>>
  goToNextQuestion: () => void
  isInvalidAnswer: boolean
  setIsInvalidAnswer: React.Dispatch<React.SetStateAction<boolean>>
}

const ScreenerQuestionAnswersContext =
  createContext<ScreenerQuestionAnswersContextType>({
    screenerQuestions: [],
    screenerQuestionAnswers: [],
    addScreenerQuestionAnswer: () => [],
    currentQuestion: undefined,
    currentQuestionIndex: 0,
    setCurrentQuestionIndex: () => 0,
    goToNextQuestion: () => null,
    isInvalidAnswer: false,
    setIsInvalidAnswer: () => false,
  })

export const useScreenerQuestionAnswersContext = () => {
  const context = useContext(ScreenerQuestionAnswersContext)

  if (context === null)
    throw new Error(
      "useScreenerQuestionAnswersContext must be used within a ScreenerQuestionAnswersContext"
    )

  return context
}

type ScreenerQuestionAnswersContextProviderProps = {
  screenerQuestions: ParticipantScreenerQuestion[]
  onSubmittedAllAnswers: (answers: ScreenerQuestionAnswer[]) => void
}

export const ScreenerQuestionAnswersContextProvider: FC<
  React.PropsWithChildren<ScreenerQuestionAnswersContextProviderProps>
> = ({ children, screenerQuestions, onSubmittedAllAnswers }) => {
  const [screenerQuestionAnswers, setScreenerQuestionAnswers] = useState<
    ScreenerQuestionAnswer[]
  >([])
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)

  // This is used to disable the down arrow when the user
  // has not answered the question or the answer is invalid
  const [isInvalidAnswer, setIsInvalidAnswer] = useState(false)

  useEffect(() => {
    if (currentQuestionIndex === screenerQuestions.length) {
      onSubmittedAllAnswers(screenerQuestionAnswers)
    }
  }, [currentQuestionIndex, screenerQuestions.length])

  const addScreenerQuestionAnswer = useCallback(
    (screenerQuestionAnswer: ScreenerQuestionAnswer) => {
      setScreenerQuestionAnswers((screenerQuestionsResponses) => {
        const newResponses = [...screenerQuestionsResponses]
        newResponses.splice(currentQuestionIndex, 1, screenerQuestionAnswer)
        return newResponses
      })
    },
    [setScreenerQuestionAnswers, setCurrentQuestionIndex, currentQuestionIndex]
  )

  const goToNextQuestion = useCallback(
    () =>
      setCurrentQuestionIndex(
        (currentQuestionIndex) => currentQuestionIndex + 1
      ),
    [setCurrentQuestionIndex]
  )

  return (
    <ScreenerQuestionAnswersContext.Provider
      value={{
        currentQuestion: screenerQuestions[currentQuestionIndex],
        screenerQuestions,
        screenerQuestionAnswers: screenerQuestionAnswers,
        addScreenerQuestionAnswer: addScreenerQuestionAnswer,
        currentQuestionIndex: currentQuestionIndex,
        setCurrentQuestionIndex: setCurrentQuestionIndex,
        goToNextQuestion: goToNextQuestion,
        isInvalidAnswer: isInvalidAnswer,
        setIsInvalidAnswer: setIsInvalidAnswer,
      }}
    >
      {children}
    </ScreenerQuestionAnswersContext.Provider>
  )
}
