// These are useful because Chakra converts its units to rems, and rems work
// with font scaling. E.g. sometimes you find 3px on something like padding in a
// design but it should actually be in rems so it scales with root font size.
// Rather than doing the inscrutable 0.1875rem in the middle of Chakra units, we
// should be able to do 0.75. Chakra gives us 0.5, 1.5 etc but not more granular.
// We're not strict about a 4px grid so this is OK to use these.
export const smallSizes = {
  "0.25": "0.0625rem", // 1px
  "0.75": "0.1875rem", // 3px
  "1.25": "0.3125rem", // 5px
  "1.75": "0.4375rem", // 7px
  "2.25": "0.5625rem", // 9px
}
