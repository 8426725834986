export const fontWeights = {
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
}

export const textStyles = {
  label: {
    fontSize: "sm",
    fontWeight: "regular",
    lineHeight: 5,
    color: "gray.700",
  },
}
