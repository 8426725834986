import { Box, HStack, Stack, Text, useId } from "@chakra-ui/react"
import { ChevronLeftOutlineIcon } from "Shared/icons/untitled-ui/ChevronLeftOutlineIcon"
import React, { PropsWithChildren, useEffect } from "react"
import { createPortal } from "react-dom"
import { IconButton } from "../IconButton"
import { Option } from "./Option"
import { Provider, useSelect } from "./Provider"
import {
  SEARCH_PATH,
  SelectPath,
  SelectPathComponent,
  SelectValue,
} from "./types"
import { joinPath } from "./util"

type InlineGroupProps<P extends string> = PropsWithChildren<{
  id?: string
  path: SelectPathComponent<P>
  parentPath?: SelectPath
  label: string
}>

export const InlineGroup = <P extends string>({
  id: idProp,
  path,
  parentPath = "",
  label,
  children,
}: InlineGroupProps<P>) => {
  const id = useId(idProp, "select-group")

  const fullPath = joinPath(parentPath, path)

  return (
    <Stack gap={0} id={id}>
      <GroupHeader path={fullPath}>{label}</GroupHeader>
      {children}
    </Stack>
  )
}

type NestedGroupProps<P extends string> = InlineGroupProps<P> & {
  isMulti?: boolean
  isActive?: boolean
  isFocused?: boolean
  isDisabled?: boolean
  isSelected?: boolean
  isIndeterminate?: boolean
  onSelect?: (path: SelectPath) => void
  onPointerEnter?: () => void
}

export const NestedGroup = <T extends SelectValue, P extends string>({
  id: idProp,
  path,
  parentPath = "",
  label,
  onSelect,
  onPointerEnter,
  children,
  ...props
}: NestedGroupProps<P>) => {
  const id = useId(idProp, "select-group")

  const fullPath = joinPath(parentPath, path)

  const { container, toggleGroup, groupSelectionState } = useSelect<T>()

  // Don't memoize this or it won't be correctly updated when the list is populated
  const selectionState = groupSelectionState(fullPath)

  return (
    <>
      <Option
        id={id}
        isGroup
        value={fullPath}
        isSelected={selectionState === "all"}
        isIndeterminate={selectionState === "some"}
        onSelect={() => {
          onSelect?.(fullPath)
        }}
        onCheckedChange={() => {
          toggleGroup(fullPath)
        }}
        onPointerEnter={onPointerEnter}
        {...props}
      >
        {label}
      </Option>
      {container &&
        createPortal(
          <GroupContents path={fullPath}>
            <GroupHeader
              path={fullPath}
              onBackClick={() => onSelect?.(parentPath)}
            >
              {label}
            </GroupHeader>
            {children}
          </GroupContents>,
          container
        )}
    </>
  )
}

type SelectGroupProps<P extends string> =
  | ({ isNested?: false } & Omit<InlineGroupProps<P>, "parentPath">)
  | ({ isNested: true } & Omit<NestedGroupProps<P>, "parentPath">)

export const SelectGroup = <P extends string>({
  isNested,
  id: idProp,
  ...props
}: SelectGroupProps<P>) => {
  const {
    groupPath,
    isMulti,
    activeItem,
    setActiveItemId,
    onPathChange,
    register,
    deregister,
  } = useSelect()

  const id = useId(idProp, "select-group")

  useEffect(() => {
    register(
      {
        id,
        type: "group",
        path: joinPath(groupPath, props.path),
        label: props.label,
      },
      groupPath
    )
    return () => {
      deregister(id, groupPath)
    }
  }, [id, props.path, groupPath, register, deregister])

  return (
    <Provider groupPath={joinPath(groupPath, props.path)}>
      {isNested ? (
        <NestedGroup
          parentPath={groupPath}
          isMulti={isMulti}
          isActive={activeItem?.id === id}
          {...props}
          onSelect={onPathChange}
          onPointerEnter={() => {
            setActiveItemId(id)
          }}
        />
      ) : (
        <InlineGroup parentPath={groupPath} {...props} />
      )}
    </Provider>
  )
}

type GroupHeaderProps = PropsWithChildren<{
  path: SelectPath
  onBackClick?: (path: SelectPath) => void
}>

const GroupHeader: React.FC<GroupHeaderProps> = ({
  path,
  onBackClick,
  children,
}) => {
  return (
    <HStack gap={2} py={1} px={3} align="center">
      {onBackClick && (
        <IconButton
          icon={<ChevronLeftOutlineIcon boxSize={4} />}
          size="compact"
          aria-label="Back"
          variant="secondary"
          color="ds.icon.subtle"
          rounded="full"
          onClick={() => onBackClick(path)}
        />
      )}
      <Text textStyle="ds.heading.subtle" color="ds.text.subtle">
        {children}{" "}
      </Text>
    </HStack>
  )
}

type GroupContentsProps = {
  path: SelectPath
}

export const GroupContents: React.FC<PropsWithChildren<GroupContentsProps>> = ({
  path,
  children,
}) => {
  const { path: currentPath, container, isSearching } = useSelect()

  // Show the group if:
  // - This is a search results group AND
  //   we're currently searching AND
  //   we're at the root
  // - This is not a search results group AND
  //   the paths match AND
  //   we're not currently searching OR we're not at the root
  // Otherwise, hide the group

  const showThisGroup =
    path === SEARCH_PATH
      ? isSearching && !currentPath
      : path === currentPath && (!isSearching || !!currentPath)

  const isHidden = !showThisGroup

  return (
    container &&
    createPortal(
      <Box
        data-path={path}
        data-hidden={isHidden || undefined}
        aria-current={!isHidden ? "page" : undefined}
        py={2}
        sx={{ "&[data-hidden]": { display: "none" } }}
      >
        {children}
      </Box>,
      container
    )
  )
}

SelectGroup.displayName = "Select.Group"
