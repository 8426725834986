import type { ComponentSingleStyleConfig } from "@chakra-ui/react"

import { styles } from "../styles"

import { noUnderlineBaseStyle as linkBaseStyle } from "./link"

export const Button: ComponentSingleStyleConfig = {
  baseStyle: {
    fontWeight: "medium",
  },
  variants: {
    round: {
      p: 0,
      mx: 2,
      borderRadius: "full",
      _expanded: {
        background: "gray.300",
      },
    },
    solid: ({ colorScheme }) => {
      if (colorScheme === "gray") {
        return {
          bg: "gray.200",
          // Gross but we can’t target button icons yet
          _hover: {
            bg: "gray.300",
          },
          _active: { bg: "gray.400" },
        }
      } else {
        return {}
      }
    },
    outline: ({ colorScheme }) => {
      if (colorScheme === "gray") {
        return {
          bg: "white",
          borderColor: "gray.300",
          shadow: "sm",
          // Gross but we can’t target button icons yet
          ".chakra-button__icon": {
            color: "gray.500",
          },
        }
      } else {
        return {}
      }
    },
    link: linkBaseStyle,
    iconToggle: {
      ...styles.iconButton.basicStyle,
      _hover: {
        ...styles.iconButton.hover,
      },
      _active: {
        ...styles.iconButton.active,
        _hover: {
          ...styles.iconButton.hover,
        },
      },
    },
    tab: {
      fontWeight: "inherit",
      borderRadius: 0,
      borderBottom: "2px solid",
      borderColor: "transparent",
      marginBottom: "-2px !important",
      _selected: {
        color: `blue.600`,
        borderColor: "currentColor",
      },
      _active: {
        bg: "gray.200",
      },
      _disabled: {
        opacity: 0.4,
        cursor: "not-allowed",
      },
    },
    tool: ({ colorScheme = "brand.primary" }) => ({
      outlineColor: `${colorScheme}.600`,
      outlineOffset: -1,
      borderWidth: 1,
      _hover: {
        bg: "gray.200",
      },
    }),
    inverted: ({ colorScheme }) => ({
      fontFamily: "promo",
      fontSize: "sm",
      border: "1px solid",
      borderColor: `${colorScheme}.700`,
      backgroundColor: "transparent",
      color: `${colorScheme}.700`,
      _hover: {
        backgroundColor: `${colorScheme}.700`,
        color: "text.inverted",
      },
      _focus: {
        backgroundColor: `${colorScheme}.700`,
        color: "text.inverted",
      },
    }),
  },
}
