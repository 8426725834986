import { Center, Flex, Grid } from "@chakra-ui/react"
import { Image } from "@chakra-ui/react"
import { Heading } from "DesignSystem/components"
import { Page } from "Shared/components/Page/Page"
import React from "react"
import { SignUpForm } from "./SignUpForm"
import { SignUpSplash } from "./SignUpSplash"
import usercrowdLogo from "./usercrowd-logo.png"

export const SignUpRoute: React.FC = () => (
  <Page title="Sign up">
    <Grid
      templateColumns={["1fr", null, null, "1fr 1fr"]}
      w="full"
      minH="100dvh"
    >
      <Center p={8}>
        <Flex flexDirection="column" gap={8} maxW="500px" w="full">
          <Flex direction="column" gap={6} align="center">
            <Image src={usercrowdLogo} maxW="240px" />

            <Heading
              as="h2"
              textStyle="ds.display.primary"
              color="ds.text.default"
            >
              Apply now to UserCrowd
            </Heading>
          </Flex>

          <SignUpForm />
        </Flex>
      </Center>

      <Center
        display={["none", null, null, "block"]}
        bg="ds.surface.raised.resting"
        boxSize="full"
        p={8}
      >
        <SignUpSplash />
      </Center>
    </Grid>
  </Page>
)
