import React from "react"
import UserEmulationApi from "~/api/admin/userEmulationApi"
import { useCurrentPanelistInfo } from "~/api/generated/usabilityhub-components"
import { EmulationAlert } from "./EmulationAlert"

export const PanelistEmulationAlert: React.FC = () => {
  const { data: currentPanelist } = useCurrentPanelistInfo({})

  if (!currentPanelist) return null

  return (
    <EmulationAlert
      name={currentPanelist.name}
      id={currentPanelist.id}
      accountId={currentPanelist.account_id}
      switchBackUrl={UserEmulationApi.stopEmulatingPanelist.path()}
    />
  )
}
