import { Box, BoxProps, forwardRef } from "@chakra-ui/react"
import React, { useContext } from "react"
import { Link } from "react-router-dom"

import { AppContext } from "Shared/contexts/AppContext"

type RoutedLink = React.FC<
  React.PropsWithChildren<
    {
      to: string
    } & BoxProps
  >
>

export const RoutedLink: RoutedLink = forwardRef(({ to, ...props }, ref) => {
  const { isSPA } = useContext(AppContext)
  return isSPA ? (
    <Box as={Link} to={to} ref={ref} {...props} />
  ) : (
    <Box as="a" href={to} ref={ref} {...props} />
  )
})
