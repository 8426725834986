import { createIcon } from "@chakra-ui/react"
import React from "react"

export const InfoCircleSolidIcon = createIcon({
  displayName: "InfoCircleSolidIcon",
  viewBox: "0 0 24 24",
  defaultProps: { fill: "none" },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9H12.01C12.5623 9 13.01 8.55228 13.01 8C13.01 7.44772 12.5623 7 12.01 7H12ZM13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12V16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16V12Z"
      fill="currentColor"
    />
  ),
})
