import { Flex, Image, Text } from "@chakra-ui/react"
import { Badge, Button, Heading } from "DesignSystem/components"
import { DisplayModal } from "UserCrowd/components/DisplayModal/DisplayModal"
import React from "react"
import { useNavigate } from "react-router"
import { Link } from "react-router-dom"
import { ROUTES } from "../../routes"
import illustrationSrc from "./practice-test-complete-illustration.png"

export const PracticeTestCompleteRoute: React.FC = () => {
  const navigate = useNavigate()

  const onClose = () => {
    navigate(ROUTES.DASHBOARD.path)
  }

  return (
    <DisplayModal isOpen onClose={onClose}>
      <DisplayModal.TwoPaneContent
        content={
          <Image
            h="full"
            w="full"
            objectFit="cover"
            roundedTop="16px"
            roundedBottom={[0, "16px"]}
            src={illustrationSrc}
          />
        }
        controls={
          <Button as={Link} variant="primary" to={ROUTES.DASHBOARD.path}>
            Go to dashboard
          </Button>
        }
      >
        <Flex direction="column" align="flex-start" gap={4}>
          <Badge variant="subtle" label="PRACTICE TEST" />

          <Heading
            as="h2"
            color="ds.text.default"
            textStyle="ds.display.primary"
          >
            Practice test complete!
          </Heading>

          <Text color="ds.text.default" textStyle="ds.paragraph.emphasized">
            You may begin receiving studies and start earning money with
            UserCrowd.
          </Text>

          <Text color="ds.text.subtle" textStyle="ds.paragraph.primary">
            Note: If your practice test is rejected by the UserCrowd team, you
            will stop receiving study invitations.
          </Text>
        </Flex>
      </DisplayModal.TwoPaneContent>
    </DisplayModal>
  )
}
