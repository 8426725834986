import { createIcon } from "@chakra-ui/react"
import React from "react"

// This isn't actually an official UntitledUI icon!

export const ScreenRecordingOutlineIcon = createIcon({
  displayName: "ScreenRecordingOutlineIcon",
  viewBox: "0 0 24 24",
  defaultProps: { fill: "none" },
  path: (
    <>
      <circle
        cx="21"
        cy="6"
        r="3"
        stroke="none"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M22.998 10.584V16.2413C22.9981 17.0463 22.9981 17.7106 22.9539 18.2518C22.9079 18.8139 22.8094 19.3306 22.5621 19.816C22.1786 20.5686 21.5667 21.1805 20.814 21.564C20.3287 21.8113 19.812 21.9099 19.2499 21.9558C18.7087 22 18.0443 22 17.2394 22H6.75682C5.95182 22 5.28743 22 4.74622 21.9558C4.18412 21.9099 3.66742 21.8113 3.18209 21.564C2.42944 21.1805 1.81752 20.5686 1.43402 19.816C1.18673 19.3306 1.08817 18.8139 1.04224 18.2518C0.998025 17.7106 0.998035 17.0463 0.998047 16.2413V7.7587C0.998035 6.95374 0.998025 6.28937 1.04224 5.74818C1.08817 5.18608 1.18673 4.66937 1.43402 4.18404C1.81752 3.43139 2.42944 2.81947 3.18209 2.43597C3.66742 2.18868 4.18413 2.09012 4.74622 2.04419C5.28742 1.99998 5.95177 1.99999 6.75674 2H17.2393C17.5066 2 17.7585 1.99999 17.9954 2.00161C17.3101 2.51705 16.7616 3.20458 16.4141 4L6.79805 4C5.94147 4 5.35918 4.00078 4.90909 4.03755C4.47067 4.07337 4.24647 4.1383 4.09007 4.21799C3.71374 4.40973 3.40778 4.7157 3.21604 5.09202C3.13635 5.24842 3.07142 5.47262 3.0356 5.91104C2.99883 6.36113 2.99805 6.94342 2.99805 7.8V8H16.4141C16.762 8.79625 17.3113 9.48438 17.9976 10H2.99805V16.2C2.99805 17.0566 2.99883 17.6389 3.0356 18.089C3.07142 18.5274 3.13635 18.7516 3.21604 18.908C3.40778 19.2843 3.71374 19.5903 4.09007 19.782C4.24647 19.8617 4.47067 19.9266 4.90909 19.9624C5.35917 19.9992 5.94147 20 6.79805 20H17.198C18.0546 20 18.6369 19.9992 19.087 19.9624C19.5254 19.9266 19.7496 19.8617 19.906 19.782C20.2824 19.5903 20.5883 19.2843 20.7801 18.908C20.8598 18.7516 20.9247 18.5274 20.9605 18.089C20.9973 17.6389 20.998 17.0566 20.998 16.2V11C21.7092 11 22.3856 10.8516 22.998 10.584Z"
        stroke="none"
        fill="currentColor"
        fillRule="evenodd"
      />
    </>
  ),
})
