import { isString, pickBy } from "lodash"
import qs, { IStringifyOptions } from "qs"

import { isPresent } from "Utilities/values"

import {
  decodeValueFromURLUse,
  encodeValueForURLUse,
} from "./encode-values-for-url"

const searchToQueryString = (search: string) => search.substr(1)

// Returns query string without leading question mark.
const getQueryString = () => searchToQueryString(window.location.search)

export function stringify(valueByKey: Record<string, any>) {
  return qs.stringify(pickBy(valueByKey, isPresent))
}

const prettyOptions: Readonly<IStringifyOptions> = {
  // Rails expects arrays to be provided like so: `?foo[]=a&foo[]=b`. Using
  // the default setting of `?foo[0]=a&foo[1]=b` creates a `Hash` instead.
  arrayFormat: "brackets",
  // This prevent HTML encoding, which looks ugly in the address bar and link
  // sharing field.
  encode: false,
}

function prettyStringify(
  valueByKey: Record<string, any>,
  options?: IStringifyOptions
) {
  return qs.stringify(pickBy(valueByKey, isPresent), {
    ...prettyOptions,
    ...options,
  })
}

export function appendQueryString(
  path: string,
  value: Record<string, any> | string
) {
  const queryString = isString(value) ? value : prettyStringify(value)

  return queryString.length === 0 ? path : path + `?${queryString}`
}

export function getParsedQueryString(): Record<string, any> {
  return qs.parse(getQueryString())
}

export function getSearchParamsForPathValues(
  path: string,
  value: any | any[],
  searchParams: URLSearchParams
) {
  const encodedValues = encodeValueForURLUse(value)

  if (encodedValues === "") {
    searchParams.delete(path)
  } else {
    searchParams.set(path, encodedValues)
  }
  return searchParams
}

export function mergeSearchParams(...searchParamsArray: URLSearchParams[]) {
  return new URLSearchParams({
    ...Object.fromEntries(
      searchParamsArray.flatMap((searchParams) => [...searchParams])
    ),
  })
}

export function getQueryStringPath(path: string, value: any) {
  const url = new URL(window.location.href)

  const newSearchParams = getSearchParamsForPathValues(
    path,
    value,
    new URLSearchParams(url.search)
  )

  return newSearchParams.toString()
}

// BY PATH
// this is using JSON.stringify and JSON.parse because he qs.stringify and qs.parse do not respect numbers (anything other than string)
// The objects are base64 encoded to make it prettier / more opaque in the URL.
export function pushQueryStringPath(path: string, value: any) {
  const url = new URL(window.location.href)

  const newSearch = getQueryStringPath(path, value)

  // Check if search is the same as in the url before
  // If it is, we don't want to push state to the history since it adds unnecessary history entries
  // This has caused an issue on the test results page where navigating to it caused 3 new entries to appear in the history
  if (newSearch === url.search) return

  url.search = newSearch
  history.pushState(null, "", url)
}

export function getParsedQueryStringByPath<Value>(
  path: string
): Value | undefined {
  const searchParams = new URLSearchParams(window.location.search)
  const base64value = searchParams.get(path)

  if (!base64value) return undefined

  return decodeValueFromURLUse(base64value)
}
