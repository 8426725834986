import { theme } from "@chakra-ui/react"
import { smallSizes } from "./smallSizes"

export const sizes = {
  ...theme.sizes,
  ...smallSizes,
  container: {
    ...theme.sizes.container,
    "2xl": "1920px",
  },
  "8xl": "84rem",
}
