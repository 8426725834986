import { Badge as ChakraBadge, forwardRef } from "@chakra-ui/react"
import { tokens } from "DesignSystem/tokens"
import React from "react"

export const BADGE_VARIANTS = ["subtle", "bold"] as const
export const BADGE_COLOR_SCHEMES = [
  "default",
  "success",
  "warning",
  "danger",
  "selected",
  "upgrade",
  "new",
] as const

export type BadgeVariant = (typeof BADGE_VARIANTS)[number]
export type BadgeColorScheme = (typeof BADGE_COLOR_SCHEMES)[number]

export type BadgeProps = {
  label: React.ReactNode
  variant?: BadgeVariant
  colorScheme?: BadgeColorScheme
}

/**
 * - Use `variant` and `colorScheme` to control appearance
 *   - `variant: "subtle" | "bold"`
 *   - `colorScheme: "default" | "success" | "warning" | "danger" | "selected" | "upgrade" | "new"`
 */

export const Badge = forwardRef<BadgeProps, "span">(
  ({ label, variant = "subtle", colorScheme = "default" }, ref) => {
    const colorName = colorScheme === "default" ? "neutral" : colorScheme
    const textColor =
      variant === "bold"
        ? colorName === "upgrade"
          ? "ds.text.default"
          : "ds.text.inverse"
        : `ds.text.${colorScheme}`
    const backgroundColor =
      variant === "subtle" && colorScheme === "default"
        ? "ds.background.neutral.resting"
        : `ds.background.${colorName}.${variant}.resting`

    return (
      <ChakraBadge
        ref={ref}
        size="xs"
        borderRadius="3px"
        bg={backgroundColor}
        color={textColor}
        // We can't apply these the normal way because Chakra's Badge
        // implementation overrides them
        sx={{ ...tokens.textStyles.ds.interface.xsmall, lineHeight: "1rem" }}
      >
        {label}
      </ChakraBadge>
    )
  }
)

Badge.displayName = "Badge"

/**
 * Tokens used in this component
 * Remember to update this list if you change the tokens in the component
 *
 * ds.background.neutral.resting
 * ds.background.neutral.bold.resting
 * ds.background.success.subtle.resting
 * ds.background.success.bold.resting
 * ds.background.warning.subtle.resting
 * ds.background.warning.bold.resting
 * ds.background.danger.subtle.resting
 * ds.background.danger.bold.resting
 * ds.background.selected.subtle.resting
 * ds.background.selected.bold.resting
 * ds.background.upgrade.subtle.resting
 * ds.background.upgrade.bold.resting
 * ds.background.new.subtle.resting
 * ds.background.new.bold.resting
 * ds.text.default
 * ds.text.inverse
 * ds.text.success
 * ds.text.warning
 * ds.text.danger
 * ds.text.selected
 * ds.text.upgrade
 * ds.text.new
 */
