import { Box, Flex, Text } from "@chakra-ui/react"
import { Button, Tag } from "DesignSystem/components"
import { cq, cqContainer } from "Shared/helpers/containerQuerySx"
import { BookOpen01OutlineIcon } from "Shared/icons/untitled-ui/BookOpen01OutlineIcon"
import React, { PropsWithChildren, ReactNode } from "react"
import { Link } from "react-router-dom"

type Props = {
  studyTypeBadge: ReactNode
  incentiveText: string
  estimatedLengthText: string
  tags?: string[]
  participateUrl: string
}

const BREAKPOINT_MAX = "23rem"

export const AssignmentCard: React.FC<PropsWithChildren<Props>> = ({
  studyTypeBadge,
  incentiveText,
  estimatedLengthText,
  tags = [],
  children,
  participateUrl,
}) => {
  return (
    <Box
      rounded={["12px", "16px"]}
      bg="ds.surface.raised.resting"
      boxShadow="ds.raised"
      p={[4, 6]}
      sx={cqContainer()}
    >
      <Flex
        gap={6}
        flexDirection="column"
        sx={cq(BREAKPOINT_MAX, { flexDirection: "row" })}
      >
        <Flex flexDirection="column" align="flex-start" gap={4}>
          {studyTypeBadge}

          <Flex direction="column" gap={2}>
            <Flex align="center" gap={2}>
              <BookOpen01OutlineIcon boxSize={5} color="ds.icon.subtle" />

              <Text textStyle="ds.display.primary" color="ds.text.default">
                {incentiveText}
              </Text>
            </Flex>

            <Text
              textStyle="ds.paragraph.secondary"
              color="ds.text.subtle"
              whiteSpace="nowrap"
            >
              {estimatedLengthText}
            </Text>
          </Flex>
        </Flex>

        <Flex direction="column" align="flex-start" gap={6}>
          <Flex direction="column" gap={4}>
            <Flex gap={2}>
              {tags.map((tag) => (
                <Tag key={tag} variant="subtle" label={tag} />
              ))}
            </Flex>

            {children}
          </Flex>

          <Button as={Link} variant="primary" to={participateUrl}>
            Take now
          </Button>
        </Flex>
      </Flex>
    </Box>
  )
}
