export function getJsonFromScriptElement<T = any>(
  id: string,
  defaultValue?: T
): T {
  const element = document.getElementById(id)
  if (element) {
    return JSON.parse(element.innerHTML) as T
  }
  if (defaultValue !== undefined) {
    return defaultValue
  }
  throw new Error(`Couldn${"\u2019"}t find element with id '${id}'`)
}
