import { keyBy } from "lodash"

import {
  CheckboxQuestion,
  ClientId,
  UsabilityTestSectionQuestion as Question,
  QuestionType,
  RadioQuestion,
  UsabilityTestSection,
} from "Types"

const getOrderedQuestions = (sections: UsabilityTestSection[]): Question[] =>
  sections.flatMap((section) => section.questions.flat())

export const getQuestionWithClientIdIn = (
  questionClientId: ClientId,
  sections: UsabilityTestSection[]
): Question | void =>
  keyBy(getOrderedQuestions(sections), "_clientId")[questionClientId]

export const getQuestionPrefix = (
  sectionNumber: number,
  questionIndex: number,
  omitDot = false
): string => {
  return `${sectionNumber}.${questionIndex + 1}${!omitDot ? "." : ""}`
}

export function isRequirable(questionType: QuestionType): boolean {
  switch (questionType) {
    case QuestionType.Ranking:
      return false
    case QuestionType.CheckboxMultipleChoice:
    case QuestionType.LinearScale:
    case QuestionType.LongAnswer:
    case QuestionType.RadioMultipleChoice:
    case QuestionType.ShortAnswer:
      return true
  }
}

export function defaultRandomized(questionType: QuestionType): boolean {
  switch (questionType) {
    case QuestionType.Ranking:
      return true
    case QuestionType.CheckboxMultipleChoice:
    case QuestionType.LinearScale:
    case QuestionType.LongAnswer:
    case QuestionType.RadioMultipleChoice:
    case QuestionType.ShortAnswer:
      return false
  }
}

export function isMultipleAnswer(questionType: QuestionType): boolean {
  switch (questionType) {
    case QuestionType.CheckboxMultipleChoice:
    case QuestionType.Ranking:
      return true
    case QuestionType.LinearScale:
    case QuestionType.LongAnswer:
    case QuestionType.RadioMultipleChoice:
    case QuestionType.ShortAnswer:
      return false
  }
}

export function isMultipleChoice(questionType: QuestionType): boolean {
  switch (questionType) {
    case QuestionType.CheckboxMultipleChoice:
    case QuestionType.RadioMultipleChoice:
    case QuestionType.Ranking:
      return true
    case QuestionType.ShortAnswer:
    case QuestionType.LongAnswer:
    case QuestionType.LinearScale:
      return false
  }
}

export function minimumMultipleChoiceOptionCount(
  questionType: QuestionType
): number {
  switch (questionType) {
    case QuestionType.CheckboxMultipleChoice:
    case QuestionType.RadioMultipleChoice:
    case QuestionType.Ranking:
      return 2
    case QuestionType.ShortAnswer:
    case QuestionType.LongAnswer:
    case QuestionType.LinearScale:
      return 0
  }
}

export function maximumMultipleChoiceOptionCount(
  questionType: QuestionType
): number {
  switch (questionType) {
    case QuestionType.CheckboxMultipleChoice:
    case QuestionType.RadioMultipleChoice:
      return Infinity
    case QuestionType.Ranking:
      return 10
    case QuestionType.ShortAnswer:
    case QuestionType.LongAnswer:
    case QuestionType.LinearScale:
      return 0
  }
}

export function isOtherAnswerValid(questionType: QuestionType): boolean {
  switch (questionType) {
    case QuestionType.CheckboxMultipleChoice:
    case QuestionType.RadioMultipleChoice:
      return true
    case QuestionType.ShortAnswer:
    case QuestionType.LongAnswer:
    case QuestionType.LinearScale:
    case QuestionType.Ranking:
      return false
  }
}

export function isRadioQuestion(question: Question): question is RadioQuestion {
  return question.type === QuestionType.RadioMultipleChoice
}

export function isLinearScaleQuestion(question: Question): boolean {
  return question.type === QuestionType.LinearScale
}

export function isCheckboxQuestion(
  question: Question
): question is CheckboxQuestion {
  return question.type === QuestionType.CheckboxMultipleChoice
}

interface Option {
  label: string
  value: QuestionType
}
const QUESTION_OPTIONS: ReadonlyArray<Option> = [
  { label: "Short text", value: QuestionType.ShortAnswer },
  { label: "Long text", value: QuestionType.LongAnswer },
  {
    label: "Single Choice",
    value: QuestionType.RadioMultipleChoice,
  },
  {
    label: "Multiple Choice",
    value: QuestionType.CheckboxMultipleChoice,
  },
  { label: "Linear scale", value: QuestionType.LinearScale },
  { label: "Ranking", value: QuestionType.Ranking },
]
export const getQuestionTypeLabel = (type: QuestionType): string => {
  const found = QUESTION_OPTIONS.find((option) => option.value === type)
  return found ? `${found.label} question` : "Question"
}
