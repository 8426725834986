// JsFromRoutes CacheKey 7e2aa536e954004ec67b8bfff604d82a
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  create: /* #__PURE__ */ definePathHelper('post', '/panelist_notification_subscriptions'),
  get: /* #__PURE__ */ definePathHelper('get', '/panelist_notification_subscriptions/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/panelist_notification_subscriptions/:id'),
}
