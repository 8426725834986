import { Role } from "Types"

export function ageToYearOfBirth(age: number | null) {
  return age !== null ? new Date().getFullYear() - age : null
}

export function userRoleString(role: Role): string {
  if (role === "admin") return "an admin"
  if (role === "member") return "an editor"
  if (role === "guest") return "a viewer"

  throw new Error(`Role ${role} is unsupported`)
}
