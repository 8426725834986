import { Icon } from "@chakra-ui/react"
import { CheckOutlineIcon } from "Shared/icons/untitled-ui/CheckOutlineIcon"
import { SlashCircle01OutlineIcon } from "Shared/icons/untitled-ui/SlashCircle01OutlineIcon"
import { XOutlineIcon } from "Shared/icons/untitled-ui/XOutlineIcon"
import React from "react"

export const Checkmark = () => <Icon as={CheckOutlineIcon} color="green.300" />

export const Cross = () => <Icon as={XOutlineIcon} color="red.500" />

export const NotApplicable = () => (
  <Icon as={SlashCircle01OutlineIcon} color="gray.400" />
)
