import React, { useCallback, useEffect, useMemo, useState } from "react"
import { matchPath, useLocation, useNavigate } from "react-router"

import { ModeratedStudyApplicationFlow } from "~/api/generated/usabilityhubResponses"

interface ModeratedStudyApplication {
  application: ModeratedStudyApplicationFlow
  nextPageLink: string | undefined
  invalidateApplicationQuery: () => Promise<void>
  navigateToThankYouPage: () => void
}

// ts-prune-ignore-next used in test
export type PageKey =
  | "apply"
  | "previewApply"
  | "questions"
  | "book"
  | "confirmed"
  | "reschedule"
  | "thankyou"
  | "join"
  | "closed"
  | "declineReschedule"
  | "declineInvitation"
type NextPageKey = Exclude<PageKey, "apply">

const ModeratedStudyApplicationContext =
  React.createContext<ModeratedStudyApplication | null>(null)

type ModeratedStudyApplicationProviderProps = {
  application: ModeratedStudyApplicationFlow
  invalidateApplicationQuery: () => Promise<void>
}

const routePaths: {
  [key in PageKey]: string
} = {
  apply: "/apply/:moderatedStudyRecruitmentLinkToken",
  previewApply: "apply",
  questions: "questions",
  book: "book",
  confirmed: "confirmed",
  reschedule: "reschedule",
  thankyou: "thankyou",
  join: "join",
  closed: "closed",
  declineReschedule: "decline-reschedule",
  declineInvitation: "decline-invitation",
}

// ts-prune-ignore-next used in test
export type PageState = {
  canBook: boolean
  declinedRescheduleRequest: boolean | null
  hasActiveBooking: boolean | null
  hasCanceledBooking: boolean
  hasSubmittedScreener: boolean
  isAllowedToBook: boolean
  showBookingPage: boolean
  showScreenerQuestions: boolean
  isInvited: boolean
  missingApplicant: boolean
  missingPanelist: boolean
}

const newPageState = (
  application: ModeratedStudyApplicationFlow
): PageState => {
  const showScreenerQuestions =
    !application.is_invited && application.screener_required

  // Either didn't need to fill out a screener or has been screened in
  const hasMetScreener: boolean =
    !showScreenerQuestions ||
    (application.has_submitted_screener && application.screener_result)

  // This can be true only for the automatic mode
  const recruitmentLinkFulfilled =
    application.recruitment_link?.fulfilled && !application.booking

  const activeRescheduleRequest =
    application.booking?.state === "rescheduled_by_researcher" &&
    !application.booking?.declined_reschedule_request

  const showBookingPage =
    !!application.panelist ||
    (application.recruitment_link?.recruitment_mode === "automatic" &&
      !recruitmentLinkFulfilled) ||
    activeRescheduleRequest

  const hasCanceledBooking = !!(
    application.booking &&
    String(application.booking.state).startsWith("canceled")
  )
  const hasActiveBooking =
    application.booking && application.booking.state === "booked"

  const noExistingBooking = !application.booking || hasCanceledBooking

  const isPanelist = !!application.panelist
  const isApplicant = !!application.applicant
  const isHandpick = application.panelist?.is_handpick ?? false
  const isInvited = application.is_invited
  const accountHasQuota = application.recruitment_link?.quota_available ?? false

  const isAllowedToBook =
    ((isPanelist && (!isHandpick || isInvited)) ||
      (isApplicant && accountHasQuota)) &&
    (application.is_invited || (showBookingPage && hasMetScreener)) &&
    !application.declined

  const canBook =
    isAllowedToBook && (noExistingBooking || activeRescheduleRequest)

  const declinedRescheduleRequest =
    application.booking?.state === "rescheduled_by_researcher" &&
    application.booking?.declined_reschedule_request

  const missingApplicant = application.applicant === null
  const missingPanelist = application.panelist === null
  const hasSubmittedScreener = application.has_submitted_screener

  return {
    canBook,
    declinedRescheduleRequest,
    hasActiveBooking,
    hasCanceledBooking,
    hasSubmittedScreener,
    isAllowedToBook,
    showBookingPage,
    showScreenerQuestions,
    isInvited,
    missingApplicant,
    missingPanelist,
  }
}

// ts-prune-ignore-next used in test
export const calculateNextPageKey = (
  currentPageKey: PageKey,
  {
    hasActiveBooking,
    isAllowedToBook,
    showBookingPage,
    showScreenerQuestions,
    isInvited,
    missingApplicant,
    missingPanelist,
  }: PageState
): NextPageKey | undefined => {
  switch (currentPageKey) {
    case "previewApply":
    case "apply":
      if (showScreenerQuestions) {
        return "questions"
      } else if (showBookingPage) {
        return "book"
      } else {
        return "thankyou"
      }
    case "questions":
      if (showBookingPage) {
        return "book"
      } else if (hasActiveBooking) {
        return "confirmed"
      } else {
        return "thankyou"
      }
    case "book":
      if (hasActiveBooking || isAllowedToBook) {
        // If we've already got an active booking, we'll be redirected to the
        // confirmed page. Otherwise if we're on the booking page and we're
        // allowed to book, the next page will be the confirmed page.
        return "confirmed"
      } else if (isInvited || (missingApplicant && missingPanelist)) {
        // When an applicant is deleted, the invitation will be deleted at
        // the same time. So when the applicant is null, the "closed" page
        // will be shown instead of the "thank you" page.
        //
        // TODO: As "Cancel Invitation" button will be added in Participant
        // Info Drawer (PRD-3346), we should confirm that the "closed" page
        // should be shown when the invitation is canceled.
        return "closed"
      } else {
        // Under normal conditions, when an applicant opens the booking page
        // but they're not allowed to make a booking, it'll be redirected to
        // "thank you" page.
        return "thankyou"
      }
    case "reschedule":
      return "confirmed"
    case "declineInvitation":
      // No next page
      return undefined
    default:
    // No next page
  }
}

// ts-prune-ignore-next used in test
export const calculateUnfinishedPage = (
  currentPageKey: PageKey,
  nextPageKey: NextPageKey | undefined,
  currentPageIsAfterTheUnfinishedPage: (unfinishedPageKey: PageKey) => boolean,
  {
    canBook,
    declinedRescheduleRequest,
    hasCanceledBooking,
    hasSubmittedScreener,
    showBookingPage,
    showScreenerQuestions,
  }: PageState
): string | null => {
  if (currentPageKey !== "declineReschedule" && declinedRescheduleRequest) {
    // If we have a declined reschedule request, there's nothing else the
    // participant can do except view the declined page
    return `${routePaths["declineReschedule"]}`
  } else if (currentPageKey === "confirmed" && hasCanceledBooking) {
    // Do nothing
  } else if (
    (currentPageKey === "questions" && hasSubmittedScreener) ||
    (currentPageKey === "book" && !canBook)
  ) {
    // Navigate to next page if the current page is finished
    if (nextPageKey) {
      return `${routePaths[nextPageKey]}`
    } else {
      return null
    }
  } else if (
    showScreenerQuestions &&
    !hasSubmittedScreener &&
    currentPageIsAfterTheUnfinishedPage("questions")
  ) {
    // Navigate back to screener questions if they haven't been submitted
    return `questions`
  } else if (
    hasCanceledBooking &&
    currentPageIsAfterTheUnfinishedPage("confirmed")
  ) {
    // Navigate back to canceled page if the booking is canceled
    return `confirmed`
  } else if (
    showBookingPage &&
    canBook &&
    currentPageIsAfterTheUnfinishedPage("book") &&
    currentPageKey !== "thankyou" &&
    currentPageKey !== "declineReschedule"
  ) {
    // Navigate back to booking page if it hasn't been submitted
    return `book`
  }

  // Stay on the current page and set the next page
  return null
}

export const ModeratedStudyApplicationProvider: React.FC<
  React.PropsWithChildren<ModeratedStudyApplicationProviderProps>
> = ({ application, invalidateApplicationQuery, children }) => {
  const navigate = useNavigate()
  const location = useLocation()

  // We could be under three different parent routes depending on whether this is a preview
  // or a real application.
  const isCalendarOnlyPreview = matchPath(
    { path: "/interviews/:moderatedStudyId/edit" },
    location.pathname
  )
  const isFullPreview = matchPath(
    { path: "/interviews/:moderatedStudyId/preview/*" },
    location.pathname
  )
  const isPreview = isCalendarOnlyPreview || isFullPreview
  const routePrefix = isPreview
    ? `/interviews/${isPreview.params["moderatedStudyId"]}/preview/`
    : `/applications/${application.moderated_study_application_id}/`

  const currentPageKey = useMemo(() => {
    return Object.keys(routePaths).find((routePathKey: PageKey) => {
      const routePrefix = isCalendarOnlyPreview
        ? "/interviews/:moderatedStudyId/edit"
        : isFullPreview
          ? "/interviews/:moderatedStudyId/preview/"
          : "/applications/:moderatedStudyApplicationId/"
      const routePath = routePaths[routePathKey].startsWith("/")
        ? routePaths[routePathKey]
        : `${routePrefix}${routePaths[routePathKey]}`

      return matchPath({ path: routePath }, location.pathname)
    }) as PageKey
  }, [location])

  const [nextPage, setNextPage] = useState<NextPageKey>()

  const currentPageIsAfterTheUnfinishedPage = useCallback(
    (unfinishedPageKey: PageKey) => {
      if (!currentPageKey) return false

      const currentPageKeyIndex = Object.keys(routePaths).findIndex(
        (routePathKey: PageKey) => {
          return routePathKey === currentPageKey
        }
      )

      const unfinishedPageKeyIndex = Object.keys(routePaths).findIndex(
        (routePathKey: PageKey) => {
          return routePathKey === unfinishedPageKey
        }
      )

      return currentPageKeyIndex > unfinishedPageKeyIndex
    },
    [currentPageKey]
  )

  useEffect(() => {
    if (!currentPageKey) return

    const pageState = newPageState(application)
    const nextPageKey = calculateNextPageKey(currentPageKey, pageState)

    if (["questions", "book"].includes(currentPageKey) && !nextPageKey)
      throw new Error("Something went wrong! Cannot find next page.")

    const unfinishedPage = calculateUnfinishedPage(
      currentPageKey,
      nextPageKey,
      currentPageIsAfterTheUnfinishedPage,
      pageState
    )
    if (unfinishedPage) {
      // If there is an unfinished page, navigate there now
      navigate(`${routePrefix}${unfinishedPage}${location.search}`, {
        replace: true,
      })
    } else if (nextPageKey) {
      // Stay on the current page and set the next page
      setNextPage(nextPageKey)
    }
  }, [
    application,
    currentPageKey,
    currentPageIsAfterTheUnfinishedPage,
    navigate,
  ])

  const nextPageLink =
    application && nextPage
      ? `${routePrefix}${nextPage}${location.search}`
      : undefined

  const navigateToThankYouPage = () =>
    navigate(`${routePrefix}thankyou${location.search}`, { replace: true })

  return (
    <ModeratedStudyApplicationContext.Provider
      value={{
        application,
        nextPageLink,
        invalidateApplicationQuery,
        navigateToThankYouPage,
      }}
    >
      {children}
    </ModeratedStudyApplicationContext.Provider>
  )
}

export const useModeratedStudyApplicationContext =
  (): ModeratedStudyApplication => {
    const context = React.useContext(ModeratedStudyApplicationContext)

    if (!context) {
      throw new Error(
        `useModeratedStudyApplicationContext must be rendered within the ModeratedStudyApplicationProvider`
      )
    }

    return context
  }
