import { DepGraph } from "dependency-graph"
import { forEach, pick } from "lodash"

/* -- Initialize state --
 *
 * This is a system for mutating the `initialState` from our server before it's
 * passed to Redux's `createStore`.
 *
 * Typically this is used to ensure that it conforms to the interfaces we use
 * internally, for example adding `_isBusy` to resources that can be affected
 * by AJAX operations, or `_clientId` IDs that can be used with unpersisted
 * records.
 *
 * In some cases (such as the test form's `UsabilityTestSection`) we create
 * secondary PK/FK relations that rely on `_clientId`. In these we must also
 * look at other sections of the store make use of the `dependencies` argument.
 *
 * Usage:
 *
 * const currentUserInitializer = createInitializer<State, "currentUser", "usabilityTests" | "orders">(
 *   (user, { usabilityTests, orders }) => {
 *     user._usabilityTestCount = countBy(usabilityTests, ownedBy(user.id))
 *     user._orderCount = countBy(orders, ownedBy(user.id))
 *   },
 *   ["usabilityTests", "orders"]
 * )
 *
 * const rawInitialState = ...
 *
 * const store = createStore<State>(
 *   reducer,
 *   initializeState(
 *     rawInitialState, {
 *        currentUser: currentUserInitializer,
 *     }
 *   )
 * )
 */

export function createInitializer<
  TRaw extends {},
  TState,
  TKey extends keyof TRaw & keyof TState,
  D extends keyof TRaw & keyof TState = never,
>(
  initialize: (
    rawSubState: TRaw[TKey],
    dependencies: Readonly<Pick<TState, D>>
  ) => TState[TKey],
  dependencyNames: ReadonlyArray<D> = []
): StateInitializer<TRaw, TState, TKey, D> {
  return {
    initialize,
    dependencyNames,
  }
}

interface StateInitializer<
  TRaw extends Record<string, unknown>,
  TState,
  TKey extends keyof TRaw & keyof TState,
  D extends keyof TRaw & keyof TState = never,
> {
  dependencyNames: ReadonlyArray<D>
  initialize(
    rawSubState: TRaw[TKey],
    dependencies: Partial<Pick<TState, D>>
  ): TState[TKey]
}

export type InitializerMap<
  TRaw extends Record<string, unknown>,
  TState,
  TKey extends keyof TRaw & keyof TState,
> = {
  [K in TKey]?: StateInitializer<TRaw, TState, K, keyof TRaw & keyof TState>
}

export default function initializeState<
  TRaw extends {},
  TState extends {},
  TKey extends keyof TRaw & keyof TState,
>(rawState: TRaw, initializers: InitializerMap<TRaw, TState, TKey>): TState {
  const graph = new DepGraph({})
  forEach(rawState, (subState, name) => {
    graph.addNode(name, subState)
  })
  Object.entries(initializers).forEach(([name, { dependencyNames }]: any) => {
    if (rawState[name as TKey] !== undefined) {
      dependencyNames.forEach((dependencyName: keyof TRaw) => {
        if (rawState[dependencyName] !== undefined) {
          graph.addDependency(name, dependencyName.toString())
        }
      })
    }
  })
  const result: Record<string, any> = Object.assign({}, rawState)
  graph.overallOrder().forEach((name) => {
    const initializer: StateInitializer<TRaw, TState, TKey, any> | undefined =
      initializers[name as TKey]
    if (initializer !== undefined) {
      const { dependencyNames, initialize } = initializer
      const dependencies = pick(result, dependencyNames)
      result[name] = initialize(rawState[name as TKey], dependencies)
    }
  })
  return result as TState
}
