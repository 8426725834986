import { useToast } from "@chakra-ui/react"
import { DemographicAttributeGroupForPanelist, Fluency } from "Types"
import { useEffect } from "react"
import {
  useDemographicProfileForUser,
  useDemographicProfileIndex,
} from "~/api/generated/usabilityhub-components"

export interface DemographicProfileData {
  demographicAttributeGroups: DemographicAttributeGroupForPanelist[]
  allInitialSelectedOptions: number[]
  formattedAddress: string | null
  googlePlaceId: string | null
  fluencies: Fluency[]
  yearOfBirth: number | null
}

export const useDemographicProfileData = (): {
  data: DemographicProfileData | null
} => {
  const toast = useToast()

  const { data: profile, isError: profileLoadError } =
    useDemographicProfileForUser({})
  const { data: index, isError: indexLoadError } = useDemographicProfileIndex(
    {}
  )

  useEffect(() => {
    if (profileLoadError || indexLoadError) {
      toast({
        title: `Couldn${"\u2019"}t load profile data. Please refresh the page and try again.`,
        duration: null,
        status: "error",
      })
    }
  }, [profileLoadError, indexLoadError])

  if (profile && index) {
    return {
      data: {
        demographicAttributeGroups: index,
        allInitialSelectedOptions:
          profile.selected_demographic_attribute_option_ids,
        formattedAddress: profile.formatted_address,
        googlePlaceId: profile.google_place_id,
        fluencies: profile.fluencies,
        yearOfBirth: profile.year_of_birth,
      },
    }
  } else {
    return { data: null }
  }
}
