import { Box, BoxProps, Flex, ModalBody } from "@chakra-ui/react"
import { cqContainer } from "Shared/helpers/containerQuerySx"
import React, { PropsWithChildren, ReactNode } from "react"
import { DisplayModal } from "./DisplayModal"

type Props = PropsWithChildren<{
  content: ReactNode
  controls: ReactNode
  topHeight?: BoxProps["h"]
}>

export const TwoPaneContent: React.FC<Props> = ({
  content,
  children,
  controls,
  topHeight = ["259px", "280px", "auto"],
}) => (
  <ModalBody
    display="flex"
    flexDirection={["column-reverse", null, "row"]}
    h="full"
    p={0}
  >
    <Flex
      direction="column"
      h="full"
      flexGrow={[1, 0, 1]}
      flexBasis={["unset", null, 0]}
      mt={[0, 6, 0]}
    >
      <Box
        pos="relative"
        flexGrow={1}
        gap={4}
        pt={[6, 0]}
        ps={[6, 0, 4]}
        pe={[6, 0, 10]}
        pb={6}
        maxH={[
          // Screen height - image height - padding - commands
          "calc(100dvh - 250px - 40px - 56px)",
          // Screen height - image height - padding - commands
          "calc(100dvh - 280px - 62px - 48px)",
          // Image height - padding - commands
          "calc(500px - 48px - 32px)",
        ]}
        overflowY="auto"
      >
        <Flex
          direction="column"
          justify={["flex-start", null, "center"]}
          align="flex-start"
          minH="100%"
        >
          {children}
        </Flex>
      </Box>

      {controls && <DisplayModal.Controls>{controls}</DisplayModal.Controls>}
    </Flex>

    <Flex
      direction={["row", "column"]}
      flexGrow={[0, null, 1]}
      flexShrink={0}
      flexBasis={["initial", null, 0]}
      h={topHeight}
      sx={cqContainer("size")}
    >
      {content}
    </Flex>
  </ModalBody>
)
