import { Button, FormControl, FormLabel, Input } from "@chakra-ui/react"
import React, { ChangeEvent, useState } from "react"

import { RailsForm } from "Components/form/form"
import {
  Card,
  CardBody,
  CardFooter,
  LegacyCardHeader,
} from "Shared/components/Card/Card"
import { userPasswordPath } from "Shared/constants/routes"
import { useQueryStringParams } from "Shared/hooks/useQueryStringParams"

export const ResetPasswordForm = () => {
  const query = useQueryStringParams()
  const defaultEmail = query.get("email") || ""
  const [email, setEmail] = useState(defaultEmail)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const handleSubmit = () => setIsSubmitting(true)
  return (
    <RailsForm
      method="POST"
      action={userPasswordPath()}
      onSubmit={handleSubmit}
    >
      <Card>
        <LegacyCardHeader size="lg">Request password reset</LegacyCardHeader>
        <CardBody>
          <FormControl>
            <FormLabel htmlFor="email">Email address</FormLabel>
            <Input
              autoFocus
              id="email"
              isRequired
              name="user[email]"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setEmail(event.target.value)
              }
              type="email"
              value={email}
            />
          </FormControl>
          <CardFooter>
            <Button
              isDisabled={!email}
              isLoading={isSubmitting}
              loadingText="Sending reset password instruction"
              type="submit"
              colorScheme="brand.primary"
              width="full"
            >
              Send me password reset instructions
            </Button>
          </CardFooter>
        </CardBody>
      </Card>
    </RailsForm>
  )
}
