import type { QueryKey, UseQueryOptions } from "@tanstack/react-query"
import { camelCase } from "lodash"

import { QueryOperation } from "./usabilityhub-components"

export type UsabilityhubContext = {
  fetcherOptions: {
    /**
     * Headers to inject in the fetcher
     */
    headers?: Record<string, string>
    /**
     * Query params to inject in the fetcher
     */
    queryParams?: {}
  }
  queryOptions: {
    /**
     * Set this to `false` to disable automatic refetching when the query mounts or changes query keys.
     * Defaults to `true`.
     */
    enabled?: boolean
  }
  /**
   * Query key manager.
   */
  queryKeyFn: (operation: QueryOperation) => QueryKey
}

/**
 * Context injected into every react-query hook wrappers
 *
 * @param queryOptions options from the useQuery wrapper
 */
export function useUsabilityhubContext<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  _queryOptions?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    "queryKey" | "queryFn"
  >
): UsabilityhubContext {
  return {
    fetcherOptions: {},
    queryOptions: {},
    queryKeyFn: (operation) => {
      const queryKey: unknown[] = hasPathParams(operation)
        ? operation.path
            .split("/")
            .filter(Boolean)
            .map((i) => resolvePathParam(i, operation.variables.pathParams))
        : operation.path.split("/").filter(Boolean)

      if (hasQueryParams(operation)) {
        queryKey.push(operation.variables.queryParams)
      }

      if (hasBody(operation)) {
        queryKey.push(operation.variables.body)
      }

      return queryKey
    },
  }
}

// Helpers
export const resolvePathParam = (
  key: string,
  pathParams: Record<string, string>
) => {
  if (key.startsWith("{") && key.endsWith("}")) {
    // OpenAPI codegen transforms param name from snake_case to camelCase
    // when checking which value this param has, it can't match them up anymore
    // The key (in url) needs to be transformed as well, which we do manually here
    // Ideally the codegen applies the same transformation to the param name in the url as well
    const correctedPathParamName = camelCase(key.slice(1, -1))
    return pathParams[correctedPathParamName]
  }
  return key
}

const hasPathParams = (
  operation: QueryOperation
): operation is QueryOperation & {
  variables: { pathParams: Record<string, string> }
} => {
  return Boolean((operation.variables as any).pathParams)
}

const hasBody = (
  operation: QueryOperation
): operation is QueryOperation & {
  variables: { body: Record<string, unknown> }
} => {
  return Boolean((operation.variables as any).body)
}

const hasQueryParams = (
  operation: QueryOperation
): operation is QueryOperation & {
  variables: { queryParams: Record<string, unknown> }
} => {
  return Boolean((operation.variables as any).queryParams)
}
