import { createIcon } from "@chakra-ui/react"
import React from "react"

export const ChevronUpOutlineIcon = createIcon({
  displayName: "ChevronUpOutlineIcon",
  viewBox: "0 0 24 24",
  defaultProps: { fill: "none" },
  path: (
    <path
      d="M18 15L12 9L6 15"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
})
