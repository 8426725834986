import {
  Box,
  Portal,
  ResponsiveValue,
  useId,
  useMergeRefs,
} from "@chakra-ui/react"
import React, {
  forwardRef,
  PropsWithChildren,
  Ref,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react"
import { usePopper } from "react-popper"
import { GroupContents } from "./Group"
import { Provider, useSelect } from "./Provider"
import { SearchResults } from "./SearchResults"
import { SelectPath, SelectValue } from "./types"
import { sameWidth } from "./util"

type ContentsProps = PropsWithChildren<{
  path?: SelectPath
  isMulti?: boolean
  onPathChange?: (path: SelectPath) => void
  onResize?: (height: number) => void
}>

export const Contents = ({
  isMulti = false,
  onResize,
  children,
  ...props
}: ContentsProps) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null)

  useLayoutEffect(() => {
    if (!container || !onResize) return

    const mutationObserver = new MutationObserver(() => {
      onResize(container.scrollHeight)
    })
    mutationObserver.observe(container, {
      childList: true,
      attributes: true,
      attributeFilter: ["data-path", "aria-hidden"],
    })

    return () => mutationObserver.disconnect()
  }, [container])

  return (
    <Provider container={container} groupPath="" {...props}>
      <Box
        ref={setContainer}
        tabIndex={-1}
        role="listbox"
        aria-multiselectable={isMulti}
        data-path={props.path}
      >
        <GroupContents path="">{children}</GroupContents>
      </Box>
    </Provider>
  )
}

type SelectContentsProps = {
  maxHeight?: ResponsiveValue<string | number>
}

export const SelectContents = forwardRef(
  <T extends SelectValue>(
    {
      children,
      maxHeight = "24rem",
      ...props
    }: PropsWithChildren<SelectContentsProps>,
    ref: Ref<HTMLDivElement>
  ) => {
    const {
      path,
      onPathChange,
      isMulti,
      trigger,
      isOpen,
      open,
      popup,
      setPopup,
      activeItem,
    } = useSelect<T>()

    const mergedRef = useMergeRefs(ref, (node) => setPopup(node))

    const id = useId("select-contents")

    const currentHeight = useRef<number>(0)

    useLayoutEffect(() => {
      if (!popup) return

      popup.scrollTop = 0
    }, [popup, path])

    const resized = useCallback(
      (height: number) => {
        if (!popup) return

        const maxHeight = parseInt(getComputedStyle(popup).maxHeight ?? "0")
        if (!maxHeight) return

        const newHeight = Math.min(height, maxHeight)

        if (newHeight === currentHeight.current) {
          popup.style.removeProperty("height")
        } else {
          popup.style.height = `${currentHeight.current}px`
          setTimeout(() => {
            popup.style.height = `${newHeight}px`
          })
          currentHeight.current = newHeight
        }
      },
      [popup]
    )

    useEffect(() => {
      if (!popup) return
      currentHeight.current = popup.offsetHeight
    }, [popup])

    const { styles, attributes, update } = usePopper(trigger, popup, {
      placement: "bottom-start",
      modifiers: [
        {
          name: "offset",
          options: { offset: [0, 8] },
        },
        {
          name: "flip",
          options: {
            fallbackPlacements: ["bottom-start"],
          },
        },
        { name: "computeStyles", options: { adaptive: true } },
        sameWidth,
      ],
    })

    useEffect(() => {
      if (!update || !trigger) return
      const resizeObserver = new ResizeObserver(() => update())
      resizeObserver.observe(trigger)
      return () => resizeObserver.disconnect()
    }, [update, trigger])

    useEffect(() => {
      if (!activeItem || !isOpen || !popup) return
      const el = popup.querySelector(`[id="${activeItem.id}"]`)
      el?.scrollIntoView({ block: "nearest" })
    }, [activeItem])

    return (
      <Portal>
        {isOpen && (
          <Box
            ref={mergedRef}
            id={id}
            maxHeight={maxHeight}
            minWidth={trigger ? undefined : "15rem"}
            overflow="auto"
            rounded={8}
            bg="ds.surface.overlay.default"
            boxShadow="ds.overlay"
            transition="height 0.2s ease-in-out"
            data-popup
            onFocusCapture={(e) => {
              e.preventDefault()
              trigger?.focus()
            }}
            onTransitionEnd={(e) => {
              e.currentTarget.style.removeProperty("height")
            }}
            onPointerDown={() => open()}
            {...attributes.popper}
            sx={styles.popper}
            {...props}
          >
            <Contents
              path={path}
              isMulti={isMulti}
              onPathChange={onPathChange}
              onResize={resized}
            >
              {children}
              <SearchResults />
            </Contents>
          </Box>
        )}
      </Portal>
    )
  }
)

SelectContents.displayName = "Select.Contents"
