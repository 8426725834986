import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
} from "@chakra-ui/react"
import {
  DesktopOnly,
  MobileOnly,
} from "Components/break-points/desktop-and-mobile-break-points"
import React from "react"

type Props = {
  name: string
  id: number
  accountId: number
  switchBackUrl: string
}

export const EmulationAlert: React.FC<Props> = ({
  name,
  id,
  accountId,
  switchBackUrl,
}) => {
  return (
    <Alert
      colorScheme="orange"
      position="sticky"
      top={0}
      zIndex="banner"
      rounded="none"
    >
      <Flex alignItems="center" justifyContent="center" w="full">
        <AlertIcon mr={4} />
        <AlertTitle mb={0}>
          <DesktopOnly>Using Lyssna as </DesktopOnly>
          <MobileOnly>Emulating </MobileOnly>
          {name}
        </AlertTitle>
        <DesktopOnly>
          <AlertDescription>
            User ID: {id}, Account ID: {accountId}
          </AlertDescription>
        </DesktopOnly>
        <Button
          as="a"
          size="sm"
          colorScheme="undefined"
          variant="inverted"
          borderColor="alert.warning.icon"
          ml={4}
          href={switchBackUrl}
          _hover={{ borderColor: "alert.warning.hover" }}
        >
          Switch back
        </Button>
      </Flex>
    </Alert>
  )
}
