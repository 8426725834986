import { ModifierArguments, ModifierPhases } from "@popperjs/core"
import { Options } from "dependency-graph"
import { deburr } from "lodash"
import {
  PATH_SEPARATOR,
  SelectItem,
  SelectPath,
  SelectPathComponent,
  SelectValue,
} from "./types"

export const parentPath = (path: SelectPath) =>
  splitPath(path).slice(0, -1).join(PATH_SEPARATOR) as SelectPath

export const joinPath = (...paths: (SelectPath | SelectPathComponent<any>)[]) =>
  paths.filter(Boolean).join(PATH_SEPARATOR)

export const splitPath = (path: SelectPath) =>
  path.split(PATH_SEPARATOR) as SelectPathComponent<any>[]

export const pathStartsWith = (parentPath: SelectPath, path: SelectPath) =>
  path === parentPath ||
  (path.startsWith(parentPath) &&
    path.substring(parentPath.length).startsWith(PATH_SEPARATOR))

export const sameWidth = {
  name: "sameWidth",
  enabled: true,
  phase: "beforeWrite" as ModifierPhases,
  requires: ["computeStyles"],
  fn: ({ state }: ModifierArguments<Options>) => {
    state.styles.popper.width = `${state.rects.reference.width}px`
  },
  effect: ({ state }: ModifierArguments<Options>) => {
    if (state.elements.reference instanceof HTMLElement) {
      state.elements.popper.style.width = `${
        state.elements.reference.offsetWidth
      }px`
    }
  },
}

export const defaultFormatValue = <T extends SelectValue>(value: T) =>
  String(value)

export const defaultSearchFunction = <T extends SelectValue>(
  query: string,
  item: SelectItem<T>,
  formatValue: (value: T) => string = defaultFormatValue
) =>
  deburr(item.type === "group" ? item.label : formatValue(item.value))
    .toLowerCase()
    .includes(query)

export const combineFilters =
  <T>(a: (value: T) => boolean, b: (value: T) => boolean) =>
  (value: T) =>
    a(value) && b(value)
