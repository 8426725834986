import { defineStyleConfig } from "@chakra-ui/react"

export const IconButtonDS = defineStyleConfig({
  baseStyle: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    _focusVisible: {
      layerStyle: "focused",
    },
    _disabled: {
      _hover: {
        color: "ds.icon.disabled",
        bg: "ds.background.disabled",
        cursor: "not-allowed",
      },
      userSelect: "none",
      "& svg": { color: "ds.icon.disabled" },
    },
  },
  sizes: {
    emphasized: {
      height: "2.5rem",
      width: "2.5rem",
      rounded: "8px",
    },
    default: {
      height: "2rem",
      width: "2rem",
      rounded: "6px",
    },
    compact: {
      height: "1.5rem",
      width: "1.5rem",
      rounded: "4px",
    },
    flush: {
      height: "1rem",
      width: "1rem",
      rounded: "4px",
    },
  },
  variants: {
    default: {
      bg: "ds.background.neutral.resting",
      color: "ds.icon.default",
      _hover: {
        bg: "ds.background.neutral.hovered",
        color: "ds.icon.default",
      },
      _active: {
        bg: "ds.background.neutral.pressed",
      },
      _disabled: {
        bg: "ds.background.disabled",
      },
    },
    primary: {
      bg: "ds.background.brand.bold.resting",
      color: "ds.icon.inverse",
      _hover: {
        bg: "ds.background.brand.bold.hovered",
        color: "ds.icon.inverse",
      },
      _active: {
        bg: "ds.background.brand.bold.pressed",
      },
      _disabled: {
        bg: "ds.background.disabled",
      },
    },
    secondary: {
      bg: "ds.background.neutral.subtle.resting",
      color: "ds.icon.default",
      _hover: {
        bg: "ds.background.neutral.subtle.hovered",
        color: "ds.icon.default",
      },
      _active: {
        bg: "ds.background.neutral.subtle.pressed",
      },
    },
    danger: {
      bg: "ds.background.danger.bold.resting",
      color: "ds.icon.inverse",
      _hover: {
        bg: "ds.background.danger.bold.hovered",
        color: "ds.icon.inverse",
      },
      _active: {
        bg: "ds.background.danger.bold.pressed",
      },
      _disabled: {
        bg: "ds.background.disabled",
      },
    },
    "danger-subtle": {
      bg: "ds.background.neutral.subtle.resting",
      color: "ds.icon.danger",
      _hover: {
        bg: "ds.background.neutral.subtle.hovered",
        color: "ds.icon.danger",
        textDecoration: "none",
      },
      _active: {
        bg: "ds.background.neutral.subtle.pressed",
      },
      _disabled: {
        bg: "ds.background.disabled",
      },
    },
    warning: {
      bg: "ds.background.warning.bold.resting",
      color: "ds.icon.inverse",
      _hover: {
        bg: "ds.background.warning.bold.hovered",
        color: "ds.text.inverse",
        textDecoration: "none",
      },
      _active: {
        bg: "ds.background.warning.bold.pressed",
      },
      _disabled: {
        bg: "ds.background.disabled",
      },
    },
    upgrade: {
      bg: "ds.background.upgrade.bold.resting",
      color: "ds.icon.default",
      _hover: {
        bg: "ds.background.upgrade.bold.hovered",
        color: "ds.icon.default",
      },
      _active: {
        bg: "ds.background.upgrade.bold.pressed",
      },
      _disabled: {
        bg: "ds.background.disabled",
      },
    },
    subtle: {
      bg: "ds.background.neutral.subtle.resting",
      color: "ds.icon.subtle",
      _hover: {
        "&:not(:disabled, [aria-disabled])": {
          bg: "ds.background.neutral.subtle.hovered",
        },
        color: "ds.icon.subtle",
      },
      _active: {
        bg: "ds.background.neutral.subtle.pressed",
      },
      _disabled: {
        _hover: {
          bg: "ds.background.neutral.subtle.resting",
        },
      },
    },
  },
  defaultProps: {
    variant: "default",
    size: "default",
  },
})
