import { Center, Flex, Text } from "@chakra-ui/react"
import { Image } from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { Button, Heading } from "DesignSystem/components"
import { UserCrowdDefaultPageLayout } from "UserCrowd/components/UserCrowdDefaultPageLayout"
import { UserCrowdNavbar } from "UserCrowd/components/UserCrowdNavbar/UserCrowdNavbar"
import React from "react"
import { Helmet } from "react-helmet"
import { Navigate } from "react-router"
import { useCurrentPanelistInfo } from "~/api/generated/usabilityhub-components"
import { ROUTES } from "../routes"
import waitlistImage from "./waitlist.svg"

export const WaitlistRoute: React.FC = () => {
  const { data: user, isLoading, isError } = useCurrentPanelistInfo({})

  if (isLoading || isError) return null

  if (user.panelist_state !== "waitlisted") {
    return <Navigate to={ROUTES.DASHBOARD.path} />
  }

  return (
    <UserCrowdDefaultPageLayout>
      <Helmet title="You're on the waitlist!" />

      <UserCrowdNavbar variant="onboarding" />

      <Center h="calc(100dvh - 130px)" px={2}>
        <Flex direction="column" align="center" gap={10}>
          <Image src={waitlistImage} alt="You're on the waitlist!" />

          <Flex direction="column" align="center" gap={4}>
            <Heading
              as="h1"
              textStyle="ds.display.emphasized"
              textAlign="center"
            >
              You{"\u2019"}re on the waitlist!
            </Heading>

            <Text textStyle="ds.paragraph.emphasized" textAlign="center">
              Keep an eye on your inbox — we will notify you as soon as you can
              start earning money with UserCrowd.
            </Text>
          </Flex>

          <Button
            variant="primary"
            size="emphasized"
            as="a"
            href={Constants.PANELIST_MARKETING_DOMAIN}
          >
            Back to website
          </Button>
        </Flex>
      </Center>
    </UserCrowdDefaultPageLayout>
  )
}
