import { Box, Heading, Text } from "@chakra-ui/react"
import {
  centsToDollars,
  creditsToCents,
  formatDollars,
} from "Utilities/currency"
import React from "react"
import { useModeratedStudyApplicationContext } from "./ModeratedStudyApplicationContext"
import { PanelistPostApplicationActions } from "./PanelistPostApplicationActions"
import { PanelistProfileCompletionAlert } from "./PanelistProfileCompletionAlert"

export function ModeratedStudyApplicationEndPage() {
  const { application: moderatedStudyApplication } =
    useModeratedStudyApplicationContext()

  return (
    <Box display="flex" maxW="500px" flexDirection="column" gap="4">
      <Heading fontSize="2xl" fontWeight="400">
        {moderatedStudyApplication.panelist
          ? "Thank you for applying to this interview!"
          : "Thank you for applying to this study!"}
      </Heading>
      <Text>
        {moderatedStudyApplication.panelist
          ? `You will be contacted if you are selected to participate in this interview. You${"\u2019"}ll receive ${formatDollars(
              centsToDollars(
                creditsToCents(
                  moderatedStudyApplication.panelist.incentive_credits
                )
              )
            )} once successfully completed.`
          : `You will be contacted if you are selected to participate in this interview.`}
      </Text>

      {moderatedStudyApplication.panelist ? (
        <>
          <PanelistPostApplicationActions mt={6} />
          <PanelistProfileCompletionAlert
            pos={["static", null, null, "absolute"]}
            maxW={[null, null, null, "500px"]}
            bottom={[null, null, null, 10]}
            mt={[6, null, null, 0]}
          />
        </>
      ) : null}
    </Box>
  )
}
