import { Box, BoxProps, Icon, Stack, Text, forwardRef } from "@chakra-ui/react"
import React from "react"

export type ItemProps = BoxProps & {
  title: string
  icon: React.ElementType
  isSelected?: boolean
}

export const Item = forwardRef<ItemProps, "button">(
  ({ title, icon, isSelected = false, children, ...props }, ref) => {
    const hasChildren = React.Children.count(children) > 0

    return (
      <Box
        as="button"
        ref={ref}
        role="menuitem"
        aria-selected={isSelected || undefined}
        display="grid"
        gridTemplate={`"icon title" auto ${hasChildren ? '". description" auto' : ""} / 1.5rem 1fr`}
        rowGap={2}
        columnGap={3}
        alignItems="start"
        p={4}
        bg={isSelected ? "ds.background.neutral.subtle.hovered" : "transparent"}
        rounded={12}
        textAlign="left"
        _hover={{
          bg: "ds.background.neutral.subtle.pressed",
        }}
        {...props}
      >
        <Icon
          as={icon}
          gridArea="icon"
          boxSize={6}
          color={isSelected ? "ds.icon.selected" : "ds.icon.subtle"}
        />
        <Text
          as="div"
          my="calc((1.5rem - 1lh) / 2)"
          gridArea="title"
          textStyle="ds.interface.large"
          color={isSelected ? "ds.text.selected" : "ds.text.default"}
        >
          {title}
        </Text>
        {hasChildren && (
          <Stack
            spacing={1}
            gridArea="description"
            textStyle="ds.paragraph.primary"
            color="ds.text.subtle"
            minW={0}
            sx={{
              p: {
                m: 0,
              },
            }}
          >
            {children}
          </Stack>
        )}
      </Box>
    )
  }
)
