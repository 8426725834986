import { Box, Center, Checkbox, Flex, Text, useToast } from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { Button, Heading } from "DesignSystem/components"
import { UserCrowdDefaultPageLayout } from "UserCrowd/components/UserCrowdDefaultPageLayout"
import { UserCrowdLogo } from "UserCrowd/components/UserCrowdNavbar/UserCrowdNavbar"
import { ROUTES } from "UserCrowd/views/routes"
import React from "react"
import { Helmet } from "react-helmet"
import { useForm } from "react-hook-form"
import { Navigate } from "react-router-dom"
import {
  useAgreeToCurrentTerms,
  useCurrentPanelistInfo,
} from "~/api/generated/usabilityhub-components"
import TESTER_AGREEMENT_CLAUSES from "./agreement.json"

type Clause = (typeof TESTER_AGREEMENT_CLAUSES)[number]["value"]

type FormInput = Record<Clause, boolean>

const allAccepted = (values: FormInput) =>
  TESTER_AGREEMENT_CLAUSES.every(({ value }) => values[value])

export const AgreementRoute: React.FC = () => {
  const queryClient = useQueryClient()
  const toast = useToast()

  const { register, handleSubmit, watch } = useForm<FormInput>()

  const { data: user } = useCurrentPanelistInfo({})
  const { mutate: agreeToTerms, isLoading } = useAgreeToCurrentTerms({
    onSuccess: () => {
      return queryClient.invalidateQueries(
        ["api", "usercrowd", "panelist", "me"],
        {
          exact: true,
        }
      )
    },
    onError: () => {
      toast({
        title: "There was a problem agreeing to the terms. Please try again.",
        status: "error",
      })
    },
  })

  const onAgree = (agreed: FormInput) => {
    agreeToTerms({ body: { agreed } })
  }

  const isComplete = user?.agreed_to_current_tester_terms
  const isAccepted = allAccepted(watch())

  if (isComplete) {
    return <Navigate to={ROUTES.DASHBOARD.path} />
  }

  return (
    <UserCrowdDefaultPageLayout hasNoNavbar>
      <Helmet title="Agree to the rules" />
      <Center minH="full">
        <Flex flexDirection="column" gap={10} w="full" maxW="500px">
          <UserCrowdLogo />

          <Heading
            as="h2"
            color="ds.text.default"
            textStyle="ds.display.primary"
            textAlign="center"
          >
            Agree to the UserCrowd tester agreement
          </Heading>

          <form onSubmit={handleSubmit(onAgree)}>
            <Flex
              direction="column"
              width="full"
              maxW="500px"
              rounded="16px"
              bg="ds.surface.raised.resting"
              boxShadow="ds.raised"
              p={6}
            >
              <Heading
                as="h3"
                color="ds.text.default"
                textStyle="ds.heading.primary"
              >
                Please read and agree to these rules
              </Heading>

              <Flex direction="column" gap={3} mt={3} mb={6}>
                {TESTER_AGREEMENT_CLAUSES.map((option) => (
                  <Checkbox
                    variant="mdWithSmFont"
                    key={option.value}
                    {...register(option.value, { required: true })}
                  >
                    <Text
                      color="ds.text.default"
                      textStyle="ds.paragraph.primary"
                    >
                      {option.label}
                    </Text>
                  </Checkbox>
                ))}
              </Flex>

              <Button
                type="submit"
                variant="primary"
                size="emphasized"
                isDisabled={!isAccepted}
                isLoading={isLoading}
              >
                Continue
              </Button>
            </Flex>
            <Box textAlign="right" pt={5}></Box>
          </form>
        </Flex>
      </Center>
    </UserCrowdDefaultPageLayout>
  )
}
