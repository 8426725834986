import React from "react"

import Constants from "Constants/shared.json"

import { MailToAnchor } from "./basic"

type Props = Omit<React.ComponentProps<typeof MailToAnchor>, "email" | "name">
export const PanelistSupportMailToAnchor = (props: Props) => (
  <MailToAnchor
    name={Constants.TESTER_SUPPORT_EMAIL_NAME}
    email={Constants.TESTER_SUPPORT_EMAIL_ADDRESS}
    {...props}
  />
)
