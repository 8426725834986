import { Link, LinkProps } from "@chakra-ui/react"
import React from "react"

import Constants from "Constants/shared.json"

type CustomerSupportMailtoLinkProps = React.PropsWithChildren<LinkProps> & {
  email?: string
}

export const CustomerSupportMailtoLink: React.FC<
  CustomerSupportMailtoLinkProps
> = ({
  email = Constants.CUSTOMER_SUPPORT_EMAIL_ADDRESS,
  children,
  ...linkProps
}) => {
  return (
    <Link href={`mailto:${email}`} isExternal {...linkProps}>
      {children ? children : email}
    </Link>
  )
}
