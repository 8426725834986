import React, { PropsWithChildren, createContext, useRef } from "react"

type SelectPortalContext = {
  selectContainer: HTMLElement | null
}

const SelectPortalContext = createContext<SelectPortalContext>({
  selectContainer: null,
})

export function SelectPortalProvider({ children }: PropsWithChildren<unknown>) {
  const ref = useRef<HTMLDivElement>(null)
  return (
    <SelectPortalContext.Provider value={{ selectContainer: ref.current }}>
      {children}
      <div ref={ref}></div>
    </SelectPortalContext.Provider>
  )
}
