import { Button, Center, Flex, Link, Text } from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { UsabilityTestIcon } from "Shared/icons/UsabilityTestIcon"
import { ClockOutlineIcon } from "Shared/icons/untitled-ui/ClockOutlineIcon"
import { LogIn04AltSolidIcon } from "Shared/icons/untitled-ui/LogIn04AltSolidIcon"
import { Microphone02OutlineIcon } from "Shared/icons/untitled-ui/Microphone02OutlineIcon"
import { ScreenRecordingOutlineIcon } from "Shared/icons/untitled-ui/ScreenRecordingOutlineIcon"
import { VideoRecorderOutlineIcon } from "Shared/icons/untitled-ui/VideoRecorderOutlineIcon"
import { DeviceType, PermittedDeviceType } from "Types"
import { AssignmentTag } from "UserCrowd/components/AssignmentTag"
import {
  centsToDollars,
  creditsToCents,
  formatDollars,
} from "Utilities/currency"
import {
  DEVICE_TYPES,
  isDeviceTypePermitted,
  permittedDeviceTypeDescriptions,
  permittedDeviceTypeIcons,
} from "Utilities/device-types"
import { formatMinutes } from "Utilities/duration"
import { capitalizeFirstLetter } from "Utilities/string"
import React from "react"
import { useCurrentPanelistInfo } from "~/api/generated/usabilityhub-components"
import { PanelOrderAssignment } from "~/api/generated/usabilityhubSchemas"
import TestInterfaceApi from "~/api/testInterfaceApi"

type Props = {
  assignment: PanelOrderAssignment
}

export const UsabilityTestCard: React.FC<Props> = ({ assignment }) => {
  const { data: panelistInfo } = useCurrentPanelistInfo({})

  const deviceTypesLabel =
    assignment.permitted_device_type === "any"
      ? "Any device"
      : DEVICE_TYPES[assignment.permitted_device_type]
  const deviceTypesTooltip =
    permittedDeviceTypeDescriptions[assignment.permitted_device_type]
  const deviceTypeIcon =
    permittedDeviceTypeIcons[assignment.permitted_device_type]

  const camera = assignment.recording_types.includes("camera")
  const microphone = assignment.recording_types.includes("microphone")
  const screen = assignment.recording_types.includes("screen")

  const isExternal = assignment.is_external

  return (
    <Flex
      direction="column"
      bg="ds.surface.raised.resting"
      shadow="ds.raised"
      rounded="16px"
      gap={4}
      p={4}
      pos="relative"
    >
      <Flex gap={2} align="center">
        <Center
          bg={isExternal ? "green.100" : "pink.100"}
          boxSize={6}
          rounded="sm"
        >
          <UsabilityTestIcon color={isExternal ? "green.400" : "#D61F69"} />
        </Center>

        <Text color="text.primary" fontWeight="bold">
          {isExternal ? "External Study" : "Test"}
        </Text>
      </Flex>
      <Flex align="flex-start" gap={2}>
        <Text color="black" fontSize="2xl" fontWeight="bold" px={2}>
          {formatDollars(
            centsToDollars(creditsToCents(assignment.credit_value))
          )}
        </Text>
        <Flex direction="column" gap={2}>
          <Flex wrap="wrap" gap={2}>
            <AssignmentTag
              label={deviceTypesLabel}
              tooltip={deviceTypesTooltip}
              icon={deviceTypeIcon}
            />
            <AssignmentTag
              tooltip={`It should take ${formatMinutes(
                assignment.estimated_duration_minutes
              ).replace("approx.", "approximately")}`}
              icon={ClockOutlineIcon}
              label={capitalizeFirstLetter(
                formatMinutes(assignment.estimated_duration_minutes)
              )}
            />
            {assignment.has_screener && (
              <AssignmentTag
                label="Incl. screener"
                tooltip={`You have to answer a few questions to check if you${"\u2019"}re the right fit`}
                bg="gray.700"
                icon={LogIn04AltSolidIcon}
                iconColor="white"
                color="white"
              />
            )}
            {camera && (
              <AssignmentTag
                icon={VideoRecorderOutlineIcon}
                label="Camera"
                tooltip="Requires a camera and microphone"
              />
            )}
            {microphone && (
              <AssignmentTag
                icon={Microphone02OutlineIcon}
                label="Think aloud"
                tooltip="Requires a microphone"
              />
            )}
            {screen && (
              <AssignmentTag
                icon={ScreenRecordingOutlineIcon}
                label="Screen share"
              />
            )}
          </Flex>
          {isExternal ? (
            <Text>
              For this test, you will be directed to an external website to
              complete a series of tasks and questions{" "}
              <Link
                variant="noUnderline"
                whiteSpace="nowrap"
                href={Constants.PANELIST_HELP_EXTERNAL_STUDY_GUIDELINES_URL}
                rel="noopener noreferer"
                target="_blank"
              >
                Learn more
              </Link>
            </Text>
          ) : (
            <Text>
              You will be asked to complete a series of tasks and answer
              questions{" "}
              <Link
                variant="noUnderline"
                whiteSpace="nowrap"
                href={Constants.PANELIST_HELP_TEST_TAKING_GUIDELINES_URL}
                rel="noopener noreferer"
                target="_blank"
              >
                Learn more
              </Link>
            </Text>
          )}
        </Flex>
      </Flex>

      <Flex justify="flex-end">
        <Button
          as={Link}
          colorScheme="brand.primary"
          textDecoration="none"
          href={TestInterfaceApi.doOrderedTest.path({
            panelist_order_assignment_id: assignment.id,
            query: {
              source: "dashboard",
            },
          })}
          isDisabled={
            !panelistInfo ||
            !isDeviceTypePermitted(
              panelistInfo.current_device_type as DeviceType,
              assignment.permitted_device_type as PermittedDeviceType
            )
          }
          _hover={{
            textDecoration: "none",
            color: "white",
            background: "brand.primary.600",
          }}
        >
          Participate
        </Button>
      </Flex>
    </Flex>
  )
}
