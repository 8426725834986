import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react"
import React from "react"

interface Props {
  estimatedPayoutReviewTimeInDays: number
}

export const SlowPayoutAlert: React.FC<Props> = ({
  estimatedPayoutReviewTimeInDays,
}) => {
  return (
    <Box my={4}>
      <Alert status="warning" alignItems="flex-start">
        <AlertIcon />
        <AlertDescription>
          <Flex direction="column" gap={2}>
            <Heading size="sm">
              Payout wait times are currently longer than usual
            </Heading>

            <Text>
              <strong>
                We{"\u2019"}re currently processing payouts in about{" "}
                {estimatedPayoutReviewTimeInDays} days,
              </strong>{" "}
              just to give you an idea of when you{"\u2019"}ll receive your
              payment.
            </Text>
          </Flex>
        </AlertDescription>
      </Alert>
    </Box>
  )
}
