import { configureScope, init } from "@sentry/browser"
import { getEnvState } from "JavaScripts/state"

export function initializeSentry(user: { id: number } | null): void {
  const {
    SENTRY_ENABLED,
    SENTRY_FRONTEND_DSN,
    HOSTNAME,
    RELEASE,
    ASSET_HOST,
    CLIENT_VERSION,
  } = getEnvState()
  try {
    init({
      enabled: SENTRY_ENABLED,
      dsn: SENTRY_FRONTEND_DSN,
      release: RELEASE,
      environment: process.env.NODE_ENV,
      allowUrls: [
        /usabilityhub\.com/,
        /lyssna\.com/,
        /usercrowd\.com/,
        ASSET_HOST,
      ],
      ignoreErrors: [
        "__gCrWeb.autofill.extractForms",
        "ResizeObserver loop limit exceeded", // https://github.com/wearelyssna/hub/issues/4430
        "Network Error", // @see https://github.com/getsentry/sentry/issues/12676
        "Non-Error promise rejection captured", // see https://github.com/getsentry/sentry-javascript/issues/3440
      ],
    })
    configureScope((scope) => {
      scope.setTag("hostname", HOSTNAME)
      scope.setTag("client-version", CLIENT_VERSION)
      if (user != null) {
        // NOTE: We explicitly don't want to send the user's email address here to reduce our PII footprint.
        // See https://linear.app/usabilityhub/issue/PRD-878.
        scope.setUser({
          id: user.id.toString(),
        })
      }
    })
  } catch (e) {
    console.error(e)
  }
}
