import {
  Box,
  BoxProps,
  HStack,
  Icon,
  Text,
  useControllableState,
  useMergeRefs,
} from "@chakra-ui/react"
import { ChevronDownOutlineIcon } from "Shared/icons/untitled-ui/ChevronDownOutlineIcon"
import { XCircleSolidIcon } from "Shared/icons/untitled-ui/XCircleSolidIcon"
import { debounce } from "lodash"
import {
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import React from "react"
import { IconButton } from "../IconButton"
import { Tag } from "../Tag"
import { useSelect } from "./Provider"
import { SelectSize, SelectValue } from "./types"
import { defaultFormatValue } from "./util"

type TriggerRef = Ref<HTMLElement | null>

type TriggerProps<T extends SelectValue> = {
  isMulti?: boolean
  isInvalid?: boolean
  isDisabled?: boolean
  isOpen?: boolean
  isSearchable?: boolean
  size?: SelectSize
  placeholder?: string
  selected?: T[]
  inputRef?: Ref<HTMLInputElement | null>
  formatValue?: (value: T) => string
  onChange?: (value: T[]) => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void
  onFocus?: () => void
  onBlur?: () => void
  children?: (
    props: FocusableTriggerProps<T>,
    ref: TriggerRef
  ) => React.ReactNode
  onClose?: () => void
  "aria-owns"?: string
  "aria-activedescendant"?: string
}

type FocusableTriggerProps<T extends SelectValue> = TriggerProps<T> & {
  isFocused?: boolean
}

const defaultPresentationProps = ({
  size,
  isInvalid,
  isDisabled,
  isFocused,
  isOpen,
  isSearchable,
}: Pick<
  FocusableTriggerProps<any>,
  "size" | "isInvalid" | "isDisabled" | "isOpen" | "isSearchable" | "isFocused"
>): Omit<BoxProps, keyof TriggerProps<SelectValue>> => ({
  role: "combobox",
  zIndex: 0,
  flex: 1,
  border: "1px solid",
  rounded: 6,
  px: `calc(var(--chakra-space-${size === "compact" ? 2 : 3}) - 1px)`,
  py: `calc(var(--chakra-space-${size === "compact" ? "1-5" : 2}) - 1px)`,
  minHeight: size === "compact" ? 8 : 10,
  backgroundColor: "ds.background.input.resting",
  color: isDisabled ? "ds.text.disabled" : "ds.text.default",
  cursor: isSearchable ? "text" : "pointer",
  borderColor: isDisabled
    ? "ds.border.disabled"
    : isFocused || isOpen
      ? "ds.border.focused"
      : isInvalid
        ? "ds.border.danger"
        : "ds.border.input",
  _hover: {
    backgroundColor: isDisabled ? undefined : "ds.background.input.hovered",
    borderColor:
      isDisabled || isFocused || isOpen || isInvalid
        ? undefined
        : "ds.border.hovered",
  },
  sx: {
    "* ::placeholder, [data-placeholder]": {
      color: isDisabled ? "ds.text.disabled" : "ds.text.placeholder",
    },
    "&:focus-within, &[aria-expanded=true]": {
      backgroundColor: isDisabled ? undefined : "ds.background.input.focus",
      borderColor: "ds.border.focused",
      outline: "none",
    },
    "button:focus-visible": {
      boxShadow: "none !important",
      backgroundColor: "transparent",
    },
  },
})

export const Trigger = forwardRef(
  <T extends SelectValue>(
    {
      isMulti = false,
      isInvalid = false,
      isDisabled = false,
      isOpen = false,
      isSearchable = false,
      size = "default",
      selected: selectedProp = [],
      placeholder = "",
      formatValue = defaultFormatValue,
      onChange,
      onKeyDown,
      onFocus,
      onBlur,
      children,
      inputRef,
      onClose,
      "aria-owns": ariaOwns,
      "aria-activedescendant": ariaActivedescendant,
      ...props
    }: TriggerProps<T>,
    ref: TriggerRef
  ) => {
    const [input, setInput] = useState<HTMLInputElement | null>(null)

    const mergedInputRef = useMergeRefs(inputRef, setInput)

    const tags = useRef<(HTMLElement | null)[]>([])

    const [isFocused, setIsFocused] = useState(false)

    const [selectedIndex, setSelectedIndex] = useState(-1)

    const [selected, setSelected] = useControllableState({
      value: selectedProp,
      onChange,
    })

    const presentationProps = defaultPresentationProps({
      size,
      isInvalid,
      isDisabled,
      isFocused,
      isOpen,
      isSearchable,
    })

    if (children) {
      return children(
        {
          isMulti,
          isSearchable,
          isInvalid,
          isDisabled,
          isFocused,
          isOpen,
          selected,
          formatValue,
          placeholder,
          onChange,
          ...props,
          ...presentationProps,
        },
        ref
      )
    }

    const removeSelected = (value: T) => {
      setSelected(selected.filter((v) => v !== value))
      setSelectedIndex(-1)
      if (isOpen) {
        input?.focus()
      }
    }

    // TODO: Changing this too rapidly (for example, if the user is adding and removing a character
    // that tips the width over the threshold to force the input onto a new line) can cause
    // the height of the container to flap around as the input resizes.
    // A possible solution is to set a temporary minWidth on the input each time it grows, and
    // remove it after a small timeout.
    const resizeInput = useMemo(
      () =>
        debounce(() => {
          if (!input) return
          input.style.flexBasis = "0"
          input.style.flexGrow = "0"
          input.style.flexBasis = `${input.scrollWidth}px`
          input.style.flexGrow = "1"
        }),
      [input]
    )

    useEffect(() => {
      if (!input) return

      input.addEventListener("input", resizeInput)
      input.addEventListener("change", resizeInput)
      const mutationObserver = new MutationObserver(resizeInput)
      mutationObserver.observe(input, {
        attributes: true,
        attributeFilter: ["value"],
      })

      resizeInput()

      return () => {
        input.removeEventListener("input", resizeInput)
        input.removeEventListener("change", resizeInput)
        mutationObserver.disconnect()
      }
    }, [input])

    useEffect(() => {
      setSelectedIndex(-1)
    }, [selected])

    useEffect(() => {
      for (let i = 0; i < tags.current.length; i++) {
        tags.current[i]?.setAttribute(
          "tabindex",
          selectedIndex === i ? "0" : "-1"
        )
      }

      if (isFocused) {
        input?.setAttribute("tabindex", selectedIndex === -1 ? "0" : "-1")
        if (selectedIndex === -1) {
          input?.focus()
        } else {
          const tag = tags.current[selectedIndex]
          tag?.focus()
        }
      }
    }, [selectedIndex, input, isFocused])

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (!input) return

      if (
        (event.key === "ArrowLeft" &&
          (selectedIndex > -1 || !input.selectionStart)) ||
        event.key === "Backspace" ||
        event.key === "Delete"
      ) {
        if (input.value || !selected.length) return

        event.preventDefault()
        event.stopPropagation()

        if (selectedIndex === -1) {
          setSelectedIndex(selected.length - 1)
        } else if (event.key === "ArrowLeft") {
          setSelectedIndex((current) => Math.max(current - 1, 0))
        } else {
          removeSelected(selected[selectedIndex])
          setSelectedIndex(-1)
        }
      }

      if (event.key === "ArrowRight" && selectedIndex > -1) {
        if (selectedIndex === -1 || !selected.length) return
        event.stopPropagation()
        setSelectedIndex((current) =>
          current < selected.length - 1 ? current + 1 : -1
        )
      }
    }

    const onFocusHandler = useRef(onFocus)
    const onBlurHandler = useRef(onBlur)
    onFocusHandler.current = onFocus
    onBlurHandler.current = onBlur

    useEffect(() => {
      if (isFocused) {
        onFocusHandler.current?.()
      } else {
        onBlurHandler.current?.()
      }
    }, [isFocused])

    return (
      <HStack
        ref={ref}
        role="combobox"
        aria-owns={ariaOwns}
        aria-activedescendant={ariaActivedescendant}
        aria-expanded={isOpen}
        gap={2}
        tabIndex={0}
        onFocus={() => {
          setIsFocused(true)
        }}
        onBlurCapture={() => {
          setIsFocused(false)
        }}
        onKeyDown={handleKeyDown}
        {...props}
        {...presentationProps}
      >
        <HStack gap={1.5} wrap="wrap" flex={1} my={0.5}>
          {isMulti &&
            selected.map((value, index) => (
              <Tag
                key={index}
                label={formatValue(value)}
                closeButtonRef={(el) => {
                  tags.current[index] = el
                }}
                tabIndex={-1}
                isDisabled={isDisabled}
                onClose={() => removeSelected(value)}
                onClick={() => {
                  setTimeout(() => {
                    setSelectedIndex(index)
                  }, 0)
                }}
                onFocus={(e) => {
                  if (e.target?.closest("button") && !isOpen) {
                    e.target.blur()
                    e.stopPropagation()
                    e.nativeEvent.stopImmediatePropagation()
                  }
                }}
              />
            ))}
          {isSearchable ? (
            <Box
              as="input"
              ref={mergedInputRef}
              defaultValue={
                isMulti || !selected.length ? "" : formatValue(selected[0])
              }
              flex="1"
              type="text"
              minWidth={0}
              width={0}
              maxWidth="100%"
              placeholder={isMulti && selected.length > 0 ? "" : placeholder}
              bg="transparent"
              outline="none"
              textStyle="ds.paragraph.primary"
              color="ds.text.default"
              border={0}
              boxShadow="none"
              appearance="none"
              tabIndex={-1}
              readOnly={isDisabled || undefined}
              aria-disabled={isDisabled || undefined}
              onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                e.currentTarget.select()
                setSelectedIndex(-1)
              }}
              onKeyDown={onKeyDown}
              _placeholder={{
                textStyle: "ds.paragraph.primary",
                color: "ds.text.subtle",
              }}
            />
          ) : selected.length > 0 ? (
            !isMulti && (
              <Text
                flex={1}
                textStyle="ds.paragraph.primary"
                color="ds.text.default"
              >
                {formatValue(selected[0])}
              </Text>
            )
          ) : (
            <Text
              flex={1}
              data-placeholder
              textStyle="ds.paragraph.primary"
              color="ds.text.subtle"
            >
              {placeholder}
            </Text>
          )}
        </HStack>
        <HStack gap={1} align="center">
          {isMulti && selected.length > 0 && (
            <IconButton
              aria-label="Clear"
              size="flush"
              variant="subtle"
              tabIndex={-1}
              icon={
                <Icon
                  as={XCircleSolidIcon}
                  boxSize={4}
                  color="ds.icon.subtle"
                />
              }
              _hover={{ bg: "transparent" }}
              onClick={() => setSelected([])}
              onFocusCapture={(e) => {
                if (!isOpen) {
                  e.stopPropagation()
                  e.currentTarget.blur()
                }
              }}
            />
          )}
          <IconButton
            aria-label="Expand"
            size="flush"
            variant="subtle"
            _hover={{ bg: "transparent" }}
            tabIndex={-1}
            icon={
              <Icon
                as={ChevronDownOutlineIcon}
                boxSize={5}
                color="ds.icon.default"
              />
            }
            onFocusCapture={(e) => {
              if (isOpen) {
                e.stopPropagation()
                e.currentTarget.blur()
                onClose?.()
              }
            }}
          />
        </HStack>
      </HStack>
    )
  }
)

type SelectTriggerProps<T extends SelectValue> = Omit<
  TriggerProps<T>,
  "isMulti" | "selected" | "onChange"
>

export const SelectTrigger = forwardRef(
  <T extends SelectValue>(props: SelectTriggerProps<T>, ref: TriggerRef) => {
    const {
      isMulti,
      isSearchable,
      selected,
      setSelected,
      formatValue,
      setTrigger,
      setInput,
      isOpen,
      popup,
      activeItem,
      open,
      close,
      cancelClose,
    } = useSelect<T>()

    const mergedRef = useMergeRefs(ref, setTrigger)

    const onFocus = useCallback(() => {
      cancelClose()
      if (!isOpen) {
        open()
      }
    }, [isOpen, open, cancelClose])

    return (
      <Trigger
        ref={mergedRef}
        inputRef={setInput}
        isMulti={isMulti}
        isOpen={isOpen}
        isSearchable={isSearchable}
        selected={Array.from(selected)}
        onChange={(value: T[]) => setSelected(new Set(value))}
        formatValue={formatValue}
        onFocus={onFocus}
        onClose={close}
        aria-owns={popup?.id}
        aria-activedescendant={activeItem?.id}
        {...props}
      />
    )
  }
)

SelectTrigger.displayName = "Select.Trigger"
