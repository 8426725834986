import { Flex, Heading, Text } from "@chakra-ui/react"
import { CalendarIcon, UserIcon } from "@heroicons/react/outline"
import React from "react"

import { Mail01OutlineIcon } from "Shared/icons/untitled-ui/Mail01OutlineIcon"
import { ModeratedStudyApplicationFlow } from "~/api/generated/usabilityhubResponses"

import { formatBookingDate } from "../formatBookingDate"

import { IconDefinitionList } from "./IconDefinitionList"

interface Props {
  moderatedStudyApplication: ModeratedStudyApplicationFlow
}

export const CanceledContent: React.FC<Props> = ({
  moderatedStudyApplication,
}) => {
  if (!moderatedStudyApplication.booking) return null

  const { applicant, panelist } = moderatedStudyApplication
  return (
    <Flex direction="column">
      <Heading fontSize="2xl" fontWeight="normal" color="green.500" mb={2}>
        Your session has been canceled
      </Heading>
      <Text fontSize="md" color="text.primary">
        The session hosts have been notified
      </Text>

      <IconDefinitionList my={4}>
        <IconDefinitionList.Item icon={UserIcon} label="Name">
          <Text color="text.primary">
            {applicant &&
              `${applicant.full_name} (${applicant.preferred_name})`}
            {panelist && panelist.preferred_name}
          </Text>
        </IconDefinitionList.Item>
        {applicant && (
          <IconDefinitionList.Item icon={Mail01OutlineIcon} label="Email">
            <Text color="text.primary">{applicant.email}</Text>
          </IconDefinitionList.Item>
        )}
        <IconDefinitionList.Item icon={CalendarIcon} label="Date & Time">
          <Text color="text.primary" textDecoration="line-through">
            {formatBookingDate(moderatedStudyApplication.booking)}
            {` (${moderatedStudyApplication.booking.participant_timezone})`}
          </Text>
        </IconDefinitionList.Item>
      </IconDefinitionList>
    </Flex>
  )
}
