import { createIcon } from "@chakra-ui/react"
import React from "react"

export const DeleteIcon = createIcon({
  displayName: "DeleteIcon",
  viewBox: "0 0 16 16",
  path: (
    <>
      <g id="delete">
        <path
          id="Shape-Copy-3"
          d="M13.258 3.91044C13.5807 3.58778 13.5807 3.06465 13.258 2.74199C12.9353 2.41934 12.4122 2.41934 12.0896 2.74199L8 6.83155L3.91044 2.74199C3.58778 2.41934 3.06465 2.41934 2.74199 2.74199C2.41933 3.06465 2.41933 3.58778 2.74199 3.91044L6.83155 8L2.74199 12.0896C2.41933 12.4122 2.41933 12.9353 2.74199 13.258C3.06465 13.5807 3.58778 13.5807 3.91044 13.258L8 9.16845L12.0896 13.258C12.4122 13.5807 12.9353 13.5807 13.258 13.258C13.5807 12.9353 13.5807 12.4122 13.258 12.0896L9.16844 8L13.258 3.91044Z"
          fill="currentColor"
        />
      </g>
    </>
  ),
})
