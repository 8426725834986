import {
  Box,
  Flex,
  FlexProps,
  StylesProvider,
  useMultiStyleConfig,
  useStyles,
} from "@chakra-ui/react"
import React from "react"
import { Helmet } from "react-helmet"

import { BasicFlash } from "Components/basic-flash/basic-flash"

const styleConfig = {
  parts: ["page", "main", "aside", "content"],
  baseStyle: {
    page: {
      minH: "100vh",
    },
    main: {
      flexGrow: 1,
    },
    content: {
      flexGrow: 1,
      px: {
        base: 6,
        md: 8,
        lg: 10,
      },
      py: 6,
    },
    aside: {
      flexShrink: 0,
      position: { base: "static", lg: "sticky" },
      maxHeight: "100vh",
      borderRight: "2px solid",
      borderColor: "gray.100",
      top: 0,
      px: {
        base: 6,
        md: 8,
        lg: 10,
      },
      py: 6,
    },
  },
}

interface PageProps extends FlexProps {
  title?: string
  // Set this to avoid rendering the <BasicFlash /> component inside since it requires a Redux store
  noBasicFlash?: boolean
  includeTitlePrefix?: boolean
}

type Page = React.FC<React.PropsWithChildren<PageProps>>

export const Page: Page = ({
  title,
  noBasicFlash = false,
  includeTitlePrefix = true,
  ...props
}) => {
  const styles = useMultiStyleConfig("Page", { styleConfig })

  return (
    <StylesProvider value={styles}>
      {title &&
        (includeTitlePrefix ? (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        ) : (
          <Helmet titleTemplate="%s">
            <title>{title}</title>
          </Helmet>
        ))}
      <Flex direction="column" sx={styles.page} {...props} />
      {noBasicFlash ? null : <BasicFlash />}
    </StylesProvider>
  )
}

export const PageMain: React.FC<React.PropsWithChildren<FlexProps>> = ({
  sx = {},
  ...props
}) => {
  const styles = useStyles()
  return (
    <Flex
      direction={{ base: "column", lg: "row" }}
      justify={{ base: "start", lg: "center" }}
      sx={{ ...styles.main, ...sx }}
      {...props}
    />
  )
}

export const PageAside: React.FC<React.PropsWithChildren<FlexProps>> = (
  props
) => {
  const styles = useStyles()
  return <Box sx={styles.aside} {...props} />
}

export const PageContent: React.FC<React.PropsWithChildren<FlexProps>> = ({
  sx = {},
  ...props
}) => {
  const styles = useStyles()
  return <Box sx={{ ...styles.content, ...sx }} {...props} />
}
