import { ComponentWithAs, IconProps } from "@chakra-ui/react"

import { Laptop01OutlineIcon } from "Shared/icons/untitled-ui/Laptop01OutlineIcon"
import { Monitor01OutlineIcon } from "Shared/icons/untitled-ui/Monitor01OutlineIcon"
import { Phone01OutlineIcon } from "Shared/icons/untitled-ui/Phone01OutlineIcon"
import { DeviceType, PermittedDeviceType } from "Types"

export const isDeviceTypePermitted = (
  deviceType: DeviceType,
  permittedDeviceType: PermittedDeviceType
) => {
  switch (permittedDeviceType) {
    case PermittedDeviceType.Any:
      return true
    case PermittedDeviceType.DesktopOnly:
      return deviceType === DeviceType.Desktop
    case PermittedDeviceType.MobileOnly:
      return deviceType === DeviceType.Mobile
  }
}

export const DEVICE_TYPES: Record<PermittedDeviceType, string> = {
  mobile_only: "Mobile only",
  desktop_only: "Desktop only",
  any: "All devices",
}

export const permittedDeviceTypeDescriptions: Record<
  PermittedDeviceType,
  string
> = {
  [PermittedDeviceType.Any]: "This test is available on any device",
  [PermittedDeviceType.DesktopOnly]:
    "This test is only available on desktop devices",
  [PermittedDeviceType.MobileOnly]:
    "This test is only available on mobile devices",
}

export const permittedDeviceTypeIcons: Record<
  PermittedDeviceType,
  ComponentWithAs<"svg", IconProps>
> = {
  [PermittedDeviceType.Any]: Laptop01OutlineIcon,
  [PermittedDeviceType.DesktopOnly]: Monitor01OutlineIcon,
  [PermittedDeviceType.MobileOnly]: Phone01OutlineIcon,
}
