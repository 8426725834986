import {
  Center,
  Flex,
  Spinner,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { Navigate } from "react-router"

import { useQueryClient } from "@tanstack/react-query"
import { JsForm } from "Components/form/form"
import { Button, Heading } from "DesignSystem/components"
import { Fluency } from "Types"
import { CountryField } from "UserCrowd/components/CountryField/CountryField"
import { DemographicAttributeField } from "UserCrowd/components/DemographicAttributeField"
import { LanguagesField } from "UserCrowd/components/LanguagesField/LanguagesField"
import { UserCrowdDefaultPageLayout } from "UserCrowd/components/UserCrowdDefaultPageLayout"
import { UserCrowdLogo } from "UserCrowd/components/UserCrowdNavbar/UserCrowdNavbar"
import { YearOfBirthField } from "UserCrowd/components/YearOfBirthField"
import {
  DemographicProfileData,
  useDemographicProfileData,
} from "UserCrowd/hooks/useDemographicProfileData"
import { ROUTES } from "UserCrowd/views/routes"
import { setUserCrowdDocumentTitle } from "Utilities/set-user-crowd-document-title"
import { useDemographicProfileCompleteProfile } from "~/api/generated/usabilityhub-components"
import { useCurrentPanelistInfo } from "~/api/generated/usabilityhub-components"

const PersonalDetailsForm: React.FC<
  React.PropsWithChildren<DemographicProfileData>
> = ({
  googlePlaceId,
  fluencies,
  yearOfBirth,
  demographicAttributeGroups,
  allInitialSelectedOptions,
}) => {
  const toast = useToast()
  const queryClient = useQueryClient()

  const { data: currentPanelist } = useCurrentPanelistInfo({})
  const nextRoute = currentPanelist?.phone_number_required_for_panel
    ? ROUTES.PHONE.path
    : ROUTES.NOTIFICATION_PREFERENCES.path

  // The order of this array controls the order of the options
  const onboardingDemographicAttributeCodes = [
    "gender",
    "education_level",
    "employment_status",
    "annual_household_income",
    "technical_proficiency",
    "daily_hours_online",
  ]

  const demographicAttributes = demographicAttributeGroups
    .flatMap(({ demographic_attributes }) => {
      return demographic_attributes
    })
    .filter(({ code }) => onboardingDemographicAttributeCodes.includes(code))
    .sort(
      (a, b) =>
        // Sort based on the order of the list of onboardingDemographicAttributeCodes
        onboardingDemographicAttributeCodes.indexOf(a.code) -
        onboardingDemographicAttributeCodes.indexOf(b.code)
    )

  const [answered, setAnswered] = useState<number[]>(
    demographicAttributes
      .filter((da) =>
        da.options.find((o) => allInitialSelectedOptions.includes(o.id))
      )
      .map(({ id }) => id)
  )
  const [isCountryAnswered, setIsCountryAnswered] = useState(
    googlePlaceId !== null
  )
  const [isLanguageAnswered, setIsLanguageAnswered] = useState(
    fluencies.filter((fluency: Fluency) => fluency.is_fluent).length > 0
  )
  const [isYearOfBirthAnswered, setIsYearOfBirthAnswered] = useState(
    yearOfBirth !== null
  )

  const allAnswered = () => {
    return (
      answered.length === demographicAttributes.length &&
      isCountryAnswered &&
      isLanguageAnswered &&
      isYearOfBirthAnswered
    )
  }

  const { mutate: completeProfile } = useDemographicProfileCompleteProfile({
    onSuccess: ({ completed_profile_at }) => {
      if (completed_profile_at === null) {
        // If we get here, `Panelists::AreCoreQuestionsComplete` has likely
        // returned false and the backend doesn't believe the profile is
        // complete
        toast({
          title: "Something went wrong. Profile not completed.",
          duration: null,
          status: "error",
        })
      }

      return queryClient.invalidateQueries(
        ["api", "usercrowd", "panelist", "me"],
        {
          exact: true,
        }
      )
    },
    onError: () => {
      toast({
        title: "Something went wrong. Please refresh the page and try again.",
        duration: null,
        status: "error",
      })
    },
  })

  if (!currentPanelist) {
    return <Spinner />
  }

  if (allAnswered() && currentPanelist.completed_profile_at) {
    return <Navigate to={nextRoute} />
  }

  return (
    <JsForm>
      <Flex direction="column" gap={6}>
        <CountryField
          initialPlaceId={googlePlaceId}
          initialFormattedAddress={null}
          setIsCountryAnswered={setIsCountryAnswered}
          shouldResetPhoneVerification={false}
        />
        <LanguagesField
          testerId={currentPanelist.id}
          initialFluencies={fluencies}
          setIsLanguageAnswered={setIsLanguageAnswered}
          isAdmin={false}
        />
        <YearOfBirthField
          initialYearOfBirth={yearOfBirth}
          setIsYearOfBirthAnswered={setIsYearOfBirthAnswered}
        />
        {demographicAttributes.map(
          ({
            id,
            code,
            question,
            options,
            multi_select,
            profile_helper,
            required,
          }) => {
            return (
              <DemographicAttributeField
                id={code}
                key={id}
                label={question}
                helpText={profile_helper}
                required={required}
                isMulti={multi_select}
                options={options}
                demographicAttributeId={id}
                allInitialSelectedOptions={allInitialSelectedOptions}
                markAnswered={(demographicAttributeId) => {
                  if (!answered.includes(demographicAttributeId)) {
                    setAnswered((ids) => [...ids, demographicAttributeId])
                  }
                }}
                markUnanswered={(demographicAttributeId) => {
                  if (answered.includes(demographicAttributeId)) {
                    setAnswered((ids) =>
                      ids.filter((id) => id !== demographicAttributeId)
                    )
                  }
                }}
              />
            )
          }
        )}

        <Tooltip
          hasArrow
          placement="top"
          label={
            allAnswered()
              ? null
              : "You must answer all questions before continuing"
          }
        >
          <Button
            variant="primary"
            size="emphasized"
            type="submit"
            isDisabled={!allAnswered()}
            onClick={() => completeProfile({})}
          >
            Continue
          </Button>
        </Tooltip>
      </Flex>
    </JsForm>
  )
}

export const ProfileRoute: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { data } = useDemographicProfileData()

  useEffect(() => {
    setUserCrowdDocumentTitle("Set up your profile")
  }, [])

  return (
    <UserCrowdDefaultPageLayout hasNoNavbar>
      <Center minH="full">
        <Flex flexDirection="column" gap={10}>
          <UserCrowdLogo />
          <Heading
            as="h2"
            color="ds.text.default"
            textStyle="ds.display.primary"
            textAlign="center"
          >
            Add profile details
          </Heading>
          <Flex
            direction="column"
            maxW="500px"
            rounded="16px"
            bg="ds.surface.raised.resting"
            boxShadow="ds.raised"
            p={6}
          >
            {data ? (
              <PersonalDetailsForm {...data} />
            ) : (
              <Flex direction="column">
                <Spinner size="xs" />
                <Text>Loading&hellip;</Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Center>
    </UserCrowdDefaultPageLayout>
  )
}
