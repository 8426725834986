import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react"
import { FunctionalModal } from "Utilities/modals/types"
import React from "react"

type Props = {
  fromName: string
  toName: string
  onConfirm: (confirm: boolean) => void | null
}

export const ConfirmChangeCountryModal: FunctionalModal<Props> = ({
  fromName,
  toName,
  onConfirm,
  ...props
}) => (
  <Modal {...props} onClose={() => onConfirm(false)}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>May require SMS verification</ModalHeader>
      <ModalBody>
        <Text>
          You are changing your location from <strong>{fromName}</strong> to{" "}
          <strong>{toName}</strong>. If your new location is in a different
          country, you will be required to re-activate your account with SMS
          verification.
        </Text>
      </ModalBody>
      <ModalFooter>
        <HStack>
          <Button variant="outline" onClick={() => onConfirm(false)}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={() => onConfirm(true)}>
            Confirm
          </Button>
        </HStack>
      </ModalFooter>
    </ModalContent>
  </Modal>
)
