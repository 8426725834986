import React, { Component, FormEvent, FormHTMLAttributes } from "react"

import { token } from "Services/rails-csrf-token"
import { Omit } from "Types"

type JsFormProps = Omit<
  FormHTMLAttributes<HTMLFormElement>,
  "action" | "method"
>

export class JsForm extends Component<JsFormProps> {
  private handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    const { onSubmit } = this.props
    // Prevent warnings from Chrome if the form is unmounted as a side-effect of
    // `onSubmit`.
    // see: https://stackoverflow.com/a/42081856/317135
    event.preventDefault()
    if (onSubmit !== undefined) {
      onSubmit(event)
    }
  }
  render() {
    const { onSubmit, ...rest } = this.props
    return <form action="" onSubmit={this.handleSubmit} {...rest} />
  }
}

interface RailsFormProps extends FormHTMLAttributes<HTMLFormElement> {
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE"
}

export const RailsForm: React.FC<React.PropsWithChildren<RailsFormProps>> = ({
  children,
  method,
  ...rest
}) => {
  // GET and POST are the only supported <form> method values in the browser.
  // For any other method value, we use POST and add a hidden field to emulate Rails' form_for behaviour.
  return (
    <form method={method === "GET" ? "GET" : "POST"} {...rest}>
      {method !== "GET" && method !== "POST" && (
        <input type="hidden" name="_method" value={method} />
      )}
      {token !== null && (
        <input type="hidden" value={token} name="authenticity_token" />
      )}
      {children}
    </form>
  )
}
