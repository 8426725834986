import React from "react"
import MediaQuery, { MediaQueryAllQueryable } from "react-responsive"

import { breakLarge } from "Constants/break-points"
import { Omit } from "Types"

type MediaQueryProps = React.ComponentProps<typeof MediaQuery>
// Prevent overriding query props when consuming our wrappers.
type Props = Omit<MediaQueryProps, "query" | keyof MediaQueryAllQueryable>

export const DesktopOnly: React.FC<Props> = (props) => (
  <MediaQuery {...props} minWidth={breakLarge} />
)

export const MobileOnly: React.FC<Props> = (props) => (
  <MediaQuery {...props} maxWidth={breakLarge - 1} />
)
