import { Box } from "@chakra-ui/react"
import { Page, PageContent, PageMain } from "Shared/components/Page/Page"
import { UserCrowdNavbar } from "UserCrowd/components/UserCrowdNavbar/UserCrowdNavbar"
import React from "react"
import { TermsAndConditionsDocument } from "./TermsAndConditionsDocument"

export const TermsAndConditionsRoute: React.FC = () => {
  return (
    <>
      <Page title="Terms and Conditions">
        <Box pt={16} mx="auto">
          <UserCrowdNavbar variant="blank" />
          <PageMain>
            <PageContent>
              <TermsAndConditionsDocument />
            </PageContent>
          </PageMain>
        </Box>
      </Page>
    </>
  )
}
