import { assertNever } from "Components/filter-controls/utils"
import UhType from "./uh-type"

// biome-ignore lint/suspicious/noConstEnum: legacy code
export const enum RecruitmentLinkState {
  Disabled,
  Collecting,
  Complete,
  Canceled,
}

export interface RecruitmentLinkable {
  is_recruitment_link_limit_enabled: boolean
  is_recruitment_link_enabled: boolean
  recruited_response_count: number
  recruitment_link_limit: number
  uh_type: UhType
}

// This is the preferred type we're moving towards instead of the enum above.
// If you have a chance to replace usages of the enum with this, please do!
export type RecruitmentLinkStateAsString =
  | "disabled"
  | "collecting"
  | "complete"
  | "canceled"

// Scaffolding while the enum exists
export const getLinkStateAsString = (
  state: RecruitmentLinkState
): RecruitmentLinkStateAsString => {
  switch (state) {
    case RecruitmentLinkState.Collecting:
      return "collecting"
    case RecruitmentLinkState.Disabled:
      return "disabled"
    case RecruitmentLinkState.Complete:
      return "complete"
    case RecruitmentLinkState.Canceled:
      return "canceled"
    default:
      assertNever(state)
      return "disabled"
  }
}
