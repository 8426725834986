import { defineStyleConfig } from "@chakra-ui/react"

// note these don't apply to all disabled states!
const defaultDisabledStyles = {
  bg: "ds.background.disabled",
  color: "ds.text.disabled",
} as const

export const ButtonDS = defineStyleConfig({
  baseStyle: {
    textStyle: "ds.interface.medium",
    // this is necessary for buttons that are actually links, since without
    // this, Chakra makes the button display: inline, which messes with spacing
    // outside it
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    _focusVisible: {
      layerStyle: "focused",
    },
    _disabled: {
      _hover: {
        color: "ds.text.disabled",
        bg: "ds.background.disabled",
        cursor: "not-allowed",
      },
      userSelect: "none",
      "& svg": { color: "ds.icon.disabled" },
    },
  },
  sizes: {
    emphasized: {
      height: "2.5rem",
      px: 4,
      rounded: "8px",
      textStyle: "ds.interface.large",
    },
    default: {
      height: "2rem",
      px: 3,
      rounded: "6px",
    },
    compact: {
      height: "1.5rem",
      px: 2,
      rounded: "4px",
    },
    flush: {
      height: "1rem",
      px: 0.5,
      rounded: "4px",
    },
  },
  variants: {
    default: {
      bg: "ds.background.neutral.resting",
      color: "ds.text.default",
      _hover: {
        bg: "ds.background.neutral.hovered",
        color: "ds.text.default",
        textDecoration: "none",
      },
      _active: {
        bg: "ds.background.neutral.pressed",
      },
      _disabled: defaultDisabledStyles,
    },
    primary: {
      bg: "ds.background.brand.bold.resting",
      color: "ds.text.inverse",
      _hover: {
        bg: "ds.background.brand.bold.hovered",
        color: "ds.text.inverse",
        textDecoration: "none",
      },
      _active: {
        bg: "ds.background.brand.bold.pressed",
      },
      _disabled: defaultDisabledStyles,
    },
    secondary: {
      bg: "ds.background.neutral.subtle.resting",
      color: "ds.text.default",
      _hover: {
        bg: "ds.background.neutral.subtle.hovered",
        color: "ds.text.default",
        textDecoration: "none",
      },
      _active: {
        bg: "ds.background.neutral.subtle.pressed",
      },
      _disabled: {
        color: "ds.text.disabled",
      },
    },
    danger: {
      bg: "ds.background.danger.bold.resting",
      color: "ds.text.inverse",
      _hover: {
        bg: "ds.background.danger.bold.hovered",
        color: "ds.text.inverse",
        textDecoration: "none",
      },
      _active: {
        bg: "ds.background.danger.bold.pressed",
      },
      _disabled: defaultDisabledStyles,
    },
    "danger-subtle": {
      bg: "ds.background.neutral.subtle.resting",
      color: "ds.text.danger",
      _hover: {
        bg: "ds.background.neutral.subtle.hovered",
        color: "ds.text.danger",
        textDecoration: "none",
      },
      _active: {
        bg: "ds.background.neutral.subtle.pressed",
      },
      _disabled: defaultDisabledStyles,
      "& svg": {
        color: "ds.icon.danger",
      },
    },
    warning: {
      bg: "ds.background.warning.bold.resting",
      color: "ds.text.inverse",
      _hover: {
        bg: "ds.background.warning.bold.hovered",
        color: "ds.text.inverse",
        textDecoration: "none",
      },
      _active: {
        bg: "ds.background.warning.bold.pressed",
      },
      _disabled: defaultDisabledStyles,
    },
    upgrade: {
      bg: "ds.background.upgrade.bold.resting",
      color: "ds.text.default",
      _hover: {
        bg: "ds.background.upgrade.bold.hovered",
        color: "ds.text.default",
        textDecoration: "none",
      },
      _active: {
        bg: "ds.background.upgrade.bold.pressed",
      },
      _disabled: defaultDisabledStyles,
    },
    subtle: {
      bg: "ds.background.neutral.subtle.resting",
      color: "ds.text.subtle",
      _hover: {
        "&:not(:disabled, [aria-disabled])": {
          bg: "ds.background.neutral.subtle.hovered",
        },
        color: "ds.text.subtle",
        textDecoration: "none",
      },
      _active: {
        bg: "ds.background.neutral.subtle.pressed",
      },
      _disabled: {
        color: "ds.text.disabled",
        _hover: {
          bg: "ds.background.neutral.subtle.resting",
        },
      },
    },
  },
  defaultProps: {
    variant: "default",
    size: "default",
  },
})
