import { createIcon } from "@chakra-ui/react"
import React from "react"

export const UsabilityTestIcon = createIcon({
  displayName: "FigmaIcon",
  viewBox: "0 0 16 10",
  path: (
    <>
      <path
        fill="currentColor"
        d="M14.944 9.583H1.056a.556.556 0 0 1 0-1.11h13.888a.556.556 0 0 1 0 1.11Zm-2.5-9.166a1.39 1.39 0 0 1 1.39 1.389v5.833H2.166V1.806A1.39 1.39 0 0 1 3.556.416h8.888ZM9.164 5.19a.416.416 0 0 0 0-.59l-.635-.634.28-.085a.417.417 0 0 0-.037-.806L7.216 2.75a.418.418 0 0 0-.493.493L7.048 4.8c.04.192.218.335.414.332a.42.42 0 0 0 .392-.294l.086-.28.634.635a.415.415 0 0 0 .59 0Z"
      />
    </>
  ),
})
