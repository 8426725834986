import {
  Box,
  BoxProps,
  Stack,
  forwardRef,
  useBreakpointValue,
} from "@chakra-ui/react"
import { isObject } from "lodash"
import React, { useEffect, useState } from "react"
import { NavigationMenuContext } from "./Context"
import { useMergedBackgroundColor } from "./useMergedBackgroundColor"

type RootProps = BoxProps

export const Root = forwardRef<RootProps, "nav">(
  ({ top: responsiveTop = "0rem", sx = {}, children, ...props }, ref) => {
    const [container, setContainer] = useState<HTMLDivElement | null>(null)

    const [items, setItems] = useState<HTMLDivElement | null>(null)

    const [footer, setFooter] = useState<HTMLDivElement | null>(null)

    const [scrollable, setScrollable] = useState(false)

    const top = useBreakpointValue(
      isObject(responsiveTop) ? responsiveTop : { base: responsiveTop }
    )

    const mergedBackgroundColor = useMergedBackgroundColor(container)

    useEffect(() => {
      if (!container) return

      const scrolledOrResized = () => {
        const top = container.getBoundingClientRect().top
        container.style.setProperty("--navigation-menu-top", `${top}px`)
      }

      scrolledOrResized()

      document.addEventListener("scroll", scrolledOrResized)
      container.addEventListener("resize", scrolledOrResized)

      return () => {
        document.removeEventListener("scroll", scrolledOrResized)
        container.removeEventListener("resize", scrolledOrResized)
      }
    }, [container])

    useEffect(() => {
      if (!items) return

      const checkForScrolling = () => {
        setScrollable(items.scrollHeight > items.clientHeight)
      }

      checkForScrolling()

      const resizeObserver = new ResizeObserver(checkForScrolling)
      resizeObserver.observe(items)

      return () => {
        resizeObserver.disconnect()
      }
    }, [items])

    return (
      <NavigationMenuContext.Provider value={{ footer }}>
        <Box
          ref={ref}
          as="nav"
          h="full"
          flex={1}
          spacing={0}
          bg="ds.background.neutral.resting"
          sx={{
            ...sx,
            "--navigation-menu-top": String(top ?? "0rem"),
            "--merged-background-color": mergedBackgroundColor,
          }}
          {...props}
        >
          <Stack
            ref={setContainer}
            pos="sticky"
            top={responsiveTop}
            align="stretch"
            justify="space-between"
            flex={1}
            spacing={0}
            h="full"
            maxH="calc(100svh - var(--navigation-menu-top))"
          >
            <Stack
              ref={setItems}
              py={4}
              pl={4}
              pr={scrollable ? 0 : 4}
              spacing={2}
              flex={1}
              overflow="auto"
              sx={{
                "--navigation-menu-footer-height": "0",
                "::-webkit-scrollbar": {
                  opacity: 1,
                  w: "1rem",
                  margin: "0.25rem",
                },
                "::-webkit-scrollbar-track": {
                  bg: "transparent",
                },
                "::-webkit-scrollbar-thumb": {
                  bg: "ds.background.neutral.subtle.pressed",
                  rounded: "full",
                  border:
                    "0.25rem solid var(--merged-background-color, transparent)",
                },
              }}
            >
              {children}
            </Stack>
            <Box flexGrow={0} pointerEvents="none" ref={setFooter} />
          </Stack>
        </Box>
      </NavigationMenuContext.Provider>
    )
  }
)
