import React, { ReactNode } from "react"

import styles from "Components/time/styles.module.scss"
import { getDateTimeString } from "Utilities/date-formats"

interface Props {
  date: Date
  children: ReactNode
}

export function TimeImpl({ date, children }: Props) {
  return (
    <time
      className={styles.hasTooltip}
      dateTime={date.toISOString()}
      title={getDateTimeString(date)}
    >
      {children}
    </time>
  )
}
