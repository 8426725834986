import { Box } from "@chakra-ui/react"
import React from "react"

import { Page, PageContent, PageMain } from "Shared/components/Page/Page"
import { EditPasswordForm } from "Shared/forms/EditPasswordForm"
import { UserCrowdNavbar } from "UserCrowd/components/UserCrowdNavbar/UserCrowdNavbar"

export const EditPasswordRoute: React.FC = () => (
  <Page title="Edit password">
    <Box pt={16} mx="auto">
      <UserCrowdNavbar variant="signed-out" />
      <PageMain>
        <PageContent maxW="lg">
          <EditPasswordForm />
        </PageContent>
      </PageMain>
    </Box>
  </Page>
)
