import ClientId from "./client-id"

/**
 * Must be kept in sync with PgEnums::TestLogicBehaviour.
 */
export enum TestLogicBehaviour {
  SHOW = "show",
  HIDE = "hide",
}

/**
 * Must be kept in sync with PgEnums::TestLogicComparator.
 */
export enum TestLogicComparator {
  EQ = "eq",
  GT = "gt",
  GTE = "gte",
  LT = "lt",
  LTE = "lte",
}

type TargetType = "UsabilityTestSection" | "UsabilityTestSectionQuestion"

interface RawTestLogicStatement {
  id: number | null
  target_id: number | null
  target_type: TargetType | null
  behaviour: TestLogicBehaviour
  comparator: TestLogicComparator
  values: string[]
}

export interface TestLogicStatement extends RawTestLogicStatement {
  _destroy: boolean
  _targetClientId: ClientId | null
}
