import { createIcon } from "@chakra-ui/react"
import React from "react"

export const Rocket02SolidIcon = createIcon({
  displayName: "Rocket02SolidIcon",
  viewBox: "0 0 24 24",
  defaultProps: { fill: "none" },
  path: (
    <>
      <path
        d="M15.2311 2.71764C17.306 1.57646 19.6383 0.985092 22.0062 0.999779C22.5561 1.00319 23 1.4499 23 1.99976C23 4.81006 22.2116 9.70747 17.1476 13.4164C17.2328 13.774 17.3298 14.2446 17.3968 14.7647C17.4699 15.3321 17.5111 15.9852 17.4513 16.6279C17.3924 17.2619 17.2287 17.9597 16.8321 18.5547L16.8312 18.556C16.1522 19.5709 14.8717 20.1594 13.9772 20.4838C13.4966 20.6582 13.0525 20.7818 12.7293 20.862C12.4858 20.9224 12.3071 20.9591 12.226 20.9749C11.9178 21.0335 11.6038 20.9719 11.3591 20.7677C11.1316 20.5777 11 20.2965 11 20V15.414L8.58605 13H4.00001C3.70354 13 3.42235 12.8685 3.23235 12.6409C3.04235 12.4133 2.96314 12.1131 3.01609 11.8214C3.04981 11.6365 3.09278 11.453 3.13801 11.2707C3.21817 10.9475 3.34184 10.5035 3.51619 10.0228C3.84065 9.12829 4.42907 7.84779 5.44399 7.16885L5.44531 7.16797C6.0403 6.77131 6.73809 6.60762 7.37215 6.54869C8.01483 6.48895 8.66796 6.5301 9.23533 6.60322C9.76085 6.67095 10.2359 6.76921 10.5948 6.85508C11.8057 5.1461 13.3898 3.73032 15.2311 2.71764Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00316 14.9883C5.22 14.9641 4.45548 15.2299 3.85622 15.7346C3.32944 16.1774 2.94265 16.7872 2.65617 17.3738C2.36398 17.9719 2.14194 18.6175 1.97608 19.1973C1.80931 19.7804 1.6934 20.3181 1.61914 20.7092C1.58187 20.9055 1.55477 21.0666 1.53677 21.1801C1.527 21.2417 1.51784 21.3035 1.50912 21.3653L1.50889 21.3669C1.4676 21.6758 1.57259 21.9866 1.79291 22.2069C2.01324 22.4273 2.32454 22.5322 2.63338 22.4909C2.69558 22.4821 2.75771 22.4729 2.81975 22.4631C2.93328 22.4451 3.09436 22.418 3.29062 22.3807C3.68176 22.3065 4.2195 22.1905 4.80253 22.0238C5.38238 21.8579 6.02792 21.6359 6.6261 21.3437C7.21241 21.0573 7.82208 20.6707 8.26475 20.1442C9.28578 18.935 9.30102 17.0516 8.11261 15.8782L8.10046 15.8664C7.53347 15.3253 6.78659 15.0126 6.00316 14.9883Z"
        fill="currentColor"
      />
    </>
  ),
})
