// Keep in sync with `app/frontend/application/stylesheets/globals.scss`
// until all components are client rendered

export const styles = {
  global: {
    body: {
      fontFamily: "body",
      color: "ds.text.default",
      lineHeight: "base",
      minHeight: "100%",
      overflowY: "scroll",
    },
    "@media screen": {
      body: {
        background: "ds.surface.sunken",
      },
    },
    "*::placeholder": {
      color: "gray.400",
    },
    "*, *::before, *::after": {
      borderColor: "gray.200",
      wordWrap: "break-word",
    },
    // Chakra doesn’t expose the ButtonIcon via the theme
    // so set to display: flex here for alignment
    ".chakra-button__icon": {
      display: "flex",
    },
  },
  iconButton: {
    basicStyle: {
      bg: "white",
      border: "1px solid",
      borderColor: "gray.300",
      shadow: "sm",
      color: "gray.400",
    },
    basicSize: {
      w: 7,
      h: 7,
    },
    hover: {
      borderColor: "gray.500",
      color: "gray.500",
      bg: "white",
    },
    active: {
      bg: "white",
      color: "brand.primary.400",
      borderColor: "brand.primary.400",
    },
  },
}
