import { capitalizeFirstLetter } from "Utilities/string"
import { DeviceRequirement } from "~/api/generated/usabilityhubSchemas"

export const deviceRequirementsLabel = (
  deviceRequirement: Omit<DeviceRequirement, "id">
) => {
  const deviceTypesLabel =
    deviceRequirement.device_types.length === 3
      ? "Any device"
      : deviceRequirement.device_types.map(capitalizeFirstLetter).join(" or ")
  const devicePeripherals = deviceRequirement.device_peripherals.join(" and ")
  const optionalDevicePeripherals =
    devicePeripherals === "" ? "" : ` with a ${devicePeripherals}`

  return `${deviceTypesLabel}${optionalDevicePeripherals}`
}
