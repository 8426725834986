import { useQuery } from "@tanstack/react-query"
import { getEnvState } from "JavaScripts/state"
import { useState } from "react"
import { v4 as uuid } from "uuid"

type GooglePlacesAutocompleteResponse = {
  suggestions: any[]
}

// Passes the given string to the Google Places Autocomplete API and returns the response.
// Note that it will re-run whenever the string changes, so you probably want to debounce anything
// that you pass in here.
// Will only fire the call if the input string is non-empty.
export const useGooglePlacesAutocomplete = (searchString: string) => {
  // Maintain a consistent session token so Google can consolidate requests for billing purposes
  // In future we could send this to the backend and use it for the final places:detail request too
  // Docs: https://developers.google.com/maps/documentation/places/web-service/place-session-tokens
  const [sessionToken] = useState(uuid)

  return useQuery<GooglePlacesAutocompleteResponse>({
    queryKey: ["google-places-autocomplete", sessionToken, searchString],
    queryFn: async (context) => {
      const [, sessionToken, input] = context.queryKey
      const env = getEnvState()

      const response = await fetch(
        `${env.GOOGLE_PLACES_DOMAIN}/v1/places:autocomplete`,
        {
          method: "POST",
          body: JSON.stringify({
            input,
            sessionToken,
            languageCode: "en",
            includedPrimaryTypes: [
              "administrative_area_level_3",
              "locality",
              "sublocality",
              "postal_code",
            ],
          }),
          headers: {
            "Content-Type": "application/json",
            "X-Goog-Api-Key": env.GOOGLE_PLACES_API_KEY,
          },
        }
      )

      if (!response.ok) {
        throw new Error("The API request failed")
      }

      return response.json()
    },
    enabled: sessionToken.length > 0 && searchString.length > 0,
    keepPreviousData: true,
  })
}
