export const Menu = {
  baseStyle: {
    list: {
      bg: "white",
      boxShadow: "lg",
      color: "inherit",
      minW: 48,
      zIndex: 1,
      borderRadius: "md",
      borderWidth: "1px",
      py: 0,
    },
    item: {
      px: 3,
      py: 3,
      fontSize: "sm",
      fontWeight: "medium",
      lineHeight: "short",
      _focus: {
        bg: "gray.50",
      },
      _active: {
        bg: "gray.200",
      },
      _disabled: {
        opacity: 0.4,
        cursor: "not-allowed",
      },

      // Gross but we can’t target menu icons yet
      ".chakra-menu__icon-wrapper": {
        marginEnd: 2,
        color: "gray.500",
      },
      ".chakra-menu__icon": {
        width: 4,
        height: 4,
      },
    },
  },
}
