import {
  endOfDay,
  getDaysInMonth,
  isAfter,
  isWithinInterval,
  startOfDay,
} from "date-fns"
import { toDate, utcToZonedTime } from "date-fns-tz"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { matchPath } from "react-router"
import {
  ListModeratedStudyBookingSlotsQueryParams,
  useListModeratedStudyBookingSlots,
  usePreviewModeratedStudyBookingSlots,
} from "~/api/generated/usabilityhub-components"
import { DateType } from "../interviewer/availability/DayOverrideModal"
import { useModeratedStudyApplicationContext } from "./ModeratedStudyApplicationContext"

type UseBookingCalendarOptions = {
  moderatedStudyApplicationId: string
  timezone: string
  slot: string | null
  existingBookingId?: string
  isFirstLoading: boolean
  setIsFirstLoading?: (isFirstLoading: boolean) => void
  setHasAvailableSlots?: (hasAvailableSlots: boolean) => void
  onSlotChange: (slot: string | null) => void
}

export const useBookingCalendar = ({
  moderatedStudyApplicationId,
  timezone,
  slot,
  existingBookingId,
  isFirstLoading,
  setIsFirstLoading,
  setHasAvailableSlots,
  onSlotChange,
}: UseBookingCalendarOptions) => {
  const isFullPreview = matchPath(
    { path: "/interviews/:moderatedStudyId/preview/*" },
    location.pathname
  )
  // For anywhere (like the drawer on the edit page) where we don't want to navigate away
  const isCalendarOnlyPreview = matchPath(
    { path: "/interviews/:moderatedStudyId/edit/*" },
    location.pathname
  )

  const isPreview = isFullPreview || isCalendarOnlyPreview

  // We only have this ID on the preview page, not the regular version so we can't use useTypedParams
  const moderatedStudyId = isPreview?.params["moderatedStudyId"]

  const { application, navigateToThankYouPage } =
    useModeratedStudyApplicationContext()

  // The year and month the calendar is currently displaying
  const [[year, month], setYearAndMonth] = useState<[number, number]>(() => {
    const now = slot ? utcToZonedTime(toDate(slot), timezone) : new Date()
    // Don't forget JS months are 0-indexed
    return [now.getFullYear(), now.getMonth() + 1]
  })

  // When we pass query parameters like {a_param: a}, the a_param is declared as a string,
  // while a is undefined. Consequently, the backend will receive the string “undefined” for a_param.
  const queryParams: ListModeratedStudyBookingSlotsQueryParams = {
    year,
    month,
    timezone,
  }

  const {
    isLoading: realIsLoading,
    isError: realIsError,
    data: realData,
    refetch: realRefetch,
  } = useListModeratedStudyBookingSlots(
    {
      pathParams: {
        moderatedStudyApplicationId,
      },
      queryParams: queryParams,
    },
    {
      keepPreviousData: true,
      enabled: !isPreview,
    }
  )

  const {
    isLoading: previewIsLoading,
    isError: previewIsError,
    data: previewData,
    refetch: previewRefetch,
  } = usePreviewModeratedStudyBookingSlots(
    {
      pathParams: {
        // Should always be present in this case, but the type system doesn't know
        moderatedStudyId: moderatedStudyId ?? "",
      },
      queryParams: queryParams,
    },
    {
      keepPreviousData: true,
      enabled: !!isPreview,
    }
  )

  // Only one or the other of these queries will be active depending on whether this is a preview
  const isLoading = isPreview ? previewIsLoading : realIsLoading
  const isError = isPreview ? previewIsError : realIsError
  const data = isPreview ? previewData : realData
  const refetch = isPreview ? previewRefetch : realRefetch

  const isRescheduling = useRef(!!existingBookingId)

  const startOfMonthInLocal = useMemo(
    () =>
      toDate(`${year}-${month > 9 ? "" : "0"}${month}}-01T00:00:00`, {
        timeZone: timezone,
      }),
    [year, month, timezone]
  )

  // The year, month, and date of the selected date by users.
  const [selectedDate, setSelectedDate] = useState<DateType>()

  // For the booking page, we want to pick the first available slot by default
  // when changing months (if any are available this month)
  // For the reschedule page, we want to show “Select a date to view available times”
  // by default when changing months.
  useEffect(() => {
    if (data) {
      // Don't do this the first time the data loads if we have an existing slot selected
      if (isRescheduling.current) {
        return
      }

      const firstSlotInMonth = data.booking_slots.find((slot) => {
        const slotStartTimeInLocal = toDate(slot.start_time)

        return isAfter(slotStartTimeInLocal, startOfMonthInLocal)
      })

      if (firstSlotInMonth?.start_time) {
        const slotTime = utcToZonedTime(firstSlotInMonth?.start_time, timezone)
        setSelectedDate([
          slotTime.getFullYear(),
          slotTime.getMonth() + 1,
          slotTime.getDate(),
        ])
        onSlotChange(firstSlotInMonth?.start_time)
      } else {
        setSelectedDate(undefined)
        onSlotChange(null)
      }
    } else {
      onSlotChange(null)
    }
  }, [data, startOfMonthInLocal, onSlotChange])

  useEffect(() => {
    // This is used for the booking page only
    if (isFirstLoading && !isLoading) {
      if (data) setHasAvailableSlots?.(data.has_available_booking_slots)
      if (
        data &&
        data.booking_slots.length === 0 &&
        !data.has_available_booking_slots &&
        !application.is_invited &&
        !isCalendarOnlyPreview
      ) {
        void navigateToThankYouPage()
      } else {
        setIsFirstLoading?.(false)
      }
    }
  }, [isLoading, isCalendarOnlyPreview])

  const onDateChange = useCallback(
    (start_time: string) => {
      if (!existingBookingId) {
        // On the booking page, we'll select the first available slot on the day by default
        onSlotChange(start_time)
      }

      const slotTime = utcToZonedTime(start_time, timezone)
      setSelectedDate([
        slotTime.getFullYear(),
        slotTime.getMonth() + 1,
        slotTime.getDate(),
      ])
    },
    [onSlotChange, timezone, existingBookingId]
  )

  const daysInMonth = useMemo(
    () => getDaysInMonth(new Date(year, month - 1)),
    [year, month]
  )

  // We have a flat list of slots from the API, group them up by day
  // Starting array from 1 so we can use the real day number as the index
  const slotsByDay = useMemo(
    () =>
      new Array(daysInMonth + 1).fill(null).map((_, i) => {
        const day = new Date(year, month - 1, i)

        return (data?.booking_slots || []).filter((slot) => {
          const slotTime = utcToZonedTime(slot.start_time, timezone)
          return isWithinInterval(slotTime, {
            start: startOfDay(day),
            end: endOfDay(day),
          })
        })
      }),
    [daysInMonth, data?.booking_slots, timezone]
  )

  return {
    isCalendarOnlyPreview,
    year,
    month,
    setYearAndMonth,
    isLoading,
    isError,
    data,
    refetch,
    selectedDate,
    isRescheduling,
    slotsByDay,
    onDateChange,
  }
}
