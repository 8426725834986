import { Flex, FlexProps, Spinner, useBreakpointValue } from "@chakra-ui/react"
import { NextAndPrevArrows } from "UsabilityHub/views/ModeratedStudy/applicant/NextAndPrevArrows"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import { useScreenerQuestionAnswersContext } from "./Questions/AnsweredQuestionsContext"
import { ScreenerQuestionAnswerForm } from "./Questions/ScreenerQuestionAnswerForm"

export function ScreenerQuestionAnswersFlow() {
  const {
    currentQuestion,
    currentQuestionIndex,
    setCurrentQuestionIndex,
    screenerQuestionAnswers,
    screenerQuestions,
    isInvalidAnswer,
  } = useScreenerQuestionAnswersContext()

  const { addScreenerQuestionAnswer: addScreenerQuestionAnswer } =
    useScreenerQuestionAnswersContext()

  const isMobile = useBreakpointValue<boolean>(
    {
      base: true,
      md: false,
    },
    { ssr: false }
  )

  const positionStyles: FlexProps = isMobile
    ? {
        bottom: 0,
        left: 0,
        right: 0,
        top: "auto",
        h: "68px",
        alignItems: "center",
        justifyContent: "flex-end",
        paddingX: "16px",
        backgroundColor: "#fff",
      }
    : {
        top: 8,
        right: 8,
        height: "42px",
        width: "90px",
        justifyContent: "flex-end",
      }

  if (currentQuestion === undefined) {
    return (
      <Flex justifyContent="center" width="full">
        <Spinner />
      </Flex>
    )
  }

  return (
    <>
      <Flex
        position={isMobile ? "fixed" : "absolute"}
        zIndex={1}
        {...positionStyles}
      >
        <NextAndPrevArrows
          moveUpAction={() => {
            setCurrentQuestionIndex(
              (currentQuestionIndex) => currentQuestionIndex - 1
            )
          }}
          moveDownAction={() => {
            setCurrentQuestionIndex(
              (currentQuestionIndex) => currentQuestionIndex + 1
            )
          }}
          upDisabled={currentQuestionIndex === 0}
          downDisabled={
            currentQuestionIndex >= screenerQuestions.length || isInvalidAnswer
          }
        />
      </Flex>
      <AnimatePresence>
        <motion.div
          key={currentQuestion.id}
          initial={{ y: 200, opacity: 0, width: "100%" }}
          animate={{ y: 0, opacity: 1, width: "100%" }}
          exit={{ display: "none" }}
          transition={{
            y: {
              type: "spring",
              stiffness: 600,
              damping: 32,
            },
          }}
        >
          <ScreenerQuestionAnswerForm
            question={currentQuestion}
            answer={screenerQuestionAnswers[currentQuestionIndex]}
            onAddAnswer={addScreenerQuestionAnswer}
          />
        </motion.div>
      </AnimatePresence>
    </>
  )
}
