import {
  Box,
  FormControl,
  FormLabel,
  Input,
  InputProps,
  Link,
  Text,
} from "@chakra-ui/react"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import { PropsWithChildren, useEffect, useMemo, useState } from "react"

type Props = {
  fullName: string
  preferredName: string
  setPreferredName: (name: string) => void
  isInvalid: boolean
  inputProps: InputProps
}

export const PreferredNameField: React.FC<Props> = ({
  fullName,
  preferredName,
  setPreferredName,
  isInvalid,
  inputProps,
}) => {
  const [showPreferredNameField, setShowPreferredNameField] = useState(false)

  const fullNameParts = useMemo(
    () => fullName.trimStart().split(" "),
    [fullName]
  )

  useEffect(() => {
    // Only update the preferred name while it's hidden
    if (showPreferredNameField) {
      return
    }

    setPreferredName(fullNameParts[0])
  }, [showPreferredNameField, setPreferredName, fullNameParts])

  return (
    <AnimatePresence mode="wait">
      {showPreferredNameField ? (
        <MotionContainer key="preferredNameField">
          <FormControl mt="4" isInvalid={isInvalid}>
            <FormLabel
              htmlFor="preferredName"
              fontSize="md"
              fontWeight="medium"
            >
              Preferred name
            </FormLabel>
            <Input
              autoFocus
              type="text"
              id="preferredName"
              placeholder="Your preferred name"
              size="lg"
              {...inputProps}
            />
          </FormControl>
        </MotionContainer>
      ) : fullNameParts.length > 1 ? (
        <MotionContainer key="preferredNameInfo">
          <Box backgroundColor="gray.200" p="4" borderRadius="md" mt="4">
            <Text mb={2}>
              Is it alright if we call you{" "}
              <Text as="span" fontWeight="semibold">
                {preferredName}
              </Text>
              ?
            </Text>
            <Link
              textDecoration="none"
              fontWeight="medium"
              onClick={() => setShowPreferredNameField(true)}
              data-qa="show-preferred-name-field"
              _hover={{
                textDecoration: "none",
                color: "brand.primary.700",
              }}
            >
              I prefer a different name
            </Link>
          </Box>
        </MotionContainer>
      ) : null}
    </AnimatePresence>
  )
}

const MotionContainer: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <motion.div
      initial={{ height: 0, overflow: "hidden" }}
      animate={{ height: "auto", transitionEnd: { overflow: "unset" } }}
      exit={{ height: 0, overflow: "hidden" }}
    >
      {children}
    </motion.div>
  )
}
