import { Flex, Text } from "@chakra-ui/react"
import { Heading } from "DesignSystem/components"
import React from "react"

export const OnboardingDrawerInstructions: React.FC = () => (
  <Flex flexDirection="column" gap={3}>
    <Heading as="h2" textStyle="ds.display.primary">
      {`Let\u2019s get started!`}
    </Heading>
    <Text textStyle="ds.paragraph.primary">
      Complete all steps to boost your earning potential and begin receiving
      studies.
    </Text>
  </Flex>
)
