import { useListLanguages } from "~/api/generated/usabilityhub-components"

export const useLanguages = () => {
  const { data: languages = [] } = useListLanguages(
    {},
    {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      staleTime: 1000 * 60 * 60 * 24,
    }
  )

  return languages
}
