export const themeTokens = {
  brand: {
    primary: "teal",
    secondary: "teal",
    neutral: {
      light: "text.secondary",
      default: "text.primary",
    },
    background: {
      light: "#EBF3FA",
      hover: "grey.mid",
    },
  },
  text: {
    primary: "#343A40",
    secondary: "gray.500",
    inverted: "white",
    default: "#0F2938",
    selected: "#006E75",
    subtlest: "#6D8494",
    disabled: "rgba(0, 0, 0, 0.24)",
    link: {
      default: "#0B978E",
    },
  },
  border: {
    default: "rgba(0, 0, 0, 0.08)",
    selected: "#0B978E",
    dark: "#6D8494",
  },

  transparent: "transparent",
  current: "currentColor",

  grey: {
    lightest: "#FFFFFF",
    light: "#F5FAFE",
    mid: "#E1EBF5",
    dark: "#97ABBA",
    darker: "#425D6D",
  },
  link: "#3182CE",
  helperText: "#627b93",

  alert: {
    info: {
      icon: "teal.500",
      background: "teal.50",
      text: "text.default",
      hover: "teal.700",
    },
    error: {
      icon: "#E83800",
      background: "#FFDCD6",
      text: "text.default",
      hover: "#9C4221",
    },
    success: {
      icon: "green.500",
      background: "green.50",
      text: "text.default",
      hover: "green.700",
    },
    warning: {
      icon: "#DD6B20",
      background: "#FFFAF0",
      text: "text.default",
      hover: "#992600",
    },
  },

  toast: {
    icon: "text.inverted",
    text: "text.inverted",
    info: "#068382",
    error: "#F05252",
    success: "#0E9F6E",
    warning: "#EF6820",
  },

  icon: {
    accent: {
      cyan: "#00A3C7",
      purple: "#6633C9",
    },
    disabled: "rgba(0, 0, 0, 0.24)",
    subtle: "#6D8494",
    success: "green.500",
  },

  bg: {
    page: "#F7F9FB",
    surface: {
      callout: "grey.light",
      sunken: "grey.light",
    },
    brand: {
      subtle: {
        default: "#DEFDF3",
      },
      bold: {
        default: "#068382",
      },
    },
    accent: {
      purple: {
        subtle: "#F0E8FF",
      },
      cyan: {
        subtle: "#DAF9FF",
      },
    },
    neutral: {
      default: "#FFFFFF",
      subtle: {
        hover: "grey.light",
      },
    },
    selected: {
      default: "#DEFDF3",
    },
    success: {
      bold: {
        default: "#25855A",
      },
    },
  },

  menu: {
    inverted: "#061D29",
  },

  // https://www.figma.com/file/qkkQqYsxTZfHbGNn0T0R1K/%5B%F0%9F%9A%A7-WIP%5D-LDS-Styles?type=design&node-id=12-155&mode=design

  brandPalette: {
    neutral: {
      darkest: "#061D29",
      dark: "#97ABBA",
      mid: "#E1EBF5",
      light: "#F5FAFE",
    },
    red: {
      mid: "#FF7658",
      light: "#FFDCD6",
    },
    pink: {
      darkest: "#4D0037",
      dark: "#8F2865",
      mid: "#EC88C1",
      light: "#FFE2F3",
    },
    purple: {
      dark: "#5723B4",
      mid: "#B698F2",
      light: "#F0E8FF",
    },
    yellow: {
      mid: "#FFE935",
      light: "#FFFACF",
    },
    teal: {
      dark: "#006E75",
      mid: "#63C9BA",
      light: "#DEFDF3",
    },
    cyan: {
      mid: "#5BD6F1",
      light: "#DAF9FF",
    },
  },

  status: {
    success: "#0E9F6E",
  },
}
