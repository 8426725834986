import { createIcon } from "@chakra-ui/react"
import React from "react"

export const PaypalLogoIcon = createIcon({
  displayName: "PaypalLogoIcon",
  viewBox: "0 0 28 32",
  defaultProps: { fill: "none", width: "28px", height: "32px" },
  path: (
    <>
      <path
        d="M23.4703 2.40437C21.9898 0.721488 19.3135 0 15.8899 0H5.95334C5.25288 0 4.65766 0.50804 4.54799 1.19689L0.410643 27.3654C0.328391 27.8814 0.729036 28.3488 1.2535 28.3488H7.38788L8.92855 18.6035L8.8808 18.9086C8.99046 18.2198 9.58126 17.7117 10.2808 17.7117H13.1959C18.9226 17.7117 23.4066 15.392 24.7165 8.68167C24.7554 8.48322 24.789 8.29006 24.8182 8.10131C24.6528 8.01399 24.6528 8.01399 24.8182 8.10131C25.2082 5.62108 24.8155 3.9329 23.4703 2.40437Z"
        fill="#27346A"
      />
      <path
        d="M11.2758 7.20782C11.4394 7.13021 11.6216 7.08699 11.8127 7.08699H19.6027C20.5252 7.08699 21.3857 7.14697 22.172 7.27309C22.3975 7.30926 22.6159 7.35071 22.8282 7.39834C23.0405 7.44509 23.2465 7.49801 23.4464 7.55622C23.5464 7.58533 23.6445 7.61531 23.7409 7.64707C24.1274 7.77584 24.4874 7.92578 24.8182 8.10131C25.2082 5.6202 24.8155 3.9329 23.4703 2.40437C21.9889 0.721488 19.3135 0 15.8899 0H5.95246C5.25288 0 4.65766 0.50804 4.54799 1.19689L0.410643 27.3645C0.328392 27.8814 0.729037 28.3479 1.25262 28.3479H7.38788L10.5842 8.13394C10.6496 7.71939 10.9167 7.37982 11.2758 7.20782Z"
        fill="#27346A"
      />
      <path
        d="M24.7164 8.68168C23.4065 15.3912 18.9225 17.7118 13.1958 17.7118H10.2799C9.58029 17.7118 8.9895 18.2198 8.88071 18.9086L6.96416 31.0258C6.89252 31.4774 7.24275 31.8866 7.70089 31.8866H12.8721C13.4842 31.8866 14.0051 31.4421 14.1006 30.8397L14.151 30.5768L15.1256 24.4168L15.1884 24.0764C15.284 23.474 15.8049 23.0294 16.4169 23.0294H17.1908C22.2002 23.0294 26.1226 20.9999 27.2688 15.1301C27.7473 12.6772 27.4997 10.6292 26.234 9.19061C25.8502 8.75489 25.3735 8.39503 24.8181 8.10132C24.788 8.29095 24.7553 8.48323 24.7164 8.68168Z"
        fill="#2790C3"
      />
      <path
        d="M23.4472 7.55639C23.2473 7.49818 23.0413 7.44526 22.829 7.39851C22.6167 7.35176 22.3974 7.31031 22.1728 7.27415C21.3856 7.14714 20.526 7.08716 19.6026 7.08716H11.8135C11.6215 7.08716 11.4394 7.13038 11.2766 7.20888C10.9167 7.38087 10.6504 7.71956 10.585 8.13499L8.92935 18.6036L8.88159 18.9088C8.99038 18.2199 9.58117 17.7119 10.2808 17.7119H13.1967C18.9234 17.7119 23.4074 15.3922 24.7172 8.68184C24.7562 8.48339 24.7889 8.29111 24.819 8.10148C24.4873 7.92684 24.1282 7.77601 23.7417 7.64812C23.6453 7.61637 23.5472 7.5855 23.4472 7.55639Z"
        fill="#1F264F"
      />
    </>
  ),
})
