import { Button, ButtonProps, Link } from "@chakra-ui/react"
import React from "react"

import { RoutedLink } from "Shared/components/Links/RoutedLink"

const getDefaultColors = (colorScheme?: string) => {
  // Make sure the button text is gray when it's supposed to be gray, not blue like a link.
  return !colorScheme
    ? {
        color: "gray.700",
        _hover: {
          color: "gray.700",
        },
      }
    : {}
}

type LinkButton = React.FC<
  React.PropsWithChildren<
    {
      href: string
      isExternal?: boolean
      target?: string
    } & ButtonProps
  >
>

export const LinkButton: LinkButton = ({
  children,
  href,
  isExternal,
  target,
  ...buttonProps
}) => {
  const linkColors = getDefaultColors(buttonProps.colorScheme)
  return (
    <Link
      href={href}
      isExternal={isExternal}
      target={target}
      textDecoration="none"
      _hover={{
        textDecoration: "none",
      }}
      {...linkColors}
    >
      <Button {...buttonProps}>{children}</Button>
    </Link>
  )
}

type RoutedLinkButton = React.FC<
  React.PropsWithChildren<
    {
      to: string
    } & ButtonProps
  >
>

export const RoutedLinkButton: RoutedLinkButton = ({
  children,
  to,
  ...buttonProps
}) => {
  const linkColors = getDefaultColors(buttonProps.colorScheme)
  return (
    <RoutedLink
      to={to}
      textDecoration="none"
      _hover={{
        textDecoration: "none",
      }}
      {...linkColors}
    >
      <Button {...buttonProps}>{children}</Button>
    </RoutedLink>
  )
}
