import { AnimatePresence } from "framer-motion"
import React, { FC, createContext, useState } from "react"

type ModalContext = {
  modals: JSX.Element[]
  setModals: React.Dispatch<React.SetStateAction<JSX.Element[]>>
}

const ModalContext = createContext<ModalContext | null>(null)

export const useModalContext = () => {
  const context = React.useContext(ModalContext)

  if (context === null)
    throw new Error(
      "useModalContext must be used within a ModalContextProvider"
    )

  return context
}

/**
 * `ModalContextProvider` must be present near the top of the component tree in order for modals
 * mounted via the `useModal` hook to render.
 *
 * If it is omitted, attempting to render a modal will throw an error.
 *
 * @example
 * const App = () => {
 *   return (
 *     <ModalContextProvider>
 *       <ReduxProvider store={store}>
 *         <Router>
 *           <Route path="/dashboard" component={<DashboardPage />} />
 *         </Router>
 *       </ReduxProvider>
 *     </ModalContextProvider>
 *   )
 * }
 */
export const ModalContextProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [modals, setModals] = useState<JSX.Element[]>([])

  return (
    <ModalContext.Provider value={{ modals, setModals }}>
      {children}
      <AnimatePresence>{modals}</AnimatePresence>
    </ModalContext.Provider>
  )
}
