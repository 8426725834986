import {
  Box,
  BoxProps,
  HStack,
  Text,
  UseCheckboxProps,
  forwardRef,
  useCheckbox,
} from "@chakra-ui/react"
import { layerStyles } from "DesignSystem/layerStyles"
import React, { PropsWithChildren } from "react"

export type CheckboxProps = PropsWithChildren<
  UseCheckboxProps & {
    onCheckedChange?: (checked: boolean) => void
  }
>

export const Checkbox = forwardRef<CheckboxProps, "input">(
  ({ children, onCheckedChange, width = "max-content", ...props }, ref) => {
    const { state, getInputProps } = useCheckbox(props)

    const { style, isChecked, isDisabled, isIndeterminate, ...inputProps } =
      getInputProps(props, ref)

    const changed = (event: React.ChangeEvent<HTMLInputElement>) => {
      const checkbox = event.currentTarget
      if (onCheckedChange && state.isChecked !== checkbox.checked) {
        onCheckedChange(checkbox.checked)
      }
    }

    return (
      <HStack as="label" gap="1" alignItems="start" data-checkbox w={width}>
        <Box
          position="relative"
          boxSize={6}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexShrink={0}
        >
          <CheckboxIcon {...state} />
          <Box
            as="input"
            type="checkbox"
            {...inputProps}
            pos="absolute"
            inset={0}
            w="full"
            h="full"
            opacity={0}
            cursor="pointer"
            onChange={changed}
          />
        </Box>
        <Text
          as="div"
          textStyle="ds.paragraph.primary"
          color={state.isDisabled ? "ds.text.disabled" : "ds.text.default"}
          py="0.125rem"
          flexGrow={1}
        >
          {children}
        </Text>
      </HStack>
    )
  }
)

type CheckboxIconProps = BoxProps &
  Partial<ReturnType<typeof useCheckbox>["state"]>

export const CheckboxIcon = ({
  isChecked,
  isIndeterminate,
  isDisabled,
  isFocused,
  isInvalid,
  isActive,
  isHovered,
  isReadOnly,
  isRequired,
  sx = {},
  ...props
}: CheckboxIconProps) => {
  const filled = isChecked || isIndeterminate

  return (
    <Box
      as="svg"
      boxSize="0.875rem"
      rounded={2}
      viewBox="0 0 14 14"
      layerStyle={isFocused ? "focused" : undefined}
      sx={{
        ...sx,
        ...(isDisabled
          ? undefined
          : {
              "[data-checkbox]:has(:active) &": layerStyles.focused,
            }),
      }}
      {...props}
    >
      <Box
        as="rect"
        fill={
          filled
            ? isDisabled
              ? "ds.background.disabled"
              : "ds.background.selected.bold.resting"
            : "ds.background.input.resting"
        }
        stroke={
          filled
            ? "none"
            : isDisabled
              ? "ds.border.disabled"
              : "ds.border.input"
        }
        strokeWidth={4}
        r={2}
        x={0}
        y={0}
        width="14px"
        height="14px"
        sx={{
          "[data-checkbox]:hover &, [data-checkbox]:has(:active) &": {
            fill: filled
              ? isDisabled
                ? "ds.background.disabled"
                : "ds.background.selected.bold.hovered"
              : isDisabled
                ? "ds.background.input.resting"
                : "ds.background.input.hovered",
            stroke: filled
              ? "none"
              : isDisabled
                ? "ds.border.disabled"
                : "ds.border.hovered",
          },
        }}
      />
      <Box
        as="path"
        d="M4,7l2,2l4-4"
        pathLength={1}
        stroke={isDisabled ? "ds.icon.disabled" : "ds.icon.inverse"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        strokeDasharray={1}
        strokeDashoffset={isChecked && !isIndeterminate ? 0 : 1}
        transition={isChecked ? "stroke-dashoffset 0.2s ease-in" : "none"}
      />
      {isIndeterminate && (
        <Box
          as="path"
          d="M4,7h6"
          stroke="ds.icon.inverse"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      )}
    </Box>
  )
}
