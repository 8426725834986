import { Text } from "@chakra-ui/react"
import React from "react"
import { PropsWithChildren } from "react"

export function QuestionText({ children }: PropsWithChildren) {
  return (
    <Text fontSize="large" color="text.primary">
      {children}
    </Text>
  )
}
