import { fullWithTime, getTimeString } from "Utilities/date-formats"
import { utcToZonedTime } from "date-fns-tz"

import { ModeratedStudyApplicationFlow } from "~/api/generated/usabilityhubResponses"

type Booking = NonNullable<ModeratedStudyApplicationFlow["booking"]>
type BookingDateArgs = Pick<
  Booking,
  "starts_at" | "ends_at" | "participant_timezone"
>

export const formatBookingDate = ({
  starts_at,
  ends_at,
  participant_timezone,
}: BookingDateArgs) => {
  const zonedStartsAt = utcToZonedTime(starts_at, participant_timezone)
  const zonedEndsAt = utcToZonedTime(ends_at, participant_timezone)
  const startDate = fullWithTime(zonedStartsAt)
  const endDate = getTimeString(zonedEndsAt)

  return `${startDate} – ${endDate}`
}
