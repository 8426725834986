import { getIsPanelistDomain } from "Shared/helpers/getIsPanelistDomain"
import _ from "lodash"
import { themeTokens as LyssnaThemeTokens } from "../../Lyssna/theme-tokens"
import { themeTokens as UserCrowdThemeTokens } from "../../UserCrowd/theme-tokens"
import { baseColors } from "../base-colors"

// A recursive function that looks up colors in the theme-tokens file against
// the list of base colors
// ts-prune-ignore-next used in test
export const getColorsWithTheming = (
  themeTokens: Record<string, any>,
  baseColors: Record<string, any>,
  allThemeTokens?: Record<string, any>
): Record<string, any> =>
  Object.entries(themeTokens)
    // Iterate through the mapping from the themeTokens file and find the matching colorSet
    .map(([token, colorToMap]: [string, Record<string, any> | string]) => {
      // first, check the color we're trying to map isn't another theme token.
      // If it is, find the value of the theme token and use that instead
      if (typeof colorToMap === "string" && colorToMap.includes(".")) {
        colorToMap = _.get(
          allThemeTokens || themeTokens,
          colorToMap,
          colorToMap
        )
      }

      // if it's already hex, job done
      if (typeof colorToMap === "string" && colorToMap.startsWith("#"))
        return [token, colorToMap] as const

      let colorValue
      if (typeof colorToMap === "object")
        // recurse into the object to find the string that holds the key
        // of the color we want :wizard:
        colorValue = getColorsWithTheming(
          colorToMap,
          baseColors,
          allThemeTokens || themeTokens
        )
      else {
        // it's a string, so it should refer to a base color at this point
        // Go find it. If you can't, use the original value in case it's chakra
        // specific
        colorValue = _.get(baseColors, colorToMap, colorToMap)
      }

      return [token, colorValue] as const
    })
    .reduce(
      // Add the mapped colors to the finalised list of colors
      (mappedColors, [newColorToken, newColorValueSet]) => ({
        ...mappedColors,
        [newColorToken]: newColorValueSet,
      }),
      {}
    )

const themeTokens = getIsPanelistDomain()
  ? UserCrowdThemeTokens
  : LyssnaThemeTokens

export const colors = {
  ...baseColors,
  ...getColorsWithTheming(themeTokens, baseColors),
}
