import { createIcon } from "@chakra-ui/react"
import React from "react"

export const DesktopTabletCustomIcon = createIcon({
  displayName: "DesktopTabletCustomIcon",
  viewBox: "0 0 24 25",
  defaultProps: { fill: "none" },
  path: (
    <>
      <path
        fill="currentColor"
        d="M6.016 5.593h-.839c-.527 0-.982 0-1.356.03-.396.032-.789.104-1.167.297a3 3 0 0 0-1.311 1.31c-.193.379-.264.772-.297 1.168-.03.374-.03.829-.03 1.356v3.677c0 .527 0 .982.03 1.357.032.395.104.788.297 1.166a3 3 0 0 0 1.31 1.312c.379.192.772.264 1.168.296.374.03.829.03 1.356.03h.839a1 1 0 1 0 0-2h-.8c-.577 0-.95 0-1.233-.023-.271-.023-.372-.06-.421-.086a1 1 0 0 1-.437-.437c-.026-.049-.063-.15-.085-.421-.024-.284-.024-.656-.024-1.232v-3.6c0-.577 0-.95.024-1.233.022-.271.06-.372.085-.421a1 1 0 0 1 .437-.437c.05-.026.15-.063.421-.086.284-.023.656-.023 1.233-.023h.8a1 1 0 1 0 0-2ZM4.516 19.593a1 1 0 1 0 0 2h1.5a1 1 0 1 0 0-2h-1.5Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.854 3.593h-6.677c-.527 0-.982 0-1.356.03-.396.032-.789.104-1.167.297a3 3 0 0 0-1.311 1.31c-.193.379-.264.772-.297 1.167-.03.375-.03.83-.03 1.357v9.677c0 .527 0 .982.03 1.357.033.395.104.788.297 1.166a3 3 0 0 0 1.31 1.312c.379.192.772.264 1.168.296.374.03.829.03 1.356.03h6.677c.527 0 .982 0 1.357-.03.395-.032.788-.104 1.167-.296a3 3 0 0 0 1.31-1.311c.193-.379.265-.772.297-1.167.03-.375.03-.83.03-1.357V7.754c0-.527 0-.982-.03-1.356-.032-.396-.104-.79-.296-1.167a3 3 0 0 0-1.311-1.311c-.379-.193-.772-.265-1.167-.297-.375-.03-.83-.03-1.357-.03Zm-8.292 2.109c.05-.026.15-.063.421-.086.284-.023.656-.023 1.233-.023h6.6c.576 0 .949 0 1.232.023.272.023.372.06.422.086a1 1 0 0 1 .437.437c.025.049.063.15.085.421.023.284.024.656.024 1.233v9.803c0 .463-.004.78-.024 1.029-.023.271-.06.372-.085.422a.999.999 0 0 1-.437.436c-.05.026-.15.063-.422.086-.283.023-.656.024-1.232.024h-6.6c-.577 0-.95-.001-1.232-.024-.272-.023-.373-.06-.422-.085a1 1 0 0 1-.437-.438c-.026-.049-.063-.15-.085-.421-.02-.249-.024-.566-.024-1.03V7.794c0-.577 0-.95.024-1.233.022-.271.06-.372.085-.421a1 1 0 0 1 .437-.437Z"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18 4.596v1.4c0 .56 0 .84-.109 1.054a1 1 0 0 1-.437.437c-.214.11-.494.11-1.054.11h-1.8c-.56 0-.84 0-1.054-.11a1 1 0 0 1-.437-.437C13 6.837 13 6.556 13 5.996v-1.4"
      />
    </>
  ),
})
