/**
 * Layer styles let you use a bundle of styles on any component via the
 * `layerStyle` prop.
 * https://v2.chakra-ui.com/docs/styled-system/text-and-layer-styles#layer-styles
 *
 * At this stage, it only seems to make sense to use this for focus outlines.
 *
 * @example
 * <InteractiveComponent
 *   bg="ds.background.input.resting"
 *   _focusVisible={{
 *     layerStyle: "focused"
 *   }}
 * />
 */
export const layerStyles = {
  focused: {
    // TODO this should be its own unique token. colors.border.focused is this
    // without the alpha value (last 2 hex digits), but we need a separate token
    // with the alpha value baked in in order to use it in one-liner rules in CSS
    // without needing color-mix etc
    outline: "4px solid #0b978e80",
  },
}
