import { Alert } from "./alert"
import { Button } from "./button"
import { Checkbox } from "./checkbox"
import { FormLabel } from "./form-label"
import { Heading } from "./heading"
import { Input } from "./input"
import { Link } from "./link"
import { Menu } from "./menu"
import { Modal } from "./modal"
import { Progress } from "./progress-bar"
import { Radio } from "./radio"
import { Select } from "./select"
import { Slider } from "./slider"
import { Steps } from "./steps"
import { Switch } from "./switch"
import { Tabs } from "./tabs"

export const components = {
  Alert,
  Button,
  Checkbox,
  FormLabel,
  Heading,
  Input,
  Link,
  Menu,
  Modal,
  Progress,
  Radio,
  Select,
  Slider,
  Steps,
  Switch,
  Tabs,
}
