import { Box, Text } from "@chakra-ui/react"
import React, { useMemo } from "react"
import { GroupContents } from "./Group"
import { Option } from "./Option"
import { useSelect } from "./Provider"
import { SEARCH_PATH, SelectItem, SelectValue } from "./types"

export const SearchResults = <T extends SelectValue>() => {
  const { searchResults } = useSelect<T>()

  return (
    <GroupContents path={SEARCH_PATH}>
      {searchResults.length > 0 ? (
        searchResults.map((result) => (
          <SearchResult key={result.id} result={result} />
        ))
      ) : (
        <Box p={6}>
          <Text
            textStyle="ds.paragraph.primary"
            color="ds.text.subtle"
            textAlign="center"
          >
            No results found
          </Text>
        </Box>
      )}
    </GroupContents>
  )
}

SearchResults.displayName = "Select.SearchResults"
const SearchResult = <T extends SelectValue>({
  result,
}: {
  result: SelectItem<T>
}) => {
  const {
    isMulti,
    activeItem,
    onPathChange,
    toggleGroup,
    groupSelectionState,
    setActiveItemId,
    formatValue,
    toggle,
    isSelected,
    getValueById,
  } = useSelect<T>()

  const isActive = activeItem?.id === result.id

  const selectionState = useMemo(() => {
    if (result.type === "group") {
      return groupSelectionState(result.path)
    }
    return "none"
  }, [groupSelectionState, result.path, result.type])

  if (result.type === "group") {
    return (
      <Option
        id={result.id}
        isMulti={isMulti}
        isGroup
        isActive={isActive}
        value={result.path}
        isSelected={selectionState === "all"}
        isIndeterminate={selectionState === "some"}
        onSelect={() => onPathChange?.(result.path)}
        onCheckedChange={() => {
          toggleGroup(result.path)
        }}
        onPointerEnter={() => setActiveItemId(result.id)}
      >
        {result.label}
      </Option>
    )
  }

  const value = getValueById(result.id.replace(/^search:/, ""))

  return (
    <Option
      id={result.id}
      isMulti={isMulti}
      isActive={isActive}
      isSelected={isSelected(value)}
      value={value}
      onSelect={() => {
        toggle(value)
      }}
      onPointerEnter={() => setActiveItemId(result.id)}
    >
      {formatValue(value)}
    </Option>
  )
}

SearchResult.displayName = "Select.SearchResult"
