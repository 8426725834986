import { Avatar as ChakraAvatar, forwardRef } from "@chakra-ui/react"
import _ from "lodash"
import React from "react"

export const AVATAR_SIZES_MAP = {
  small: "1.5rem",
  medium: "2rem",
  large: "2.5rem",
  "x-large": "3rem",
} as const

const TEXT_SIZES_MAP = {
  small: "0.5625rem",
  medium: "0.75rem",
  large: "1rem",
  "x-large": "1.25rem",
}

const AVATAR_INITIALS_STYLES_MAP = {
  teal: {
    subtle: "ds.background.accent.teal.subtle",
    bold: "ds.background.accent.teal.bold",
  },
  blue: {
    subtle: "ds.background.accent.blue.subtle",
    bold: "ds.background.accent.blue.bold",
  },
  purple: {
    subtle: "ds.background.accent.purple.subtle",
    bold: "ds.background.accent.purple.bold",
  },
  pink: {
    subtle: "ds.background.accent.pink.subtle",
    bold: "ds.background.accent.pink.bold",
  },
  yellow: {
    subtle: "ds.background.accent.yellow.subtle",
    bold: "ds.background.accent.yellow.bold",
  },
  orange: {
    subtle: "ds.background.accent.orange.subtle",
    bold: "ds.background.accent.orange.bold",
  },
  red: {
    subtle: "ds.background.accent.red.subtle",
    bold: "ds.background.accent.red.bold",
  },
  cyan: {
    subtle: "ds.background.accent.cyan.subtle",
    bold: "ds.background.accent.cyan.bold",
  },
  green: {
    subtle: "ds.background.accent.green.subtle",
    bold: "ds.background.accent.green.bold",
  },
} as const

type AvatarSize = keyof typeof AVATAR_SIZES_MAP
type Name = string
type ColorScheme = keyof typeof AVATAR_INITIALS_STYLES_MAP
type Variant = keyof (typeof AVATAR_INITIALS_STYLES_MAP)[ColorScheme]

const AVATAR_COLOR_SCHEMES = Object.keys(
  AVATAR_INITIALS_STYLES_MAP
) as ColorScheme[]
const AVATAR_VARIANTS = ["subtle", "bold"] as Variant[]
export const AVATAR_APPEARANCES = ["profile", "generic"] as const
export const AVATAR_SIZES = Object.keys(AVATAR_SIZES_MAP) as AvatarSize[]

type CommonAvatarProps = {
  size?: AvatarSize
}

type ProfileAvatarProps = {
  appearance: "profile"
  name: Name
  profileImageSrc?: string
}

type GenericAvatarProps = {
  appearance: "generic"
  name?: never
  profileImageSrc?: never
}

export type AvatarProps = CommonAvatarProps &
  (ProfileAvatarProps | GenericAvatarProps)

const getDeterministicallyRandomColors = (name: string) => {
  // Simple string hashing function
  const hash = _.sum(_.map(name, (char) => char.charCodeAt(0)))

  // Select color and variant based on the hash
  const color = AVATAR_COLOR_SCHEMES[hash % AVATAR_COLOR_SCHEMES.length]
  const variant = AVATAR_VARIANTS[hash % AVATAR_VARIANTS.length]

  const bg = AVATAR_INITIALS_STYLES_MAP[color][variant]
  const textColor = variant === "bold" ? "ds.text.inverse" : "ds.text.default"

  return { bg, textColor }
}

/**
 * - Use `appearance` to choose between the two types:
 *   - `profile` (requires a `name`, but `profileImageSrc` is optional)
 *   - `generic` (shows a generic "user" icon)
 * - Use `name` (= a full name) to generate initials. Initials will only show if no
 * `profileImageSrc` is provided.
 *   - The `name` prop is used to generate a deterministically random color
 *   scheme for showing initials. You can't manually specify a color scheme –
 *   this is by design.
 */
export const Avatar = forwardRef<AvatarProps, "span">(
  ({ appearance, size = "medium", name, profileImageSrc }, ref) => {
    const colorStyles =
      appearance === "profile" && !!name
        ? getDeterministicallyRandomColors(name)
        : {}
    const profileProps =
      appearance === "profile" ? { name, src: profileImageSrc } : {}
    const sizeStyles = {
      // Chakra really doesn't want us overriding its `font-size: var(--avatar-font-size)`
      // declaration, but we just change the value of that var directly
      "--avatar-font-size": TEXT_SIZES_MAP[size],
      boxSize: AVATAR_SIZES_MAP[size],
      fontWeight: "medium",
    }

    return (
      <ChakraAvatar
        ref={ref}
        {...profileProps}
        {...colorStyles}
        sx={sizeStyles}
      />
    )
  }
)

Avatar.displayName = "Avatar"
