import { Box } from "@chakra-ui/react"
import { XCloseSolidIcon } from "Shared/icons/untitled-ui/XCloseSolidIcon"
import React from "react"

type FloatingCloseButtonProps = {
  onClose: () => void
}

export const FloatingCloseButton: React.FC<FloatingCloseButtonProps> = ({
  onClose,
}) => (
  <Box
    as="button"
    position="absolute"
    display="flex"
    justifyContent="center"
    alignItems="center"
    top={[-9, -10, 0]}
    right={[1, 0, -10]}
    p={2}
    bg="ds.background.neutral.bold.resting"
    boxSize={8}
    rounded="full"
    zIndex={1}
    onClick={onClose}
    _hover={{
      bg: "ds.background.neutral.bold.hovered",
    }}
    _active={{
      bg: "ds.background.neutral.bold.pressed",
    }}
  >
    <XCloseSolidIcon boxSize={6} color="ds.icon.inverse" />
  </Box>
)
