import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { InterviewDetails } from "UsabilityHub/components/InterviewDetails/InterviewDetails"
import { ROUTES } from "UsabilityHub/views/routes"
import React from "react"
import { Link } from "react-router-dom"
import { ListUsercrowdUpcomingBookingsResponse } from "~/api/generated/usabilityhub-components"
import { calculateBookingDuration } from "~/shared/helpers/calculateBookingDuration"

type Props = {
  booking: ListUsercrowdUpcomingBookingsResponse["moderated_study_bookings"][0]
}

export const UpcomingBookingCard: React.FC<Props> = ({ booking }) => {
  const startTime = isoDateToShortTime(booking.starts_at)
  const endTime = isoDateToShortTime(booking.ends_at)

  return (
    <AccordionItem
      display="flex"
      flexDirection="column"
      bg="white"
      borderWidth={1}
      borderColor="gray.200"
      rounded="lg"
    >
      <AccordionButton
        display="flex"
        justifyContent="space-between"
        borderWidth={0}
        gap={2}
        py={5}
      >
        <Text color="text.primary" fontWeight="bold" whiteSpace="nowrap">
          {startTime} - {endTime}
        </Text>
        <Tooltip hasArrow placement="top" label={booking.external_name}>
          <Text align="left" noOfLines={1} flexShrink={1}>
            {booking.external_name}
          </Text>
        </Tooltip>

        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel p={0} as={Flex} flexDirection="column">
        <Box px={4}>
          <InterviewDetails
            incentive={booking.incentive_text}
            eventDurationMins={calculateBookingDuration(booking)}
            deviceRequirement={booking.device_requirement}
          />
        </Box>

        <Button
          as={Link}
          to={ROUTES.INTERVIEW_APPLICATIONS.APPLICATION.JOIN.buildPath({
            moderatedStudyApplicationId: booking.moderated_study_application_id,
          })}
          colorScheme="brand.primary"
          m={4}
          _hover={{ bg: "brand.primary.600", color: "white" }}
        >
          Go to session page
        </Button>
      </AccordionPanel>
    </AccordionItem>
  )
}

const isoDateToShortTime = (isoDateString: string) => {
  return new Date(isoDateString).toLocaleTimeString(navigator.language, {
    timeStyle: "short",
  })
}
