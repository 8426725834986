import commaNumber from "comma-number"

import { CENTS_PER_CREDIT } from "Constants/testers"

export function formatDollars(value: number): string {
  // Always format decimal values to 2 decimal places
  const absValue = Math.abs(value)
  const formattedValue = Number.isInteger(absValue)
    ? absValue.toString()
    : absValue.toFixed(2)

  return `${value < 0 ? "-" : ""}$${commaNumber(formattedValue)}`
}

export function centsToDollars(value: number): number {
  return value / 100
}

export function creditsToCents(value: number): number {
  return value * CENTS_PER_CREDIT
}

/**
 * @example
 * centsToDollarsAndCents(500)  // $5.00
 * centsToDollarsAndCents(-500) // –$5.00
 * centsToDollarsAndCents(500_000) // $5,000.00
 */
export const centsToDollarsAndCents = (cents: number): string => {
  // Always format decimal values to 2 decimal places
  const absValue = centsToDollars(Math.abs(cents))
  const formattedValue = absValue.toFixed(2)

  // \u2013 is an en dash, which is preferred for negative numbers
  return `${cents < 0 ? "\u2013" : ""}$${commaNumber(formattedValue)}`
}
