import { Box, Center, Heading, Spinner, Text, useToast } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { useApplicantDeclineModeratedStudyInvitation } from "~/api/generated/usabilityhub-components"
import { useModeratedStudyApplicationContext } from "./ModeratedStudyApplicationContext"

export const DeclineInvitationPage = () => {
  const toast = useToast()

  const { application, invalidateApplicationQuery } =
    useModeratedStudyApplicationContext()

  const { mutate, isLoading } = useApplicantDeclineModeratedStudyInvitation({
    onSuccess: () => {
      invalidateApplicationQuery()

      toast({
        title: "Invitation declined",
        status: "success",
      })
    },
    onError: () => {
      toast({
        title: "Could not decline the invitation",
        description: "The link you used may be invalid or expired.",
        status: "error",
      })
    },
  })

  const [firstPageLoad, setFirstPageLoad] = useState(true)

  useEffect(() => {
    if (!application.declined) {
      mutate({
        pathParams: {
          moderatedStudyApplicationId:
            application.moderated_study_application_id,
        },
      })
    }

    setFirstPageLoad(false)
  }, [application])

  return isLoading || firstPageLoad ? (
    <Center h="100vh">
      <Spinner />
    </Center>
  ) : (
    <Box display="flex" maxW="500px" flexDirection="column" gap="4">
      {application.declined ? (
        <>
          <Heading fontSize="2xl" fontWeight="400">
            Session declined
          </Heading>
          <Text>Your session is declined.</Text>
        </>
      ) : (
        <>
          <Heading fontSize="2xl" fontWeight="400">
            Error
          </Heading>
          <Text>Something went wrong declining your session</Text>
        </>
      )}
    </Box>
  )
}
