import { ToastProviderProps } from "@chakra-ui/react"

// if you're looking for toast styling, see alerts.ts

export default {
  defaultOptions: {
    position: "top-right",
    isClosable: true,
    // for all other defaults, refer to the documentation in Chakra
  },
} as ToastProviderProps
