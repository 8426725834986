import { Center, Image, ImageProps } from "@chakra-ui/react"
import React from "react"

import { NewTabAnchor } from "Components/anchors/basic"
import styles from "Components/powered-logo/powered-logo.module.scss"
import LyssnaLogo from "Images/powered-by-lyssna.png"

const POWERED_BY_HREF =
  "https://lyssna.com?utm_source=lyssna&utm_campaign=test-interface-powered-by"

export const PoweredLogo: React.FC<React.PropsWithChildren<ImageProps>> = (
  imageProps
) => {
  return (
    <Center>
      <NewTabAnchor className={styles.main} href={POWERED_BY_HREF}>
        <Image src={LyssnaLogo} ml={1} mb={0.5} w="112px" {...imageProps} />
      </NewTabAnchor>
    </Center>
  )
}

export const PoweredLogoUnstyled: React.FC<
  React.PropsWithChildren<ImageProps>
> = (imageProps) => {
  return (
    <Center>
      <NewTabAnchor href={POWERED_BY_HREF}>
        <Image src={LyssnaLogo} {...imageProps} />
      </NewTabAnchor>
    </Center>
  )
}
