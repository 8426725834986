import React from "react"

import { getDateString } from "Utilities/date-formats"
import { TimeImpl } from "./time-impl"

interface Props {
  date: Date | string | number
}

export function DisplayDate({ date }: Props) {
  const d = new Date(date)
  return <TimeImpl date={d}>{getDateString(d, "short")}</TimeImpl>
}
