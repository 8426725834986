import { add, formatDistance, intervalToDuration } from "date-fns"
import { useEffect, useState } from "react"

type UseSessionTimeTitleProps = {
  startsAt: Date | null
  endsAt?: Date | null
  title?: string
}

export const useSessionTimeTitle = ({
  startsAt,
  endsAt = null,
  title: titlePart = "Your session",
}: UseSessionTimeTitleProps) => {
  const [title, setTitle] = useState(titlePart)

  useEffect(() => {
    let checkTimer: number

    const checkTimes = () => {
      if (!startsAt) return

      const now = new Date(Date.now())
      let timeToNextCheck = Infinity

      if (endsAt && endsAt < now) {
        setTitle(`${titlePart} has ended`)
      } else if (startsAt < now) {
        setTitle(`${titlePart} has started`)
        timeToNextCheck = 1000
      } else {
        const interval = intervalToDuration({ start: now, end: startsAt })

        if (startsAt < add(now, { minutes: 1 })) {
          setTitle(`${titlePart} is in ${interval.seconds || 0} seconds`)
          timeToNextCheck = 1000 // 1 second
        } else {
          setTitle(`${titlePart} is in ${formatDistance(startsAt, now)}`)
          if ((interval.days || 0) > 0) {
            timeToNextCheck = 1000 * 60 * 60 // 1 hour
          } else {
            timeToNextCheck = 1000 * 60 // 1 minute
          }
        }
      }

      if (timeToNextCheck < Infinity) {
        checkTimer = window.setTimeout(checkTimes, timeToNextCheck)
      }
    }

    checkTimes()

    return () => clearTimeout(checkTimer)
  }, [startsAt, endsAt, titlePart])

  return title
}
