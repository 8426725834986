import { ComponentType } from "react"
import { Store } from "redux"

import { mountReact } from "JavaScripts/mount-react"
import { UsabilityHubProvider } from "UsabilityHub/UsabilityHubProvider"
import { UserCrowdProvider } from "UserCrowd/UserCrowdProvider"

export function mountSpa<
  PExtra extends JSX.IntrinsicAttributes,
  PData extends JSX.IntrinsicAttributes,
>(
  Root: ComponentType<React.PropsWithChildren<PData & PExtra>>,
  props: PExtra,
  store: Store<any> | null,
  AppProvider: UsabilityHubProvider | UserCrowdProvider
) {
  document.addEventListener("DOMContentLoaded", () => {
    const mainElem = document.getElementById("main")!
    const dataPropsElem = document.getElementById("data-props")!
    const dataProps = JSON.parse(dataPropsElem.dataset.props!) as PData
    mountReact(
      Root,
      store,
      { ...dataProps, ...props },
      mainElem,
      AppProvider,
      true
    )
  })
}
