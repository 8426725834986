import { formatNumber } from "Utilities/number"

export function pluralizeWithCount(
  count: number,
  singular: string,
  plural?: string,
  format = false
): string {
  const countStr = format ? formatNumber(count) : count
  return `${countStr} ${pluralize(count, singular, plural)}`
}

export function pluralize(
  count: number,
  singular: string,
  plural?: string
): string {
  return count === 1 ? singular : plural || `${singular}s`
}

export function capitalizeFirstLetter(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export function stripWhitespace(text: string): string {
  return text.replace(/\s+/g, "")
}
