import { styles } from "../styles"
export const Checkbox = {
  defaultProps: {
    colorScheme: "brand.primary",
  },
  sizes: {
    xl: {
      control: { ...styles.iconButton.basicSize },
      label: { fontSize: "lg" },
      icon: { fontSize: "0.675rem" },
    },
  },
  variants: {
    iconToggle: {
      icon: {
        opacity: 1,
      },
      control: {
        ...styles.iconButton.basicStyle,
        _checked: {
          ...styles.iconButton.active,
          _hover: {
            ...styles.iconButton.hover,
          },
        },
        _focus: {
          boxShadow: "none",
        },
        _hover: {
          ...styles.iconButton.hover,
        },
      },
    },
    mdWithSmFont: {
      size: "md",
      label: {
        fontSize: "sm",
        marginLeft: 4,
        lineHeight: 5,
      },
    },
  },
}
