import { lerp } from "Math/number"
import { Color } from "Types"

export function hexToColor(hexString: string): Color {
  // Strip the #
  if (hexString[0] === "#") {
    hexString = hexString.substring(1)
  }

  // Convert rrggbb to decimal
  const rgb = parseInt(hexString, 16)

  return {
    r: (rgb >> 16) & 0xff, // extract red
    g: (rgb >> 8) & 0xff, // extract green
    b: (rgb >> 0) & 0xff, // extract blue
  }
}

// Returns a luma value between 0 (full black) and 255 (full white)
export function getLumaFromHex(hexString: string): number {
  const { r, g, b } = hexToColor(hexString)
  return 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709
}

export function lerpColor(
  from: Readonly<Color>,
  to: Readonly<Color>,
  t: number
): Color {
  return {
    r: lerp(from.r, to.r, t),
    g: lerp(from.g, to.g, t),
    b: lerp(from.b, to.b, t),
  }
}
