import {
  Modal,
  ModalContent,
  ModalContentProps,
  ModalOverlay,
  ModalOverlayProps,
  ModalProps,
  useBreakpointValue,
} from "@chakra-ui/react"
import { motion } from "framer-motion"
import React, { PropsWithChildren } from "react"
import { Controls } from "./Controls"
import { FloatingCloseButton } from "./FloatingCloseButton"
import { SinglePaneContent } from "./SinglePaneContent"
import { TwoPaneContent } from "./TwoPaneContent"

type Props = PropsWithChildren<{
  closeButton?: boolean
  overlayProps?: ModalOverlayProps
  modalContentProps?: ModalContentProps
}> &
  ModalProps

const DisplayModalRoot: React.FC<Props> = ({
  children,
  closeButton = true,
  overlayProps = {},
  modalContentProps = {},
  ...modalProps
}) => {
  const isCentered = useBreakpointValue({
    base: false,
    sm: true,
  })

  const roundedBottom = useBreakpointValue({
    base: 0,
    sm: "24px",
  })

  return (
    <Modal isCentered={isCentered} {...modalProps}>
      <ModalOverlay {...overlayProps} />

      <ModalContent
        as={motion.div}
        key="modal-content"
        layout
        layoutId="modal-content"
        position="relative" // To position the close button
        w="full"
        maxW={["full", "500px", "800px"]}
        h={["full", "auto", "full"]}
        maxH={["calc(100dvh - 40px)", "unset", "500px"]}
        bgColor="ds.surface.overlay.default"
        boxShadow="ds.overlay"
        mt={[10, 0]}
        mb={0}
        mx={[0, 7, 12]}
        p={[0, 6]}
        style={{
          borderTopLeftRadius: "24px",
          borderTopRightRadius: "24px",
          borderBottomLeftRadius: roundedBottom,
          borderBottomRightRadius: roundedBottom,
        }}
        {...modalContentProps}
      >
        {closeButton && <FloatingCloseButton onClose={modalProps.onClose} />}

        {children}
      </ModalContent>
    </Modal>
  )
}

export const DisplayModal: typeof DisplayModalRoot & {
  TwoPaneContent: typeof TwoPaneContent
  SinglePaneContent: typeof SinglePaneContent
  Controls: typeof Controls
} = DisplayModalRoot as any

DisplayModal.TwoPaneContent = TwoPaneContent
DisplayModal.SinglePaneContent = SinglePaneContent
DisplayModal.Controls = Controls
