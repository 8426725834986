import { Box, Grid } from "@chakra-ui/react"
import React from "react"

import { TestersHeader } from "UserCrowd/components/TestersHeader"
import { UserCrowdDefaultPageLayout } from "../UserCrowdDefaultPageLayout"
import { UserCrowdNavbar } from "../UserCrowdNavbar/UserCrowdNavbar"
import { SettingsSidebar } from "./SettingsSidebar"

export const SettingsPage: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <UserCrowdDefaultPageLayout>
    <UserCrowdNavbar variant="inner-page" />
    <TestersHeader heading="Settings" />

    <Grid templateColumns={["1fr", null, "1fr 2fr"]}>
      <SettingsSidebar />
      <Box>{children}</Box>
    </Grid>
  </UserCrowdDefaultPageLayout>
)
