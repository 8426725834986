import { number, route } from "react-router-typesafe-routes/dom"

export const ROUTES = {
  // Waitlist and email verification
  WAITLIST: route("waitlist"),
  VERIFICATION: route("verification"),

  // Onboarding
  AGREEMENT: route("agreement"),
  PROFILE: route("profile"),
  PHONE: route("phone"),
  NOTIFICATION_PREFERENCES: route("notification_preferences"),
  PRACTICE_TEST: route("practice_test"),

  ASSIGNMENT: route("assignment/:id"),
  DASHBOARD: route(
    "dashboard",
    {},
    {
      START_PRACTICE_TEST: route("start_practice_test"),
      PRACTICE_TEST_COMPLETE: route("practice_test_complete"),
    }
  ),
  HISTORY: route("history/:page?", {
    params: { page: number() },
  }),
  LEGAL: route(
    "legal",
    {},
    {
      TERMS: route("terms"),
    }
  ),
  PAYOUTS: route("payouts"),
  SETTINGS: route(
    "settings",
    {},
    {
      ACCOUNT: route("account"),
      NOTIFICATIONS: route("notifications"),
      PROFILE: route("profile"),
    }
  ),
  USERS: route(
    "users",
    {},
    {
      SIGN_IN: route("sign_in"),
      SIGN_UP: route("sign_up"),
      PASSWORD: route(
        "password",
        {},
        {
          NEW: route("new"),
          EDIT: route("edit"),
        }
      ),
    }
  ),
}
