import { createIcon } from "@chakra-ui/react"
import React from "react"

export const TabletMobileCustomIcon = createIcon({
  displayName: "TabletMobileCustomIcon",
  viewBox: "0 0 24 24",
  defaultProps: { fill: "none" },
  path: (
    <>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.854 3h-6.677c-.527 0-.982 0-1.356.03-.396.033-.789.104-1.167.297a3 3 0 0 0-1.311 1.311c-.193.378-.264.772-.297 1.167-.03.375-.03.83-.03 1.356v9.678c0 .527 0 .982.03 1.356.033.395.104.789.297 1.167a3 3 0 0 0 1.31 1.311c.379.193.772.264 1.168.296.374.031.829.031 1.356.031h6.677c.527 0 .982 0 1.357-.03.395-.033.788-.104 1.167-.297a3 3 0 0 0 1.31-1.311c.193-.378.265-.772.297-1.167.03-.375.03-.83.03-1.356V7.16c0-.527 0-.981-.03-1.356-.032-.395-.104-.789-.296-1.167a3 3 0 0 0-1.311-1.311c-.379-.193-.772-.264-1.167-.296C19.836 3 19.38 3 18.854 3Zm-8.292 2.109c.05-.025.15-.063.421-.085C11.268 5 11.64 5 12.216 5h6.6c.576 0 .949 0 1.232.024.272.022.372.06.422.085a1 1 0 0 1 .437.437c.025.05.063.15.085.422.023.283.024.655.024 1.232v9.803c0 .463-.004.78-.024 1.03-.023.27-.06.371-.085.421a1 1 0 0 1-.437.437c-.05.025-.15.063-.422.085-.283.023-.656.024-1.232.024h-6.6c-.577 0-.95 0-1.232-.024-.272-.022-.373-.06-.422-.085a1 1 0 0 1-.437-.437c-.026-.05-.063-.15-.085-.422-.02-.248-.024-.566-.024-1.029V7.2c0-.577 0-.949.024-1.232.022-.272.06-.373.085-.422a1 1 0 0 1 .437-.437Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M6.016 5h-.839c-.527 0-.982 0-1.356.03-.396.033-.789.104-1.167.297a3 3 0 0 0-1.311 1.311c-.193.378-.264.772-.297 1.167-.03.375-.03.83-.03 1.356v6.678c0 .527 0 .981.03 1.356.032.395.104.789.297 1.167a3 3 0 0 0 1.31 1.311c.379.193.772.264 1.168.296.374.031.829.031 1.356.031h.839a1 1 0 1 0 0-2h-.8c-.577 0-.95 0-1.233-.024-.271-.022-.372-.06-.421-.085a1 1 0 0 1-.437-.437c-.026-.05-.063-.15-.085-.422-.024-.283-.024-.655-.024-1.232V9.2c0-.577 0-.949.024-1.232.022-.272.06-.373.085-.422a1 1 0 0 1 .437-.437c.05-.025.15-.063.421-.085C4.267 7 4.64 7 5.216 7h.8a1 1 0 1 0 0-2Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18 4.004v1.4c0 .56 0 .84-.109 1.054a1 1 0 0 1-.437.437c-.214.109-.494.109-1.054.109h-1.8c-.56 0-.84 0-1.054-.11a1 1 0 0 1-.437-.436C13 6.244 13 5.964 13 5.404v-1.4"
      />
    </>
  ),
})
