import { captureException } from "@sentry/browser"
import { isAxiosError } from "Services/axios"

/**
 * Throw an error in development/test environment, but just report to Sentry
 * in production.
 *
 * @example
 *
 * handleEvent(event) {
 *   const { shouldBeDefined } = this.state
 *   const { doAThing } = this.props
 *   if (shouldBeDefined == null) {
 *     reportErrorToSentry(new TypeError(`shouldBeDefined is ${shouldBeDefined}`), { extra: { props: this.props }})
 *     return
 *   }
 *   doAThing(shouldBeDefined)
 * }
 */
export function reportErrorToSentry(
  error: Error,
  captureContext?: { extra: { [key: string]: any } }
): void {
  if (process.env.DEBUG) {
    console.error(error, captureContext)
  } else {
    captureException(error, captureContext)
  }
}

export function logDebugError(error: Error): void {
  if (process.env.DEBUG) {
    console.error(error)
  }
}

export function throwIfNonAxiosError(error: any): void {
  // The error can be safely ignored when it's from the axios request, because the errors are handled in the backend.
  // We re-throw unknown errors to avoid silently swallowing them.
  // TODO: Once we move an API to OpenAPI, we’ll handle errors in `onError` instead. Once all APIs use OpenAPI, we can remove this function.
  if (isAxiosError(error)) {
    return
  } else {
    throw error
  }
}
