import { Consumer } from "@rails/actioncable"
import React from "react"

interface Context {
  isSPA: boolean
  title: string
  url: string
  consumer: Consumer | null
}

export const AppContext = React.createContext<Context>({
  isSPA: false,
  title: "",
  url: "",
  consumer: null,
})
