import {
  IconButton as ChakraIconButton,
  IconButtonProps,
  forwardRef,
  useStyleConfig,
} from "@chakra-ui/react"
import React from "react"
import { selectedStyles } from "./Button"

type IsSelectedProp = {
  isSelected?: boolean
}

type Props = IconButtonProps & IsSelectedProp

/**
 * - Use `variant` and `size` props to control appearance.
 * - Use `icon={<ImportedIcon />}` to choose an icon.
 * - Use the `isSelected` prop to access the selected state.
 *   - State management for selected buttons is handled by the consumer (similar to `isDisabled`).
 */
export const IconButton = forwardRef<Props, "button">((props: Props, ref) => {
  const { variant, size, isSelected, ...rest } = props

  const styles = useStyleConfig("IconButtonDS", { variant, size })

  const styleOverrides = isSelected ? selectedStyles() : undefined

  return (
    <ChakraIconButton ref={ref} __css={styles} {...rest} sx={styleOverrides} />
  )
})

IconButton.displayName = "IconButton"
