import { Flex, Text, TextProps, Tooltip } from "@chakra-ui/react"
import React from "react"

import { DisplayDate } from "Components/time/display-date"
import { payoutToDisplayContent } from "Constants/payout-status"
import { MINIMUM_REQUEST } from "Constants/testers"
import { EmptyListPlaceholder } from "UserCrowd/components/EmptyListPlaceholder"
import { Card, CardListItem } from "UserCrowd/components/TestersCard"
import {
  centsToDollars,
  creditsToCents,
  formatDollars,
} from "Utilities/currency"
import { PanelistPayoutsResponse } from "~/api/generated/usabilityhub-components"

type PayoutStatus = PanelistPayoutsResponse["payouts"][number]["status"]

interface Props {
  payouts: PanelistPayoutsResponse["payouts"]
}

function getPayoutStatusLabel(payoutStatus: PayoutStatus): string {
  switch (payoutStatus) {
    case "approved":
      return "review successful"
    case "open":
      return "review pending"
    case "paid":
      return "payment successful"
    case "payment_failed":
      return "payment failed"
    case "payment_pending":
      return "payment pending"
    case "rejected":
      return "review failed"
  }
}

function getPayoutStatusColor(payoutStatus: PayoutStatus): TextProps["color"] {
  switch (payoutStatus) {
    case "approved":
      return "brand.primary.500"
    case "open":
      return "brand.primary.300"
    case "paid":
      return "green.500"
    case "payment_failed":
      return "orange.500"
    case "payment_pending":
      return "orange.500"
    case "rejected":
      return "red.500"
  }
}

export const OpenPayoutList: React.FC<Props> = ({ payouts }) => {
  if (payouts.length === 0) {
    return (
      <EmptyListPlaceholder heading="No payouts yet">
        <Text fontSize="md" color="text.secondary">
          You haven{"\u2019"}t requested a payout yet.
        </Text>
        <Text fontSize="md" color="text.secondary">
          You can request when your current balance is&nbsp;
          {formatDollars(centsToDollars(creditsToCents(MINIMUM_REQUEST)))}
        </Text>
      </EmptyListPlaceholder>
    )
  }

  return (
    <Card p={0}>
      <Flex direction="column">
        {payouts.map((payout) => (
          <CardListItem key={payout.id}>
            <Flex gap={2}>
              <Text>
                {formatDollars(centsToDollars(payout.amount))} payout requested
                on <DisplayDate date={payout.created_at} />
              </Text>

              <Tooltip
                hasArrow
                rounded="md"
                placement="right"
                label={payoutToDisplayContent(payout)}
                p={2}
              >
                <Flex
                  alignSelf="center"
                  align="center"
                  border="1px solid currentColor"
                  rounded="md"
                  color={getPayoutStatusColor(payout.status)}
                  px={1}
                  py={0.5}
                  cursor="help"
                >
                  <Text
                    fontSize="2xs"
                    fontWeight="medium"
                    textTransform="uppercase"
                    whiteSpace="nowrap"
                  >
                    {getPayoutStatusLabel(payout.status)}
                  </Text>
                </Flex>
              </Tooltip>
            </Flex>
          </CardListItem>
        ))}
      </Flex>
    </Card>
  )
}
