import { InterviewDetails } from "UsabilityHub/components/InterviewDetails/InterviewDetails"
import {
  centsToDollars,
  creditsToCents,
  formatDollars,
} from "Utilities/currency"
import React from "react"
import { ModeratedStudyApplicationFlow } from "~/api/generated/usabilityhubResponses"
import { calculateBookingDuration } from "~/shared/helpers/calculateBookingDuration"
interface ModeratedStudySummaryProps {
  applicationFlow: ModeratedStudyApplicationFlow
}

export const ModeratedStudySummary: React.FC<ModeratedStudySummaryProps> = ({
  applicationFlow,
}) => {
  const {
    booking,
    moderated_study: study,
    recruitment_link: link,
    panelist,
  } = applicationFlow
  const eventDuration = booking
    ? calculateBookingDuration(booking)
    : study.event_duration_mins
  const incentive = calculateIncentive(booking, link, panelist)

  return (
    <InterviewDetails
      incentive={incentive}
      eventDurationMins={eventDuration}
      deviceRequirement={study.device_requirements}
    />
  )
}

const calculateIncentive = (
  booking: ModeratedStudyApplicationFlow["booking"],
  link: ModeratedStudyApplicationFlow["recruitment_link"],
  panelist: ModeratedStudyApplicationFlow["panelist"]
) => {
  if (booking) {
    return booking.incentive_type !== "none" ? booking.incentive_text : null
  } else if (link) {
    return link.incentive_type !== "none" ? link.incentive_text : null
  } else if (panelist) {
    return formatDollars(
      centsToDollars(creditsToCents(panelist.incentive_credits))
    )
  }

  return null
}
