import { Platform } from "Types"

import { DeviceType } from "./device-type"

export enum ReviewStatus {
  Accepted = 0,
  Rejected = 1,
  AutoAccepted = 2,
  AcceptedLowQuality = 3,
  RejectedLegacy = 4,
  Void = 5,
}

export enum ParticipantDeletionReason {
  ConfusingTest = "confusing_test",
  InappropriateTest = "inappropriate_test",
  IncorrectTestLanguage = "incorrect_test_language",
  IncorrectProfileLanguage = "incorrect_profile_language",
  Skipped = "skipped",
  TechnicalProblem = "technical_problem",
  ImagesFailedToLoad = "images_failed_to_load",
  Inactive = "inactive",
  CanceledToStartAnotherResponse = "canceled_to_start_another_response",
  TestRequestedPersonalInformation = "test_requested_personal_information",
  TestSentMeOffsite = "test_sent_me_offsite",

  // Reasons for recording issues
  RecordingPermissionDenied = "recording_permission_denied",
  RecordingUploadFailed = "recording_upload_failed",
  RecordingPrematureStop = "recording_premature_stop",
  RecordingFailed = "recording_failed",

  // External study issues
  NoCompletionCode = "no_completion_code",
  ParticipantIdNotRequested = "participant_id_not_requested",
}

export type PanelistPublicDeletionReason =
  | ParticipantDeletionReason
  | AutomaticDeletionReason

export enum OwnerDeletionReason {
  WeakLanguage = "weak_language",
  Spam = "spam",
  Malicious = "malicious",
  LowEffort = "low_effort",
  DidNotSpeak = "did_not_speak",
  RecordingQuality = "recording_quality",
  Unspecified = "unspecified",
}

export enum AutomaticDeletionReason {
  Disconnected = "disconnected",
  Malicious = "malicious",
  TimedOut = "timed_out",
  TooFast = "too_fast_response",
}

export type ResponseDeletionReason =
  | ParticipantDeletionReason
  | OwnerDeletionReason
  | AutomaticDeletionReason

export interface ResponseDemographicProfile {
  age: number | null
  country: string | null
  location?: {
    type: "country" | "state" | "city"
    id: string
  }
  demographic_attribute_option_ids: number[]
}

export interface Response {
  automated_review_status: ReviewStatus | null
  deleted_at: string | null
  deletion_reason: ResponseDeletionReason
  device_type: DeviceType | null
  duration_ms: number | null
  estimated_duration_ms: number | null
  id: number
  order_id: number | null
  platform: Platform | null
  review_status: ReviewStatus | null
  submitted_at: string | null
  third_party_order_id: number | null
  usability_test_id: number
  user_id: number | null
  query_parameters: Readonly<Record<string, string>> | null
  response_demographic_profile?: ResponseDemographicProfile | null
  cint_respondent_id: string | null
}
