import { Flex, Progress, Text } from "@chakra-ui/react"
import React from "react"
import { useOnboardingDrawerContext } from "./OnboardingDrawerContextProvider"

export const OnboardingDrawerProgress: React.FC = () => {
  const { stepsCompletedCount, totalSteps } = useOnboardingDrawerContext()

  return (
    <Flex gap={4} w="full">
      <Progress
        flexGrow={1}
        value={stepsCompletedCount}
        min={0}
        max={3}
        bg="ds.background.neutral.resting"
        rounded="full"
        alignSelf="stretch"
        // Chakra provides no prop to override the track color
        sx={{
          "> *": {
            backgroundColor: "ds.background.brand.bold.resting !important",
          },
        }}
      />
      <Text textStyle="ds.interface.medium">
        {stepsCompletedCount}/{totalSteps}
      </Text>
    </Flex>
  )
}
