// @ts-ignore
function assert<T extends never>() {}
type TypeEqualityGuard<A, B> = Exclude<A, B> | Exclude<B, A>

export type FilterOptionChoice = {
  label: string
  value: string
  // Optional icon, will be shown in the menu if present
  icon?: JSX.Element
}

type FilterOption =
  | { type: "freeText" }
  | { type: "static" }
  | { type: "multi"; pluralEntity: string; choices: FilterOptionChoice[] }
  | { type: "single"; choices: FilterOptionChoice[] }

export type FilterOptionValue =
  | { type: "freeText"; value: string }
  | { type: "static" }
  | { type: "multi"; choices: FilterOptionChoice[] }
  | { type: "single"; choice: FilterOptionChoice }

// This slightly cryptic utility ensures that the possible types of FilterOption
// and FilterOptionValue stay in sync
assert<TypeEqualityGuard<FilterOption["type"], FilterOptionValue["type"]>>()

export type FilterComparator = {
  value: string
  labelSingle: string
  labelMulti: string
  onlyShowForMulti?: boolean
  default?: boolean // If no defaults are specified, the first in the list will be used
  icon?: JSX.Element
}

export type FilterAttribute = {
  label: string
  value: string
  possibleComparators: FilterComparator[]
  possibleOptions: FilterOption
}

export type AppliedFilter = {
  id: string
  attribute: FilterAttribute
  comparator: FilterComparator
  options: FilterOptionValue
}
