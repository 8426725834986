import { List, ListItem, Text } from "@chakra-ui/react"
import React from "react"
import { useLocation } from "react-router"

import { Link } from "UserCrowd/components/Link"
import { ROUTES } from "UserCrowd/views/routes"

const SETTINGS_ROUTES = [
  [ROUTES.SETTINGS.ACCOUNT.path, "Account"],
  [ROUTES.SETTINGS.NOTIFICATIONS.path, "Availability & notifications"],
  [ROUTES.SETTINGS.PROFILE.path, "Your profile"],
]

export const SettingsSidebar: React.FC = () => {
  const location = useLocation()

  return (
    <List fontSize="lg" lineHeight="45px" fontWeight="medium" mb={10}>
      {SETTINGS_ROUTES.map(([route, label]) => {
        if (route === location.pathname) {
          return <Text key={route}>{label}</Text>
        }

        return (
          <ListItem key={route}>
            <Link variant="noUnderline" to={route}>
              {label}
            </Link>
          </ListItem>
        )
      })}
    </List>
  )
}
