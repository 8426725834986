import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from "@chakra-ui/react"
import React from "react"
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom"

export const Link: React.FC<ChakraLinkProps & RouterLinkProps> = (props) => (
  <ChakraLink as={RouterLink} {...props} />
)
