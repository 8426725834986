import { Box, Flex, Spinner, Text } from "@chakra-ui/react"
import React, { useEffect, useRef } from "react"

import { ScreenerQuestionAnswersContextProvider } from "UsabilityHub/components/ModeratedStudy/Screener/Questions/AnsweredQuestionsContext"
import { ScreenerQuestionAnswer } from "UsabilityHub/components/ModeratedStudy/Screener/Questions/types"
import { ScreenerQuestionAnswersFlow } from "UsabilityHub/components/ModeratedStudy/Screener/ScreenerQuestionAnswersFlow"
import {
  useCalculateScreenerResult,
  useStartScreenerResponse,
  useSubmitScreenerResponse,
  useUpdateScreenerRequired,
} from "~/api/generated/usabilityhub-components"

import { interviewApplicationReceivedGoogle } from "JavaScripts/analytics/google"
import { useModeratedStudyPreviewData } from "UsabilityHub/hooks/useModeratedStudyPreviewData"
import { useModeratedStudyApplicationContext } from "./ModeratedStudyApplicationContext"

export function ModeratedStudyScreenerPage() {
  const updatePreviewData = useModeratedStudyPreviewData()

  const { application, invalidateApplicationQuery } =
    useModeratedStudyApplicationContext()

  const screenerResponseIdRef = useRef<string>()

  const { mutateAsync: startScreenerResponse, error } =
    useStartScreenerResponse({
      onSuccess: (data) => {
        screenerResponseIdRef.current = data.screener_response_id
        // Gives us an up-to-date value for has_started_screener, which is used in the UI
        invalidateApplicationQuery()
      },
    })

  const { mutateAsync: updateScreenerRequired, error: disableError } =
    useUpdateScreenerRequired({
      onSuccess: invalidateApplicationQuery,
    })

  const { mutate: submitScreenerResponse, error: submitError } =
    useSubmitScreenerResponse({
      onSuccess: () => {
        // If the screener is required, we'll record the activity when the applicant completes the screener questions.
        // If the screener is not required, we'll record the activity when the applicant clicks on the button "I'm interested".
        interviewApplicationReceivedGoogle()

        invalidateApplicationQuery()
      },
    })

  // This is used when previewing, we need to be able to calculate the result of a screener
  // submission without actually creating any DB records.
  const { mutate: calculateScreenerResult } = useCalculateScreenerResult({
    onSuccess: (data) => {
      updatePreviewData({
        has_submitted_screener: true,
        screener_result: data.result,
      })
    },
  })

  useEffect(() => {
    // We're checking "has submitted screener" not "has started" because we can use the
    // "start response" API as a "start or resume response" to get the ID.
    if (!application.has_submitted_screener) {
      // It's possible that the screener questions were deleted after the applicant started the
      // application but before they submitted their answers.
      // In such cases, we update the "screener_required" field to reflect the changes and
      // prevent them from being stuck on the screener page.
      //
      // If the deletion occurred while the screener page was loading, they will be automatically
      // redirected to the next page.
      //
      // However, if the deletion happened while they were submitting their answers, they will
      // encounter an error.
      // Refreshing the page will redirect them to the next step of the application process.
      if (application.moderated_study.screener_questions.length === 0) {
        void updateScreenerRequired({
          pathParams: {
            moderatedStudyApplicationId:
              application.moderated_study_application_id,
          },
        })
      } else {
        if (application.moderated_study_application_id === "preview") {
          screenerResponseIdRef.current = "preview"

          updatePreviewData({
            has_started_screener: true,
          })
        } else {
          startScreenerResponse({
            body: {
              moderated_study_application_id:
                application.moderated_study_application_id,
            },
          })
        }
      }
    }
  }, [
    application.has_submitted_screener,
    application.moderated_study_application_id,
    application.moderated_study.screener_questions.length,
    startScreenerResponse,
    updateScreenerRequired,
  ])

  if (application.has_submitted_screener) return null

  if (application.moderated_study.screener_questions.length === 0) return null

  if (error || disableError)
    return (
      <Flex
        placeItems="center"
        justifyContent="center"
        height="full"
        width="full"
      >
        <Box margin="4">
          <Text>Something went wrong</Text>
        </Box>
      </Flex>
    )

  if (!screenerResponseIdRef.current)
    return (
      <Flex placeItems="center" justifyContent="center" width="full">
        <Spinner />
      </Flex>
    )

  if (submitError)
    return <Text>Something went wrong! Failed to save your response!</Text>

  const handleSubmittedAllAnswers = async (
    answers: ScreenerQuestionAnswer[]
  ) => {
    if (application.moderated_study_application_id === "preview") {
      calculateScreenerResult({
        body: {
          screener_question_answers: answers,
        },
      })
    } else {
      submitScreenerResponse({
        pathParams: {
          screenerResponseId: screenerResponseIdRef.current!,
        },
        body: {
          screener_question_answers: answers,
        },
      })
    }
  }

  return (
    <ScreenerQuestionAnswersContextProvider
      screenerQuestions={application.moderated_study.screener_questions}
      onSubmittedAllAnswers={handleSubmittedAllAnswers}
    >
      <ScreenerQuestionAnswersFlow />
    </ScreenerQuestionAnswersContextProvider>
  )
}
