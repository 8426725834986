import { Flex } from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { Button } from "DesignSystem/components"
import { CountryField } from "UserCrowd/components/CountryField/CountryField"
import React, { useState } from "react"

type Props = {
  onSkip: (() => void) | false
  onSave: () => void
}

export const GranularLocation: React.FC<Props> = ({ onSkip, onSave }) => {
  const queryClient = useQueryClient()
  const [isSaved, setIsSaved] = useState(false)

  return (
    <>
      <CountryField
        initialPlaceId={null}
        initialFormattedAddress={null}
        setIsCountryAnswered={() => {
          // We don't move to the next question immediately to give them a chance to correct any mistakes.
          // The refetch will happen when they click the next button below, which invalidates the relevant queries.
          setIsSaved(true)
        }}
        shouldResetPhoneVerification // (Only requires reverification if the country changes)
      />

      <Flex gap={2} mt={[0, 7]}>
        <Button
          variant="primary"
          isDisabled={!isSaved}
          onClick={async () => {
            // Refetch the /me endpoint so the next question appears
            await Promise.all([
              queryClient.invalidateQueries([
                "api",
                "usercrowd",
                "panelist",
                "me",
              ]),
              // Make sure the profile completion percentage updates too
              queryClient.invalidateQueries([
                "api",
                "usercrowd",
                "panelist",
                "stats",
              ]),
            ])

            onSave()
          }}
        >
          Next
        </Button>

        {onSkip && <Button onClick={onSkip}>Skip</Button>}
      </Flex>
    </>
  )
}
