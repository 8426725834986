import React, { ReactNode } from "react"

import { Link } from "@chakra-ui/react"
import { DisplayDate } from "Components/time/display-date"
import Constants from "Constants/shared.json"
import { centsToDollars, formatDollars } from "Utilities/currency"
import { PanelistPayoutsResponse } from "~/api/generated/usabilityhub-components"

type Payout = PanelistPayoutsResponse["payouts"][number]

export function payoutToDisplayContent(payout: Payout): string | ReactNode {
  switch (payout.status) {
    case "approved":
      return "You will receive a payment shortly"
    case "open":
      return "Please wait while we review your responses"
    case "paid":
      return (
        <>
          A{" "}
          {payout.reviewed_amount === null
            ? ""
            : formatDollars(centsToDollars(payout.amount))}{" "}
          payment was sent to {payout.paypal_email_address} on{" "}
          {payout.paid_at === null ? (
            "an unknown date"
          ) : (
            <DisplayDate date={payout.paid_at} />
          )}
        </>
      )
    case "payment_failed":
      return (
        <>
          We were unable to pay this payout. Please{" "}
          <Link href={Constants.HELP_CENTER_URL}>contact us</Link> to resolve
          this issue.
        </>
      )
    case "payment_pending":
      return "You will receive a payment shortly"
    case "rejected":
      return "We could not approve this payout"
  }
}
