import { useCallback, useMemo } from "react"

import { Country } from "Types"
import { useListCountries } from "~/api/generated/usabilityhub-components"

type UseCountriesResult = {
  isLoading: boolean
  countries: ReadonlyArray<Readonly<Country>>
  displayNameByCountryCode: (code: string) => string
}

export const useCountries = (): UseCountriesResult => {
  const { data, isLoading } = useListCountries(
    {},
    { cacheTime: Infinity, staleTime: Infinity }
  )

  const countries = useMemo(() => data?.countries || [], [data])

  const byCountryCode = useMemo<Map<string, Country>>(
    () =>
      countries.reduce(
        (acc, country) => acc.set(country.code, country),
        new Map()
      ),
    [countries]
  )

  const displayNameByCountryCode = useCallback(
    (code: string): string => (byCountryCode.get(code) || { name: "" }).name,
    [byCountryCode]
  )

  return { isLoading, countries, displayNameByCountryCode }
}
