// These *must* be kept in sync with `TestLogicStatement::PrototypeAnswer`
export enum PrototypeResponseState {
  Completed = "Completed",
  NotCompleted = "Not completed",
}
export const PrototypeResponseStates = Object.values(PrototypeResponseState)

export type PrototypeType = "free_roam" | "task_flow"

export interface FigmaFileFlow {
  start_node_id: string
  scaling: "min_zoom" | "scale_down" | "contain" | "scale_down_width"
  figma_file_version_id: number
  goal_node_id?: string
  common_path_names: { [common_path: string]: string }
  show_success_screen?: boolean
}
