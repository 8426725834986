import { Box, Flex } from "@chakra-ui/react"
import { cq, cqContainer } from "Shared/helpers/containerQuerySx"
import {
  USERCROWD_MAX_CONTENT_WIDTH,
  USERCROWD_NAVBAR_HEIGHT,
  USERCROWD_PAGE_BOTTOM_PADDING,
  USERCROWD_PAGE_GUTTER_MAX,
  USERCROWD_PAGE_GUTTER_MID,
  USERCROWD_PAGE_GUTTER_MIN,
  USERCROWD_PAGE_TOP_PADDING,
} from "UserCrowd/constants"
import React from "react"

type Props = {
  hasNoNavbar?: boolean
  hasNoVerticalPadding?: boolean
}

export const UserCrowdDefaultPageLayout: React.FC<
  React.PropsWithChildren<Props>
> = ({
  children,
  // Used for centered layouts like the sign-up process
  hasNoNavbar,
  hasNoVerticalPadding,
}) => {
  return (
    /**
     * We're using a container query here so that things like the onboarding
     * drawer (which pushes the main content over) can be accommodated.
     */
    <Flex sx={cqContainer()} justifyContent="center" w="full" minH="100dvh">
      <Box
        flexBasis="full"
        maxW={USERCROWD_MAX_CONTENT_WIDTH}
        mt={hasNoNavbar ? undefined : USERCROWD_NAVBAR_HEIGHT}
        pt={hasNoVerticalPadding ? undefined : USERCROWD_PAGE_TOP_PADDING}
        pb={hasNoVerticalPadding ? undefined : USERCROWD_PAGE_BOTTOM_PADDING}
        mx={USERCROWD_PAGE_GUTTER_MIN}
        sx={{
          ...cq("48rem", { mx: USERCROWD_PAGE_GUTTER_MID }),
          ...cq("80rem", { mx: USERCROWD_PAGE_GUTTER_MAX }),
        }}
      >
        {children}
      </Box>
    </Flex>
  )
}
