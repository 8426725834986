import { isEmpty, isObject, isString } from "lodash"

// NOTE: Not possible to express empty array.
type Blank = null | undefined | Record<string, never> | ""

export function isBlank<T>(value: T | Blank): value is Blank {
  // Strings need to be truncated.
  if (isString(value)) {
    return value.trim().length === 0
  }
  // Arrays and objects delegated to Lodash.
  if (isObject(value)) {
    return isEmpty(value)
  }

  // Basic data types are "present" unless null.
  return value == null
}

export function isPresent<T>(value: T | Blank): value is T {
  return !isBlank(value)
}

export function presence<T>(value: T | Blank): T | null {
  return isPresent(value) ? value : null
}

export function stringPresence<T>(value: T | Blank): string | null {
  return isPresent(value) ? String(value) : null
}

export function assertPresent<T>(value: T | Blank): T {
  if (!isPresent(value)) {
    throw new TypeError(`value === ${String(value)}`)
  }
  return value
}

export function isIn<T>(values: readonly T[], x: any): x is T {
  return values.includes(x)
}

// Get to the const values of any object
// Reference: https://fettblog.eu/tidy-typescript-avoid-enums/#prefer-union-types
export type Values<T> = T[keyof T]
