import { SelectContents } from "./Contents"
import { SelectGroup } from "./Group"
import { SelectOption } from "./Option"
import { Provider } from "./Provider"
import { Root } from "./Root"
import { Separator } from "./Separator"
import { SelectTrigger } from "./Trigger"

export const Select = {
  Contents: SelectContents,
  Group: SelectGroup,
  Option: SelectOption,
  Provider: Provider,
  Root: Root,
  Separator: Separator,
  Trigger: SelectTrigger,
}
