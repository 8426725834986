import { createIcon } from "@chakra-ui/react"
import React from "react"

export const InterviewStudyIcon = createIcon({
  displayName: "FigmaIcon",
  viewBox: "0 0 50 40",
  path: (
    <>
      <path
        fill="#00A3C4"
        d="M35.255 0c2.922 0 5.3 2.375 5.3 5.294v29.412a5.304 5.304 0 0 1-5.3 5.294h-20.51a5.304 5.304 0 0 1-5.301-5.294V5.294A5.304 5.304 0 0 1 14.744 0h20.511ZM5 33.333H2.778a2.222 2.222 0 0 1-2.222-2.222V8.89c0-1.227.995-2.222 2.222-2.222H5v26.666ZM45 6.667h2.221c1.228 0 2.223.995 2.223 2.222V31.11a2.222 2.222 0 0 1-2.223 2.222H45V6.667Z"
      />
      <path
        fill="#C4F1F9"
        d="M25 10c2.628 0 4.76 2.344 4.76 5.236S27.627 20.47 25 20.47c-2.628 0-4.76-2.343-4.76-5.235C20.24 12.344 22.372 10 25 10ZM16.433 26.945c1.55-2.705 4.792-4.57 8.552-4.57 3.783 0 7.044 1.887 8.582 4.619v2.52a.477.477 0 0 1-.476.476c-4.042.014-12.15.013-16.182 0a.477.477 0 0 1-.476-.476v-2.569Z"
      />
    </>
  ),
})
