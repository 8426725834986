import { debounce } from "lodash"
import { MutableRefObject, useCallback, useEffect, useState } from "react"

// This hook takes a reference to a scrollable element and will return how scrolled
// it is — 0 means it's at the top, 100 is scrolled all the way to the bottom.
// Scrolling will trigger a re-render in the consuming component.
export function useScrollPercentage<T extends HTMLElement>(
  targetRef: MutableRefObject<T | null>,
  axis: "x" | "y" | undefined = "y"
) {
  const [scrollPercentage, setScrollPercentage] = useState<number | null>(null)

  const handleScroll = useCallback(() => {
    if (!targetRef.current) {
      return
    }

    let percentage
    if (axis === "y") {
      const { scrollTop, scrollHeight, clientHeight } = targetRef.current
      const height = scrollHeight - clientHeight

      if (height === 0) {
        percentage = null
      } else {
        percentage = Math.round((scrollTop / height) * 100)
      }
    } else {
      const { scrollLeft, scrollWidth, clientWidth } = targetRef.current
      const width = scrollWidth - clientWidth

      if (width === 0) {
        percentage = null
      } else {
        percentage = Math.round((scrollLeft / width) * 100)
      }
    }

    setScrollPercentage(percentage)
  }, [targetRef, axis])

  useEffect(() => {
    const element = targetRef.current
    if (!element) return

    const debouncedHandleScroll = debounce(handleScroll, 100)
    element.addEventListener("scroll", debouncedHandleScroll)
    window.addEventListener("resize", debouncedHandleScroll)

    handleScroll()

    return () => {
      element.removeEventListener("scroll", debouncedHandleScroll)
      window.removeEventListener("resize", debouncedHandleScroll)
    }
  }, [targetRef, handleScroll])

  return scrollPercentage
}
