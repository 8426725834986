import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  IconButton,
  Spinner,
  Text,
  useBoolean,
  useBreakpointValue,
} from "@chakra-ui/react"
import { Variants, motion } from "framer-motion"
import React, { useEffect } from "react"
import { Outlet } from "react-router"

import { XOutlineIcon } from "Shared/icons/untitled-ui/XOutlineIcon"

import { useQueryClient } from "@tanstack/react-query"
import { PoweredLogoUnstyled } from "Components/powered-logo/powered-logo"
import { useGetModeratedStudyApplication } from "~/api/generated/usabilityhub-components"
import { ModeratedStudyApplicationFlow } from "~/api/generated/usabilityhubResponses"
import { ModeratedStudyApplicationProvider } from "./ModeratedStudyApplicationContext"
import { StudyOverviewPage } from "./StudyOverviewPage"

interface ApplicationContentLayoutProps {
  application: ModeratedStudyApplicationFlow
}

export const ApplicationContentLayoutWrapper: React.FC<{
  applicationId: string
}> = ({ applicationId }) => {
  const {
    error,
    data: application,
    isLoading,
  } = useGetModeratedStudyApplication({
    pathParams: {
      moderatedStudyApplicationId: applicationId,
    },
  })

  if (error) {
    return (
      <Flex
        flexBasis={1}
        flexGrow={1}
        justifyContent="center"
        align="center"
        bg="white"
      >
        <Text>Invalid token</Text>
      </Flex>
    )
  }

  if (isLoading) {
    return (
      <Center bg="transparent">
        <Spinner />
      </Center>
    )
  }

  return <ApplicationContentLayout application={application} />
}

const MotionFlex = motion(Flex)
export function ApplicationContentLayout({
  application,
}: ApplicationContentLayoutProps) {
  const queryClient = useQueryClient()
  const [isDrawerOpen, { on: openDrawer, off: closeDrawer }] = useBoolean(true)
  const isMobile =
    useBreakpointValue<boolean>(
      {
        base: true,
        lg: false,
      },
      { ssr: false }
    ) ?? false

  const variants: Variants = {
    start: {
      height: "auto",
      minHeight: 0,
      transition: {
        when: "beforeChildren",
      },
    },
    desktop: {
      height: "auto",
      minHeight: 0,
    },
    open: {
      height: "100%",
      bottom: 0,
      transition: {
        when: "beforeChildren",
      },
    },
    closed: {
      height: "5rem",
      transition: {
        when: "afterChildren",
      },
    },
  }

  const contentVariants: Variants = {
    open: {
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
    closed: {
      opacity: 0,
    },
  }

  const hasStartedScreener = application?.has_started_screener ?? false
  const canApply =
    !!application?.panelist ||
    application?.recruitment_link?.enabled ||
    application?.is_invited
  const hasBooking = !!application?.booking
  const isClosedMobile = isMobile && !isDrawerOpen
  const isOpenMobile = isMobile && isDrawerOpen
  const showPoweredBy =
    !application?.panelist && (!isMobile || hasStartedScreener)

  const animateProp = getAnimateProp(hasStartedScreener, isMobile, isDrawerOpen)

  useEffect(() => {
    // close drawer automatically when screener starts
    if (hasStartedScreener) {
      closeDrawer()
      window.scrollTo(0, 0)
    }
  }, [hasStartedScreener, closeDrawer])

  return (
    <>
      {application && (canApply || hasBooking) ? (
        <ModeratedStudyApplicationProvider
          application={application}
          invalidateApplicationQuery={async () => {
            queryClient.invalidateQueries([
              "api",
              "moderated_study_applications",
              application.moderated_study_application_id,
            ])
          }}
        >
          <MotionFlex
            direction="column"
            initial="start"
            animate={animateProp}
            variants={variants}
            bg="white"
            flexBasis="50%"
            flexGrow={[1, null, null, 0]}
            pt={4}
            pb={4}
            position={
              !hasStartedScreener
                ? "static"
                : isClosedMobile
                  ? "fixed"
                  : isOpenMobile
                    ? "absolute"
                    : "static"
            }
            borderBottomWidth={[1, null, null, 0]}
            borderBottomColor="gray.200"
            left={[0, null, null, "auto"]}
            right={[0, null, null, "auto"]}
            zIndex={[2, null, null, "auto"]}
            top={0}
            px={["16px", null, null, null, "60px"]}
            maxH={["unset", null, null, "100vh"]}
            overflowY={["initial", null, null, "auto"]}
            justifyContent="flex-start"
          >
            <MotionFlex
              direction="column"
              minHeight={isMobile && isDrawerOpen ? "100%" : "auto"}
              flexGrow={1}
              variants={contentVariants}
            >
              {hasStartedScreener && isOpenMobile && (
                <Flex
                  position="fixed"
                  top={[4, null, 8]}
                  right={[4, null, 8]}
                  zIndex={1}
                >
                  <IconButton
                    variant="ghost"
                    size="md"
                    icon={<XOutlineIcon boxSize={6} />}
                    aria-label="Hide details"
                    onClick={closeDrawer}
                  />
                </Flex>
              )}
              {(!isMobile || isOpenMobile) && (
                <>
                  <Flex
                    minHeight={!hasStartedScreener ? "auto" : "100vh"}
                    position="fixed"
                    bg="white"
                  />
                  <Flex
                    direction="column"
                    align="center"
                    minHeight="full"
                    flexGrow={1}
                    py={[hasStartedScreener ? "7rem" : "1rem", null, null, 0]}
                  >
                    <Flex
                      flexGrow={1}
                      maxWidth="600px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Flex
                        mt={showPoweredBy ? "100px" : undefined}
                        flexGrow={[1, null, null, 0]}
                      >
                        <StudyOverviewPage />
                      </Flex>
                    </Flex>
                    {showPoweredBy && (
                      <Flex width="full" justifyContent="center" height="auto">
                        <Box mt={8} pb={4}>
                          <PoweredLogoUnstyled w="160px" />
                        </Box>
                      </Flex>
                    )}
                  </Flex>
                </>
              )}
            </MotionFlex>
            {isClosedMobile && (
              <Flex flexDirection="column" alignItems="flex-start">
                <Heading
                  as="h2"
                  fontWeight="normal"
                  fontSize="lg"
                  mb={2}
                  noOfLines={1}
                >
                  {application.moderated_study.external_name}
                </Heading>
                <Button
                  variant="link"
                  fontSize="sm"
                  textDecoration="none"
                  fontWeight="medium"
                  textColor="link"
                  onClick={openDrawer}
                >
                  See details
                </Button>
              </Flex>
            )}
          </MotionFlex>

          <Flex
            bg="linear-gradient(90deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0) 5.68%, rgba(0, 0, 0, 0) 99.1%)"
            flexBasis={1}
            flexGrow={1}
            align={["flex-start", null, null, "center"]}
            justifyContent="center"
            py={!hasStartedScreener ? "1rem" : "5rem"}
            px={["16px", null, null, null, "60px"]}
          >
            <Flex
              align="center"
              width="full"
              height="full"
              maxWidth="600px"
              justifyContent="center"
            >
              <Outlet />
            </Flex>
          </Flex>
        </ModeratedStudyApplicationProvider>
      ) : (
        <Center maxH="100vh">
          <Box
            px={3}
            py={2}
            bg="white"
            borderWidth={1}
            borderColor="gray.200"
            rounded="sm"
            color="text.secondary"
          >
            This study is not currently accepting applications
          </Box>
        </Center>
      )}
    </>
  )
}

const getAnimateProp = (
  hasStartedScreener: boolean,
  isMobile: boolean,
  isDrawerOpen: boolean
) => {
  if (!hasStartedScreener) return "start"
  if (!isMobile) return "desktop"
  return isDrawerOpen ? "open" : "closed"
}
