import { isString, pickBy } from "lodash"
import LZString from "lz-string"

import { isPresent } from "./values"

function getValueString(value: any) {
  if (isString(value)) return value

  if (Array.isArray(value)) return JSON.stringify(value)

  return JSON.stringify(pickBy(value, isPresent))
}

export const encodeValueForURLUse = (value: any) => {
  const valueString = getValueString(value)

  if (valueString === "" || valueString === "{}" || valueString === "[]")
    return ""

  const base64value = LZString.compressToEncodedURIComponent(valueString)

  return base64value
}

export const decodeValueFromURLUse = <Value>(
  valueString: string
): Value | undefined => {
  const value = LZString.decompressFromEncodedURIComponent(valueString)

  if (!value) return undefined

  try {
    return JSON.parse(value) as Value
  } catch (e) {
    return {} as Value
  }
}
