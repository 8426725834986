import { AppliedFilter, FilterOptionValue } from "Types"

export const MENU_NAVIGATION_KEYS = [
  "ArrowUp",
  "ArrowDown",
  "Enter",
  "Tab",
  " ",
]

export const serializeAppliedFilter = (appliedFilter: AppliedFilter) => {
  return {
    attribute: appliedFilter.attribute.value,
    comparator: appliedFilter.comparator.value,
    values: serializeFilterOptions(appliedFilter.options),
  }
}

export const serializeFilterOptions = (option: FilterOptionValue) => {
  switch (option.type) {
    case "static":
      return []
    case "freeText":
      return [option.value]
    case "single":
      return [option.choice.value]
    case "multi":
      return option.choices.map((c) => c.value)
  }

  assertNever(option)
}

export const assertNever = (val: never): never => {
  throw new Error(`Unsupported filter option: ${JSON.stringify(val)}`)
}
