// These values are from https://github.com/fnando/browser/
export enum Platform {
  Ios = "ios",
  Linux = "linux",
  Windows = "windows",
  MacOs = "mac",
  WindowsPhone = "windows_phone",
  WindowsMobile = "windows_mobile",
  FirefoxOs = "firefox_os",
  Blackberry = "blackberry",
  Android = "android",
  Other = "other",
  ChromsOs = "chrome_os",
  AdobeAir = "adobe_air",
}
