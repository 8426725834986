export const tokens = {
  colors: {
    blue: {
      50: "#ebf8ff",
      100: "#e1effe",
      200: "#c3ddfd",
      300: "#a4cafe",
      400: "#76a9fa",
      500: "#3f83f8",
      600: "#1c64f2",
      700: "#1a56db",
      800: "#1e429f",
      900: "#233876",
    },
    cyan: {
      50: "#daf9ff",
      100: "#b5f2ff",
      200: "#88e4f8",
      300: "#5bd6f1",
      400: "#2ec8ea",
      500: "#00bae3",
      600: "#00a3c7",
      700: "#008cab",
      800: "#00758e",
      900: "#004c65",
    },
    darkNeutral: {
      alpha: {
        0: "#ffffff00",
        50: "#ffffff0a",
        100: "#ffffff0f",
        200: "#ffffff14",
        300: "#ffffff29",
        400: "#ffffff3d",
        500: "#ffffff5c",
        600: "#ffffff7a",
        700: "#ffffffa3",
        800: "#ffffffcc",
        900: "#ffffffeb",
      },
      opaque: {
        0: "#061d29",
        50: "#0f2938",
        100: "#183547",
        200: "#425d6d",
        300: "#6d8494",
        400: "#97abba",
        500: "#bccbd8",
        600: "#e1ebf5",
        700: "#ebf3fa",
        800: "#f5fafe",
        900: "#ffffff",
      },
    },
    green: {
      50: "#f0fff4",
      100: "#c6f6d5",
      200: "#9ae6b4",
      300: "#68d391",
      400: "#48bb78",
      500: "#38a169",
      600: "#25855a",
      700: "#276749",
      800: "#22543d",
      900: "#1c4532",
    },
    neutral: {
      alpha: {
        0: "#061d2900",
        50: "#061d290a",
        100: "#061d290f",
        200: "#061d2914",
        300: "#061d2929",
        400: "#061d293d",
        500: "#061d295c",
        600: "#061d297a",
        700: "#061d29a3",
        800: "#061d29cc",
        900: "#061d29eb",
      },
      opaque: {
        0: "#ffffff",
        50: "#f5fafe",
        100: "#ebf3fa",
        200: "#e1ebf5",
        300: "#bccbd8",
        400: "#97abba",
        500: "#6d8494",
        600: "#425d6d",
        700: "#183547",
        800: "#0f2938",
        900: "#061d29",
      },
    },
    orange: {
      50: "#fffaf0",
      100: "#feebcb",
      200: "#fbd38d",
      300: "#f6ad55",
      400: "#ed8936",
      500: "#dd6b20",
      600: "#c05621",
      700: "#9c4221",
      800: "#7b341e",
      900: "#652b19",
    },
    pink: {
      50: "#ffe2f3",
      100: "#ffc5e7",
      200: "#ffa9db",
      300: "#ec88c1",
      400: "#d966a6",
      500: "#c55292",
      600: "#b03d7d",
      700: "#8f2865",
      800: "#6e144e",
      900: "#4d0037",
    },
    purple: {
      50: "#f0e8ff",
      100: "#e1d1ff",
      200: "#d1b9ff",
      300: "#b698f2",
      400: "#9b77e5",
      500: "#8056d8",
      600: "#6633c9",
      700: "#5723b4",
      800: "#47129e",
      900: "#31046d",
    },
    red: {
      50: "#ffdcd6",
      100: "#ffbaac",
      200: "#ff9882",
      300: "#ff7658",
      400: "#f4572c",
      500: "#e83800",
      600: "#c12f00",
      700: "#992600",
      800: "#872416",
      900: "#6b1400",
    },
    teal: {
      50: "#defdf3",
      100: "#bcfbe7",
      200: "#8fe2d0",
      300: "#63c9ba",
      400: "#37b0a4",
      500: "#0b978e",
      600: "#068382",
      700: "#006e75",
      800: "#044a4e",
      900: "#143333",
    },
    yellow: {
      50: "#fffacf",
      100: "#fff59f",
      200: "#ffef6a",
      300: "#ffe935",
      400: "#ffe300",
      500: "#dcc300",
      600: "#b9a200",
      700: "#978101",
      800: "#756002",
      900: "#533f03",
    },
  },
  semanticTokens: {
    colors: {
      ds: {
        background: {
          accent: {
            blue: {
              bold: "blue.500",
              subtle: "blue.50",
            },
            cyan: {
              bold: "cyan.500",
              subtle: "cyan.50",
            },
            gray: {
              bold: "neutral.opaque.400",
              bolder: "neutral.opaque.500",
              subtle: "neutral.opaque.100",
              subtler: "neutral.opaque.50",
            },
            green: {
              bold: "green.500",
              subtle: "green.50",
            },
            orange: {
              bold: "orange.500",
              subtle: "orange.50",
            },
            pink: {
              bold: "pink.500",
              subtle: "pink.50",
            },
            purple: {
              bold: "purple.500",
              subtle: "purple.50",
            },
            red: {
              bold: "red.500",
              subtle: "red.50",
            },
            teal: {
              bold: "teal.500",
              subtle: "teal.50",
            },
            yellow: {
              bold: "yellow.500",
              subtle: "yellow.50",
            },
          },
          brand: {
            bold: {
              hovered: "teal.600",
              pressed: "teal.700",
              resting: "teal.500",
            },
            subtle: {
              hovered: "teal.100",
              pressed: "teal.200",
              resting: "teal.50",
            },
          },
          danger: {
            bold: {
              hovered: "red.600",
              pressed: "red.700",
              resting: "red.500",
            },
            subtle: {
              hovered: "red.100",
              pressed: "red.200",
              resting: "red.50",
            },
          },
          disabled: "neutral.alpha.100",
          information: {
            bold: {
              hovered: "blue.600",
              pressed: "blue.700",
              resting: "blue.500",
            },
            subtle: {
              hovered: "blue.100",
              pressed: "blue.200",
              resting: "blue.50",
            },
          },
          input: {
            hovered: "neutral.opaque.50",
            pressed: "neutral.opaque.100",
            resting: "neutral.opaque.0",
          },
          inverse: {
            bold: {
              hovered: "neutral.alpha.500",
              pressed: "neutral.alpha.600",
              resting: "neutral.alpha.400",
            },
            subtle: {
              hovered: "neutral.alpha.100",
              pressed: "neutral.alpha.200",
              resting: "neutral.alpha.50",
            },
          },
          neutral: {
            bold: {
              resting: "neutral.opaque.700",
              hovered: "neutral.opaque.800",
              pressed: "neutral.opaque.900",
            },
            hovered: "neutral.alpha.100",
            pressed: "neutral.alpha.200",
            resting: "neutral.alpha.50",
            subtle: {
              hovered: "neutral.alpha.50",
              pressed: "neutral.alpha.100",
              resting: "neutral.alpha.0",
            },
          },
          new: {
            bold: {
              hovered: "pink.600",
              pressed: "pink.700",
              resting: "pink.500",
            },
            subtle: {
              hovered: "pink.100",
              pressed: "pink.200",
              resting: "pink.50",
            },
          },
          selected: {
            bold: {
              hovered: "teal.600",
              pressed: "teal.700",
              resting: "teal.500",
            },
            subtle: {
              hovered: "teal.100",
              pressed: "teal.200",
              resting: "teal.50",
            },
          },
          success: {
            bold: {
              hovered: "green.600",
              pressed: "green.700",
              resting: "green.500",
            },
            subtle: {
              hovered: "green.100",
              pressed: "green.200",
              resting: "green.50",
            },
          },
          upgrade: {
            bold: {
              hovered: "yellow.500",
              pressed: "yellow.600",
              resting: "yellow.400",
            },
            subtle: {
              hovered: "yellow.100",
              pressed: "yellow.200",
              resting: "yellow.50",
            },
          },
          warning: {
            bold: {
              hovered: "orange.600",
              pressed: "orange.700",
              resting: "orange.500",
            },
            subtle: {
              hovered: "orange.100",
              pressed: "orange.200",
              resting: "orange.50",
            },
          },
        },
        border: {
          danger: "red.500",
          default: "neutral.alpha.200",
          disabled: "neutral.alpha.100",
          focused: "teal.500",
          hovered: "neutral.opaque.300",
          input: "neutral.alpha.400",
          inverse: "neutral.opaque.0",
          selected: "teal.500",
        },
        icon: {
          accent: {
            blue: "blue.500",
            cyan: "cyan.500",
            gray: "neutral.opaque.500",
            green: "green.500",
            orange: "orange.500",
            pink: "pink.500",
            purple: "purple.500",
            red: "red.500",
            teal: "teal.500",
            yellow: "yellow.500",
          },
          brand: "teal.500",
          danger: "red.500",
          default: "neutral.opaque.800",
          disabled: "neutral.alpha.400",
          info: "blue.500",
          inverse: "neutral.opaque.0",
          new: "pink.500",
          selected: "teal.700",
          subtle: "neutral.opaque.500",
          success: "green.500",
          upgrade: "yellow.500",
          warning: "orange.500",
        },
        link: {
          default: "teal.500",
          pressed: "teal.600",
          visited: "purple.500",
        },
        surface: {
          backdrop: "neutral.alpha.500",
          overlay: {
            default: "neutral.opaque.0",
            hovered: "neutral.opaque.0",
            pressed: "neutral.opaque.100",
          },
          raised: {
            hovered: "neutral.opaque.0",
            pressed: "neutral.opaque.100",
            resting: "neutral.opaque.0",
          },
          sunken: "neutral.opaque.50",
        },
        text: {
          accent: {
            blue: "blue.600",
            cyan: "cyan.600",
            gray: "neutral.opaque.600",
            green: "green.600",
            orange: "red.600",
            pink: "pink.600",
            purple: "purple.600",
            red: "red.600",
            teal: "teal.600",
            yellow: "yellow.600",
          },
          brand: "teal.600",
          danger: "red.700",
          default: "neutral.opaque.900",
          disabled: "neutral.alpha.400",
          inverse: "neutral.opaque.0",
          new: "pink.600",
          selected: "teal.600",
          subtle: "neutral.opaque.500",
          subtlest: "neutral.opaque.400",
          success: "green.600",
          upgrade: "yellow.600",
          warning: "orange.600",
        },
      },
    },
  },
  shadows: {
    ds: {
      overflow: "0 0 8px 0 #061d2929, 0 0 1px 0 #061d291f",
      overlay: "0 8px 12px 0 #061d2926, 0 0 1px 0 #061d294f",
      raised: "0 1px 2px 0 #061d290f, 0 1px 3px 0 #061d291a",
    },
  },
  textStyles: {
    ds: {
      display: {
        emphasized: {
          fontFamily: "Mint Grotesk",
          fontSize: "2rem",
          fontWeight: "500",
          letterSpacing: "normal",
          lineHeight: "1.25",
          textDecoration: "none",
          textTransform: "none",
        },
        primary: {
          fontFamily: "Mint Grotesk",
          fontSize: "1.25rem",
          fontWeight: "500",
          letterSpacing: "normal",
          lineHeight: "1.4",
          textDecoration: "none",
          textTransform: "none",
        },
        secondary: {
          fontFamily: "Mint Grotesk",
          fontSize: "1.125rem",
          fontWeight: "500",
          letterSpacing: "normal",
          lineHeight: "1.333",
          textDecoration: "none",
          textTransform: "none",
        },
      },
      heading: {
        primary: {
          fontFamily: "Inter",
          fontSize: "1rem",
          fontWeight: "500",
          letterSpacing: "normal",
          lineHeight: "1.5",
          textDecoration: "none",
          textTransform: "none",
        },
        secondary: {
          fontFamily: "Inter",
          fontSize: "0.875rem",
          fontWeight: "500",
          letterSpacing: "normal",
          lineHeight: "1.429",
          textDecoration: "none",
          textTransform: "none",
        },
        subtle: {
          fontFamily: "Inter",
          fontSize: "0.75rem",
          fontWeight: "600",
          letterSpacing: "0.025em",
          lineHeight: "1.333",
          textDecoration: "none",
          textTransform: "uppercase",
        },
      },
      interface: {
        large: {
          fontFamily: "Inter",
          fontSize: "1rem",
          fontWeight: "500",
          letterSpacing: "0.0025em",
          lineHeight: "0.875",
          textDecoration: "none",
          textTransform: "none",
        },
        medium: {
          fontFamily: "Inter",
          fontSize: "0.875rem",
          fontWeight: "500",
          letterSpacing: "0.0025em",
          lineHeight: "0.857",
          textDecoration: "none",
          textTransform: "none",
        },
        small: {
          fontFamily: "Inter",
          fontSize: "0.75rem",
          fontWeight: "500",
          letterSpacing: "0.0025em",
          lineHeight: "0.833",
          textDecoration: "none",
          textTransform: "none",
        },
        xsmall: {
          fontFamily: "Inter",
          fontSize: "0.6875rem",
          fontWeight: "600",
          letterSpacing: "0.025em",
          lineHeight: "0.727",
          textDecoration: "none",
          textTransform: "uppercase",
        },
      },
      paragraph: {
        emphasized: {
          fontFamily: "Inter",
          fontSize: "1rem",
          fontWeight: "400",
          letterSpacing: "normal",
          lineHeight: "1.5",
          textDecoration: "none",
          textTransform: "none",
        },
        primary: {
          fontFamily: "Inter",
          fontSize: "0.875rem",
          fontWeight: "400",
          letterSpacing: "normal",
          lineHeight: "1.429",
          textDecoration: "none",
          textTransform: "none",
        },
        secondary: {
          fontFamily: "Inter",
          fontSize: "0.75rem",
          fontWeight: "400",
          letterSpacing: "normal",
          lineHeight: "1.333",
          textDecoration: "none",
          textTransform: "none",
        },
      },
    },
  },
} as const

export const userCrowdThemeOverrideTokens = {
  semanticTokens: {
    colors: {
      ds: {
        surface: {
          sunken: "neutral.opaque.50",
          backdrop: "neutral.alpha.500",
          raised: {
            resting: "neutral.opaque.0",
            hovered: "neutral.opaque.0",
            pressed: "neutral.opaque.100",
          },
          overlay: {
            default: "neutral.opaque.0",
            hovered: "neutral.opaque.0",
            pressed: "neutral.opaque.100",
          },
        },
        background: {
          disabled: "neutral.alpha.100",
          input: {
            resting: "neutral.opaque.0",
            hovered: "neutral.opaque.50",
            pressed: "neutral.opaque.100",
          },
          neutral: {
            resting: "neutral.alpha.50",
            hovered: "neutral.alpha.100",
            pressed: "neutral.alpha.200",
            subtle: {
              resting: "neutral.alpha.0",
              hovered: "neutral.alpha.50",
              pressed: "neutral.alpha.100",
            },
            bold: {
              resting: "neutral.opaque.600",
              hovered: "neutral.opaque.700",
              pressed: "neutral.opaque.800",
            },
          },
          inverse: {
            subtle: {
              resting: "neutral.alpha.50",
              hovered: "neutral.alpha.100",
              pressed: "neutral.alpha.200",
            },
            bold: {
              resting: "neutral.alpha.400",
              hovered: "neutral.alpha.500",
              pressed: "neutral.alpha.600",
            },
          },
          selected: {
            subtle: {
              resting: "purple.50",
              hovered: "purple.100",
              pressed: "purple.200",
            },
            bold: {
              resting: "purple.500",
              hovered: "purple.600",
              pressed: "purple.700",
            },
          },
          success: {
            subtle: {
              resting: "green.50",
              hovered: "green.100",
              pressed: "green.200",
            },
            bold: {
              resting: "green.500",
              hovered: "green.600",
              pressed: "green.700",
            },
          },
          warning: {
            subtle: {
              resting: "orange.50",
              hovered: "orange.100",
              pressed: "orange.200",
            },
            bold: {
              resting: "orange.500",
              hovered: "orange.600",
              pressed: "orange.700",
            },
          },
          danger: {
            subtle: {
              resting: "red.50",
              hovered: "red.100",
              pressed: "red.200",
            },
            bold: {
              resting: "red.500",
              hovered: "red.600",
              pressed: "red.700",
            },
          },
          information: {
            subtle: {
              resting: "blue.50",
              hovered: "blue.100",
              pressed: "blue.200",
            },
            bold: {
              resting: "blue.500",
              hovered: "blue.600",
              pressed: "blue.700",
            },
          },
          new: {
            subtle: {
              resting: "pink.50",
              hovered: "pink.100",
              pressed: "pink.200",
            },
            bold: {
              resting: "pink.500",
              hovered: "pink.600",
              pressed: "pink.700",
            },
          },
          upgrade: {
            subtle: {
              resting: "yellow.50",
              hovered: "yellow.100",
              pressed: "yellow.200",
            },
            bold: {
              resting: "yellow.400",
              hovered: "yellow.500",
              pressed: "yellow.600",
            },
          },
          brand: {
            subtle: {
              resting: "purple.50",
              hovered: "purple.100",
              pressed: "purple.200",
            },
            bold: {
              resting: "purple.500",
              hovered: "purple.600",
              pressed: "purple.700",
            },
          },
          accent: {
            purple: {
              subtle: "purple.50",
              bold: "purple.500",
            },
            pink: {
              subtle: "pink.50",
              bold: "pink.500",
            },
            blue: {
              subtle: "blue.50",
              bold: "blue.500",
            },
            teal: {
              subtle: "teal.50",
              bold: "teal.500",
            },
            yellow: {
              subtle: "yellow.50",
              bold: "yellow.500",
            },
            orange: {
              subtle: "orange.50",
              bold: "orange.500",
            },
            red: {
              subtle: "red.50",
              bold: "red.500",
            },
            cyan: {
              subtle: "cyan.50",
              bold: "cyan.500",
            },
            green: {
              subtle: "green.50",
              bold: "green.500",
            },
            gray: {
              subtler: "neutral.opaque.50",
              subtle: "neutral.opaque.100",
              bold: "neutral.opaque.400",
              bolder: "neutral.opaque.500",
            },
          },
        },
        text: {
          default: "neutral.opaque.900",
          subtle: "neutral.opaque.500",
          subtlest: "neutral.opaque.400",
          inverse: "neutral.opaque.0",
          selected: "purple.600",
          disabled: "neutral.alpha.400",
          success: "green.600",
          warning: "orange.600",
          danger: "red.700",
          brand: "teal.600",
          upgrade: "yellow.600",
          new: "pink.600",
          accent: {
            purple: "purple.600",
            pink: "pink.600",
            green: "green.600",
            orange: "red.600",
            red: "red.600",
            blue: "blue.600",
            yellow: "yellow.600",
            teal: "teal.600",
            cyan: "cyan.600",
            gray: "neutral.opaque.600",
          },
        },
        link: {
          default: "purple.500",
          pressed: "purple.600",
          visited: "teal.500",
        },
        icon: {
          default: "neutral.opaque.800",
          subtle: "neutral.opaque.500",
          inverse: "neutral.opaque.0",
          selected: "purple.700",
          disabled: "neutral.alpha.400",
          success: "green.500",
          warning: "orange.500",
          danger: "red.500",
          info: "blue.500",
          upgrade: "yellow.500",
          new: "pink.500",
          brand: "purple.500",
          accent: {
            red: "red.500",
            blue: "blue.500",
            orange: "orange.500",
            green: "green.500",
            purple: "purple.500",
            pink: "pink.500",
            yellow: "yellow.500",
            cyan: "cyan.500",
            teal: "teal.500",
            gray: "neutral.opaque.500",
          },
        },
        border: {
          default: "neutral.alpha.200",
          input: "neutral.alpha.400",
          hovered: "neutral.opaque.300",
          inverse: "neutral.opaque.0",
          danger: "red.500",
          disabled: "neutral.alpha.100",
          focused: "purple.500",
          selected: "purple.500",
        },
      },
    },
  },
} as const
