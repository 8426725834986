import classNames from "classnames"
import React, { ElementType } from "react"

interface Props {
  className?: string
}

export function createStyledComponent(
  Component: ElementType,
  className: string
): React.FC<React.PropsWithChildren<Props>> {
  return (props) => (
    <Component className={classNames(className, props.className)}>
      {props.children}
    </Component>
  )
}
