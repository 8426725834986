import React from "react"
import { Helmet } from "react-helmet"

import { CardGroup } from "Shared/components/Card/Card"
import { SettingsPage } from "UserCrowd/components/SettingsPage/SettingsPage"
import { PanelistChangePasswordForm } from "UserCrowd/views/settings/account/PanelistChangePasswordForm"
import { DeleteTesterAccount } from "./DeleteTesterAccount"
import { PanelistDetails } from "./PanelistDetailsForm"

export const AccountSettingsRoute: React.FC = () => {
  return (
    <SettingsPage>
      <Helmet>
        <title>Account Settings • UserCrowd</title>
      </Helmet>

      <CardGroup>
        <PanelistDetails />
        <PanelistChangePasswordForm />
        <DeleteTesterAccount />
      </CardGroup>
    </SettingsPage>
  )
}
