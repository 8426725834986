import { Box, Icon, IconButton } from "@chakra-ui/react"
import React from "react"

import { ChevronDownOutlineIcon } from "Shared/icons/untitled-ui/ChevronDownOutlineIcon"
import { ChevronUpOutlineIcon } from "Shared/icons/untitled-ui/ChevronUpOutlineIcon"

interface Props {
  moveUpAction: () => void
  moveDownAction: () => void
  upDisabled: boolean
  downDisabled: boolean
}

export const NextAndPrevArrows: React.FC<Props> = ({
  moveUpAction,
  moveDownAction,
  upDisabled,
  downDisabled,
}) => {
  return (
    <Box>
      <IconButton
        variant="outline"
        aria-label="Move up"
        icon={<Icon as={ChevronUpOutlineIcon} boxSize={5} />}
        size="md"
        onClick={moveUpAction}
        mr={2}
        isDisabled={upDisabled}
      />
      <IconButton
        variant="outline"
        aria-label="Move down"
        icon={<Icon as={ChevronDownOutlineIcon} boxSize={5} />}
        size="md"
        onClick={moveDownAction}
        isDisabled={downDisabled}
      />
    </Box>
  )
}
