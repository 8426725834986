export const Input = {
  variants: {
    outline: {
      field: {
        background: "white",
        fontWeight: "normal",
      },
    },
  },
}
