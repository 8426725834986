import { Box } from "@chakra-ui/react"
import React from "react"

import { Page, PageContent, PageMain } from "Shared/components/Page/Page"
import { ResetPasswordForm } from "Shared/forms/ResetPasswordForm"
import { UserCrowdNavbar } from "UserCrowd/components/UserCrowdNavbar/UserCrowdNavbar"

export const ResetPasswordRoute: React.FC = () => (
  <Page title="Reset password">
    <Box pt={16} mx="auto">
      <UserCrowdNavbar variant="signed-out" />
      <PageMain>
        <PageContent maxW="lg">
          <ResetPasswordForm />
        </PageContent>
      </PageMain>
    </Box>
  </Page>
)
