import React from "react"

import { SettingsPage } from "UserCrowd/components/SettingsPage/SettingsPage"
import { TestersNotificationsForm } from "UserCrowd/components/TestersNotificationsForm/TestersNotificationsForm"
import { Helmet } from "react-helmet"

export const NotificationsSettingsRoute = () => (
  <SettingsPage>
    <Helmet title="Notification Settings" />
    <TestersNotificationsForm />
  </SettingsPage>
)
