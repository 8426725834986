import { createMultiStyleConfigHelpers } from "@chakra-ui/react"
import { Z_INDEX_SUBNAV } from "Constants/zIndexes"

const helpers = createMultiStyleConfigHelpers([
  "container",
  "divider",
  "step",
  "stepIndicator",
  "stepIcon",
])

export const Steps = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      bg: "white",
      fontWeight: "medium",
      height: 12,
      position: "sticky",
      top: 0,
      zIndex: Z_INDEX_SUBNAV,
      _after: {
        content: '""',
        position: "absolute",
        top: "calc(100% - 0px)",
        left: 0,
        right: 0,
        height: "8px",
        pointerEvents: "none",
        background:
          "linear-gradient(to bottom, rgba(6, 29, 41, 0.12) -1px, rgba(6, 29, 41, 0) 1px), linear-gradient(to bottom, rgba(6, 29, 41, 0.16) -4px, rgba(6, 29, 41, 0) 4px)",
      },
    },
    divider: {
      width: 0,
      mx: 3,
      position: "relative",
      _before: {
        content: '""',
        display: "block",
        position: "absolute",
        transform: "skew(24deg, 0deg)",
        bg: "gray.100",
        width: "2px",
        height: "50%",
        top: 0,
        left: 0,
      },
      _after: {
        content: '""',
        display: "block",
        position: "absolute",
        transform: "skew(-24deg, 0deg)",
        bg: "gray.100",
        width: "2px",
        height: "50%",
        bottom: 0,
        left: 0,
      },
    },
    step: {
      py: 3,
      px: 4,
      gap: 2,
      fontWeight: "medium",
      color: "brand.primary.500",
      _hover: {
        color: "brand.primary.700",
      },
      _activeLink: {
        color: "gray.700",
        fontWeight: "bold",
        "> [data-component=number]": {
          // This is a bit gross but there is no `_groupActiveLink` selector
          bg: "gray.700",
          color: "white",
        },
      },
      _disabled: {
        color: "gray.400",
        cursor: "not-allowed",
      },
      _focus: { outline: 0, boxShadow: "outline" },
    },
    stepIndicator: {
      boxSize: 6,
      rounded: "full",
      fontSize: "sm",
      ml: -2,
      lineHeight: 0,
      bg: "brand.primary.100",
      _groupDisabled: {
        bg: "gray.100",
      },
    },
    stepIcon: {
      color: "currentColor",
    },
    stepCount: {
      font: "inherit",
      fontWeight: "normal",
    },
  },
})
