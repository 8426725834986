import { Box, BoxProps, Button, Heading, Text } from "@chakra-ui/react"
import { ROUTES } from "UserCrowd/views/routes"
import React from "react"
import { Link } from "react-router-dom"
import { usePanelistStats } from "~/api/generated/usabilityhub-components"

export const PanelistProfileCompletionAlert: React.FC<BoxProps> = (props) => {
  const { data } = usePanelistStats({})

  if (!data) return null

  const profileCompletion = Math.round(data.profile_completeness)

  if (profileCompletion >= 100) return null

  return (
    <Box
      bg="white"
      borderWidth={1}
      borderColor="gray.200"
      rounded="lg"
      p={8}
      {...props}
    >
      <Heading fontWeight="semibold" color="text.primary" fontSize="lg" mb={1}>
        Want to get sent more tests & interviews?
      </Heading>

      <Text color="text.primary">
        Your tester profile is only {profileCompletion}% complete. Answering
        more profile questions gives you more opportunities to be selected for
        studies.
      </Text>

      <Button
        as={Link}
        to={ROUTES.SETTINGS.PROFILE.path}
        variant="outline"
        mt={6}
      >
        Complete my profile
      </Button>
    </Box>
  )
}
