import {
  Box,
  Button,
  Link as ChakraLink,
  Checkbox,
  Flex,
  Heading,
  ListItem,
  OrderedList,
  Text,
} from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { ROUTES } from "UsabilityHub/views/routes"
import {
  centsToDollars,
  creditsToCents,
  formatDollars,
} from "Utilities/currency"
import React, { useState } from "react"
import { Link, Navigate } from "react-router-dom"
import { useUpdatePanelistAccountDetails } from "~/api/generated/usabilityhub-components"
import { ModeratedStudyApplicationFlow } from "~/api/generated/usabilityhubResponses"
import { useModeratedStudyApplicationContext } from "./ModeratedStudyApplicationContext"
import { PreferredNameField } from "./PreferredNameField"

export const PanelistWelcomePage: React.FC = () => {
  const { application } = useModeratedStudyApplicationContext()
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [preferredName, setPreferredName] = useState(
    application.panelist?.preferred_name ?? ""
  )

  const moderatedStudyApplicationId = application.moderated_study_application_id
  const deviceString = generateDeviceStringFromStudy(
    application.moderated_study
  )

  const { mutate: updateDetails } = useUpdatePanelistAccountDetails({})

  const nextStep = application.screener_required
    ? ROUTES.INTERVIEW_APPLICATIONS.APPLICATION.QUESTIONS.buildPath({
        moderatedStudyApplicationId,
      })
    : ROUTES.INTERVIEW_APPLICATIONS.APPLICATION.BOOK.buildPath({
        moderatedStudyApplicationId,
      })

  if (!application.panelist) {
    return <Navigate to={ROUTES.DASHBOARD.path} />
  }

  return (
    <Flex direction="column" mx={2} my={[6, null, null, 0]}>
      <Heading as="h2" fontSize="2xl" fontWeight="normal">
        Hello {preferredName}! 👋
      </Heading>

      {application.panelist.preferred_name === null && (
        <PreferredNameField
          fullName={application.panelist.full_name}
          preferredName={preferredName}
          setPreferredName={setPreferredName}
          isInvalid={preferredName.length === 0}
          inputProps={{
            onChange: (e) => setPreferredName(e.target.value),
          }}
        />
      )}

      <Box bg="gray.100" p={6} mt={5}>
        <Heading fontSize="lg" fontWeight="medium">
          You{"\u2019"}re about to apply for an interview
        </Heading>

        <OrderedList mt={4} ps={2} spacing={2}>
          {getInstructionText(application).map((line, index) => (
            <ListItem key={index}>{line}</ListItem>
          ))}
          <ListItem>You can join the session from {deviceString}.</ListItem>
          <ListItem>
            Upon successfully completing the session, you{"\u2019"}ll earn{" "}
            {formatDollars(
              centsToDollars(
                creditsToCents(application.panelist.incentive_credits)
              )
            )}
            .{" "}
            <ChakraLink
              isExternal
              href={Constants.PANELIST_INTERVIEWS_FAQ_URL}
              fontWeight="normal"
              textDecoration="none"
              _hover={{ textDecoration: "none" }}
            >
              Learn more about interviews
            </ChakraLink>
            .
          </ListItem>
        </OrderedList>
        <Text mt={4}>
          <strong>Note:</strong> Please refrain from providing any of your
          contact details in the screener or during interviews. Only your
          preferred name will be shared with the interview hosts.{" "}
          <ChakraLink
            isExternal
            href={Constants.PANELIST_INTERVIEWS_FAQ_URL}
            fontWeight="normal"
            textDecoration="none"
            _hover={{ textDecoration: "none" }}
          >
            Learn more about how we protect your privacy
          </ChakraLink>
          .
        </Text>
      </Box>

      <Checkbox
        isChecked={termsAccepted}
        onChange={(e) => setTermsAccepted(e.target.checked)}
        mt={4}
        spacing={2}
        alignItems="flex-start"
        sx={{
          "& > .chakra-checkbox__control": {
            transform: "translateY(4px)",
          },
        }}
      >
        I have read, understood, and agree to the interview terms and conditions
        listed above.
      </Checkbox>

      {termsAccepted ? (
        <Button
          as={Link}
          to={nextStep}
          w="fit-content"
          colorScheme="brand.primary"
          mt={10}
          _hover={{
            color: "white",
            bg: "brand.primary.600",
          }}
          onClick={() =>
            updateDetails({ body: { preferred_name: preferredName } })
          }
        >
          Accept and continue
        </Button>
      ) : (
        <Button isDisabled w="fit-content" colorScheme="brand.primary" mt={10}>
          Accept and continue
        </Button>
      )}
    </Flex>
  )
}

const generateDeviceStringFromStudy = (
  moderatedStudy: ModeratedStudyApplicationFlow["moderated_study"]
) => {
  const deviceTypesLabel =
    moderatedStudy.device_requirements.device_types.length === 3
      ? "any device"
      : moderatedStudy.device_requirements.device_types.join(" or ")
  const devicePeripherals =
    moderatedStudy.device_requirements.device_peripherals.join(" and ")

  if (devicePeripherals === "") {
    return deviceTypesLabel
  } else {
    return `${deviceTypesLabel} with a ${devicePeripherals}`
  }
}

const getInstructionText = ({
  screener_required,
  panelist,
}: Pick<ModeratedStudyApplicationFlow, "screener_required" | "panelist">) => {
  const isHandpick = panelist?.is_handpick ?? false

  if (isHandpick) {
    return screener_required
      ? [
          "You\u2019ll be asked a few questions to see if you\u2019re the right fit.",
          "If you are, you\u2019ll receive an invitation from the researcher to book a time for an online meeting.",
        ]
      : [
          "You\u2019ll be asked to apply for an online meeting. If selected, you\u2019ll receive an invitation from the researcher to book a time.",
        ]
  } else {
    return screener_required
      ? [
          "You\u2019ll be asked a few questions to see if you\u2019re the right fit.",
          "If you are eligible, you\u2019ll be asked to select a time on the booking calendar.",
        ]
      : [
          "You\u2019ll be asked to book a time for an online meeting from a list of times available.",
        ]
  }
}
