import { Flex, FlexProps } from "@chakra-ui/react"
import React from "react"

export const Controls: React.FC<FlexProps> = ({ children, ...props }) => (
  <Flex
    gap={2}
    justify="flex-end"
    boxShadow={["ds.overflow", "none"]}
    bg="ds.surface.raised.resting"
    py={[3, 0]}
    ps={[6, 0, 4]}
    pe={[6, 0, 10]}
    {...props}
  >
    {children}
  </Flex>
)
