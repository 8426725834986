import { Flex, Img } from "@chakra-ui/react"
import { Heading } from "DesignSystem/components"
import WaitingForStudiesImage from "Images/app-illustrations/waiting-for-studies.png"
import React, { ReactNode } from "react"

interface EmptyListPlaceholderProps {
  heading: string
  children: ReactNode
}

export const EmptyListPlaceholder: React.FC<
  React.PropsWithChildren<EmptyListPlaceholderProps>
> = ({ heading, children }) => {
  return (
    <Flex
      bg="ds.background.neutral.resting"
      minH="20.25rem"
      alignItems="center"
      justifyContent="center"
      rounded="16px"
      p={10}
    >
      <Flex flexDirection="column" alignItems="center" gap={6}>
        <Img
          src={WaitingForStudiesImage}
          alt="Waiting for studies"
          maxW="7.6875rem"
        />
        <Flex flexDirection="column" gap={3} css={{ textWrap: "balance" }}>
          <Heading as="h3" textStyle="ds.display.secondary" textAlign="center">
            {heading}
          </Heading>
          {children}
        </Flex>
      </Flex>
    </Flex>
  )
}
