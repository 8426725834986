import { ReactNode } from "react"

import { IMPORTANT_DURATION, STANDARD_DURATION } from "Constants/flash"
import { State as AppState, Dispatch } from "Redux/app-store"
import { Flash, FlashType, NoopAction } from "Types"

type FlashAction = HideAction | ShowAction | NoopAction

interface HideAction {
  id: number
  type: ActionType.Hide
}

interface ShowAction {
  type: ActionType.Show
  payload: Flash
}

export type ReducerState = null | Flash

// -- Action types --

enum ActionType {
  Show = "SHOW_FLASH",
  Hide = "HIDE_FLASH",
}

// -- Action creators --

let nextFlashId = 0
export const getNextFlashId = () => nextFlashId++

function showFlash(children: ReactNode, kind: FlashType, duration: number) {
  return (dispatch: Dispatch<AppState, ShowAction>): number => {
    const id = getNextFlashId()
    dispatch({
      type: ActionType.Show,
      payload: { id, children, kind, duration },
    })
    return id
  }
}

function showFlashMessage(message: string, kind: FlashType, duration: number) {
  const children = message.trim().split(/\s*\n\s*/g)
  return showFlash(children, kind, duration)
}

/**
 * @deprecated
 * Prefer Chakra's useToast hook.
 */
export const showNotice = (
  children: ReactNode,
  duration: number = STANDARD_DURATION
) => showFlash(children, FlashType.Notice, duration)

/**
 * @deprecated
 * Prefer Chakra's useToast hook.
 */
export const showError = (
  children: ReactNode,
  duration: number = IMPORTANT_DURATION
) => showFlash(children, FlashType.Error, duration)

/**
 * @deprecated
 * Prefer Chakra's useToast hook.
 */
export const showNoticeMessage = (
  message: string,
  duration: number = STANDARD_DURATION
) => showFlashMessage(message, FlashType.Notice, duration)

/**
 * @deprecated
 * Prefer Chakra's useToast hook.
 */
export function showErrorMessage(
  message: string,
  duration: number = IMPORTANT_DURATION
) {
  return showFlashMessage(message, FlashType.Error, duration)
}

/**
 * @deprecated
 * Prefer Chakra's useToast hook.
 */
export function hideFlash(id: number): HideAction {
  return { id, type: ActionType.Hide }
}

// -- Reducer --

export default function flash(
  state: ReducerState = null,
  action: FlashAction = { type: "__NOOP__" }
): ReducerState {
  switch (action.type) {
    case ActionType.Show:
      return action.payload
    case ActionType.Hide:
      return state !== null && state.id === action.id ? null : state
    default:
      return state
  }
}
