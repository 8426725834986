import { useQueryClient } from "@tanstack/react-query"
import { useCallback, useMemo } from "react"
import { matchPath, useLocation } from "react-router"
import { ModeratedStudyApplicationFlow } from "~/api/generated/usabilityhubResponses"

// When researchers are previewing the application flow, we don't update anything on
// the backend after initially obtaining the `ModeratedStudyApplicationFlow`.
// Instead, we'll manually update the tanstack-query cache with realistic-looking changes.
export const useModeratedStudyPreviewData = () => {
  const queryClient = useQueryClient()
  const location = useLocation()

  const isPreview = matchPath(
    { path: "/interviews/:moderatedStudyId/preview/*" },
    location.pathname
  )

  if (!isPreview) return () => {}

  const queryParams = new URLSearchParams(location.search)
  const recruitmentLinkId = queryParams.get("recruitmentLinkId")
  const args = useMemo(
    () => (recruitmentLinkId ? { recruitment_link_id: recruitmentLinkId } : {}),
    [recruitmentLinkId]
  )

  const moderatedStudyId = isPreview.params["moderatedStudyId"]

  const updater = useCallback(
    (changes: Partial<ModeratedStudyApplicationFlow>) => {
      queryClient.setQueryData(
        ["api", "moderated_study_previews", moderatedStudyId, args],
        (oldData: any) => {
          return {
            ...oldData,
            ...changes,
          }
        }
      )
    },
    [moderatedStudyId, queryClient, args]
  )

  return updater
}
