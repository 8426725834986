import commaNumber from "comma-number"

/**
 * Format a percentage from a fraction between 0 and 1.
 *
 * @param value
 * @param scale
 * @returns string
 */
export function formatPercentage01(value: number, scale = 0): string {
  return formatPercentage(value * 100, scale)
}

/**
 * Format percentage from an number between 0 and 100.
 *
 * @param percent
 * @param scale
 * @returns string
 */
export function formatPercentage(percent: number, scale = 0): string {
  if (Number.isNaN(percent)) {
    return `n/a`
  }
  if (percent === 0 || percent === 100) {
    return `${percent}%`
  }
  return percent.toFixed(scale) + "%"
}

export function formatNumber(value: number, scale = 0): string {
  return commaNumber(value.toFixed(scale))
}
