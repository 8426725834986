import { BroadcastChannel } from "broadcast-channel"

import { usersSignInPath } from "Shared/constants/routes"

export const channel = new BroadcastChannel("usabilityhub")

const SKIP_PAGES = ["/research-panel-calculator"]

// ts-prune-ignore-next used in test
export type BroadcastMessage = "SIGN_OUT"

// ts-prune-ignore-next used in test
export function handleBroadcastMessage(msg: BroadcastMessage): void {
  if (msg === "SIGN_OUT") {
    // Redirect all windows to the login screen during logout except specific pages
    if (!SKIP_PAGES.includes(window.location.pathname))
      window.location.href = usersSignInPath()
  }
}

channel.onmessage = (msg) => handleBroadcastMessage(msg)
