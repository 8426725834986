import { useMemo } from "react"
import tinycolor from "tinycolor2"

const resolveCSSVariable = (name: string, element: HTMLElement) => {
  const value = getComputedStyle(element).getPropertyValue(name)
  const match = value.match(/var\(([^)]+)\)/)
  if (match) {
    return resolveCSSVariable(match[1], element)
  }
  return value.trim()
}

export const useMergedBackgroundColor = (element: HTMLElement | null) =>
  useMemo(() => {
    if (!element) return "transparent"
    const backgrounds: tinycolor.ColorFormats.RGBA[] = []
    for (let el: HTMLElement | null = element; el; el = el.parentElement) {
      const c = tinycolor(resolveCSSVariable("background-color", el)).toRgb()
      if (c.a) backgrounds.unshift(c)
    }

    const merged = backgrounds.reduce((acc, c) => {
      const a = c.a
      const a_ = 1 - a
      return {
        r: acc.r * a_ + c.r * a,
        g: acc.g * a_ + c.g * a,
        b: acc.b * a_ + c.b * a,
        a: 1,
      }
    })

    return tinycolor(merged).toRgbString()
  }, [element])
