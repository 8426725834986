import { Box, Heading, Text } from "@chakra-ui/react"
import React from "react"

export const NotAcceptingParticipantsPage: React.FC = () => {
  return (
    <Box display="flex" maxW="500px" flexDirection="column" gap="4">
      <Heading fontSize="2xl" fontWeight="400">
        Thanks for your interest
      </Heading>
      <Text>This study is not accepting participants at this time.</Text>
    </Box>
  )
}
