import { useCallback, useEffect, useRef, useState } from "react"

// Returns a function that can be called to force a re-render
// The re-render is debounced to prevent thrashing
export const useForceUpdate = (onUpdate?: () => void) => {
  const [_, setForceUpdate] = useState({})
  const debouncedForceUpdate = useRef<ReturnType<typeof setTimeout>>()

  const forceUpdate = useCallback(() => {
    clearTimeout(debouncedForceUpdate.current)
    debouncedForceUpdate.current = setTimeout(() => {
      setForceUpdate({})
      onUpdate?.()
    })
  }, [onUpdate])

  useEffect(() => {
    return () => clearTimeout(debouncedForceUpdate.current)
  }, [])

  return forceUpdate
}
