import { format } from "date-fns"

/**
 * @example
 * for en-GB locale and Australia/Melbourne timezone:
 * getTimeString(new Date("2023-08-01T09:00:00.000Z"))
 * // "7:00pm"
 */
export const getTimeString = (date: Date) => {
  return date
    .toLocaleTimeString(undefined, {
      hour: "numeric",
      minute: "2-digit",
      hourCycle: "h12",
    })
    .replace(/\s+(am|pm)/i, "$1")
}

/**
 * This won't be affected by the user's locale.
 * @example
 * for Australia/Melbourne timezone:
 * getDateTimeString(new Date("2023-08-01T09:00:00.000Z"))
 * // "7:00pm on August 1st"
 */
export const getDateTimeString = (date: Date) => {
  return format(date, "h:mmaaaaa'm' 'on' MMMM do")
}

/**
 * @example
 * for en-GB locale and Australia/Melbourne timezone:
 * fullWithoutTime(new Date("2023-08-01T09:00:00.000Z"))
 * // "Tuesday, 1 August 2023"
 */
export const fullWithoutTime = (dateOrIsoString: Date | string) => {
  const date =
    typeof dateOrIsoString === "string"
      ? new Date(dateOrIsoString)
      : dateOrIsoString

  return date.toLocaleString(undefined, {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  })
}

/**
 * @example
 * for en-GB locale and Australia/Melbourne timezone:
 * fullWithTime(new Date("2023-08-01T09:00:00.000Z"))
 * // "Tuesday, 1 August 2023 at 7:00pm"
 */
export const fullWithTime = (date: Date) => {
  return date
    .toLocaleString(undefined, {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hourCycle: "h12",
    })
    .replace(/\s+(am|pm)/i, "$1")
}

/**
 * @example
 * for en-GB locale and Australia/Melbourne timezone:
 * longWithTime(new Date("2023-08-01T09:00:00.000Z"))
 * // "1 August 2023 at 07:00 pm"
 */
export const longWithTime = (date: Date) => {
  return date.toLocaleString(undefined, {
    dateStyle: "long",
    timeStyle: "short",
    hourCycle: "h12",
  })
}

/**
 * @example
 * for en-GB locale and Australia/Melbourne timezone:
 * getDateString("2020-08-01") or getDateString("2020-08-01", "medium")
 * // "1 Aug 2020"
 * getDateString("2020-08-01", "short")
 * // "01/08/2020"
 * getDateString("2020-08-01", "long")
 * // "1 August 2020"
 */
export const getDateString = (
  date: Date | string | number | undefined | null,
  dateStyle: "short" | "medium" | "long" = "medium"
) => {
  if (!date) return ""

  if (typeof date === "string" || typeof date === "number") {
    date = new Date(date)
  }

  return date.toLocaleDateString(undefined, { dateStyle: dateStyle })
}

/**
 * @example
 * getMonthYearString("2023-08-01T09:00:00.000Z")
 * // "08/2023"
 */
export const getMonthYearString = (date: string) => {
  const d = new Date(date)
  return d.toLocaleDateString(undefined, {
    month: "numeric",
    year: "numeric",
  })
}
