import { pluralize } from "Utilities/string"

// Produce a description of a time range like:
// 'under a minute', 'under 2 minutes', '1 to 2 mins', etc.
export function formatMinutesRange(
  lower: number,
  upper: number,
  abbreviated = false
): string {
  let formattedUpper = `${upper === 1 ? "a" : upper} `
  if (upper !== 1 && abbreviated) {
    formattedUpper += `${pluralize(upper, "min", "mins")}`
  } else {
    formattedUpper += `${pluralize(upper, "minute", "minutes")}`
  }

  // If it's 0 to 1 or X to X, return "under a minute" or "under X minutes".
  if ((lower === 0 && upper === 1) || lower === upper) {
    return `under ${formattedUpper}`
  }
  // Otherwise, cap the lower bound at 1.
  else {
    return lower === 0
      ? `1 to ${formattedUpper}`
      : `${lower} to ${formattedUpper}`
  }
}

/**
 * Returns either "under a minute" or "approx. x minutes".
 */
export function formatMinutes(minutes: number): string {
  return minutes < 1
    ? "under a minute"
    : `approx. ${minutes} ${pluralize(minutes, "minute", "minutes")}`
}
