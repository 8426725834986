import { Flex } from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { Button } from "DesignSystem/components"
import { DemographicAttributeField } from "UserCrowd/components/DemographicAttributeField"
import React, { useState } from "react"
import { findUnansweredQuestions } from "./findUnansweredQuestions"

type DemographicQuestionProps = {
  question: ReturnType<typeof findUnansweredQuestions>[number]
  onSkip: (() => void) | false
  onSave: () => void
}

export const DemographicQuestion: React.FC<DemographicQuestionProps> = ({
  question,
  onSkip,
  onSave,
}) => {
  const queryClient = useQueryClient()
  const [isSaved, setIsSaved] = useState(false)

  return (
    <>
      <DemographicAttributeField
        id={question.code}
        key={question.id}
        label={question.question}
        helpText={question.profile_helper}
        required={question.required}
        isMulti={question.multi_select}
        options={question.options}
        demographicAttributeId={question.id}
        allInitialSelectedOptions={[]}
        markAnswered={(_demographicAttributeId) => {
          // Although we're hitting the API at this point, we don't want to immediately refetch
          // the other endpoints or the question will disappear. The refetch will happen when they
          // click the next button below.
          setIsSaved(true)
        }}
        markUnanswered={(_demographicAttributeId) => {
          // Do nothing
        }}
      />

      <Flex gap={2} mt={[0, 7]}>
        <Button
          variant="primary"
          isDisabled={!isSaved}
          onClick={async () => {
            // Refetch the completed demographics so the next question appears
            await Promise.all([
              queryClient.invalidateQueries([
                "api",
                "demographic_profiles",
                "demographic_profiles_for_user",
              ]),
              // Make sure the profile completion percentage updates too
              queryClient.invalidateQueries([
                "api",
                "usercrowd",
                "panelist",
                "stats",
              ]),
            ])

            onSave()
          }}
        >
          Submit
        </Button>

        {onSkip && <Button onClick={onSkip}>Skip</Button>}
      </Flex>
    </>
  )
}
