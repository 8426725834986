import { Icon, Link, LinkProps } from "@chakra-ui/react"
import { ExternalLinkIcon } from "@heroicons/react/solid"
import React from "react"

type ExternalLink = React.FC<React.PropsWithChildren<LinkProps>>

export const ExternalLink: ExternalLink = ({ children, ...props }) => {
  return (
    <Link isExternal {...props}>
      {children}
      <Icon as={ExternalLinkIcon} ml={0.5} />
    </Link>
  )
}
