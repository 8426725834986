import type { ComponentSingleStyleConfig } from "@chakra-ui/react"

export const Tabs: ComponentSingleStyleConfig = {
  defaultProps: {
    colorScheme: "brand.primary",
  },
  variants: {
    "soft-rounded": {
      tab: {
        borderRadius: "md",
      },
    },
  },
}
