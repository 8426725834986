import { Box, Radio, RadioGroup, Stack } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useEffect } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import * as Yup from "yup"

import { ThemedButton } from "Components/button/themed-button"

import { useScreenerQuestionAnswersContext } from "./AnsweredQuestionsContext"
import { QuestionText } from "./QuestionText"
import { ParticipantScreenerQuestion, ScreenerQuestionAnswer } from "./types"

const SingleSelectScreenerQuestionAnswer = Yup.object().shape({
  selected_screener_question_option_id: Yup.string().required(),
})

type SingleSelectScreenerQuestionAnswerValues = Yup.InferType<
  typeof SingleSelectScreenerQuestionAnswer
>

interface SingleSelectScreenerQuestionsAnswerFormProps {
  question: ParticipantScreenerQuestion
  defaultValue: string
  onSubmit: (values: ScreenerQuestionAnswer) => void
}

export function SingleSelectScreenerQuestionsAnswerForm({
  question,
  defaultValue,
  onSubmit: onExternalSubmit,
}: SingleSelectScreenerQuestionsAnswerFormProps) {
  const { goToNextQuestion, setIsInvalidAnswer } =
    useScreenerQuestionAnswersContext()

  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid },
  } = useForm<SingleSelectScreenerQuestionAnswerValues>({
    defaultValues: {
      selected_screener_question_option_id: defaultValue,
    },
    resolver: yupResolver(SingleSelectScreenerQuestionAnswer),
  })

  const onSubmit: SubmitHandler<SingleSelectScreenerQuestionAnswerValues> = (
    values
  ) => {
    onExternalSubmit({
      screener_question_id: question.id,
      type: "single_select",
      screener_question_options: [values.selected_screener_question_option_id],
    })
  }

  useEffect(() => {
    const subscription = watch(() => {
      void handleSubmit(onSubmit)()
    })

    return () => subscription.unsubscribe()
  })

  useEffect(() => {
    setIsInvalidAnswer(!isValid)
  }, [isValid])

  return (
    <div>
      <QuestionText>{question.text}</QuestionText>
      <Box mt={5}>
        <form
          onSubmit={handleSubmit((values) => {
            onSubmit(values)
            goToNextQuestion()
          })}
        >
          <Controller
            control={control}
            name="selected_screener_question_option_id"
            render={({ field }) => (
              <RadioGroup {...field}>
                <Stack direction="column">
                  {question.options?.map((questionOption) => (
                    <Radio key={questionOption.id} value={questionOption.id}>
                      {questionOption.value}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            )}
          />
          <ThemedButton mt={8} type="submit" isDisabled={!isValid}>
            Continue
          </ThemedButton>
        </form>
      </Box>
    </div>
  )
}
