if (!("performance" in window)) {
  ;(window as any).performance = {}
}

// thanks IE8
Date.now =
  Date.now ||
  function now() {
    new Date().getTime()
  }

if (!("now" in window.performance)) {
  let nowOffset = Date.now()
  if (
    (window.performance as any).timing &&
    (window.performance as any).timing.navigationStart
  ) {
    nowOffset = (window.performance as any).timing.navigationStart
  }
  ;(window.performance as any).now = function now() {
    return Date.now() - nowOffset
  }
}
