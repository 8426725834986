export const newUserPasswordPath = () => "/users/password/new"
export const userPasswordPath = () => "/users/password"
export const usersSignInPath = (options?: {
  queryParams?: { message: string }
}) => {
  const url = new URL("/users/sign_in", window.location.href)
  if (options?.queryParams?.message) {
    url.searchParams.set("message", options.queryParams.message)
  }
  return url.toString()
}
export const usersSamlSignInPath = () => "/users/saml_sign_in"
const newUserRegistrationPath = () => `/users/sign_up`
export const newUserRegistrationUrl = (
  host: string,
  queryParams?: Record<string, string>
) => {
  const url = new URL(`https://${host}${newUserRegistrationPath()}`)
  if (queryParams) {
    for (const [key, value] of Object.entries(queryParams)) {
      url.searchParams.set(key, value)
    }
  }
  return url.toString()
}
export const usersSignOutPath = () => "/users/sign_out"
export const panelistSignUpPath = (host: string) =>
  `https://${host}/testers/sign_up`
export const usersPath = () => "/users"

export const usabilityTestRecruitPath = (uniqueId: string) =>
  `/tests/${uniqueId}/recruit`
export const usabilityTestsEstimatePath = () => "/tests/estimate"

export const adminRevisePayoutOrders = (orderId: number) =>
  `/admin/payouts/orders/${orderId}/revise`
