import { Box, Center, Flex, Spinner, Text } from "@chakra-ui/react"
import React, { FC, useEffect, useMemo, useState } from "react"
import { useTypedParams } from "react-router-typesafe-routes/dom"

import { Page, PageMain } from "Shared/components/Page/Page"
import { TestBrandingType } from "Types"
import { TestBrandingContextProvider } from "UsabilityHub/components/UsabilityTest/context/testBranding"
import { ROUTES } from "UsabilityHub/views/routes"
import {
  useFindOrCreateModeratedStudyApplication,
  useGetModeratedStudyApplication,
} from "~/api/generated/usabilityhub-components"

import { ApplicationContentLayoutWrapper } from "./ApplicationContentLayout"

export const RootApplicationLayout: FC = () => {
  const { moderatedStudyRecruitmentLinkToken } = useTypedParams(
    ROUTES.INTERVIEW_APPLICATIONS.APPLY
  )
  const { moderatedStudyApplicationId } = useTypedParams(
    ROUTES.INTERVIEW_APPLICATIONS.APPLICATION
  )

  const [applicationId, setApplicationId] = useState<string>()

  const { data: moderatedStudyApplication } = useGetModeratedStudyApplication(
    {
      pathParams: {
        moderatedStudyApplicationId:
          applicationId ?? moderatedStudyApplicationId ?? "",
      },
    },
    {
      enabled: !!(applicationId ?? moderatedStudyApplicationId),
    }
  )

  const { mutate, error, isLoading } = useFindOrCreateModeratedStudyApplication(
    {
      onSuccess: (data) => {
        setApplicationId(data.moderated_study_application_id)
      },
    }
  )

  useEffect(() => {
    if (!moderatedStudyRecruitmentLinkToken || moderatedStudyApplicationId)
      return

    mutate({
      body: {
        token: moderatedStudyRecruitmentLinkToken,
      },
    })
  }, [mutate, moderatedStudyRecruitmentLinkToken, moderatedStudyApplicationId])

  const isPanelist = !!moderatedStudyApplication?.panelist
  const branding = useMemo(() => {
    // Panelists don't see the branding
    if (isPanelist) return null

    return {
      type: TestBrandingType.UsabilityHub,
      logo_url: "",
      button_color: moderatedStudyApplication?.brand_color ?? "",
    }
  }, [moderatedStudyApplication?.brand_color, isPanelist])

  const pageTitle = moderatedStudyApplication
    ? moderatedStudyApplication.moderated_study.external_name
    : "Loading..."

  return (
    <Page noBasicFlash title={pageTitle} includeTitlePrefix={false}>
      <PageMain direction={{ base: "column", lg: "row" }}>
        {error && (
          <Center maxH="100vh">
            <Box
              px={3}
              py={2}
              bg="white"
              borderWidth={1}
              borderColor="gray.200"
              rounded="sm"
              color="text.secondary"
            >
              <Text>{error.payload.message ?? "Something went wrong"}</Text>
            </Box>
          </Center>
        )}

        {!moderatedStudyApplicationId &&
          !moderatedStudyRecruitmentLinkToken && (
            <Flex
              flexBasis={1}
              flexGrow={1}
              justifyContent="center"
              align="center"
              bg="white"
            >
              <Text>Invalid token</Text>
            </Flex>
          )}

        {isLoading && (
          <Flex
            flexBasis={1}
            flexGrow={1}
            justifyContent="center"
            align="center"
            bg="white"
          >
            <Spinner />
          </Flex>
        )}

        {(moderatedStudyApplicationId || applicationId) && (
          <TestBrandingContextProvider branding={branding}>
            <ApplicationContentLayoutWrapper
              applicationId={moderatedStudyApplicationId ?? applicationId ?? ""}
            />
          </TestBrandingContextProvider>
        )}
      </PageMain>
    </Page>
  )
}
