import {
  Button,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
  useToast,
} from "@chakra-ui/react"
import React from "react"

import { RoutedLink } from "Shared/components/Links/RoutedLink"
import { ROUTES } from "UserCrowd/views/routes"
import { useDeletePanelistAccount } from "~/api/generated/usabilityhub-components"

type DeleteTesterAccountModal = React.FC<
  React.PropsWithChildren<
    Pick<React.ComponentProps<typeof Modal>, "isOpen" | "onClose">
  >
>

export const DeleteTesterAccountModal: DeleteTesterAccountModal = ({
  isOpen,
  onClose,
}) => {
  const toast = useToast()
  const { mutate: deleteAccount, isLoading: isDeletingAccount } =
    useDeletePanelistAccount({
      onSuccess: (data) => {
        toast({
          title: data.message,
          status: "success",
        })
        // Give a moment for the message to be noticed before redirecting to the marketing site
        setTimeout(() => {
          window.location.replace("https://www.usercrowd.com/")
        }, 4000)
      },
      onError: (error) => {
        if (error.status === 401) {
          // If you haven't recently authenticated this endpoint will return 401
          // which is handled by the global error handler, so no need to show a toast.
          return
        }

        toast({
          title: error.payload.message,
          status: "error",
        })
      },
    })

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay>
        <ModalContent borderRadius="md">
          <ModalHeader>Are you sure?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <UnorderedList>
              <ListItem>
                We can{"\u2019"}t restore it for you if you change your mind
                later
              </ListItem>
              <ListItem>
                You can{"\u2019"}t create a new account in the future
              </ListItem>
              <ListItem>
                You forfeit any credit you{"\u2019"}ve earned participating in
                studies
              </ListItem>
              <ListItem>
                Any open payout requests will be closed without payment.
              </ListItem>
            </UnorderedList>
            <br />

            <Text>
              If you{"\u2019"}d prefer to temporarily take a break from
              UserCrowd, consider{" "}
              <Link as={RoutedLink} to={ROUTES.SETTINGS.NOTIFICATIONS.path}>
                turning off your availability
              </Link>{" "}
              instead.
            </Text>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button onClick={onClose}>Cancel</Button>
            <Button
              onClick={() => {
                deleteAccount({})
              }}
              isDisabled={isDeletingAccount}
              colorScheme="red"
            >
              Permanently delete my account
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
