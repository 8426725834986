import { BoxProps, Flex } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import {
  useDemographicProfileForUser,
  useDemographicProfileIndex,
} from "~/api/generated/usabilityhub-components"
import { ProfileQuestionSection } from "./ProfileQuestionSection"
import { ProfileStrengthSection } from "./ProfileStrengthSection"
import { findUnansweredQuestions } from "./findUnansweredQuestions"

export const ProfileCompletionBanner: React.FC<BoxProps> = (boxProps) => {
  const [questionsSkipped, setQuestionsSkipped] = useState(0)
  const [locationSkipped, setLocationSkipped] = useState(false)
  const [hasJustFinished, setHasJustFinished] = useState(false)

  const {
    data: demographicData,
    isLoading: isDemographicsLoading,
    isError: isDemographicsError,
  } = useDemographicProfileIndex({})

  const {
    data: profileData,
    isLoading: isProfileLoading,
    isError: isProfileError,
  } = useDemographicProfileForUser({})

  const hasGranularLocation = profileData?.google_place_id !== null
  const unansweredQuestions =
    demographicData && profileData
      ? findUnansweredQuestions(demographicData, profileData)
      : []

  // Make sure we can't get into a situation where they've skipped everything and just see a blank screen
  // (this can happen if they skip the location question then answer all remaining profile questions)
  useEffect(() => {
    if (unansweredQuestions.length === 0) {
      setLocationSkipped(false)
      setQuestionsSkipped(0)
    }
  }, [unansweredQuestions.length])

  // Don't show loading UI to avoid things shifting around since most of the time this banner will not show.
  if (isDemographicsLoading || isProfileLoading) {
    return null
  }

  // If any of the API requests failed, don't show anything at all
  // (they'll retry the default number of times before failing so it shouldn't happen often)
  if (isDemographicsError || isProfileError) {
    return null
  }

  // Note that the profile page also shows things like age, but this component is just the
  // demographic attributes and location which we handle as a special case.

  if (
    !hasJustFinished &&
    hasGranularLocation &&
    unansweredQuestions.length === 0
  ) {
    return null
  }

  const nextQuestionIndex = questionsSkipped % unansweredQuestions.length
  const nextQuestion = unansweredQuestions.length
    ? unansweredQuestions[nextQuestionIndex]
    : null
  const totalRemainingItems =
    unansweredQuestions.length + (hasGranularLocation ? 0 : 1)

  const onSkip = (skippingLocation: boolean) => {
    if (skippingLocation) {
      setLocationSkipped(true)
    } else if (questionsSkipped === unansweredQuestions.length - 1) {
      // Loop back around to the beginning once they skip the last item
      setLocationSkipped(false)
      setQuestionsSkipped(0)
    } else {
      setQuestionsSkipped((n) => n + 1)
    }
  }

  const onNext = () => {
    // Check to see if we've completed the profile
    // This runs before the answers have refreshed, so we never get to zero questions remaining
    if (totalRemainingItems <= 1) {
      setHasJustFinished(true)
    }
  }

  return (
    <Flex
      direction="column"
      gap={4}
      bg="ds.surface.raised.resting"
      rounded="16px"
      shadow="ds.raised"
      p={4}
      {...boxProps}
    >
      <ProfileStrengthSection />

      <ProfileQuestionSection
        totalRemainingItems={totalRemainingItems}
        nextQuestion={nextQuestion}
        locationSkipped={locationSkipped}
        questionsSkipped={questionsSkipped}
        hasGranularLocation={hasGranularLocation}
        onNext={onNext}
        onSkip={onSkip}
      />
    </Flex>
  )
}
