import { Box, BoxProps } from "@chakra-ui/react"
import React from "react"
import { createPortal } from "react-dom"
import { useNavigationMenuContext } from "./Context"

type FooterProps = BoxProps

export const Footer: React.FC<FooterProps> = ({ children, ...props }) => {
  const { footer } = useNavigationMenuContext()

  if (!footer) return null

  return createPortal(
    <Box p={4} pointerEvents="auto" {...props}>
      {children}
    </Box>,
    footer
  )
}
