import { useGetActiveFeatureFlags } from "~/api/generated/usabilityhub-components"
import { FeatureFlag } from "~/api/generated/usabilityhubSchemas"

export const useFeatureFlagLoading = (featureFlagName: FeatureFlag) => {
  const { data, isLoading, isError } = useGetActiveFeatureFlags(
    {},
    { retry: false, cacheTime: Infinity, staleTime: Infinity }
  )

  if (isLoading || isError) {
    return { loading: true, enabled: undefined } as const
  }

  return {
    loading: false,
    enabled: data.feature_flags.includes(featureFlagName),
  } as const
}

/**
 * Get the value of a feature flag for the current user.
 *
 * Note that feature flags must be in the snake_case format used in `lib/lyssna/feature_flag.rb`.
 */
export const useFeatureFlag = (featureFlagName: FeatureFlag): boolean => {
  const { loading, enabled } = useFeatureFlagLoading(featureFlagName)

  return loading ? false : enabled
}
