import {
  BoxProps,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Stack,
  Switch,
} from "@chakra-ui/react"
import { newUserPasswordPath, usersSignInPath } from "Shared/constants/routes"
import React, { ChangeEvent, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { Link as ReactRouterLink } from "react-router-dom"

import { RailsForm } from "Components/form/form"
import { getApplicationState } from "JavaScripts/state"

type SignInFormProps = BoxProps & {
  rememberMeControl?: typeof Switch | typeof Checkbox
  recaptchaAlign?: BoxProps["justifyContent"]
}

export const SignInForm: React.FC<SignInFormProps> = ({
  recaptchaAlign = "center",
  rememberMeControl: RememberMeControl = Switch,
  children,
  ...props
}) => {
  const { recaptchaSiteKey } = getApplicationState()
  const [captchaCompleted, setCaptchaCompleted] = useState(false)
  const [email, setEmail] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [password, setPassword] = useState("")
  const [rememberMe, setRememberMe] = useState(true)

  const isSubmitDisabled =
    email === "" ||
    password === "" ||
    (recaptchaSiteKey !== null && !captchaCompleted)

  const recaptchaRef = React.createRef<ReCAPTCHA>()
  const resetRecaptchaResponse = () => {
    recaptchaRef.current?.reset()
  }

  return (
    <Flex
      flexDirection="column"
      bg="white"
      color="text.primary"
      border="1px"
      borderColor="gray.200"
      borderRadius="lg"
      p={6}
      gap={6}
      {...props}
    >
      <RailsForm
        action={usersSignInPath()}
        autoComplete="off"
        id="signInForm"
        method="POST"
        onSubmit={() => setIsSubmitting(true)}
      >
        <Stack spacing={4}>
          <FormControl isRequired>
            <FormLabel htmlFor="email" fontWeight="medium" fontSize="md">
              Email address
            </FormLabel>
            <Input
              autoFocus
              id="email"
              isRequired
              name="user[email]"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setEmail(event.target.value)
              }
              type="email"
              value={email}
              // biome-ignore lint/a11y/noPositiveTabindex: required for form
              tabIndex={1}
            />
          </FormControl>
          <FormControl isRequired>
            <Flex justify="space-between" align="baseline">
              <FormLabel htmlFor="password" fontWeight="medium" fontSize="md">
                Password
              </FormLabel>
              <Link
                textStyle="label"
                as={ReactRouterLink}
                to={newUserPasswordPath()}
              >
                Forgot your password?
              </Link>
            </Flex>
            <Input
              id="password"
              isRequired
              name="user[password]"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setPassword(event.target.value)
              }
              type="password"
              value={password}
              // biome-ignore lint/a11y/noPositiveTabindex: required for form
              tabIndex={1}
            />
          </FormControl>
          <FormControl>
            <Flex align="first-baseline">
              <RememberMeControl
                id="remember_me"
                isChecked={rememberMe}
                mr={3}
                name="user[remember_me]"
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setRememberMe(event.target.checked)
                }
              />
              <FormLabel mb={0} htmlFor="user[remember_me]" fontWeight={500}>
                Remember me
              </FormLabel>
            </Flex>
          </FormControl>
          {recaptchaSiteKey !== null && (
            <FormControl mt={4}>
              <Flex basis="full" justify={recaptchaAlign}>
                <ReCAPTCHA
                  onErrored={() => resetRecaptchaResponse}
                  onExpired={() => resetRecaptchaResponse}
                  onChange={(token) => setCaptchaCompleted(token !== null)}
                  sitekey={recaptchaSiteKey}
                />
              </Flex>
            </FormControl>
          )}
          <Button
            isDisabled={isSubmitDisabled}
            isLoading={isSubmitting}
            loadingText="Signing in"
            type="submit"
            colorScheme="brand.primary"
            width="full"
            mt={4}
          >
            Sign in
          </Button>
        </Stack>
      </RailsForm>
      {children}
    </Flex>
  )
}
