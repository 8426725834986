import {
  PreloadedState,
  Reducer,
  Store,
  applyMiddleware,
  compose,
  createStore,
} from "redux"
import thunk from "redux-thunk"

// -- Hook into redux devtools --

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof compose
  }
}

let composeEnhancers = compose
if (process.env.DEBUG) {
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  }
}

export default function createUhStore<S>(
  reducer: Reducer<S>,
  initialState: PreloadedState<S>
): Store<S> {
  return createStore(
    reducer,
    // Disable types with `as {}` to prevent "Excessive stack depth comparing
    // types 'S' and 'DeepPartial<S>'."
    initialState,
    composeEnhancers(applyMiddleware(thunk))
  )
}
