import React from "react"

import { Page, PageContent, PageMain } from "Shared/components/Page/Page"
import { SignInForm } from "Shared/forms/SignInForm"
import { getParsedQueryString } from "Utilities/query-string"

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
  Link,
} from "@chakra-ui/react"
import { Heading } from "DesignSystem/components"
import { newUserRegistrationUrl } from "Shared/constants/routes"
import { UserCrowdNavbar } from "UserCrowd/components/UserCrowdNavbar/UserCrowdNavbar"
import { Link as ReactRouterLink } from "react-router-dom"
import { ROUTES } from "~/usercrowd/views/routes"

export const SignInRoute: React.FC = () => {
  const query = getParsedQueryString()
  const wasRedirectedFromUsabilityHub = query.from_usabilityhub === "1"
  return (
    <Page title="Sign in">
      <Box pt={16}>
        <UserCrowdNavbar variant="blank" />
        {wasRedirectedFromUsabilityHub && <RedirectedFromUsabilityHubAlert />}
        <PageMain>
          <PageContent display="flex" justifyContent="center">
            <Flex flexDirection="column" gap={4} maxW="28rem" w="full">
              <Heading
                as="h1"
                textStyle="ds.display.emphasized"
                my={6}
                textAlign="center"
              >
                Sign in to continue
              </Heading>
              <SignInForm />
              <Flex justify="center" mt={3} align="baseline">
                Don{"\u2019"}t have an account?
                <Link
                  textAlign="center"
                  as={ReactRouterLink}
                  ml={1}
                  to={ROUTES.USERS.SIGN_UP.path}
                >
                  Sign up
                </Link>
              </Flex>
            </Flex>
          </PageContent>
        </PageMain>
      </Box>
    </Page>
  )
}

const RedirectedFromUsabilityHubAlert = () => {
  const usabilityHubSignUpUrl = newUserRegistrationUrl(window.location.hostname)
  return (
    <Alert status="error" justifyContent="center">
      <AlertIcon />
      <AlertTitle mr={2}>Panelist accounts cannot log in to Lyssna.</AlertTitle>
      <AlertDescription>
        You need to{" "}
        <a href={usabilityHubSignUpUrl}>
          sign up for a separate customer account
        </a>{" "}
        to access Lyssna. If you intended to log in to UserCrowd, please log in
        again below.
      </AlertDescription>
    </Alert>
  )
}
