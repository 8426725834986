import {
  AlertStatus,
  ToastId,
  UseToastOptions,
  useToast,
} from "@chakra-ui/react"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { IMPORTANT_DURATION } from "Constants/flash"
import { State } from "Redux/app-store"
import { hideFlash } from "Redux/reducers/flash"
import { Flash, FlashType } from "Types"

const FLASHTYPE_TO_STATUS: Record<FlashType, AlertStatus> = {
  [FlashType.Info]: "info",
  [FlashType.Error]: "error",
  [FlashType.Notice]: "success",
  [FlashType.Alert]: "warning",
}

const toToastOptions = (flash: Flash): UseToastOptions => {
  const [title, ...description] = React.Children.toArray(flash.children)
  return {
    status: FLASHTYPE_TO_STATUS[flash.kind],
    title,
    description,
    duration: flash.duration === IMPORTANT_DURATION ? null : flash.duration,
    // even though these should inherit chakra's defaults, they don't, so don't remove these
    position: "top-right",
    isClosable: true,
  }
}

export const BasicFlash: React.FC = () => {
  const dispatch = useDispatch()
  const flash = useSelector((state: State) => state.flash)
  const toast = useToast()
  const activeToastId = React.useRef<ToastId | null>(null)

  useEffect(() => {
    if (flash) {
      activeToastId.current = toast({
        ...toToastOptions(flash),
        onCloseComplete: () => dispatch(hideFlash(flash.id)),
      })
    }

    return () => {
      if (activeToastId.current !== null) {
        toast.close(activeToastId.current)
      }
    }
  }, [flash, dispatch, toast])
  return null
}
