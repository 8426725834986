import {
  Box,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Link,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { JsForm } from "Components/form/form"
import { UhPhoneInput, prependPlus } from "Components/form/phone-input"
import { TextInput } from "Components/form/text-input/text-input"
import { Alert, Button } from "DesignSystem/components"
import React, { ChangeEvent, useState } from "react"
import "react-phone-number-input/style.css"
import { stripWhitespace } from "Utilities/string"
import { isBlank } from "Utilities/values"
import {
  useCurrentPanelistInfo,
  usePhoneVerificationRequestCode,
  usePhoneVerificationVerify,
} from "~/api/generated/usabilityhub-components"

const VerificationCodeLength = 4

type Props = {
  initialPhoneNumber: string | null
}
const TestersPhoneFormImpl: React.FC<Props> = ({ initialPhoneNumber }) => {
  const toast = useToast()
  const queryClient = useQueryClient()

  const [phone, setPhone] = useState(initialPhoneNumber ?? "")
  const [code, setCode] = useState("")
  const [hasCodeBeenSent, setHasCodeBeenSent] = useState(false)

  const { mutate: requestCode, isLoading: isRequestingCode } =
    usePhoneVerificationRequestCode({
      onSuccess: () => {
        toast({ status: "success", title: "Verification code sent" })
        setHasCodeBeenSent(true)
      },
      onError: (error) => {
        toast({
          status: "error",
          title: error.payload.message ?? "Sending verification code failed",
        })
      },
    })

  const { mutate: verifyCode, isLoading: isSubmittingCode } =
    usePhoneVerificationVerify({
      onSuccess: () => {
        queryClient.invalidateQueries(["api", "usercrowd", "panelist", "me"], {
          exact: true,
        })
        toast({ status: "success", title: "Phone number verified" })
      },
      onError: (error) => {
        toast({
          status: "error",
          title: error.payload.message ?? "Something went wrong",
        })
      },
    })

  // `phone` is sometimes `undefined`.
  const handlePhoneChange = (phone = "") => {
    setPhone(phone)
    setHasCodeBeenSent(false)
  }

  const handleRequestCode = () => {
    requestCode({
      body: {
        phone_number: phone,
      },
    })
  }

  const handleCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCode(stripWhitespace(event.target.value))
  }

  const handleSubmitCode = () => {
    verifyCode({
      body: {
        phone_number: phone,
        code: code,
      },
    })
  }

  return (
    <Flex
      direction="column"
      w="full"
      maxW="500px"
      rounded="16px"
      bg="ds.surface.raised.resting"
      boxShadow="ds.raised"
      p={6}
    >
      <JsForm onSubmit={handleRequestCode}>
        <Flex flexDirection="column" gap={4} align="flex-start">
          <FormControl>
            <FormLabel htmlFor="phone-number">
              <Text color="ds.text.default" textStyle="ds.heading.primary">
                Your mobile phone number
              </Text>
            </FormLabel>

            <FormHelperText
              color="ds.text.default"
              textStyle="ds.paragraph.primary"
              mb={3}
            >
              Enter your phone number including the country code. A VoIP number
              is not permitted.
            </FormHelperText>

            <UhPhoneInput
              id="phone-number"
              w="full"
              onChange={handlePhoneChange}
              placeholder="Enter your mobile phone number"
              value={prependPlus(phone)}
            />
          </FormControl>

          <Button
            type="submit"
            variant="primary"
            size="emphasized"
            w="full"
            isDisabled={isBlank(phone) || hasCodeBeenSent}
            isLoading={isRequestingCode}
          >
            Send verification code SMS
          </Button>
        </Flex>
      </JsForm>

      {hasCodeBeenSent && (
        <JsForm onSubmit={handleSubmitCode}>
          <Box my={4}>
            <Alert
              status="info"
              description={
                <>
                  You should receive an SMS containing a verification code. If
                  you do not please check your number or{" "}
                  <Link variant="link" onClick={handleRequestCode}>
                    click here
                  </Link>{" "}
                  to resend.
                </>
              }
            />
          </Box>
          <FormLabel htmlFor="code">
            <Text color="ds.text.default" textStyle="ds.heading.primary">
              Enter your {VerificationCodeLength}-digit code
            </Text>
          </FormLabel>

          <Flex gap={2}>
            <TextInput
              id="code"
              minLength={VerificationCodeLength}
              maxLength={VerificationCodeLength}
              width={VerificationCodeLength + 6 + "rem"}
              fontSize="xl"
              textAlign="center"
              onChange={handleCodeChange}
              value={code}
            />
            <Button
              variant="primary"
              size="emphasized"
              type="submit"
              isLoading={isSubmittingCode}
            >
              Verify
            </Button>
          </Flex>
        </JsForm>
      )}
    </Flex>
  )
}

export const TestersPhoneForm: React.FC = () => {
  const { data: currentPanelist } = useCurrentPanelistInfo({})

  if (!currentPanelist) return <Spinner />

  return (
    <TestersPhoneFormImpl initialPhoneNumber={currentPanelist.phone_number} />
  )
}
