import { merge } from "lodash"
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from "react"
import {
  GroupSelectionState,
  SelectItem,
  SelectPath,
  SelectSize,
  SelectValue,
} from "./types"
import { defaultFormatValue } from "./util"

export type SelectContextType<T extends SelectValue> = {
  everything: Map<SelectPath, SelectItem<T>[]>
  path: SelectPath
  isLazy: boolean
  isOpen: boolean
  isMulti: boolean
  isSearchable: boolean
  size?: SelectSize
  selected: Set<T>
  setSelected: (selected: Set<T>) => void
  isSelected: (value: T) => boolean
  groupPath: SelectPath
  trigger: HTMLElement | null
  input: HTMLInputElement | null
  container: HTMLDivElement | null
  popup: HTMLElement | null
  activeItem: SelectItem<T> | null
  isSearching: boolean
  searchResults: SelectItem<T>[]
  toggle: (value: T) => void
  register: (item: SelectItem<T>, path: SelectPath) => void
  deregister: (id: string, path: SelectPath) => void
  toggleGroup: (path: SelectPath) => void
  open: () => void
  close: () => void
  cancelClose: () => void
  setActiveItemId: (id: string) => void
  formatValue: (value: T) => string
  setTrigger: (trigger: HTMLElement | null) => void
  setInput: (input: HTMLInputElement | null) => void
  setPopup: (popup: HTMLElement | null) => void
  onPathChange?: (path: SelectPath) => void
  getValueById: (id: string) => T
  groupSelectionState: (path: SelectPath) => GroupSelectionState
}

export const SelectContext = createContext<SelectContextType<any>>({
  everything: new Map(),
  isLazy: true,
  isOpen: true,
  isMulti: false,
  isSearchable: false,
  size: "default",
  selected: new Set(),
  setSelected: () => {},
  isSelected: () => false,
  path: "",
  groupPath: "",
  container: null,
  trigger: null,
  input: null,
  popup: null,
  activeItem: null,
  isSearching: false,
  searchResults: [],
  setTrigger: () => {},
  setInput: () => {},
  setPopup: () => {},
  onPathChange: () => {},
  toggle: () => {},
  register: () => {},
  deregister: () => {},
  toggleGroup: () => {},
  formatValue: defaultFormatValue,
  open: () => {},
  close: () => {},
  cancelClose: () => {},
  setActiveItemId: () => {},
  getValueById: () => {
    throw new Error("Not implemented")
  },
  groupSelectionState: () => "none",
})

export const Provider = <T extends SelectValue>({
  children,
  ...props
}: PropsWithChildren<Partial<SelectContextType<T>>>) => {
  const parentContext = useContext(SelectContext)
  const context = useMemo<SelectContextType<T>>(
    () => merge({}, parentContext, props),
    [parentContext, props]
  )
  return (
    <SelectContext.Provider value={context}>{children}</SelectContext.Provider>
  )
}

export const useSelect = <T extends SelectValue>() =>
  useContext(SelectContext) as SelectContextType<T>

Provider.displayName = "Select.Provider"
