import { Box, BoxProps, HStack, Icon, Text, useId } from "@chakra-ui/react"
import { ChevronRightOutlineIcon } from "Shared/icons/untitled-ui/ChevronRightOutlineIcon"
import React, { forwardRef, useEffect } from "react"
import { CheckboxIcon } from "../Checkbox"
import { useSelect } from "./Provider"
import { SelectPath, SelectValue } from "./types"

type BaseOptionProps<T extends SelectValue> = {
  id?: string
  isMulti?: boolean
  isSelected?: boolean
  isIndeterminate?: boolean
  isDisabled?: boolean
  isActive?: boolean
  isFocused?: boolean
  isGroup?: boolean
  onCheckedChange?: (isChecked: boolean) => void
} & (
  | { isGroup?: false; value: T; onSelect?: (value: T) => void }
  | {
      isGroup: true
      value: SelectPath
      onSelect?: (path: SelectPath) => void
    }
)

type OptionProps<T extends SelectValue> = Omit<
  BoxProps,
  keyof BaseOptionProps<T> | "onSelect"
> &
  BaseOptionProps<T>

export const Option = forwardRef(
  <T extends SelectValue>(
    {
      id,
      value,
      isMulti = false,
      isSelected = false,
      isIndeterminate = false,
      isDisabled = false,
      isActive = false,
      isFocused = false,
      isGroup = false,
      sx = {},
      onSelect,
      onCheckedChange,
      children,
      ...props
    }: OptionProps<T>,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <HStack
        ref={ref}
        id={id}
        role="option"
        aria-selected={isSelected}
        aria-disabled={isDisabled}
        aria-checked={
          isMulti
            ? isIndeterminate
              ? "mixed"
              : isSelected
                ? "true"
                : "false"
            : undefined
        }
        data-selected={isIndeterminate ? "some" : isSelected ? "true" : "false"}
        align="start"
        gap={1}
        py={1}
        pe={1}
        ps="calc(var(--chakra-space-3) - 0.125rem)"
        borderLeft="0.125rem solid"
        borderColor={
          (isSelected || isIndeterminate) && !isFocused
            ? "ds.border.selected"
            : "transparent"
        }
        layerStyle={isFocused && !isDisabled ? "focused" : undefined}
        outlineOffset="-4px"
        userSelect="none"
        bg={
          isDisabled
            ? "transparent"
            : isActive
              ? "ds.background.neutral.subtle.hovered"
              : "ds.background.neutral.subtle.resting"
        }
        sx={{
          "&:not([aria-disabled='true'])": {
            cursor: "pointer",
            _hover: {
              bg: "ds.background.neutral.subtle.hovered",
            },
            _active: {
              bg: "ds.background.neutral.pressed",
            },
          },
          "& [data-checkbox] *, & [data-checkbox] :has(:active) *": {
            outline: "none",
          },
          ...sx,
        }}
        onClick={() => {
          if (!onSelect) return
          if (isGroup) {
            ;(onSelect as (path: SelectPath) => void)(value as SelectPath)
          } else {
            ;(onSelect as (value: T) => void)(value as T)
          }
        }}
        {...props}
      >
        {isMulti && (
          <Box
            boxSize={6}
            flexShrink={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CheckboxIcon
              isChecked={isSelected}
              isIndeterminate={isIndeterminate}
              isDisabled={isDisabled}
              onClick={(e) => {
                if (onCheckedChange) {
                  e.stopPropagation()
                  onCheckedChange(!isSelected || isIndeterminate)
                }
              }}
            />
          </Box>
        )}
        <Text
          as="div"
          py="0.125rem"
          textStyle="ds.paragraph.primary"
          color={isDisabled ? "ds.text.disabled" : "ds.text.default"}
          flex={1}
        >
          {children}
        </Text>
        {isGroup && (
          <Icon
            as={ChevronRightOutlineIcon}
            boxSize={4}
            m={1}
            color="ds.icon.subtle"
          />
        )}
      </HStack>
    )
  }
)

export const SelectOption = forwardRef(
  <T extends SelectValue>(
    { id: idProp, ...props }: OptionProps<T>,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const id = useId(idProp, "ds-select-option")

    const {
      isMulti,
      toggle,
      groupPath,
      register,
      deregister,
      activeItem,
      setActiveItemId,
      isSelected,
    } = useSelect()

    useEffect(() => {
      register(
        {
          id,
          type: "option",
          value: props.value,
          path: groupPath,
          isDisabled: props.isDisabled,
        },
        groupPath
      )
      return () => {
        deregister(id, groupPath)
      }
    }, [id, props.value, props.isDisabled, groupPath, register, deregister])

    const isActive = activeItem?.id === id

    return (
      <Option
        ref={ref}
        id={id}
        isMulti={isMulti}
        isSelected={isSelected(props.value)}
        isActive={isActive}
        onSelect={() => {
          if (!props.isDisabled) {
            toggle(props.value)
          }
        }}
        onPointerEnter={() => {
          if (!props.isDisabled) {
            setActiveItemId(id)
          }
        }}
        {...props}
      />
    )
  }
)

SelectOption.displayName = "Select.Option"
