import { Box } from "@chakra-ui/react"
import React from "react"

export const Separator = () => (
  <Box
    as="hr"
    border="1px solid"
    borderColor="ds.border.default"
    borderWidth="1px 0 0"
    my={2}
  />
)

Separator.displayName = "Select.Separator"
