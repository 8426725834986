import { Flex, Image } from "@chakra-ui/react"
import { Text } from "@chakra-ui/react"
import { Heading } from "DesignSystem/components"
import React from "react"
import userCrowdSplashImage from "./usercrowd-splash-image.png"

export const SignUpSplash: React.FC = () => {
  return (
    <Flex h="full" direction="column" gap={10} justify="center" align="center">
      <Image src={userCrowdSplashImage} maxW="300px" />

      <Flex direction="column" gap={4} align="center" maxW="500px">
        <Heading
          as="h2"
          textStyle="ds.display.emphasized"
          color="ds.text.default"
          textAlign="center"
        >
          Get paid to give your feedback
        </Heading>

        <Text
          textStyle="ds.paragraph.emphasized"
          color="ds.text.default"
          textAlign="center"
        >
          Join our research panel and participate in quick surveys, tests, or
          interviews. There{"\u2019"}s no limit to how much you can earn by
          sharing your thoughts on designs, messaging, prototypes, and more.
        </Text>
      </Flex>
    </Flex>
  )
}
