import { Box, Center, Heading, Spinner, Text, useToast } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { useParticipantDeclineModeratedStudyBookingRescheduleRequest } from "~/api/generated/usabilityhub-components"
import { ModeratedStudyApplicationFlow } from "~/api/generated/usabilityhubResponses"
import { useModeratedStudyApplicationContext } from "./ModeratedStudyApplicationContext"

type Booking = ModeratedStudyApplicationFlow["booking"]

export const DeclineResearcherReschedulePage = () => {
  const toast = useToast()

  const { application, invalidateApplicationQuery } =
    useModeratedStudyApplicationContext()

  const { mutate, isLoading } =
    useParticipantDeclineModeratedStudyBookingRescheduleRequest({
      onSuccess: () => {
        invalidateApplicationQuery()

        toast({
          title: "Session declined",
          status: "success",
        })
      },
      onError: () => {
        toast({
          title: "Could not decline the reschedule request",
          description: "The link you used may be invalid or expired.",
          status: "error",
        })
      },
    })

  const [firstPageLoad, setFirstPageLoad] = useState(true)
  const booking = application.booking

  const isNotDeclined = (booking: NonNullable<Booking>): boolean =>
    booking.state === "rescheduled_by_researcher" &&
    !booking.declined_reschedule_request

  useEffect(() => {
    if (booking && isNotDeclined(booking)) {
      mutate({
        pathParams: {
          moderatedStudyApplicationId:
            application.moderated_study_application_id,
        },
        body: { moderated_study_booking_id: booking.id },
      })
    }

    setFirstPageLoad(false)
  }, [booking])

  return isLoading || firstPageLoad ? (
    <Center h="100vh">
      <Spinner />
    </Center>
  ) : (
    <Box display="flex" maxW="500px" flexDirection="column" gap="4">
      {booking === null ? (
        <>
          <Heading fontSize="2xl" fontWeight="400">
            Error
          </Heading>
          <Text>Booking not found</Text>
        </>
      ) : isNotDeclined(booking) ? (
        <>
          <Heading fontSize="2xl" fontWeight="400">
            Error
          </Heading>
          <Text>Something went wrong declining your session</Text>
        </>
      ) : (
        <>
          <Heading fontSize="2xl" fontWeight="400">
            Session declined
          </Heading>
          <Text>Your session is canceled.</Text>
        </>
      )}
    </Box>
  )
}
