import { ChakraProvider as RawProvider } from "@chakra-ui/provider"
import { ChakraProvider } from "@chakra-ui/react"
import {
  Mutation,
  MutationCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { theme } from "Shared/theme"
import toastDefaults from "Shared/theme/components/toasts"
import { UsabilityHubProvider } from "UsabilityHub/UsabilityHubProvider"
import { SelectPortalProvider } from "UsabilityHub/contexts/select-portal"
import { UserCrowdProvider } from "UserCrowd/UserCrowdProvider"
import { ReauthenticationModal } from "Utilities/modals/ReauthenticationModal"
import { MotionConfig } from "framer-motion"
import React, { ComponentType, useState } from "react"
import { createRoot } from "react-dom/client"
import { Provider as ReduxProvider } from "react-redux"
import { Store } from "redux"
import * as Yup from "yup"

export function mountReact<P extends JSX.IntrinsicAttributes>(
  Root: ComponentType<React.PropsWithChildren<P>>,
  store: Store<any> | null,
  props: P,
  rootContainer: HTMLElement,
  AppProvider: UsabilityHubProvider | UserCrowdProvider,
  isSPA: boolean,
  isFirst = true
) {
  // Workaround for multiple toast being shown at the same time, due to multiple toast provider being rendered
  // The ChakraProvider should only be rendered once as it contains the toast provider, the RawProvider does not.
  // GH issue: https://github.com/chakra-ui/chakra-ui/issues/6328
  let Provider: typeof ChakraProvider
  if (isFirst) {
    Provider = ChakraProvider
  } else {
    Provider = RawProvider as typeof ChakraProvider
  }

  const root = createRoot(rootContainer)

  root.render(
    <AppProvider isSPA={isSPA}>
      <MotionConfig reducedMotion="user">
        <Provider theme={theme} toastOptions={toastDefaults}>
          {store ? (
            <ReduxProvider store={store}>
              <QueryRoot>
                <SelectPortalProvider>
                  <Root {...props} />
                </SelectPortalProvider>
              </QueryRoot>
            </ReduxProvider>
          ) : (
            <QueryRoot>
              <SelectPortalProvider>
                <Root {...props} />
              </SelectPortalProvider>
            </QueryRoot>
          )}
        </Provider>
      </MotionConfig>
    </AppProvider>
  )
}

type AuthenticatableAction = {
  isDestructive: boolean
  actionToConfirm: string | null
  mutation: Mutation<unknown, unknown, unknown, unknown>
}

const AuthSchema = Yup.object({
  status: Yup.number().required().oneOf([401]),
  payload: Yup.object({
    is_destructive: Yup.boolean().defined(),
    action: Yup.string().required(),
  }),
})

const QueryRoot: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [authenticatableAction, setAuthenticatableAction] =
    useState<AuthenticatableAction | null>(null)

  const mutationCache = new MutationCache({
    onError: async (error, _variables, _context, mutation) => {
      try {
        const validatedError = await AuthSchema.validate(error)

        setAuthenticatableAction({
          isDestructive: validatedError.payload.is_destructive,
          actionToConfirm: validatedError.payload.action,
          mutation,
        })
      } catch (e) {
        if (!(e instanceof Yup.ValidationError)) {
          // Not a valid authentication error, rethrow
          throw e
        }
      }
    },
  })

  const [queryClient] = useState(
    () =>
      new QueryClient({
        mutationCache,
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
        // Overriding the logger is deprecated but when they remove it they will also stop logging
        // mutation failures to the console, so we can remove this code at that point.
        // The reason we want to handle the errors here is to stop expected mutation failures (like a 401
        // when reauthentication is needed) from breaking `assert_no_js_errors` in tests.
        // (Logging is only active in development, so this won't affect production)
        logger: {
          log: (...args) => {
            // biome-ignore lint/suspicious/noConsoleLog: preserve react-query v4 `log` output method
            console.log(args)
          },
          warn: (...args) => {
            console.warn(args)
          },
          error: (...args) => {
            console.warn(args)
          },
        },
      })
  )

  return (
    <QueryClientProvider client={queryClient}>
      {authenticatableAction !== null && (
        <ReauthenticationModal
          onClose={() => setAuthenticatableAction(null)}
          mutation={authenticatableAction.mutation}
          isDestructive={authenticatableAction.isDestructive}
          actionToConfirm={authenticatableAction.actionToConfirm}
        />
      )}
      <ReactQueryDevtools initialIsOpen={false} />
      {children}
    </QueryClientProvider>
  )
}
