import { HStack, Icon, Text } from "@chakra-ui/react"
import { AlertCircleSolidIcon } from "Shared/icons/untitled-ui/AlertCircleSolidIcon"
import React, { PropsWithChildren } from "react"

type ErrorProps = PropsWithChildren<{
  icon?: React.ElementType
  severity?: "danger" | "warning"
}>

export const ErrorMessage: React.FC<ErrorProps> = ({
  icon = AlertCircleSolidIcon,
  severity = "danger",
  children,
}) => {
  return (
    <HStack align="start" lineHeight="1.25rem" color={`ds.text.${severity}`}>
      <Icon as={icon} boxSize={4} my="0.125rem" color={`ds.icon.${severity}`} />
      <Text textStyle="ds.paragraph.primary">{children}</Text>
    </HStack>
  )
}
