import { Flex, Spinner, Text, useToast } from "@chakra-ui/react"
import chimeAudioPath from "Audio/chime.mp3"
import { getEnvState } from "JavaScripts/state"
import { playAudioPath } from "Utilities/audio"
import { pluralizeWithCount } from "Utilities/string"
import { difference } from "lodash"
import React, { useEffect, useState } from "react"
import {
  useCurrentPanelistInfo,
  useGetPanelistSettings,
  useListUsercrowdOrderAssignments,
} from "~/api/generated/usabilityhub-components"

import { Button, Heading } from "DesignSystem/components"
import { usePushNotifications } from "Shared/hooks/usePushNotifications"
import { EmptyListPlaceholder } from "UserCrowd/components/EmptyListPlaceholder"
import { PracticeTestCard } from "UserCrowd/views/dashboard/PracticeTestCard"
import { ROUTES } from "UserCrowd/views/routes"
import { Link as ReactRouterLink } from "react-router-dom"
import { ModeratedStudyCard } from "./ModeratedStudyCard"
import { UsabilityTestCard } from "./UsabilityTestCard"

export const AssignmentsHeading = () => (
  <Heading as="h3" textStyle="ds.display.primary">
    Available studies
  </Heading>
)

export const AssignmentList: React.FC = () => {
  const toast = useToast()
  const { PANELIST_ORDER_ASSIGNMENT_POLL_INTERVAL_IN_SECONDS: pollTime } =
    getEnvState()

  const { data: assignments, isLoading: isLoadingAssignments } =
    useListUsercrowdOrderAssignments(
      {},
      {
        refetchInterval: pollTime * 1000,
      }
    )
  const [previousAssignments, setPreviousAssignments] = useState<
    string[] | null
  >(null)

  const { data: user } = useCurrentPanelistInfo(
    {},
    { cacheTime: Infinity, staleTime: Infinity }
  )
  const { data: settings } = useGetPanelistSettings({})

  const practiceTestRequired = user?.practice_test_state === "not_started"
  const isEmailNotificationEnabled =
    // We want to default to true before this has loaded so users don't incorrectly
    // get a flash of the empty state telling them to enable notifications
    settings?.notify_assignments_by_email ?? true

  const { isEnabled: isPushNotificationEnabled } = usePushNotifications()

  const showEnableNotificationsMessage =
    !isPushNotificationEnabled && !isEmailNotificationEnabled

  // Fire off an alert whenever new tests come in
  useEffect(() => {
    if (!assignments) return

    const assignmentIds = assignments.panelist_order_assignments
      .map((a) => a.id.toString())
      .concat(assignments.moderated_study_order_assignments.map((a) => a.id))

    const newAssignments = difference(assignmentIds, previousAssignments ?? [])

    if (newAssignments.length) setPreviousAssignments(assignmentIds)

    if (newAssignments.length > 0 && previousAssignments) {
      toast({
        status: "success",
        title: `You have ${pluralizeWithCount(
          newAssignments.length,
          "new assignment",
          "new assignments"
        )}`,
      })

      void playAudioPath(chimeAudioPath)
    }
  }, [assignments, previousAssignments, toast])

  const noAssignments =
    !practiceTestRequired &&
    (!assignments ||
      (assignments.panelist_order_assignments.length === 0 &&
        assignments.moderated_study_order_assignments.length === 0))

  if (isLoadingAssignments) {
    return <LoadingState />
  }

  if (noAssignments && showEnableNotificationsMessage) {
    return <EmptyListWithEnableNotificationsMessage />
  }

  if (noAssignments) {
    return <EmptyList />
  }

  return (
    <Flex direction="column" gap={4}>
      {practiceTestRequired && <PracticeTestCard />}

      {assignments?.moderated_study_order_assignments.map((assignment) => (
        <ModeratedStudyCard key={assignment.id} assignment={assignment} />
      ))}

      {assignments?.panelist_order_assignments.map((assignment) => (
        <UsabilityTestCard key={assignment.id} assignment={assignment} />
      ))}
    </Flex>
  )
}

const EmptyList = () => (
  <EmptyListPlaceholder heading="No available studies">
    <Text textStyle="ds.paragraph.primary" textAlign="center">
      You{"\u2019"}ll be notified as soon as a study becomes available.
    </Text>
  </EmptyListPlaceholder>
)

const EmptyListWithEnableNotificationsMessage = () => (
  <EmptyListPlaceholder heading="No available studies">
    <Flex flexDirection="column" alignItems="center" gap={6}>
      <Text textStyle="ds.paragraph.primary" textAlign="center">
        Enable notifications to be notified when a study becomes available.
      </Text>
      <Button
        as={ReactRouterLink}
        to={ROUTES.SETTINGS.NOTIFICATIONS.path}
        variant="primary"
      >
        Notification settings
      </Button>
    </Flex>
  </EmptyListPlaceholder>
)

const LoadingState = () => (
  <Flex alignItems="center" justifyContent="center" minH="5rem">
    <Spinner size="xl" />
  </Flex>
)
