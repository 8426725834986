import {
  Tag as ChakraTag,
  TagCloseButton as ChakraTagCloseButton,
  TagLeftIcon as ChakraTagLeftIcon,
  IconProps,
  forwardRef,
} from "@chakra-ui/react"
import { assertNever } from "Components/filter-controls/utils"
import { tokens } from "DesignSystem/tokens"
import React, { ComponentProps, Ref, SVGProps } from "react"

// Instead of a style config in TagDS.ts, we have all our theming in this component.
// theme.components.tagDS does not need to exist if we use this approach.

export const TAG_VARIANTS = ["subtle", "bold"] as const

type TagVariant = (typeof TAG_VARIANTS)[number]

export const TAG_COLOR_SCHEMES = [
  "blue",
  "cyan",
  "gray",
  "green",
  "orange",
  "pink",
  "purple",
  "red",
  "teal",
  "yellow",
] as const

export type TagColorScheme = (typeof TAG_COLOR_SCHEMES)[number]

export type TagProps = {
  label: React.ReactNode
  variant?: TagVariant
  colorScheme?: TagColorScheme
  leftIcon?:
    | React.FC<IconProps>
    // this is to support Heroicons for edge cases, although we should avoid them
    | ((props: SVGProps<SVGSVGElement>) => JSX.Element)
  closeButtonRef?: Ref<HTMLButtonElement>
  onClose?: () => void
  isDisabled?: boolean
} & Pick<ComponentProps<"span">, "onClick" | "onFocus" | "onBlur">

const defaultSubtleColors = {
  container: {
    bg: "ds.background.neutral.resting",
    color: "ds.text.default",
  },
  closeButton: {
    color: "ds.icon.default",
  },
  leftIcon: {
    color: "ds.icon.subtle",
  },
}
const defaultBoldColors = {
  container: {
    bg: "ds.background.neutral.bold.resting",
    color: "ds.text.inverse",
  },
  closeButton: {
    color: "ds.icon.inverse",
  },
  leftIcon: {
    color: "ds.icon.inverse",
  },
}
const getSubtleColors = (colorScheme: TagProps["colorScheme"]) => ({
  bg: `ds.background.accent.${colorScheme}.subtle`,
  color: `ds.text.accent.${colorScheme}`,
})
const getBoldColors = (colorScheme: TagProps["colorScheme"]) => ({
  bg:
    colorScheme === "gray"
      ? "ds.background.accent.gray.bolder"
      : `ds.background.accent.${colorScheme}.bold`,
  color: "ds.text.inverse",
})

const getColors = ({
  colorScheme,
  variant = "subtle",
  isDisabled = false,
}: Pick<TagProps, "colorScheme" | "variant" | "isDisabled">) => {
  let colorSchemeStyles

  if (isDisabled) {
    return {
      container: {
        bg: "ds.background.disabled",
        color: "ds.text.disabled",
      },
      leftIcon: {
        color: "ds.icon.disabled",
      },
      closeButton: {
        color: "ds.icon.disabled",
        _hover: {
          color: "ds.icon.disabled",
        },
        _active: {
          color: "ds.icon.disabled",
        },
      },
    }
  }

  switch (variant) {
    case "bold":
      if (!colorScheme) return defaultBoldColors

      colorSchemeStyles = {
        container: getBoldColors(colorScheme),
      }
      return {
        ...defaultBoldColors,
        ...colorSchemeStyles,
      }

    case "subtle":
      if (!colorScheme) return defaultSubtleColors

      colorSchemeStyles = {
        container: getSubtleColors(colorScheme),
        closeButton: { color: `ds.icon.accent.${colorScheme}` },
        leftIcon: { color: `ds.icon.accent.${colorScheme}` },
      }
      return {
        ...defaultSubtleColors,
        ...colorSchemeStyles,
      }
  }

  assertNever(variant)
}

const dismissibleHoverStyles = {
  // Intentionally using a primitive here and below - designers prefer the
  // `ds.background.danger.subtle.resting` color for the hover state and the
  // `hovered` color for the active state, but we don't want to misuse semantic tokens
  bg: "red.50",
  color: "ds.text.danger",
  "& svg": {
    color: "ds.icon.danger",
  },
}
const dismissibleActiveStyles = {
  ...dismissibleHoverStyles,
  bg: "red.100",
}

/**
 * - Use `variant` and `colorScheme` to control appearance
 *   - `variant: "subtle" | "bold"`
 *   - `colorScheme: "blue" | "cyan" | "gray" | "green" | "orange" | "pink" | "purple" | "red" | "teal" | "yellow"`
 * - `leftIcon` should just be a reference: `leftIcon={SomeImportedIcon}`
 * - Passing an `onClose` function will render a close button
 */
export const Tag = forwardRef<TagProps, "span">(
  (
    {
      label,
      variant = "subtle",
      leftIcon,
      onClose,
      closeButtonRef,
      colorScheme,
      isDisabled,
      tabIndex,
      ...props
    },
    ref
  ) => {
    const colors = getColors({ variant, colorScheme, isDisabled })

    return (
      <ChakraTag
        ref={ref}
        size="sm"
        px={1.5}
        alignItems="center"
        rounded="full"
        flexShrink={0}
        {...colors.container}
        // We can't use textStyle as a normal prop
        // because some properties get overridden by Chakra
        {...tokens.textStyles.ds.interface.small}
        // When the close button (a child) is hovered or pressed,
        // the entire component should get these colors
        sx={{
          ...(!isDisabled && {
            "&:has([data-hover-target='close-button']:hover)":
              dismissibleHoverStyles,
            "&:has([data-hover-target='close-button']:active)":
              dismissibleActiveStyles,
            "&:has([data-hover-target='close-button']:focus)":
              dismissibleHoverStyles,
          }),
        }}
        {...props}
      >
        {leftIcon && (
          <ChakraTagLeftIcon
            me={0.75}
            as={leftIcon}
            boxSize={2.5}
            {...colors.leftIcon}
          />
        )}
        {label}
        {onClose && (
          <ChakraTagCloseButton
            ref={closeButtonRef}
            onClick={onClose}
            data-hover-target="close-button"
            alignItems="center"
            transition="none"
            ms={0.5}
            tabIndex={tabIndex ?? 0}
            isDisabled={isDisabled}
            cursor={isDisabled ? "not-allowed" : "pointer"}
            {...colors.closeButton}
            // Make the icon smaller without changing the size of the button's touch area
            sx={{
              svg: {
                boxSize: 3.5,
              },
            }}
          />
        )}
      </ChakraTag>
    )
  }
)

Tag.displayName = "Tag"
