import {
  ManagedPromise,
  createManagedPromise,
} from "Shared/helpers/createManagedPromise"
import { DependencyList, useMemo } from "react"

/**
 * Defers execution until previous call to the function has finished.
 * If multiple calls are made while the last call is still pending,
 * only the last call will be executed.
 */
export const useDebounceWhileLoading = <
  F extends (...args: any) => Promise<any>,
>(
  fn: F,
  options?: { dependencies?: DependencyList }
): ((...args: Parameters<F>) => ManagedPromise<ReturnType<F>>) => {
  return useMemo(() => {
    const state = { next: undefined, running: false } as {
      next: ManagedPromise<any> | undefined
      running: boolean
    }
    const callNext = () => {
      const promise = state.next
      if (state.running || !promise) {
        return
      }

      state.next = undefined
      state.running = true
      promise?.call().finally(() => {
        state.running = false
        callNext()
      })
    }

    return (...args: Parameters<F>) => {
      state.next?.cancel()
      const promise = createManagedPromise(() => fn(...args))
      state.next = promise
      callNext()

      return promise
    }
  }, options?.dependencies ?? [fn])
}
