import React from "react"
import { Helmet } from "react-helmet"

import { getEnvState } from "JavaScripts/state"
import { AppContext } from "Shared/contexts/AppContext"

export type UserCrowdProvider = React.FC<
  React.PropsWithChildren<{
    isSPA: boolean
  }>
>

export const UserCrowdProvider: UserCrowdProvider = ({ children, isSPA }) => {
  const env = getEnvState()
  const app = {
    isSPA,
    title: "UserCrowd",
    url: `http://${String(env.PANELIST_DOMAIN)}/`,
    consumer: null,
  }
  return (
    <AppContext.Provider value={app}>
      <Helmet titleTemplate={`${app.title} • %s`} />
      {children}
    </AppContext.Provider>
  )
}
