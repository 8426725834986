import { createIcon } from "@chakra-ui/react"
import React from "react"

export const Car02OutlineIcon = createIcon({
  displayName: "Car02OutlineIcon",
  viewBox: "0 0 24 24",
  defaultProps: { fill: "none" },
  path: (
    <path
      d="M19 10V6.2C19 5.0799 19 4.51984 18.782 4.09202C18.5903 3.71569 18.2843 3.40973 17.908 3.21799C17.4802 3 16.9201 3 15.8 3H8.2C7.07989 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V10M5 9H2V8M19 9H22V8M6 13.5H6.01M18 13.5H18.01M6.8 10H17.2C18.8802 10 19.7202 10 20.362 10.327C20.9265 10.6146 21.3854 11.0735 21.673 11.638C22 12.2798 22 13.1198 22 14.8V18C22 18.9319 22 19.3978 21.8478 19.7654C21.6448 20.2554 21.2554 20.6448 20.7654 20.8478C20.3978 21 19.9319 21 19 21H18.4C18.0284 21 17.8426 21 17.6871 20.9754C16.8313 20.8398 16.1602 20.1687 16.0246 19.3129C16 19.1574 16 18.9716 16 18.6C16 18.5071 16 18.4606 15.9938 18.4218C15.96 18.2078 15.7922 18.04 15.5782 18.0062C15.5394 18 15.4929 18 15.4 18H8.6C8.5071 18 8.46065 18 8.42178 18.0062C8.20784 18.04 8.04004 18.2078 8.00616 18.4218C8 18.4606 8 18.5071 8 18.6C8 18.9716 8 19.1574 7.97538 19.3129C7.83983 20.1687 7.16865 20.8398 6.31287 20.9754C6.1574 21 5.9716 21 5.6 21H5C4.06812 21 3.60218 21 3.23463 20.8478C2.74458 20.6448 2.35523 20.2554 2.15224 19.7654C2 19.3978 2 18.9319 2 18V14.8C2 13.1198 2 12.2798 2.32698 11.638C2.6146 11.0735 3.07354 10.6146 3.63803 10.327C4.27976 10 5.11984 10 6.8 10ZM6.5 13.5C6.5 13.7761 6.27614 14 6 14C5.72386 14 5.5 13.7761 5.5 13.5C5.5 13.2239 5.72386 13 6 13C6.27614 13 6.5 13.2239 6.5 13.5ZM18.5 13.5C18.5 13.7761 18.2761 14 18 14C17.7239 14 17.5 13.7761 17.5 13.5C17.5 13.2239 17.7239 13 18 13C18.2761 13 18.5 13.2239 18.5 13.5Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
})
