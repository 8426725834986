export const themeTokens = {
  brand: {
    primary: "purple",
    secondary: "purple",
    neutral: {
      light: "text.secondary",
      default: "text.primary",
    },
    background: {
      light: "#EBF3FA",
    },
  },
  text: {
    primary: "#343A40",
    secondary: "gray.500",
    inverted: "white",
    default: "#0F2938",
  },
  border: {
    default: "rgba(0, 0, 0, 0.08)",
    selected: "#0B978E",
    dark: "#6D8494",
  },

  transparent: "transparent",
  current: "currentColor",

  grey: {
    dark: "#97ABBA",
  },

  link: "#3182CE",
  helperText: "#627b93",

  alert: {
    info: {
      icon: "teal.500",
      background: "teal.50",
      text: "text.default",
      hover: "teal.700",
    },
    error: {
      icon: "#E83800",
      background: "#FFDCD6",
      text: "text.default",
      hover: "#9C4221",
    },
    success: {
      icon: "green.500",
      background: "green.50",
      text: "text.default",
      hover: "green.700",
    },
    warning: {
      icon: "#DD6B20",
      background: "#FFFAF0",
      text: "text.default",
      hover: "#992600",
    },
  },

  toast: {
    icon: "text.inverted",
    text: "text.inverted",
    info: "#068382",
    error: "#F05252",
    success: "#0E9F6E",
    warning: "#EF6820",
  },

  bg: {
    page: "#F7F9FB",
  },

  brandPalette: {
    neutral: {
      darkest: "#061D29",
    },
  },
}
