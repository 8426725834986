import { Box, Heading, Link, useBoolean } from "@chakra-ui/react"
import { debounce } from "lodash"
import React, { useCallback, useEffect, useRef, useState } from "react"

import { DisplayBlockMarkdownText } from "Components/display-markdown-text/display-markdown-text"
import { ModeratedStudySummary } from "UserCrowd/components/ModeratedStudy/ModeratedStudySummary"

import { useModeratedStudyApplicationContext } from "./ModeratedStudyApplicationContext"

const DESCRIPTION_MAX_LINES = 7
const LINE_HEIGHT = 24 //px

export function StudyOverviewPage() {
  const { application } = useModeratedStudyApplicationContext()

  return (
    <Box wordBreak="break-word" color="text.primary" fontSize="md">
      <Heading as="h1" fontSize="2xl" fontWeight="normal">
        {application.moderated_study.external_name}
      </Heading>
      <Box mt="5">
        <TruncatableMarkdownText
          text={application.moderated_study.description}
          maxLines={DESCRIPTION_MAX_LINES}
        />
      </Box>
      <Box mt="6">
        <ModeratedStudySummary applicationFlow={application} />
      </Box>
    </Box>
  )
}

type TruncatableMarkdownTextProps = {
  text: string
  maxLines: number
}
const TruncatableMarkdownText: React.FC<TruncatableMarkdownTextProps> = ({
  text,
  maxLines,
}) => {
  const textRef = useRef<HTMLParagraphElement>(null)
  const [shouldTruncate, setShouldTruncate] = useState(false)
  const [showFull, { toggle: toggleShowFull }] = useBoolean(false)
  const maxHeight = LINE_HEIGHT * maxLines

  const handleScroll = () => {
    if (!textRef.current) return

    const { scrollHeight, offsetHeight } = textRef.current
    setShouldTruncate(scrollHeight > offsetHeight)
  }
  const debouncedHandleScroll = useCallback(debounce(handleScroll, 100), [])

  useEffect(() => {
    const element = textRef.current
    if (!element) return

    element.addEventListener("scroll", debouncedHandleScroll)
    window.addEventListener("resize", debouncedHandleScroll)
    handleScroll()

    return () => {
      element.removeEventListener("scroll", debouncedHandleScroll)
      window.removeEventListener("resize", debouncedHandleScroll)
    }
  })
  return (
    <>
      <Box
        ref={textRef}
        maxH={showFull ? undefined : maxHeight}
        overflow="hidden"
      >
        <Box
          style={{ columnWidth: "100%" }}
          sx={{
            p: {
              marginBottom: `${LINE_HEIGHT}px`, // Needs to match line height for the truncation to be clean
            },
          }}
        >
          <DisplayBlockMarkdownText text={text} />
        </Box>
      </Box>
      {shouldTruncate || showFull ? (
        <Link variant="noUnderline" onClick={toggleShowFull} fontWeight="400">
          {showFull ? "See less" : "See more"}
        </Link>
      ) : null}
    </>
  )
}
