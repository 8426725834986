import type { ComponentMultiStyleConfig } from "@chakra-ui/react"

export const Progress: ComponentMultiStyleConfig = {
  parts: ["track"],
  sizes: {
    sm: {
      track: { h: "0.5rem" },
    },
    md: {
      track: { h: "0.75rem" },
    },
    lg: {
      track: { h: "1rem" },
    },
  },
  variants: {
    round: {
      track: {
        minW: "100px",
        w: "100%",
        borderRadius: "4px",
        bg: "gray.100",
      },
      filledTrack: {
        transition: "width 0.5s ease",
        borderRadius: "0",
      },
    },
  },
}
