import { createIcon } from "@chakra-ui/react"
import React from "react"

export const UserCrowdLogoIconLegacy = createIcon({
  displayName: "UserCrowdLogoIconLegacy",
  viewBox: "0 0 16 16",
  path: (
    <>
      <g id="usercrowd-logo">
        <path
          id="Rectangle Copy 5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.2549 0H13.7451C15.0281 0 16 1.18594 16 2.24327V11.3752C16 12.7168 14.8117 13.6185 13.7451 13.6185H10.7555C10.57 13.6185 10.394 13.6998 10.2747 13.8406L8.48548 15.7778C8.26278 16.0409 7.86701 16.0753 7.60152 15.8546C7.57374 15.8315 7.54803 15.8061 7.52468 15.7786L5.72825 13.8398C5.60902 13.6995 5.43333 13.6185 5.24818 13.6185H2.2549C1.01211 13.6185 0 12.7231 0 11.3752V2.24327C0 1.02852 1.02891 0 2.2549 0ZM2.64835 1C1.73799 1 1 1.73799 1 2.64835V11.0474C1 11.9577 1.73799 12.6957 2.64835 12.6957H5.06151C5.71111 12.6957 6.32878 12.9865 6.74967 13.4924L8.00399 15L9.25191 13.4952C9.67281 12.9876 10.2915 12.6957 10.9424 12.6957H13.3516C14.262 12.6957 15 11.9577 15 11.0474V2.64835C15 1.73799 14.262 1 13.3516 1H2.64835ZM6.33477 8H9.68513C9.88077 8 10.0217 8.19113 9.99724 8.38554C9.86446 9.32007 9.00038 10 7.99773 10C6.99506 10 6.13011 9.304 6.00282 8.38554C5.97837 8.19276 6.11468 8 6.33477 8ZM4 7C4.55228 7 5 6.55229 5 6C5 5.44771 4.55228 5 4 5C3.44772 5 3 5.44771 3 6C3 6.55229 3.44772 7 4 7ZM13 6C13 6.55229 12.5523 7 12 7C11.4477 7 11 6.55229 11 6C11 5.44771 11.4477 5 12 5C12.5523 5 13 5.44771 13 6Z"
          fill="#EE6B67"
        />
      </g>
    </>
  ),
})
