import { forwardRef } from "@chakra-ui/react"
import React, { ComponentProps } from "react"
import {
  Link as RouterLink,
  useLocation,
  useResolvedPath,
} from "react-router-dom"
import { Item, ItemProps } from "./Item"

type LinkProps = Omit<ItemProps, "isSelected"> &
  Pick<ComponentProps<typeof RouterLink>, "to"> & {
    exact?: boolean
  }

export const Link = forwardRef<LinkProps, "a">(
  ({ to, exact = false, children, ...props }, ref) => {
    const path = useResolvedPath(to)
    const location = useLocation()

    const isSelected = exact
      ? location.pathname === path.pathname
      : location.pathname.startsWith(path.pathname)

    return (
      <Item
        as={RouterLink}
        to={to}
        isSelected={isSelected}
        {...props}
        ref={ref}
      >
        {children}
      </Item>
    )
  }
)
