import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Link,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import React, { useCallback, useState } from "react"
import { Link as RouterLink } from "react-router-dom"

import { useParticipantCancelModeratedStudyBooking } from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"
import { ModeratedStudyApplicationFlow } from "~/api/generated/usabilityhubResponses"

interface Props {
  moderatedStudyApplication: ModeratedStudyApplicationFlow
  defaultOpen?: boolean
  isPanelist?: boolean
  isWithin12Hours?: boolean
}

export const CancelBooking: React.FC<Props> = ({
  moderatedStudyApplication,
  defaultOpen = false,
  isPanelist = false,
  isWithin12Hours = false,
}) => {
  const isPreview =
    moderatedStudyApplication.moderated_study_application_id === "preview"
  const queryClient = useQueryClient()
  const { queryKeyFn } = useUsabilityhubContext()

  const invalidateApplicationQuery = useCallback(() => {
    return queryClient.invalidateQueries(
      queryKeyFn({
        path: "/api/moderated_study_applications/{moderated_study_application_id}",
        operationId: "getModeratedStudyApplication",
        variables: {
          pathParams: {
            moderatedStudyApplicationId:
              moderatedStudyApplication.moderated_study_application_id,
          },
        },
      })
    )
  }, [
    moderatedStudyApplication.moderated_study_application_id,
    queryClient,
    queryKeyFn,
  ])

  const toast = useToast()
  const { mutate: participantCancelBooking } =
    useParticipantCancelModeratedStudyBooking({
      onSuccess: () => {
        setCanceling(false)
        setReason("")

        toast({
          title: "Your session has been canceled",
          status: "success",
        })

        return invalidateApplicationQuery()
      },
      onError: (error) => {
        toast({
          title:
            error.payload?.message ??
            "There was a problem canceling the session",
          status: "error",
        })
      },
    })

  const [canceling, setCanceling] = useState(defaultOpen)
  const [reason, setReason] = useState("")

  if (!moderatedStudyApplication.booking) return null

  const cancelBooking = () => {
    if (!moderatedStudyApplication.booking) return

    participantCancelBooking({
      body: {
        moderated_study_booking_id: moderatedStudyApplication.booking.id,
        reason: reason,
      },
      pathParams: {
        moderatedStudyApplicationId:
          moderatedStudyApplication.moderated_study_application_id,
      },
    })
  }

  return canceling ? (
    <Box>
      <Flex flexDirection="column" gap={4}>
        <FormControl>
          <FormLabel fontSize="md" fontWeight="medium" mb={3}>
            Please provide a reason for canceling your session
          </FormLabel>
          <Textarea
            autoFocus
            backgroundColor="white"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            placeholder="Enter reason here..."
          />
          {isPanelist || (
            <FormHelperText color="text.secondary">
              This will be sent to all attendees.
            </FormHelperText>
          )}
        </FormControl>
        {isPanelist && isWithin12Hours && (
          <Alert status="warning" fontSize="md">
            <AlertIcon />
            This session is within the next 12 hours. If you cancel this
            session, your eligibility for future interviews may be negatively
            impacted.
          </Alert>
        )}
        <Flex justify="flex-end" gap={2}>
          <Button variant="outline" onClick={() => setCanceling(false)}>
            Keep the session
          </Button>
          <Button
            colorScheme="red"
            onClick={cancelBooking}
            float="right"
            isDisabled={isPreview}
          >
            Cancel session
          </Button>
        </Flex>
      </Flex>
    </Box>
  ) : (
    <Box>
      <Text mb={1}>
        Need to make a change?{" "}
        {isPreview ? (
          "Reschedule"
        ) : (
          <Link
            as={RouterLink}
            to={`/applications/${moderatedStudyApplication.moderated_study_application_id}/reschedule`}
            color="brand.neutral.default"
            fontWeight="normal"
          >
            Reschedule
          </Link>
        )}
        {" or "}
        <Link
          color="brand.neutral.default"
          fontWeight="normal"
          onClick={() => setCanceling(true)}
        >
          Cancel
        </Link>
      </Text>
      <Text color="text.secondary" fontSize="sm">
        If you need to reschedule or cancel the session, kindly do so at least
        24 hours prior to the scheduled time.
      </Text>
    </Box>
  )
}
