import { Flex, FlexProps, Text } from "@chakra-ui/react"
import { Heading } from "DesignSystem/components"
import React from "react"

type Props = {
  readonly heading: string
  readonly subheading?: string
  readonly showBackButton?: boolean
  readonly isOnboarding?: boolean
} & FlexProps

export const TestersHeader = ({
  heading,
  subheading,
  showBackButton = true,
  isOnboarding = false,
  ...flexProps
}: Props) => (
  // TODO We should remove this mb={8} because it prevents using flex gap in consumers
  // (and page layout is a separate concern that does not belong in this component).
  // Currently it's used in 5 places, so we need to tidy those up first.
  // We're using flexProps to override the mb={8} on the dashboard but can remove it later.
  <Flex as="header" direction="column" mb={8} {...flexProps}>
    <Flex
      direction={["column", "row"]}
      justify="space-between"
      align={["flex-start", "center"]}
    >
      <Heading as="h1" textStyle="ds.display.emphasized">
        {heading}
      </Heading>
      {subheading && <Text>{subheading}</Text>}
    </Flex>
  </Flex>
)
