import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  HStack,
  Heading,
  VStack,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { AnimatePresence, motion } from "framer-motion"
import React, { useState } from "react"
import { useNavigate } from "react-router"
import { Link } from "react-router-dom"

import { useParticipantRescheduleModeratedStudyBooking } from "~/api/generated/usabilityhub-components"

import { TimezoneChanger } from "Shared/components/TimezoneChanger/TimezoneChanger"

import { add, isWithinInterval } from "date-fns"
import { BookingCalendar, calendarHeight } from "./BookingCalendar"
import { useModeratedStudyApplicationContext } from "./ModeratedStudyApplicationContext"
import { RescheduleBookingTimes } from "./RescheduleBookingTimes"

const MotionBox = motion(Box)
export function ModeratedStudyBookingReschedulePage() {
  const navigate = useNavigate()
  const toast = useToast()
  const queryClient = useQueryClient()

  const {
    application: moderatedStudyApplication,
    nextPageLink,
    invalidateApplicationQuery,
  } = useModeratedStudyApplicationContext()

  const {
    booking,
    panelist,
    moderated_study_application_id: applicationId,
  } = moderatedStudyApplication

  const [selectedSlot, setSelectedSlot] = useState<string | null>(null)

  const [timezone, setTimezone] = React.useState<string>(
    booking?.participant_timezone ??
      Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  const { mutateAsync: rescheduleBooking, isLoading: isBooking } =
    useParticipantRescheduleModeratedStudyBooking({
      onSuccess: invalidateApplicationQuery,
      onError: (error) => {
        toast({
          title:
            error.payload.message ??
            "There was a problem rescheduling the booking",
          status: "error",
        })

        // Invalidate _all_ calendar data to remove any slots that are no longer available
        return queryClient.invalidateQueries([
          "api",
          "moderated_study_applications",
          applicationId,
          "booking_slots",
        ])
      },
    })

  const startTime = selectedSlot

  const endTime = React.useMemo(() => {
    if (!startTime || !booking) return null

    const length =
      new Date(booking.ends_at).valueOf() -
      new Date(booking.starts_at).valueOf()

    const date = new Date(new Date(startTime).valueOf() + length)

    return date.toISOString()
  }, [startTime, booking])

  if (!booking) return null

  const different =
    timezone !== booking.participant_timezone || startTime !== booking.starts_at

  const submit = async () => {
    if (!startTime || !timezone || !nextPageLink) return

    await rescheduleBooking({
      pathParams: {
        moderatedStudyApplicationId: applicationId,
      },
      body: {
        starts_at: startTime,
        participant_timezone: timezone,
        moderated_study_booking_id: booking.id,
      },
    })

    navigate(nextPageLink)
  }

  const now = new Date()
  const startsAt = new Date(booking.starts_at)
  const isWithin12Hours = isWithinInterval(startsAt, {
    start: now,
    end: add(now, { hours: 12 }),
  })

  return (
    <AnimatePresence>
      <MotionBox
        width="min-content"
        initial={{ y: 200, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -200, opacity: 0 }}
        transition={{
          y: {
            type: "spring",
            stiffness: 600,
            damping: 32,
          },
        }}
      >
        {/* This is the min width of the BookingCalendar, and is to keep
        the headings from being squashed by the min-content rule above before
        the calendar has loaded */}
        <Box minW={["342px", null, "610px"]}>
          <Heading fontSize="2xl" fontWeight="normal">
            Pick a date and time
          </Heading>

          <Heading fontSize="md" fontWeight="normal">
            Let us know when you are available
          </Heading>
        </Box>
        <VStack mt={10} alignItems="stretch" gap={4}>
          <Grid
            templateColumns={["1fr", null, null, "1fr 236px"]}
            templateRows={["auto", null, null, `${calendarHeight}px auto`]}
            columnGap="30px"
            rowGap={10}
          >
            <BookingCalendar
              moderatedStudyApplicationId={
                moderatedStudyApplication.moderated_study_application_id
              }
              slot={selectedSlot}
              onSlotChange={setSelectedSlot}
              timezone={timezone}
              existingBookingId={booking.id}
              isFirstLoading={false}
            />

            <TimezoneChanger
              fontSize="md"
              lineHeight={6}
              fontWeight="medium"
              value={timezone}
              onChange={(e) => {
                setTimezone(e.target.value)
              }}
            />
          </Grid>
          <Divider marginBlock="4" borderColor="gray.300" />
          <Flex flexDirection="column" gap={4} fontSize="md">
            <RescheduleBookingTimes
              selectedSlot={selectedSlot}
              startTime={startTime}
              endTime={endTime}
              timezone={timezone}
              different={different}
              booking={booking}
            />
            {!!panelist && isWithin12Hours && (
              <Alert status="warning">
                <AlertIcon />
                This session is within the next 12 hours. Rescheduling may
                affect your eligibility for future interviews.
              </Alert>
            )}
            <HStack justifyContent="flex-end">
              {nextPageLink && (
                <Button as={Link} variant="outline" to={nextPageLink}>
                  Don{"\u2019"}t change
                </Button>
              )}
              <Button
                colorScheme="brand.primary"
                variant="solid"
                isLoading={isBooking}
                isDisabled={!different || !selectedSlot || undefined}
                onClick={submit}
              >
                Reschedule
              </Button>
            </HStack>
          </Flex>
        </VStack>
      </MotionBox>
    </AnimatePresence>
  )
}
