import { useEffect } from "react"

import { useListUsercrowdOrderAssignments } from "~/api/generated/usabilityhub-components"

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Favico = require("favico.js") as favicojs.FavicoJsStatic

const Favicon = new Favico({
  bgColor: "#329af0", // $bright-blue
  type: "rectangle",
  animation: "slide",
})

export const useUsercrowdFavicon = () => {
  const { data: assignments } = useListUsercrowdOrderAssignments({})
  const count = assignments
    ? assignments.panelist_order_assignments.length +
      assignments.moderated_study_order_assignments.length
    : 0

  useEffect(() => {
    if (count === 0) {
      Favicon.reset()
    } else {
      Favicon.badge(count)
    }
  }, [count])
}
