import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Text,
  useToast,
} from "@chakra-ui/react"
import { useLanguages } from "Hooks/use-languages"
import {
  OptionWithHelper,
  SelectWithOptionHelper,
} from "Shared/components/SelectWithOptionHelper/SelectWithOptionHelper"
import { Fluency, Language } from "Types"
import { partition } from "lodash"
import React, {
  Dispatch as ReactDispatch,
  SetStateAction,
  useState,
} from "react"
import {
  AdminDemographicProfileUpdateFluenciesError,
  AdminDemographicProfileUpdateFluenciesRequestBody,
  AdminDemographicProfileUpdateFluenciesResponse,
  DemographicProfileUpdateFluenciesError,
  DemographicProfileUpdateFluenciesRequestBody,
  DemographicProfileUpdateFluenciesResponse,
  useAdminDemographicProfileUpdateFluencies,
  useDemographicProfileUpdateFluencies,
} from "~/api/generated/usabilityhub-components"
import { RestrictedFluenciesAlert } from "./RestrictedFluenciesAlert"

type FluencyUpdate =
  | AdminDemographicProfileUpdateFluenciesRequestBody["fluencies"][number]
  | DemographicProfileUpdateFluenciesRequestBody["fluencies"][number]

interface LanguagesFieldProps {
  testerId: number
  initialFluencies: readonly Fluency[]
  setIsLanguageAnswered: ReactDispatch<SetStateAction<boolean>>
  isAdmin: boolean
}

const usePartitionedLanguages = (restrictedCodes: string[]) => {
  const languages = useLanguages()
  return partition(languages, (l) => !restrictedCodes.includes(l.code))
}

// A wrapper around the LanguagesFieldWithData component that makes sure the data is loaded first
// This avoids issues with the SelectWithOptionHelper component being initially rendered empty
// and then never updating.
export const LanguagesField: React.FC<
  React.PropsWithChildren<LanguagesFieldProps>
> = (props) => {
  const { testerId } = props

  const restrictedCodes = props.initialFluencies
    .filter((f) => f.updater_id !== testerId && !f.is_fluent)
    .map((f) => f.language_code)

  const [allowedLanguages, restrictedLanguages] =
    usePartitionedLanguages(restrictedCodes)

  const availableLanguages = props.isAdmin
    ? allowedLanguages.concat(restrictedLanguages)
    : allowedLanguages

  if (availableLanguages.length === 0) {
    return null
  }

  return (
    <LanguagesFieldWithData
      {...props}
      availableLanguages={availableLanguages}
      restrictedLanguages={restrictedLanguages}
    />
  )
}

const LanguagesFieldWithData: React.FC<
  React.PropsWithChildren<
    LanguagesFieldProps & {
      availableLanguages: Language[]
      restrictedLanguages: Language[]
    }
  >
> = ({
  testerId,
  initialFluencies,
  setIsLanguageAnswered,
  isAdmin,
  availableLanguages,
  restrictedLanguages,
}) => {
  const toast = useToast()

  const languageOptions: OptionWithHelper[] = availableLanguages.map(
    (language) => {
      return {
        label: language.english_name,
        value: language.code,
        helper: language.local_name,
      }
    }
  )

  const fluencies = initialFluencies
    .filter(({ is_fluent }) => is_fluent)
    .map(({ id, language_code }) => {
      return {
        id,
        language_code,
      }
    })

  const [currentLanguages, setCurrentLanguages] = useState(
    languageOptions.filter(({ value }) =>
      fluencies.find(({ language_code }) => language_code === value)
    )
  )
  const [savedFluencies, setSavedFluencies] = useState(initialFluencies)
  const [modified, setModified] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)

  const updateFluenciesSuccess = (
    data:
      | AdminDemographicProfileUpdateFluenciesResponse
      | DemographicProfileUpdateFluenciesResponse
  ) => {
    setIsLanguageAnswered(true)
    setSavedFluencies((oldFluencies) => {
      const ids = data.fluencies.map((f) => f.id)
      const old = oldFluencies.filter((f) => !ids.includes(f.id))
      return [...old, ...data.fluencies]
    })
  }

  const updateFluenciesError = (
    data:
      | DemographicProfileUpdateFluenciesError
      | AdminDemographicProfileUpdateFluenciesError
  ) => {
    toast({
      title:
        data.payload.message ||
        "Something went wrong. Please refresh the page and try again.",
      duration: null,
      status: "error",
    })

    setIsLanguageAnswered(false)

    // Reset selected languages back to the previously saved list
    setCurrentLanguages(
      languageOptions.filter(({ value }) =>
        savedFluencies.find(({ language_code }) => language_code === value)
      )
    )
  }

  const { mutate: updateFluencies, isLoading } =
    useDemographicProfileUpdateFluencies({
      onSuccess: updateFluenciesSuccess,
      onError: updateFluenciesError,
    })

  const { mutate: adminUpdateFluencies, isLoading: isAdminLoading } =
    useAdminDemographicProfileUpdateFluencies({
      onSuccess: updateFluenciesSuccess,
      onError: updateFluenciesError,
    })

  const onBlur = () => {
    if (!modified) {
      return
    }

    if (currentLanguages.length === 0) {
      setIsInvalid(true)
      setIsLanguageAnswered(false)
      return
    } else {
      setIsInvalid(false)
    }

    // All currently saved languages need to be included so we can update the
    // is_fluent flag if needed
    const nextFluencies: FluencyUpdate[] = savedFluencies.map(
      ({ id, language_code }) => {
        return {
          id,
          language_code,
          is_fluent: !!currentLanguages.find((l) => l.value === language_code),
        }
      }
    )

    // Next we add anything new from the currently selected languages
    currentLanguages.forEach(({ value }) => {
      if (!nextFluencies.find(({ language_code }) => language_code === value)) {
        nextFluencies.push({
          id: null,
          language_code: value,
          is_fluent: true,
        })
      }
    })

    if (isAdmin) {
      adminUpdateFluencies({
        pathParams: { id: testerId },
        body: { fluencies: nextFluencies },
      })
    } else {
      updateFluencies({
        body: { fluencies: nextFluencies },
      })
    }
  }

  const onChange = (languages: OptionWithHelper[]) => {
    setCurrentLanguages(languages)
    setModified(true)
  }

  return (
    <FormControl isInvalid={isInvalid} id="language">
      <FormLabel htmlFor="language">
        <Text color="ds.text.default" textStyle="ds.heading.primary">
          Which languages do you speak?
        </Text>
      </FormLabel>

      <FormHelperText
        color="ds.text.default"
        textStyle="ds.paragraph.primary"
        mb={3}
      >
        Please only enter languages you speak fluently.
      </FormHelperText>

      <SelectWithOptionHelper
        isMulti
        closeMenuOnSelect={false}
        selectedOptionStyle="check"
        hideSelectedOptions={false}
        isClearable={false}
        isDisabled={isLoading || isAdminLoading}
        options={languageOptions}
        value={currentLanguages}
        onChange={onChange}
        onBlur={onBlur}
      />

      {restrictedLanguages.length > 0 && (
        <Box mt={2}>
          <RestrictedFluenciesAlert languages={restrictedLanguages} />
        </Box>
      )}
    </FormControl>
  )
}
